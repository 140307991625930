import React from "react";
import { useNavigate } from "react-router-dom";

function GetBlog( ) {

    const navigate= useNavigate();

  // Your WhatsApp function
  function getBlog() {
    const url = `https://www.metrohealthhmo.com/post-list/`;
    window.open(url, "_blank");
  }

  React.useEffect(() => {
     getBlog();


    navigate("/dashboard/individual", { replace: true });
    
  }, []);

  return (
    <div>
    
    </div>
  );
}

export default GetBlog;