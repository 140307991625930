import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";


import mini from "../assets/img/mini.jpg";
import midi from "../assets/img/midi.jpg";
import maxi from "../assets/img/maxi.jpg";
import ppp from "../assets/img/ppp.jpg";
import PlanPurchaseModal from "../components/PlanPurchaseModal";

export default function ComparePlans() {


  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

      const [showPaymentModal,setShowPaymentModal] = React.useState(false);
      const [selectedPlan, setSelectedPlan] = useState(null);


  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const plans = [
  
    {
      id: 3,
      premium: 60000,
      img: mini,
      planName: 'METRO MINI',
      description: 'Coverage for essential medical services.',
      planType: 'RETAIL_STANDARD',
    },
    {
      id: 2,
      premium: 70000,
      img: midi,
      planName: 'METRO MIDI',
      description: 'Comprehensive coverage with additional benefits.',
      planType: 'RETAIL_STANDARD',
    },
    {
      id: 4,
      premium: 130000,
      img: maxi,
      planName: 'METRO MAXI',
      description: 'Premium Coverage for your medical needs.',
      planType: 'RETAIL_STANDARD',
    },
    {
      id: 5,
      premium: 400000,
      img: ppp,
      planName: 'METRO PPP',
      description: 'Topnotch Coverage for your Family',
      planType: 'RETAIL_STANDARD',
    },
  ];


    const handlePlanClick = (plan) => {
      setSelectedPlan(plan);
      setShowPaymentModal(true);
  };
  
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">My Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Compare our Health Plans</li>
            </ol>
          </div>

        </div>
       
        <h1 className="pricing-title mb-2">Compare our Health Plans.</h1>
        <br/>
        {/* <p className="pricing-text mb-4">The total price includes the item price and a buyer fee.</p> */}

        {/* <div className="d-flex gap-2">
          <Button variant="primary" className="fw-medium">Start Free Trial</Button>
          <Button variant="" className="btn-white fw-medium">Learn more</Button>
        </div> */}


        {
          showPaymentModal && (

            <PlanPurchaseModal
              plan={selectedPlan}
              onClose={() => setShowPaymentModal(false)}
            />
          )
        }

        <hr className="my-4 opacity-0" />

{
     <Table className="table-pricing" responsive>
  <thead>
    <tr>
      <th className="w-32"></th>
      <th className="w-17 selected">
        <span>MetroMini</span>
        <div className="pricing-price">MetroMini</div>
      </th>
      <th className="w-17">
        <span>MetroMidi</span>
        <div className="pricing-price">MetroMidi</div>
        <small>A discount of $30 a month if billed yearly</small>
      </th>
      <th className="w-17">
        <span>MetroMaxi</span>
        <div className="pricing-price">MetroMaxi</div>
        <small>A discount of 2% per month if billed yearly</small>
      </th>
      <th className="w-17">
        <span>MetroPPP</span>
        <div className="pricing-price">MetroPPP</div>
        <small>A discount of 2% per month if billed yearly</small>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr className="title">
      <td>Regular</td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>
        <p className="mb-1">consectetur adipiscing elit</p>
        <small>Et quasi architecto beatae vitae dicta sunt explicabo</small>
      </td>
      <td className="selected"><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td>Nemo enim ipsam voluptatem</td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td>dolores eos qui ratione</td>
      <td className="selected">&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td>sed quia consequuntur magni</td>
      <td className="selected"><span>0</span></td>
      <td><span>50</span></td>
      <td><span>300</span></td>
      <td><span>500</span></td>
    </tr>
    <tr>
      <td>exercitationem ullam</td>
      <td className="selected"><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td><div className="d-flex align-items-center gap-2">Duis aute irure dolor <i className="ri-information-line"></i></div></td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td><div className="d-flex align-items-center gap-2">voluptatem sequi <i className="ri-information-line"></i></div></td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td><div className="d-flex align-items-center gap-2">dignissimos <i className="ri-information-line"></i></div></td>
      <td className="selected"><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr className="title">
      <td>Extended</td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>blanditiis praesentium</td>
      <td className="selected">&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td>culpa qui officia deserunt</td>
      <td className="selected">&nbsp;</td>
      <td>&nbsp;</td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td><div className="d-flex align-items-center gap-2">optio cumque nihil impedit <i className="ri-information-line"></i></div></td>
      <td className="selected"><span>1</span></td>
      <td><span>10</span></td>
      <td><span>100</span></td>
      <td><span>1,000</span></td>
    </tr>
    <tr>
      <td>reiciendis voluptatibus maiores</td>
      <td className="selected"><span>1000</span></td>
      <td><span>1000</span></td>
      <td><span>1000</span></td>
      <td><span>custom</span></td>
    </tr>
    <tr>
      <td>alias consequatur</td>
      <td className="selected"><span>1GB</span></td>
      <td><span>10GB</span></td>
      <td><span>100GB</span></td>
      <td><span>100GB</span></td>
    </tr>
    <tr>
      <td><div className="d-flex align-items-center gap-2">maxime placeat facere <i className="ri-information-line"></i></div></td>
      <td className="selected"><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
      <td><i className="ri-check-fill"></i></td>
    </tr>
    <tr>
      <td>&nbsp;</td>
      <td className="selected"><Button onClick={() => handlePlanClick(plans[0])}variant="primary">Choose Plan</Button></td>
      <td><Button onClick={() => handlePlanClick(plans[1])}variant="primary">Choose Plan</Button></td>
      <td><Button onClick={() => handlePlanClick(plans[2])}variant="primary">Choose Plan</Button></td>
      <td><Button onClick={() => handlePlanClick(plans[3])}variant="primary">Choose Plan</Button></td>
    </tr>
  </tbody>
</Table> }


        <Footer />
      </div>
    </React.Fragment>
  )
}