import React from "react";
import { useNavigate } from "react-router-dom";

function ReportDelay( ) {

    const navigate= useNavigate();

  // Your WhatsApp function
  function openWhatsAppWithMessage(number, message) {
    const url = `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  }

  React.useEffect(() => {
    openWhatsAppWithMessage("+2349091546863", "DELAY");

    navigate("/dashboard/individual", { replace: true });
    
  }, []);

  return (
    <div>
    
    </div>
  );
}

export default ReportDelay;
