const lgaList = {
  Abia: [
    "Aba North",
    "Aba South",
    "Arochukwu",
    "Bende",
    "Ikwuano",
    "Isiala Ngwa North",
    "Isiala Ngwa South",
    "Isuikwuato",
    "Obi Ngwa",
    "Ohafia",
    "Osisioma",
    "Ugwunagbo",
    "Ukwa East",
    "Ukwa West",
    "Umuahia North",
    "muahia South",
    "Umu Nneochi",
  ],
  Adamawa: [
    "Demsa",
    "Fufure",
    "Ganye",
    "Gayuk",
    "Gombi",
    "Grie",
    "Hong",
    "Jada",
    "Larmurde",
    "Madagali",
    "Maiha",
    "Mayo Belwa",
    "Michika",
    "Mubi North",
    "Mubi South",
    "Numan",
    "Shelleng",
    "Song",
    "Toungo",
    "Yola North",
    "Yola South",
  ],
  AkwaIbom: [
    "Abak",
    "Eastern Obolo",
    "Eket",
    "Esit Eket",
    "Essien Udim",
    "Etim Ekpo",
    "Etinan",
    "Ibeno",
    "Ibesikpo Asutan",
    "Ibiono-Ibom",
    "Ika",
    "Ikono",
    "Ikot Abasi",
    "Ikot Ekpene",
    "Ini",
    "Itu",
    "Mbo",
    "Mkpat-Enin",
    "Nsit-Atai",
    "Nsit-Ibom",
    "Nsit-Ubium",
    "Obot Akara",
    "Okobo",
    "Onna",
    "Oron",
    "Oruk Anam",
    "Udung-Uko",
    "Ukanafun",
    "Uruan",
    "Urue-Offong Oruko",
    "Uyo",
  ],
  Anambra: [
    "Aguata",
    "Anambra East",
    "Anambra West",
    "Anaocha",
    "Awka North",
    "Awka South",
    "Ayamelum",
    "Dunukofia",
    "Ekwusigo",
    "Idemili North",
    "Idemili South",
    "Ihiala",
    "Njikoka",
    "Nnewi North",
    "Nnewi South",
    "Ogbaru",
    "Onitsha North",
    "Onitsha South",
    "Orumba North",
    "Orumba South",
    "Oyi",
  ],

  Anambra: [
    "Aguata",
    "Anambra East",
    "Anambra West",
    "Anaocha",
    "Awka North",
    "Awka South",
    "Ayamelum",
    "Dunukofia",
    "Ekwusigo",
    "Idemili North",
    "Idemili South",
    "Ihiala",
    "Njikoka",
    "Nnewi North",
    "Nnewi South",
    "Ogbaru",
    "Onitsha North",
    "Onitsha South",
    "Orumba North",
    "Orumba South",
    "Oyi",
  ],
  Bauchi: [
    "Alkaleri",
    "Bauchi",
    "Bogoro",
    "Damban",
    "Darazo",
    "Dass",
    "Gamawa",
    "Ganjuwa",
    "Giade",
    "Itas-Gadau",
    "Jama are",
    "Katagum",
    "Kirfi",
    "Misau",
    "Ningi",
    "Shira",
    "Tafawa Balewa",
    " Toro",
    " Warji",
    " Zaki",
  ],

  Bayelsa: [
    "Brass",
    "Ekeremor",
    "Kolokuma Opokuma",
    "Nembe",
    "Ogbia",
    "Sagbama",
    "Southern Ijaw",
    "Yenagoa",
  ],
  Benue: [
    "Agatu",
    "Apa",
    "Ado",
    "Buruku",
    "Gboko",
    "Guma",
    "Gwer East",
    "Gwer West",
    "Katsina-Ala",
    "Konshisha",
    "Kwande",
    "Logo",
    "Makurdi",
    "Obi",
    "Ogbadibo",
    "Ohimini",
    "Oju",
    "Okpokwu",
    "Oturkpo",
    "Tarka",
    "Ukum",
    "Ushongo",
    "Vandeikya",
  ],
  Borno: [
    "Abadam",
    "Askira-Uba",
    "Bama",
    "Bayo",
    "Biu",
    "Chibok",
    "Damboa",
    "Dikwa",
    "Gubio",
    "Guzamala",
    "Gwoza",
    "Hawul",
    "Jere",
    "Kaga",
    "Kala-Balge",
    "Konduga",
    "Kukawa",
    "Kwaya Kusar",
    "Mafa",
    "Magumeri",
    "Maiduguri",
    "Marte",
    "Mobbar",
    "Monguno",
    "Ngala",
    "Nganzai",
    "Shani",
  ],
  "Cross River": [
    "Abi",
    "Akamkpa",
    "Akpabuyo",
    "Bakassi",
    "Bekwarra",
    "Biase",
    "Boki",
    "Calabar Municipal",
    "Calabar South",
    "Etung",
    "Ikom",
    "Obanliku",
    "Obubra",
    "Obudu",
    "Odukpani",
    "Ogoja",
    "Yakuur",
    "Yala",
  ],

  Delta: [
    "Aniocha North",
    "Aniocha South",
    "Bomadi",
    "Burutu",
    "Ethiope East",
    "Ethiope West",
    "Ika North East",
    "Ika South",
    "Isoko North",
    "Isoko South",
    "Ndokwa East",
    "Ndokwa West",
    "Okpe",
    "Oshimili North",
    "Oshimili South",
    "Patani",
    "Sapele",
    "Udu",
    "Ughelli North",
    "Ughelli South",
    "Ukwuani",
    "Uvwie",
    "Warri North",
    "Warri South",
    "Warri South West",
  ],

  Ebonyi: [
    "Abakaliki",
    "Afikpo North",
    "Afikpo South",
    "Ebonyi",
    "Ezza North",
    "Ezza South",
    "Ikwo",
    "Ishielu",
    "Ivo",
    "Izzi",
    "Ohaozara",
    "Ohaukwu",
    "Onicha",
  ],
  Edo: [
    "Akoko-Edo",
    "Egor",
    "Esan Central",
    "Esan North-East",
    "Esan South-East",
    "Esan West",
    "Etsako Central",
    "Etsako East",
    "Etsako West",
    "Igueben",
    "Ikpoba Okha",
    "Orhionmwon",
    "Oredo",
    "Ovia North-East",
    "Ovia South-West",
    "Owan East",
    "Owan West",
    "Uhunmwonde",
  ],

  Ekiti: [
    "Ado Ekiti",
    "Efon",
    "Ekiti East",
    "Ekiti South-West",
    "Ekiti West",
    "Emure",
    "Gbonyin",
    "Ido Osi",
    "Ijero",
    "Ikere",
    "Ikole",
    "Ilejemeje",
    "Irepodun-Ifelodun",
    "Ise-Orun",
    "Moba",
    "Oye",
  ],
  Rivers: [
    "Port Harcourt",
    "Obio-Akpor",
    "Okrika",
    "Ogu–Bolo",
    "Eleme",
    "Tai",
    "Gokana",
    "Khana",
    "Oyigbo",
    "Opobo–Nkoro",
    "Andoni",
    "Bonny",
    "Degema",
    "Asari-Toru",
    "Akuku-Toru",
    "Abua–Odual",
    "Ahoada West",
    "Ahoada East",
    "Ogba–Egbema–Ndoni",
    "Emohua",
    "Ikwerre",
    "Etche",
    "Omuma",
  ],
  Enugu: [
    "Aninri",
    "Awgu",
    "Enugu East",
    "Enugu North",
    "Enugu South",
    "Ezeagu",
    "Igbo Etiti",
    "Igbo Eze North",
    "Igbo Eze South",
    "Isi Uzo",
    "Nkanu East",
    "Nkanu West",
    "Nsukka",
    "Oji River",
    "Udenu",
    "Udi",
    "Uzo Uwani",
  ],
  FCT: [
    "Abaji",
    "Bwari",
    "Gwagwalada",
    "Kuje",
    "Kwali",
    "Municipal Area Council",
  ],
  Gombe: [
    "Akko",
    "Balanga",
    "Billiri",
    "Dukku",
    "Funakaye",
    "Gombe",
    "Kaltungo",
    "Kwami",
    "Nafada",
    "Shongom",
    "Yamaltu-Deba",
  ],
  Imo: [
    "Aboh Mbaise",
    "Ahiazu Mbaise",
    "Ehime Mbano",
    "Ezinihitte",
    "Ideato North",
    "Ideato South",
    "Ihitte-Uboma",
    "Ikeduru",
    "Isiala Mbano",
    "Isu",
    "Mbaitoli",
    "Ngor Okpala",
    "Njaba",
    "Nkwerre",
    "Nwangele",
    "Obowo",
    "Oguta",
    "Ohaji-Egbema",
    "Okigwe",
    "Orlu",
    "Orsu",
    "Oru East",
    "Oru West",
    "Owerri Municipal",
    "Owerri North",
    "Owerri West",
    "Unuimo",
  ],
  Jigawa: [
    "Auyo",
    "Babura",
    "Biriniwa",
    "Birnin Kudu",
    "Buji",
    "Dutse",
    "Gagarawa",
    "Garki",
    "Gumel",
    "Guri",
    "Gwaram",
    "Gwiwa",
    "Hadejia",
    "Jahun",
    "Kafin Hausa",
    "Kazaure",
    "Kiri Kasama",
    "Kiyawa",
    "Kaugama",
    "Maigatari",
    "Malam Madori",
    "Miga",
    "Ringim",
    "Roni",
    "Sule Tankarkar",
    "Taura",
    "Yankwashi",
  ],
  Kaduna: [
    "Birnin Gwari",
    "Chikun",
    "Giwa",
    "Igabi",
    "Ikara",
    "Jaba",
    "Jema a",
    "Kachia",
    "Kaduna North",
    "Kaduna South",
    "Kagarko",
    "Kajuru",
    "Kaura",
    "Kauru",
    "Kubau",
    "Kudan",
    "Lere",
    "Makarfi",
    "Sabon Gari",
    "Sanga",
    "Soba",
    "Zangon Kataf",
    "Zaria",
  ],
  Kano: [
    "Ajingi",
    "Albasu",
    "Bagwai",
    "Bebeji",
    "Bichi",
    "Bunkure",
    "Dala",
    "Dambatta",
    "Dawakin Kudu",
    "Dawakin Tofa",
    "Doguwa",
    "Fagge",
    "Gabasawa",
    "Garko",
    "Garun Mallam",
    "Gaya",
    "Gezawa",
    "Gwale",
    "Gwarzo",
    "Kabo",
    "Kano Municipal",
    "Karaye",
    "Kibiya",
    "Kiru",
    "Kumbotso",
    "Kunchi",
    "Kura",
    "Madobi",
    "Makoda",
    "Minjibir",
    "Nasarawa",
    "Rano",
    "Rimin Gado",
    "Rogo",
    "Shanono",
    "Sumaila",
    "Takai",
    "Tarauni",
    "Tofa",
    "Tsanyawa",
    "Tudun Wada",
    "Ungogo",
    "Warawa",
    "Wudil",
  ],
  Katsina: [
    "Bakori",
    "Batagarawa",
    "Batsari",
    "Baure",
    "Bindawa",
    "Charanchi",
    "Dandume",
    "Danja",
    "Dan Musa",
    "Daura",
    "Dutsi",
    "Dutsin Ma",
    "Faskari",
    "Funtua",
    "Ingawa",
    "Jibia",
    "Kafur",
    "Kaita",
    "Kankara",
    "Kankia",
    "Katsina",
    "Kurfi",
    "Kusada",
    "Mai Adua",
    "Malumfashi",
    "Mani",
    "Mashi",
    "Matazu",
    "Musawa",
    "Rimi",
    "Sabuwa",
    "Safana",
    "Sandamu",
    "Zango",
  ],
  Kebbi: [
    "Aleiro",
    "Arewa Dandi",
    "Argungu",
    "Augie",
    "Bagudo",
    "Birnin Kebbi",
    "Bunza",
    "Dandi",
    "Fakai",
    "Gwandu",
    "Jega",
    "Kalgo",
    "Koko Besse",
    "Maiyama",
    "Ngaski",
    "Sakaba",
    "Shanga",
    "Suru",
    "Wasagu Danko",
    "Yauri",
    "Zuru",
  ],
  Kogi: [
    "Adavi",
    "Ajaokuta",
    "Ankpa",
    "Bassa",
    "Dekina",
    "Ibaji",
    "Idah",
    "Igalamela Odolu",
    "Ijumu",
    "Kabba Bunu",
    "Kogi",
    "Lokoja",
    "Mopa Muro",
    "Ofu",
    "Ogori Magongo",
    "Okehi",
    "Okene",
    "Olamaboro",
    "Omala",
    "Yagba East",
    "Yagba West",
  ],
  Kwara: [
    "Asa",
    "Baruten",
    "Edu",
    "Ekiti",
    "Ifelodun",
    "Ilorin East",
    "Ilorin South",
    "Ilorin West",
    "Irepodun",
    "Isin",
    "Kaiama",
    "Moro",
    "Offa",
    "Oke Ero",
    "Oyun",
    "Pategi",
  ],
  Lagos: [
    "Agege",
    "Ajeromi-Ifelodun",
    "Alimosho",
    "Amuwo-Odofin",
    "Apapa",
    "Badagry",
    "Epe",
    "Eti Osa",
    "Ibeju-Lekki",
    "Ifako-Ijaiye",
    "Ikeja",
    "Ikorodu",
    "Kosofe",
    "Lagos Island",
    "Lagos Mainland",
    "Mushin",
    "Ojo",
    "Oshodi-Isolo",
    "Shomolu",
    "Surulere",
  ],
  Nasarawa: [
    "Akwanga",
    "Awe",
    "Doma",
    "Karu",
    "Keana",
    "Keffi",
    "Kokona",
    "Lafia",
    "Nasarawa",
    "Nasarawa Egon",
    "Obi",
    "Toto",
    "Wamba",
  ],
  Niger: [
    "Agaie",
    "Agwara",
    "Bida",
    "Borgu",
    "Bosso",
    "Chanchaga",
    "Edati",
    "Gbako",
    "Gurara",
    "Katcha",
    "Kontagora",
    "Lapai",
    "Lavun",
    "Magama",
    "Mariga",
    "Mashegu",
    "Mokwa",
    "Moya",
    "Paikoro",
    "Rafi",
    "Rijau",
    "Shiroro",
    "Suleja",
    "Tafa",
    "Wushishi",
  ],
  Ogun: [
    "Abeokuta North",
    "Abeokuta South",
    "Ado-Odo Ota",
    "Egbado North",
    "Egbado South",
    "Ewekoro",
    "Ifo",
    "Ijebu East",
    "Ijebu North",
    "Ijebu North East",
    "Ijebu Ode",
    "Ikenne",
    "Imeko Afon",
    "Ipokia",
    "Obafemi Owode",
    "Odeda",
    "Odogbolu",
    "Ogun Waterside",
    "Remo North",
    "Shagamu",
  ],
  Ondo: [
    "Akoko North-East",
    "Akoko North-West",
    "Akoko South-West",
    "Akoko South-East",
    "Akure North",
    "Akure South",
    "Ese Odo",
    "Idanre",
    "Ifedore",
    "Ilaje",
    "Ile Oluji-Okeigbo",
    "Irele",
    "Odigbo",
    "Okitipupa",
    "Ondo East",
    "Ondo West",
    "Ose",
    "Owo",
  ],
  Osun: [
    "Atakunmosa East",
    "Atakunmosa West",
    "Aiyedaade",
    "Aiyedire",
    "Boluwaduro",
    "Boripe",
    "Ede North",
    "Ede South",
    "Ife Central",
    "Ife East",
    "Ife North",
    "Ife South",
    "Egbedore",
    "Ejigbo",
    "Ifedayo",
    "Ifelodun",
    "Ila",
    "Ilesa East",
    "Ilesa West",
    "Irepodun",
    "Irewole",
    "Isokan",
    "Iwo",
    "Obokun",
    "Odo Otin",
    "Ola Oluwa",
    "Olorunda",
    "Oriade",
    "Orolu",
    "Osogbo",
  ],
  Oyo: [
    "Afijio",
    "Akinyele",
    "Atiba",
    "Atisbo",
    "Egbeda",
    "Ibadan North",
    "Ibadan North-East",
    "Ibadan North-West",
    "Ibadan South-East",
    "Ibadan South-West",
    "Ibarapa Central",
    "Ibarapa East",
    "Ibarapa North",
    "Ido",
    "Irepo",
    "Iseyin",
    "Itesiwaju",
    "Iwajowa",
    "Kajola",
    "Lagelu",
    "Ogbomosho North",
    "Ogbomosho South",
    "Ogo Oluwa",
    "Olorunsogo",
    "Oluyole",
    "Ona Ara",
    "Orelope",
    "Ori Ire",
    "Oyo",
    "Oyo East",
    "Saki East",
    "Saki West",
    "Surulere",
  ],
  Plateau: [
    "Bokkos",
    "Barkin Ladi",
    "Bassa",
    "Jos East",
    "Jos North",
    "Jos South",
    "Kanam",
    "Kanke",
    "Langtang South",
    "Langtang North",
    "Mangu",
    "Mikang",
    "Pankshin",
    "Qua an Pan",
    "Riyom",
    "Shendam",
    "Wase",
  ],
  Sokoto: [
    "Binji",
    "Bodinga",
    "Dange Shuni",
    "Gada",
    "Goronyo",
    "Gudu",
    "Gwadabawa",
    "Illela",
    "Isa",
    "Kebbe",
    "Kware",
    "Rabah",
    "Sabon Birni",
    "Shagari",
    "Silame",
    "Sokoto North",
    "Sokoto South",
    "Tambuwal",
    "Tangaza",
    "Tureta",
    "Wamako",
    "Wurno",
    "Yabo",
  ],
  Taraba: [
    "Ardo Kola",
    "Bali",
    "Donga",
    "Gashaka",
    "Gassol",
    "Ibi",
    "Jalingo",
    "Karim Lamido",
    "Kumi",
    "Lau",
    "Sardauna",
    "Takum",
    "Ussa",
    "Wukari",
    "Yorro",
    "Zing",
  ],
  Yobe: [
    "Bade",
    "Bursari",
    "Damaturu",
    "Fika",
    "Fune",
    "Geidam",
    "Gujba",
    "Gulani",
    "Jakusko",
    "Karasuwa",
    "Machina",
    "Nangere",
    "Nguru",
    "Potiskum",
    "Tarmuwa",
    "Yunusari",
    "Yusufari",
  ],
  Zamfara: [
    "Anka",
    "Bakura",
    "Birnin Magaji Kiyaw",
    "Bukkuyum",
    "Bungudu",
    "Gummi",
    "Gusau",
    "Kaura Namoda",
    "Maradun",
    "Maru",
    "Shinkafi",
    "Talata Mafara",
    "Chafe",
    "Zurmi",
  ],
};
const stateOptions = [
  { value: "Abia", label: "Abia" },
  { value: "Adamawa", label: "Adamawa" },
  { value: "Akwa-ibom", label: "Akwa Ibom" },
  { value: "Anambra", label: "Anambra" },
  { value: "Bauchi", label: "Bauchi" },
  { value: "Bayelsa", label: "Bayelsa" },
  { value: "Benue", label: "Benue" },
  { value: "Borno", label: "Borno" },
  { value: "Cross-river", label: "Cross River" },
  { value: "Delta", label: "Delta" },
  { value: "Ebonyi", label: "Ebonyi" },
  { value: "Edo", label: "Edo" },
  { value: "Ekiti", label: "Ekiti" },
  { value: "Enugu", label: "Enugu" },
  { value: "Gombe", label: "Gombe" },
  { value: "Imo", label: "Imo" },
  { value: "jigawa", label: "Jigawa" },
  { value: "Kaduna", label: "Kaduna" },
  { value: "Kano", label: "Kano" },
  { value: "Katsina", label: "Katsina" },
  { value: "kebbi", label: "Kebbi" },
  { value: "kogi", label: "Kogi" },
  { value: "kwara", label: "Kwara" },
  { value: "lagos", label: "Lagos" },
  { value: "nasarawa", label: "Nasarawa" },
  { value: "niger", label: "Niger" },
  { value: "ogun", label: "Ogun" },
  { value: "ondo", label: "Ondo" },
  { value: "osun", label: "Osun" },
  { value: "oyo", label: "Oyo" },
  { value: "plateau", label: "Plateau" },
  { value: "rivers", label: "Rivers" },
  { value: "sokoto", label: "Sokoto" },
  { value: "taraba", label: "Taraba" },
  { value: "yobe", label: "Yobe" },
  { value: "zamfara", label: "Zamfara" },
];


const statesWithLgas = [
  {
    value: '3',
    state: 'Abia',
    lgas: [
      { value: '0', label: 'Choose One' },
      { value: '20', label: 'Umu-Neochi' },
      { value: '3', label: 'Aba North' },
      { value: '4', label: 'Aba South' },
      { value: '5', label: 'Arochukwu' },
      { value: '6', label: 'Bende' },
      { value: '7', label: 'Ikwuano' },
      { value: '8', label: 'Isiala-Ngwa North' },
      { value: '9', label: 'Isiala-Ngwa South' },
      { value: '10', label: 'Isuikwato' },
      { value: '14', label: 'Ngwa' },
      { value: '11', label: 'Obi Nwa' },
      { value: '12', label: 'Ohafia' },
      { value: '13', label: 'Osisioma' },
      { value: '15', label: 'Ugwunagbo' },
      { value: '16', label: 'Ukwa East' },
      { value: '17', label: 'Ukwa West' },
      { value: '18', label: 'Umuahia North' },
      { value: '19', label: 'Umuahia South' },
      { value: '779', label: 'Umu-Neochi Umuahia' },
    ]
  },
  {
    value: '4',
    state: 'Adamawa',
    lgas: [
      { value: '21', label: 'Demsa' },
      { value: '22', label: 'Fufore' },
      { value: '23', label: 'Ganaye' },
      { value: '24', label: 'Gireri' },
      { value: '25', label: 'Gombi' },
      { value: '26', label: 'Guyuk' },
      { value: '27', label: 'Hong' },
      { value: '28', label: 'Jada' },
      { value: '29', label: 'Lamurde' },
      { value: '30', label: 'Madagali' },
      { value: '31', label: 'Maiha' },
      { value: '32', label: 'Mayo-Belwa' },
      { value: '33', label: 'Michika' },
      { value: '34', label: 'Mubi North' },
      { value: '35', label: 'Mubi South' },
      { value: '36', label: 'Numan' },
      { value: '37', label: 'Shelleng' },
      { value: '38', label: 'Song' },
      { value: '39', label: 'Toungo' },
      { value: '40', label: 'Yola North' },
      { value: '41', label: 'Yola South' },
    ]
    
    // This array includes options for the LGAs without the "Choose One" optio,
  },
  {
    value: '5',
    state: 'Akwa Ibom',
    lgas: [
      { value: '42', label: 'Abak' },
      { value: '43', label: 'Eastern Obolo' },
      { value: '44', label: 'Eket' },
      { value: '45', label: 'Esit Eket' },
      { value: '46', label: 'Essien Udim' },
      { value: '47', label: 'Etim Ekpo' },
      { value: '48', label: 'Etinan' },
      { value: '49', label: 'Ibeno' },
      { value: '50', label: 'Ibesikpo Asutan' },
      { value: '51', label: 'Ibiono Ibom' },
      { value: '52', label: 'Ika' },
      { value: '53', label: 'Ikono' },
      { value: '54', label: 'Ikot Abasi' },
      { value: '55', label: 'Ikot Ekpene' },
      { value: '56', label: 'Ini' },
      { value: '57', label: 'Itu' },
      { value: '58', label: 'Mbo' },
      { value: '59', label: 'Mkpat Enin' },
      { value: '60', label: 'Nsit Atai' },
      { value: '61', label: 'Nsit Ibom' },
      { value: '62', label: 'Nsit Ubium' },
      { value: '63', label: 'Obot Akara' },
      { value: '64', label: 'Okobo' },
      { value: '65', label: 'Onna' },
      { value: '66', label: 'Oron' },
      { value: '67', label: 'Oruk Anam' },
      { value: '68', label: 'Udung Uko' },
      { value: '69', label: 'Ukanafun' },
      { value: '70', label: 'Uruan' },
      { value: '71', label: 'Urue-Offong/Oruko' },
      { value: '72', label: 'Uyo' },
    ],
  },
  {
    value: '6',
    state: 'Anambra',
    lgas: [
      { value: '73', label: 'Aguata' },
      { value: '74', label: 'AnambraEast' },
      { value: '75', label: 'AnambraWest' },
      { value: '76', label: 'Anaocha' },
      { value: '77', label: 'AwkaNorth' },
      { value: '78', label: 'AwkaSouth' },
      { value: '79', label: 'Ayamelum' },
      { value: '80', label: 'Dunukofia' },
      { value: '81', label: 'Ekwusigo' },
      { value: '82', label: 'IdemiliNorth' },
      { value: '83', label: 'Idemilisouth' },
      { value: '84', label: 'Ihiala' },
      { value: '85', label: 'Njikoka' },
      { value: '86', label: 'NnewiNorth' },
      { value: '87', label: 'NnewiSouth' },
      { value: '88', label: 'Ogbaru' },
      { value: '89', label: 'OnitshaNorth' },
      { value: '90', label: 'OnitshaSouth' },
      { value: '91', label: 'OrumbaNorth' },
      { value: '92', label: 'OrumbaSouth' },
      { value: '93', label: 'Oyi' }
    ],
  },
  {
    value: '7',
    state: 'Bauchi',
    lgas: [ { value: '94', label: 'Alkaleri' },
    { value: '95', label: 'Bauchi' },
    { value: '96', label: 'Bogoro' },
    { value: '97', label: 'Damban' },
    { value: '98', label: 'Darazo' },
    { value: '99', label: 'Dass' },
    { value: '100', label: 'Ganjuwa' },
    { value: '101', label: 'Giade' },
    { value: '102', label: 'Itas/Gadau' },
    { value: '103', label: 'Jama\'are' },
    { value: '104', label: 'Katagum' },
    { value: '105', label: 'Kirfi' },
    { value: '106', label: 'Misau' },
    { value: '107', label: 'Ningi' },
    { value: '108', label: 'Shira' },
    { value: '109', label: 'Tafawa-Balewa' },
    { value: '110', label: 'Toro' },
    { value: '111', label: 'Warji' },
    { value: '112', label: 'Zaki' }],
  },
  {
    value: '8',
    state: 'Bayelsa',
    lgas: [  { value: '113', label: 'Brass' },
    { value: '114', label: 'Ekeremor' },
    { value: '115', label: 'Kolokuma/Opokuma' },
    { value: '116', label: 'Nembe' },
    { value: '117', label: 'Ogbia' },
    { value: '118', label: 'Sagbama' },
    { value: '119', label: 'SouthernJaw' },
    { value: '120', label: 'Yenegoa' }],
  },
  {
    value: '9',
    state: 'Benue',
    lgas: [  { value: '121', label: 'Ado' },
    { value: '122', label: 'Agatu' },
    { value: '123', label: 'Apa' },
    { value: '124', label: 'Buruku' },
    { value: '125', label: 'Gboko' },
    { value: '126', label: 'Guma' },
    { value: '127', label: 'GwerEast' },
    { value: '128', label: 'GwerWest' },
    { value: '129', label: 'Katsina-Ala' },
    { value: '130', label: 'Konshisha' },
    { value: '131', label: 'Kwande' },
    { value: '132', label: 'Logo' },
    { value: '133', label: 'Makurdi' },
    { value: '134', label: 'Obi' },
    { value: '135', label: 'Ogbadibo' },
    { value: '136', label: 'Oju' },
    { value: '137', label: 'Okpokwu' },
    { value: '138', label: 'Ohimini' },
    { value: '139', label: 'Oturkpo' },
    { value: '140', label: 'Tarka' },
    { value: '141', label: 'Ukum' },
    { value: '142', label: 'Ushongo' },
    { value: '143', label: 'Vandeikya' }],
  },
  {
    value: '10',
    state: 'Bornu',
    lgas: [ { value: '144', label: 'Abadam' },
    { value: '145', label: 'Askira/Uba' },
    { value: '146', label: 'Bama' },
    { value: '147', label: 'Bayo' },
    { value: '148', label: 'Biu' },
    { value: '149', label: 'Chibok' },
    { value: '150', label: 'Damboa' },
    { value: '151', label: 'Dikwa' },
    { value: '152', label: 'Gubio' },
    { value: '153', label: 'Guzamala' },
    { value: '154', label: 'Gwoza' },
    { value: '155', label: 'Hawul' },
    { value: '156', label: 'Jere' },
    { value: '157', label: 'Kaga' },
    { value: '158', label: 'Kala/Balge' },
    { value: '159', label: 'Konduga' },
    { value: '160', label: 'Kukawa' },
    { value: '773', label: 'Kusar' },
    { value: '161', label: 'KwayaKusar' },
    { value: '162', label: 'Mafa' },
    { value: '163', label: 'Magumeri' },
    { value: '164', label: 'Maiduguri' },
    { value: '165', label: 'Marte' },
    { value: '166', label: 'Mobbar' },
    { value: '167', label: 'Monguno' },
    { value: '168', label: 'Ngala' },
    { value: '169', label: 'Nganzai' },
    { value: '170', label: 'Shani' }],
  },
  {
    value: '11',
    state: 'Cross River',
    lgas: [ { value: '175', label: 'Abi' },
    { value: '173', label: 'Akamkpa' },
    { value: '171', label: 'Akpabuyo' },
    { value: '187', label: 'Bakassi' },
    { value: '186', label: 'Bekwara' },
    { value: '174', label: 'Biase' },
    { value: '179', label: 'Boki' },
    { value: '188', label: 'Calabar Municipality' },
    { value: '184', label: 'Calabar South' },
    { value: '185', label: 'Etung' },
    { value: '176', label: 'Ikom' },
    { value: '182', label: 'Obanliku' },
    { value: '183', label: 'Obudu' },
    { value: '178', label: 'Obubra' },
    { value: '172', label: 'Odukpani' },
    { value: '180', label: 'Ogoja' },
    { value: '181', label: 'Yala' },
    { value: '177', label: 'Yarkur' }],
  },
  {
    value: '12',
    state: 'Delta',
    lgas: [{ value: '190', label: 'Aniocha' },
    { value: '191', label: 'Aniocha South' },
    { value: '784', label: 'Asaba' },
    { value: '198', label: 'Bomadi' },
    { value: '199', label: 'Burutu' },
    { value: '203', label: 'Ethiope East' },
    { value: '202', label: 'Ethiope West' },
    { value: '193', label: 'Ika North-East' },
    { value: '192', label: 'Ika South' },
    { value: '197', label: 'Isoko North' },
    { value: '196', label: 'Isoko South' },
    { value: '195', label: 'Ndokwa East' },
    { value: '194', label: 'Ndokwa West' },
    { value: '205', label: 'Okpe' },
    { value: '189', label: 'Oshimili' },
    { value: '212', label: 'Oshimili North' },
    { value: '213', label: 'Patani' },
    { value: '204', label: 'Sapele' },
    { value: '209', label: 'Udu' },
    { value: '201', label: 'Ughelli North' },
    { value: '200', label: 'Ughelli South' },
    { value: '211', label: 'Ukwani' },
    { value: '208', label: 'Uvwie' },
    { value: '781', label: 'Warri South West' },
    { value: '210', label: 'Warri Central' },
    { value: '206', label: 'Warri North' },
    { value: '207', label: 'Warri South' }],
  },
  {
    value: '13',
    state: 'Ebonyi',
    lgas: [ { value: '218', label: 'Abakaliki' },
    { value: '215', label: 'Afikpo North' },
    { value: '214', label: 'Afikpo South' },
    { value: '224', label: 'Ebonyi' },
    { value: '221', label: 'Ezza' },
    { value: '222', label: 'Ezza South' },
    { value: '774', label: 'Ikwo' },
    { value: '219', label: 'Ishielu' },
    { value: '225', label: 'Ivo' },
    { value: '788', label: 'Izzi' },
    { value: '220', label: 'Ikwo' },
    { value: '217', label: 'Ohaozara' },
    { value: '223', label: 'Ohaukwu' },
    { value: '216', label: 'Onicha' }],
  },
  {
    value: '14',
    state: 'Edo',
    lgas: [  { value: '231', label: 'Central' },
    { value: '229', label: 'Egor' },
    { value: '227', label: 'Esan Central' },
    { value: '226', label: 'Esan North-East' },
    { value: '240', label: 'Esan South-East' },
    { value: '228', label: 'Esan West' },
    { value: '786', label: 'Etsako West' },
    { value: '232', label: 'Etsako Central' },
    { value: '239', label: 'Etsako East' },
    { value: '233', label: 'Igueben' },
    { value: '787', label: 'Ikpoba-Okha' },
    { value: '234', label: 'Oredo' },
    { value: '237', label: 'Orhionwon' },
    { value: '236', label: 'Ovia South-East' },
    { value: '235', label: 'Ovia South-West' },
    { value: '777', label: 'Owan West' },
    { value: '238', label: 'Uhunmwonde' },
    { value: '230', label: 'Ukpoba' }],
  },
  {
    value: '15',
    state: 'Ekiti',
    lgas: [ { value: '241', label: 'Ado' },
    { value: '782', label: 'Aiyekire' },
    { value: '254', label: 'Efon' },
    { value: '242', label: 'Ekiti-East' },
    { value: '245', label: 'Ekiti South-West' },
    { value: '243', label: 'Ekiti-West' },
    { value: '244', label: 'Emure/Ise/Orun' },
    { value: '253', label: 'Gbonyin' },
    { value: '249', label: 'Ido/Osi' },
    { value: '248', label: 'Ijero' },
    { value: '246', label: 'Ikare' },
    { value: '251', label: 'Ikole' },
    { value: '256', label: 'Ilejemeje' },
    { value: '247', label: 'Irepodun' },
    { value: '255', label: 'Ise/Orun' },
    { value: '252', label: 'Moba' },
    { value: '250', label: 'Oye' }],
  },
  {
    value: '16',
    state: 'Enugu',
    lgas: [  { value: '270', label: 'Aninri' },
    { value: '783', label: 'Awgu' },
    { value: '269', label: 'Enugu East' },
    { value: '259', label: 'Enugu North' },
    { value: '257', label: 'Enugu South' },
    { value: '263', label: 'Ezeagu' },
    { value: '267', label: 'Igbo-Ekiti' },
    { value: '264', label: 'IgboEze North' },
    { value: '258', label: 'Igbo-Eze South' },
    { value: '265', label: 'Isi-Uzo' },
    { value: '260', label: 'Nkanu' },
    { value: '271', label: 'Nkanu East' },
    { value: '266', label: 'Nsukka' },
    { value: '262', label: 'Oji-River' },
    { value: '272', label: 'Udenu' },
    { value: '261', label: 'UdiAgwu' },
    { value: '268', label: 'Uzo-Uwani' }],
  },
  {
    value: '39',
    state: 'Federal Capital Territory',
    lgas: [
      { value: '768', label: 'Abaji' },
      { value: '769', label: 'Abuja Municipal' },
      { value: '770', label: 'Bwari' },
      { value: '766', label: 'Gwagwalada' },
      { value: '767', label: 'Kuje' },
      { value: '771', label: 'Kwali' }
    ],
    
  },
  {
    value: '17',
    state: 'Gombe',
    lgas: [ { value: '273', label: 'Akko' },
    { value: '274', label: 'Balanga' },
    { value: '275', label: 'Billiri' },
    { value: '276', label: 'Dukku' },
    { value: '280', label: 'Funakaye' },
    { value: '281', label: 'Gombe' },
    { value: '277', label: 'Kaltungo' },
    { value: '278', label: 'Kwami' },
    { value: '282', label: 'Nafada/Bajoga' },
    { value: '279', label: 'Shongom' },
    { value: '283', label: 'Yamaltu/Delta' }],
  },
  {
    value: '18',
    state: 'Imo',
    lgas: [{ value: '284', label: 'Aboh-Mbaise' },
    { value: '285', label: 'Ahiazu-Mbaise' },
    { value: '286', label: 'Ehime-Mbano' },
    { value: '287', label: 'Ezinihitte' },
    { value: '288', label: 'Ideato North' },
    { value: '289', label: 'Ideato South' },
    { value: '290', label: 'Ihitte/Uboma' },
    { value: '291', label: 'Ikeduru' },
    { value: '292', label: 'Isiala Mbano' },
    { value: '293', label: 'Isu' },
    { value: '294', label: 'Mbaitoli' },
    { value: '295', label: 'Mbaitoli' },
    { value: '296', label: 'Ngor-Okpala' },
    { value: '297', label: 'Njaba' },
    { value: '299', label: 'Nkwerre' },
    { value: '298', label: 'Nwangele' },
    { value: '300', label: 'Obowo' },
    { value: '301', label: 'Oguta' },
    { value: '302', label: 'Ohaji/Egbema' },
    { value: '303', label: 'Okigwe' },
    { value: '304', label: 'Orlu' },
    { value: '305', label: 'Orsu' },
    { value: '306', label: 'Oru East' },
    { value: '307', label: 'Oru West' },
    { value: '308', label: 'Owerri-Municipal' },
    { value: '309', label: 'Owerri North' },
    { value: '310', label: 'Owerri West' },
    { value: '780', label: 'Unuimo' }],
  },
  {
    value: '19',
    state: 'Jigawa',
    lgas: [  { value: '311', label: 'Auyo' },
    { value: '312', label: 'Babura' },
    { value: '314', label: 'Biriniwa' },
    { value: '313', label: 'Birni Kudu' },
    { value: '315', label: 'Buji' },
    { value: '316', label: 'Dutse' },
    { value: '317', label: 'Gagarawa' },
    { value: '318', label: 'Garki' },
    { value: '319', label: 'Gumel' },
    { value: '320', label: 'Guri' },
    { value: '321', label: 'Gwaram' },
    { value: '322', label: 'Gwiwa' },
    { value: '323', label: 'Hadejia' },
    { value: '324', label: 'Jahun' },
    { value: '325', label: 'Kafin Hausa' },
    { value: '326', label: 'Kaugama Kazaure' },
    { value: '327', label: 'Kiri Kasamma' },
    { value: '328', label: 'Kiyawa' },
    { value: '329', label: 'Maigatari' },
    { value: '330', label: 'Malam Madori' },
    { value: '331', label: 'Miga' },
    { value: '332', label: 'Ringim' },
    { value: '333', label: 'Roni' },
    { value: '334', label: 'Sule-Tankarkar' },
    { value: '335', label: 'Taura' },
    { value: '336', label: 'Yankwashi' }],
  },
  {
    value: '20',
    state: 'Kaduna',
    lgas: [{ value: '337', label: 'Birni-Gwari' },
    { value: '338', label: 'Chikun' },
    { value: '339', label: 'Giwa' },
    { value: '340', label: 'Igabi' },
    { value: '341', label: 'Ikara' },
    { value: '342', label: 'Jaba' },
    { value: '343', label: 'Jema\'a' },
    { value: '344', label: 'Kachia' },
    { value: '345', label: 'Kaduna North' },
    { value: '346', label: 'Kaduna South' },
    { value: '347', label: 'Kagarko' },
    { value: '348', label: 'Kajuru' },
    { value: '349', label: 'Kaura' },
    { value: '350', label: 'Kauru' },
    { value: '351', label: 'Kubau' },
    { value: '352', label: 'Kudan' },
    { value: '353', label: 'Lere' },
    { value: '354', label: 'Makarfi' },
    { value: '355', label: 'Sabon-Gari' },
    { value: '356', label: 'Sanga' },
    { value: '357', label: 'Soba' },
    { value: '358', label: 'Zango-Kataf' },
    { value: '359', label: 'Zaria' }],
  },
  {
    value: '21',
    state: 'Kano',
    lgas: [ { value: '360', label: 'Ajingi' },
    { value: '361', label: 'Albasu' },
    { value: '362', label: 'Bagwai' },
    { value: '363', label: 'Bebeji' },
    { value: '364', label: 'Bichi' },
    { value: '365', label: 'Bunkure' },
    { value: '366', label: 'Dala' },
    { value: '367', label: 'Dambatta' },
    { value: '368', label: 'DawakinKudu' },
    { value: '369', label: 'DawakinTofa' },
    { value: '370', label: 'Doguwa' },
    { value: '371', label: 'Fagge' },
    { value: '372', label: 'Gabasawa' },
    { value: '373', label: 'Garko' },
    { value: '374', label: 'Garum' },
    { value: '376', label: 'Gaya' },
    { value: '377', label: 'Gezawa' },
    { value: '378', label: 'Gwale' },
    { value: '379', label: 'Gwarzo' },
    { value: '380', label: 'Kabo' },
    { value: '381', label: 'Kano Municipal' },
    { value: '382', label: 'Karaye' },
    { value: '383', label: 'Kibiya' },
    { value: '384', label: 'Kiru' },
    { value: '385', label: 'Kumbotso' },
    { value: '386', label: 'Kunchi' },
    { value: '387', label: 'Kura' },
    { value: '388', label: 'Madobi' },
    { value: '389', label: 'Makoda' },
    { value: '375', label: 'Mallam' },
    { value: '390', label: 'Minjibir' },
    { value: '391', label: 'Nasarawa' },
    { value: '392', label: 'Rano' },
    { value: '393', label: 'RiminGado' },
    { value: '394', label: 'Rogo' },
    { value: '395', label: 'Shanono' },
    { value: '396', label: 'Sumaila' },
    { value: '397', label: 'Takali' },
    { value: '398', label: 'Tarauni' },
    { value: '399', label: 'Tofa' },
    { value: '400', label: 'Tsanyawa' },
    { value: '401', label: 'TudunWada' },
    { value: '402', label: 'Ungogo' },
    { value: '403', label: 'Warawa' },
    { value: '404', label: 'Wudil' }],
  },
  {
    value: '22',
    state: 'Katsina',
    lgas: [{ value: '405', label: 'Bakori' },
    { value: '406', label: 'Batagarawa' },
    { value: '407', label: 'Batsari' },
    { value: '408', label: 'Baure' },
    { value: '409', label: 'Bindawa' },
    { value: '410', label: 'Charanchi' },
    { value: '411', label: 'Dandume' },
    { value: '412', label: 'Danja' },
    { value: '413', label: 'DanMusa' },
    { value: '414', label: 'Daura' },
    { value: '415', label: 'Dutsi' },
    { value: '416', label: 'Dutsin-Ma' },
    { value: '417', label: 'Faskari' },
    { value: '418', label: 'Funtua' },
    { value: '419', label: 'Ingawa' },
    { value: '420', label: 'Jibia' },
    { value: '421', label: 'Kafur' },
    { value: '422', label: 'Kaita' },
    { value: '423', label: 'Kankara' },
    { value: '424', label: 'Kankia' },
    { value: '425', label: 'Katsina' },
    { value: '426', label: 'Kurfi' },
    { value: '427', label: 'Kusada' },
    { value: '428', label: 'Mai\'Adua' },
    { value: '429', label: 'Malumfashi' },
    { value: '430', label: 'Mani' },
    { value: '431', label: 'Mashi' },
    { value: '432', label: 'Matazu' },
    { value: '433', label: 'Musawa' },
    { value: '434', label: 'Rimi' },
    { value: '435', label: 'Sabuwa' },
    { value: '436', label: 'Safana' },
    { value: '437', label: 'Sandamu' },
    { value: '438', label: 'Zango' }],
  },
  {
    value: '23',
    state: 'Kebbi',
    lgas: [   { value: '439', label: 'Aleiro' },
    { value: '440', label: 'Arewa-Dandi' },
    { value: '441', label: 'Argungu' },
    { value: '442', label: 'Augie' },
    { value: '443', label: 'Bagudo' },
    { value: '444', label: 'BirninKebbi' },
    { value: '445', label: 'Bunza' },
    { value: '446', label: 'Dandi' },
    { value: '447', label: 'Fakai' },
    { value: '448', label: 'Gwandu' },
    { value: '449', label: 'Jega' },
    { value: '450', label: 'Kalgo' },
    { value: '451', label: 'Koko/Besse' },
    { value: '452', label: 'Maiyama' },
    { value: '453', label: 'Ngaski' },
    { value: '454', label: 'Sakaba' },
    { value: '455', label: 'Shanga' },
    { value: '456', label: 'Suru' },
    { value: '457', label: 'Wasagu/Danko' },
    { value: '458', label: 'Yauri' },
    { value: '459', label: 'Zuru' }],
  },
  {
    value: '24',
    state: 'Kogi',
    lgas: [ { value: '460', label: 'Adavi' },
    { value: '461', label: 'Ajaokuta' },
    { value: '785', label: 'Ankpa' },
    { value: '462', label: 'Ankpa' },
    { value: '463', label: 'Bassa' },
    { value: '464', label: 'Dekina' },
    { value: '465', label: 'Ibaji' },
    { value: '466', label: 'Idah' },
    { value: '467', label: 'Igalamela-Odolu' },
    { value: '468', label: 'Ijumu' },
    { value: '469', label: 'Kabba/Bunu' },
    { value: '470', label: 'Kogi' },
    { value: '471', label: 'Lokoja' },
    { value: '472', label: 'Mopa-Muro' },
    { value: '473', label: 'Ofu' },
    { value: '474', label: 'Ogori/Mangongo' },
    { value: '475', label: 'Okehi' },
    { value: '476', label: 'Okene' },
    { value: '477', label: 'Olamabolo' },
    { value: '478', label: 'Omala' },
    { value: '479', label: 'YagbaEast' },
    { value: '480', label: 'YagbaWest' }],
  },
  {
    value: '25',
    state: 'Kwara',
    lgas: [ { value: '481', label: 'Asa' },
    { value: '482', label: 'Baruten' },
    { value: '483', label: 'Edu' },
    { value: '484', label: 'Ekiti' },
    { value: '485', label: 'Ifelodun' },
    { value: '486', label: 'IlorinEast' },
    { value: '487', label: 'IlorinWest' },
    { value: '488', label: 'Irepodun' },
    { value: '489', label: 'Isin' },
    { value: '490', label: 'Kaiama' },
    { value: '491', label: 'Moro' },
    { value: '492', label: 'Offa' },
    { value: '493', label: 'Oke-Ero' },
    { value: '494', label: 'Oyun' },
    { value: '495', label: 'Pategi' }],
  },
  {
    value: '26',
    state: 'Lagos',
    lgas: 
      [
        { value: '496', label: 'Agege' },
        { value: '497', label: 'Ajeromi-Ifelodun' },
        { value: '498', label: 'Alimosho' },
        { value: '499', label: 'Amuwo-Odofin' },
        { value: '500', label: 'Apapa' },
        { value: '501', label: 'Badagry' },
        { value: '502', label: 'Epe' },
        { value: '503', label: 'Eti-Osa' },
        { value: '504', label: 'Ibeju/Lekki' },
        { value: '505', label: 'Ifako-Ijaye' },
        { value: '506', label: 'Ikeja' },
        { value: '507', label: 'Ikorodu' },
        { value: '772', label: 'Ikotun' },
        { value: '508', label: 'Kosofe' },
        { value: '509', label: 'LagosIsland' },
        { value: '510', label: 'LagosMainland' },
        { value: '511', label: 'Mushin' },
        { value: '512', label: 'Ojo' },
        { value: '513', label: 'Oshodi-Isolo' },
        { value: '514', label: 'Shomolu' },
        { value: '515', label: 'Surulere' }
      
      
    ],
  },
  {
    value: '27',
    state: 'Nasarawa',
    lgas: [ { value: '516', label: 'Akwanga' },
    { value: '517', label: 'Awe' },
    { value: '518', label: 'Doma' },
    { value: '519', label: 'Karu' },
    { value: '520', label: 'Keana' },
    { value: '521', label: 'Keffi' },
    { value: '522', label: 'Kokona' },
    { value: '523', label: 'Lafia' },
    { value: '524', label: 'Nasarawa' },
    { value: '525', label: 'Nasarawa-Eggon' },
    { value: '526', label: 'Obi' },
    { value: '527', label: 'Toto' },
    { value: '528', label: 'Wamba' }],
  },
  {
    value: '28',
    state: 'Niger',
    lgas: [ { value: '529', label: 'Agaie' },
    { value: '530', label: 'Agwara' },
    { value: '531', label: 'Bida' },
    { value: '532', label: 'Borgu' },
    { value: '533', label: 'Bosso' },
    { value: '534', label: 'Chanchaga' },
    { value: '535', label: 'Edati' },
    { value: '536', label: 'Gbako' },
    { value: '537', label: 'Gurara' },
    { value: '538', label: 'Katcha' },
    { value: '539', label: 'Kontagora' },
    { value: '540', label: 'Lapai' },
    { value: '541', label: 'Lavun' },
    { value: '542', label: 'Magama' },
    { value: '543', label: 'Mariga' },
    { value: '544', label: 'Mashegu' },
    { value: '775', label: 'Minna' },
    { value: '545', label: 'Mokwa' },
    { value: '546', label: 'Muya' },
    { value: '547', label: 'Pailoro' },
    { value: '548', label: 'Rafi' },
    { value: '549', label: 'Rijau' },
    { value: '550', label: 'Shiroro' },
    { value: '551', label: 'Suleja' },
    { value: '552', label: 'Tafa' },
    { value: '553', label: 'Wushishi' }],
  },
  {
    value: '29',
    state: 'Ogun',
    lgas: [  { value: '554', label: 'Abeokuta North' },
    { value: '555', label: 'Abeokuta South' },
    { value: '556', label: 'Ado-Odo/Ota' },
    { value: '557', label: 'Egbado North' },
    { value: '558', label: 'Egbado South' },
    { value: '559', label: 'Ewekoro' },
    { value: '560', label: 'Ifo' },
    { value: '561', label: 'Ijebu East' },
    { value: '562', label: 'Ijebu North' },
    { value: '563', label: 'Ijebu North East' },
    { value: '564', label: 'Ijebu Ode' },
    { value: '565', label: 'Ikenne' },
    { value: '566', label: 'Imeko-Afon' },
    { value: '567', label: 'Ipokia' },
    { value: '568', label: 'Obafemi-Owode' },
    { value: '570', label: 'Odeda' },
    { value: '571', label: 'Odogbolu' },
    { value: '569', label: 'Ogun Waterside' },
    { value: '572', label: 'Remo North' },
    { value: '573', label: 'Shagamu' }],
  },
  {
    value: '30',
    state: 'Ondo',
    lgas: [ { value: '574', label: 'Akoko North East' },
    { value: '575', label: 'Akoko North West' },
    { value: '576', label: 'Akoko South Akure East' },
    { value: '577', label: 'Akoko South West' },
    { value: '578', label: 'Akure North' },
    { value: '579', label: 'Akure South' },
    { value: '580', label: 'Ese-Odo' },
    { value: '581', label: 'Idanre' },
    { value: '582', label: 'Ifedore' },
    { value: '583', label: 'Ilaje' },
    { value: '584', label: 'Ile-Oluji' },
    { value: '586', label: 'Irele' },
    { value: '587', label: 'Odigbo' },
    { value: '585', label: 'Okeigbo' },
    { value: '588', label: 'Okitipupa' },
    { value: '589', label: 'Ondo East' },
    { value: '590', label: 'Ondo West' },
    { value: '591', label: 'Ose' },
    { value: '592', label: 'Owo' }],
  },
  {
    value: '31',
    state: 'Osun',
    lgas: [ { value: '593', label: 'Aiyedade' },
    { value: '594', label: 'Aiyedire' },
    { value: '595', label: 'Atakumosa East' },
    { value: '596', label: 'Atakumosa West' },
    { value: '597', label: 'Boluwaduro' },
    { value: '598', label: 'Boripe' },
    { value: '599', label: 'Ede North' },
    { value: '600', label: 'Ede South' },
    { value: '601', label: 'Egbedore' },
    { value: '602', label: 'Ejigbo' },
    { value: '603', label: 'Ife Central' },
    { value: '607', label: 'Ifedayo' },
    { value: '604', label: 'Ife East' },
    { value: '608', label: 'Ifelodun' },
    { value: '605', label: 'Ife North' },
    { value: '606', label: 'Ife South' },
    { value: '609', label: 'Ila' },
    { value: '610', label: 'Ilesha East' },
    { value: '611', label: 'Ilesha West' },
    { value: '612', label: 'Irepodun' },
    { value: '613', label: 'Irewole' },
    { value: '614', label: 'Isokan' },
    { value: '615', label: 'Iwo' },
    { value: '616', label: 'Obokun' },
    { value: '617', label: 'Odo-Otin' },
    { value: '618', label: 'Ola-Oluwa' },
    { value: '619', label: 'Olorunda' },
    { value: '620', label: 'Oriade' },
    { value: '621', label: 'Orolu' },
    { value: '622', label: 'Osogbo' }],
  },
  {
    value: '32',
    state: 'Oyo',
    lgas: [  { value: '623', label: 'Afijio' },
    { value: '624', label: 'Akinyele' },
    { value: '625', label: 'Atiba' },
    { value: '626', label: 'Atigbo' },
    { value: '627', label: 'Egbeda' },
    { value: '628', label: 'Ibadan Central' },
    { value: '629', label: 'Ibadan North' },
    { value: '630', label: 'Ibadan North West' },
    { value: '631', label: 'Ibadan South East' },
    { value: '632', label: 'Ibadan South West' },
    { value: '633', label: 'Ibarapa Central' },
    { value: '634', label: 'Ibarapa East' },
    { value: '635', label: 'Ibarapa North' },
    { value: '636', label: 'Ido' },
    { value: '637', label: 'Irepo' },
    { value: '638', label: 'Iseyin' },
    { value: '639', label: 'Itesiwaju' },
    { value: '640', label: 'Iwajowa' },
    { value: '641', label: 'Kajola' },
    { value: '642', label: 'Lagelu Ogbomosho North' },
    { value: '643', label: 'Ogbomosho South' },
    { value: '776', label: 'Ogbomosho North' },
    { value: '644', label: 'Ogo Oluwa' },
    { value: '645', label: 'Olorunsogo' },
    { value: '646', label: 'Oluyole' },
    { value: '647', label: 'Ona-Ara' },
    { value: '648', label: 'Orelope' },
    { value: '649', label: 'Ori Ire' },
    { value: '650', label: 'Oyo East' },
    { value: '651', label: 'Oyo West' },
    { value: '652', label: 'Saki East' },
    { value: '653', label: 'Saki West' },
    { value: '654', label: 'Surulere' }],
  },
  {
    value: '33',
    state: 'Plateau',
    lgas: [  { value: '655', label: 'Barikin Ladi' },
    { value: '656', label: 'Bassa' },
    { value: '657', label: 'Bokkos' },
    { value: '658', label: 'Jos East' },
    { value: '659', label: 'Jos North' },
    { value: '660', label: 'Jos South' },
    { value: '661', label: 'Kanam' },
    { value: '662', label: 'Kanke' },
    { value: '663', label: 'Langtang North' },
    { value: '664', label: 'Langtang South' },
    { value: '665', label: 'Mangu' },
    { value: '666', label: 'Mikang' },
    { value: '667', label: 'Pankshin' },
    { value: '668', label: 'Quaan Pan' },
    { value: '669', label: 'Riyom' },
    { value: '670', label: 'Shendam' },
    { value: '671', label: 'Wase' }],
  },
  {
    value: '34',
    state: 'Rivers',
    lgas: [ { value: '672', label: 'Abua/Odual' },
    { value: '673', label: 'Ahoada East' },
    { value: '674', label: 'Ahoada West' },
    { value: '675', label: 'Akuku Toru' },
    { value: '676', label: 'Andoni' },
    { value: '677', label: 'Asari-Toru' },
    { value: '678', label: 'Bonny' },
    { value: '679', label: 'Degema' },
    { value: '681', label: 'Eleme' },
    { value: '680', label: 'Emohua' },
    { value: '682', label: 'Etche' },
    { value: '683', label: 'Gokana' },
    { value: '684', label: 'Ikwerre' },
    { value: '685', label: 'Khana' },
    { value: '686', label: 'Obia/Akpor' },
    { value: '687', label: 'Ogba/Egbema/Ndoni' },
    { value: '688', label: 'Ogu/Bolo' },
    { value: '689', label: 'Okrika' },
    { value: '690', label: 'Omumma' },
    { value: '691', label: 'Opobo/Nkoro' },
    { value: '692', label: 'Oyigbo' },
    { value: '693', label: 'Port-Harcourt' },
    { value: '694', label: 'Tai' }],
  },
  {
    value: '35',
    state: 'Sokoto',
    lgas: [{ value: '695', label: 'Binji' },
    { value: '696', label: 'Bodinga' },
    { value: '697', label: 'Dange-shnsi' },
    { value: '698', label: 'Gada' },
    { value: '701', label: 'Gawabawa' },
    { value: '699', label: 'Goronyo' },
    { value: '700', label: 'Gudu' },
    { value: '702', label: 'Illela' },
    { value: '703', label: 'Isa' },
    { value: '705', label: 'Kebbe' },
    { value: '704', label: 'Kware' },
    { value: '706', label: 'Rabah' },
    { value: '707', label: 'Sabonbirni' },
    { value: '708', label: 'Shagari' },
    { value: '709', label: 'Silame' },
    { value: '710', label: 'Sokoto North' },
    { value: '711', label: 'Sokoto South' },
    { value: '712', label: 'Tambuwal' },
    { value: '778', label: 'Tangaza' },
    { value: '713', label: 'Tqngaza' },
    { value: '714', label: 'Tureta' },
    { value: '715', label: 'Wamako' },
    { value: '716', label: 'Wurno' },
    { value: '717', label: 'Yabo' }],
  },
  {
    value: '36',
    state: 'Taraba',
    lgas: [  { value: '718', label: 'Ardo-kola' },
    { value: '719', label: 'Bali' },
    { value: '722', label: 'Cassol' },
    { value: '720', label: 'Donga' },
    { value: '721', label: 'Gashaka' },
    { value: '723', label: 'Ibi' },
    { value: '724', label: 'Jalingo' },
    { value: '725', label: 'Karin-Lamido' },
    { value: '726', label: 'Kurmi' },
    { value: '727', label: 'Lau' },
    { value: '728', label: 'Sardauna' },
    { value: '729', label: 'Takum' },
    { value: '730', label: 'Ussa' },
    { value: '731', label: 'Wukari' },
    { value: '732', label: 'Yorro' },
    { value: '733', label: 'Zing' }],
  },
  {
    value: '37',
    state: 'Yobe',
    lgas: [  { value: '734', label: 'Bade' },
    { value: '735', label: 'Bursari' },
    { value: '736', label: 'Damaturu' },
    { value: '737', label: 'Fika' },
    { value: '738', label: 'Fune' },
    { value: '739', label: 'Geidam' },
    { value: '740', label: 'Gujba' },
    { value: '741', label: 'Gulani' },
    { value: '742', label: 'Jakusko' },
    { value: '743', label: 'Karasuwa' },
    { value: '744', label: 'Karawa' },
    { value: '745', label: 'Machina' },
    { value: '746', label: 'Nangere' },
    { value: '747', label: 'NguruPotiskum' },
    { value: '748', label: 'Tarmua' },
    { value: '749', label: 'Yunusari' },
    { value: '750', label: 'Yusufari' }],
  },
  {
    value: '38',
    state: 'Zamfara',
    lgas: [  { value: '751', label: 'Anka' },
    { value: '752', label: 'Bakura' },
    { value: '753', label: 'BirninMagaji' },
    { value: '754', label: 'Bukkuyum' },
    { value: '755', label: 'Bungudu' },
    { value: '756', label: 'Gummi' },
    { value: '757', label: 'Gusau' },
    { value: '758', label: 'Kaura' },
    { value: '760', label: 'Maradun' },
    { value: '761', label: 'Maru' },
    { value: '759', label: 'Namoda' },
    { value: '762', label: 'Shinkafi' },
    { value: '763', label: 'TalataMafara' },
    { value: '764', label: 'Tsafe' },
    { value: '765', label: 'Zurmi' }],
  },
];


export { lgaList, stateOptions,statesWithLgas };
