const providerDataMidi = [
    {
        "ProviderID": 18739,
        "ProviderName": "...",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352195",
        "provideremail1": "deltacrownhospital2013@gmail.com",
        "address1": "14, Ajayi Street, Ketu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18638,
        "ProviderName": "A. Harmony Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033838599",
        "provideremail1": "aharmonyhospital1@gmail.com",
        "address1": "10, Samuel Ekundayo Road, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19450,
        "ProviderName": "AB Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023183747",
        "provideremail1": "absp80@yahoo.com",
        "address1": "87, Randle Avenue, Surulere, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18640,
        "ProviderName": "Abdul Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036861006",
        "provideremail1": "NULL",
        "address1": "5 Donga Road, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19737,
        "ProviderName": "Abundant Health Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09037147632",
        "provideremail1": "Abundant.h@yahoo.com",
        "address1": "No 9 Aleruchi Close Off 5 Ada George Road, Port Harcourt, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18641,
        "ProviderName": "Abundant Life Hospital Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023537448",
        "provideremail1": "abundantlifehospital@gmail.com",
        "address1": "1, Idahosa Street, Olope-Meji, Mowe.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18644,
        "ProviderName": "Ace Medicare Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08098432614",
        "provideremail1": "medicareclinicsota@yahoo.com",
        "address1": "Km 4 Idiroko Road, Ota, Ogun State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18852,
        "ProviderName": "Ade Vision Optical Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033334850",
        "provideremail1": "adevision20@gmail.com",
        "address1": "14, Kayode Street, off Ijaiye Road, Ogba",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18652,
        "ProviderName": "Ade-Tade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034753037",
        "provideremail1": "NULL",
        "address1": "11, Okebola Street, Ado Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18646,
        "ProviderName": "Adebare Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033596164",
        "provideremail1": "NULL",
        "address1": "Km 4, Gbongan road, Osogbo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18647,
        "ProviderName": "Adebayo Ogunsanya Memorial Hospital  ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023070698",
        "provideremail1": "NULL",
        "address1": "8, Eniasoro Beyioku Street, Ajao Road, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18648,
        "ProviderName": "Adefemi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08024308447",
        "provideremail1": "adefemihospital@rocketmail.com",
        "address1": "49, Seriki Aro St., Off Ajao Road, Off Obafemi St., Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18649,
        "ProviderName": "Ademola Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033360135",
        "provideremail1": "ademolahospital@yahoo.com",
        "address1": "1, Omowale Street, KM 28 Lagos Badagry Expressway, Ijanikin, Ojo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18650,
        "ProviderName": "Adeoye Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08042127443",
        "provideremail1": "NULL",
        "address1": "NW4/183 George Street, Idi Oro, Ekotado, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18653,
        "ProviderName": "Adetoyin Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060351244",
        "provideremail1": "NULL",
        "address1": "Secretariat road, Opp NNPC petrol station, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18654,
        "ProviderName": "Afolabi Hospital, Ado-Ekiti",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039737773",
        "provideremail1": "NULL",
        "address1": "off Opopogbooro Road, Ado- Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18655,
        "ProviderName": "Afolabi Memorial Hospital, Offa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08104398292",
        "provideremail1": "NULL",
        "address1": "Offa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18849,
        "ProviderName": "Afolabi Vincent Memorial Hospital Oworonshoki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08109210100",
        "provideremail1": "folabimedicalcentreoworo@yahoo.com",
        "address1": "78, Oworo Road, Oworonshoki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18656,
        "ProviderName": "AfriGlobal Medicare Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032127570",
        "provideremail1": "info@afriglobalmedicare.com",
        "address1": "8 Mobolaji Bank Anthony Way, Ikeja, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19781,
        "ProviderName": "Afrimed Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022837554",
        "provideremail1": "afrimedhospital@yahoo.com",
        "address1": "1B Williams Street Off DNA Street Ifako Gbagada, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18657,
        "ProviderName": "Agape Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023039306",
        "provideremail1": "agapemedicalcentre@yahoo.com",
        "address1": "16, Ogunbiyi Ilo St, Olude, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19390,
        "ProviderName": "Ago Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08183057999",
        "provideremail1": "agomedicalcentre@yahoo.com",
        "address1": "6, Oyinlola Close, Ago, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18661,
        "ProviderName": "Ajara Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033233169",
        "provideremail1": "NULL",
        "address1": "3, Dr. Dauda Bioku St., Ajara-Topa, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18662,
        "ProviderName": "Ajiko Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036064326",
        "provideremail1": "ajikomedicalcentre2018@gmail.com",
        "address1": "Along Gashua Road, Damaturu.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18663,
        "ProviderName": "Akobi Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033527927",
        "provideremail1": "drayindeae@yahoo.com",
        "address1": "Plot 5, Blk WV Molipa Comm. Layout.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18664,
        "ProviderName": "Alafia Hospital (1938) Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033701690",
        "provideremail1": "alafiahospitalbrcr@gmail.com",
        "address1": "72, Adekunle Fajuyi Road, Adamasingba.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18665,
        "ProviderName": "Alba Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052727600",
        "provideremail1": "albaclinics@yahoo.com",
        "address1": "25, Constitution Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18666,
        "ProviderName": "Albarka Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034457903",
        "provideremail1": "albarkaclinic@gmail.com",
        "address1": "Beside Estate Qtrs, Dutse.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18669,
        "ProviderName": "All Souls Hospital, Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033142354",
        "provideremail1": "allsoulshosp@yahoo.com",
        "address1": "255, Ojo Road, Suru Alaba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18670,
        "ProviderName": "All Souls Infirmary, Agege",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034962603",
        "provideremail1": "NULL",
        "address1": "8 Church Street, Elere, Agege",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18672,
        "ProviderName": "Alma Clinics & Surgery",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07065113966",
        "provideremail1": "NULL",
        "address1": "19, Ikot Udoro Street, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18675,
        "ProviderName": "Alphonso Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033107798",
        "provideremail1": "NULL",
        "address1": "8, Trans-Woji Road, Elelenwo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19456,
        "ProviderName": "Alverna Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033393321",
        "provideremail1": "alvernamedical@gmail.com",
        "address1": "15, Olowotabutabu Street, Agric Bus Stop, Ojo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18676,
        "ProviderName": "Amana Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08043272013",
        "provideremail1": "amanamedicalcentre@yahoo.com",
        "address1": "5, Ilorin Street, Off Ogbomosho Street, Area 8, Garki.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18677,
        "ProviderName": "Amanda Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08162418899",
        "provideremail1": "NULL",
        "address1": "Plot 412/415, Works Layout, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19388,
        "ProviderName": "Amara Medicare Multi Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07064189209",
        "provideremail1": "eemenike@amaramedi.care",
        "address1": "Plot 76B, Eleganza Garden, Opp. VGC, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18678,
        "ProviderName": "Amazing Grace Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033595524",
        "provideremail1": "NULL",
        "address1": "7, Iloro Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18679,
        "ProviderName": "Ameso Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055675758",
        "provideremail1": "amesoclinic@yahoo.com",
        "address1": "7, Jumat Olukoya St., Off Ogudu Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18680,
        "ProviderName": "Amfani Clinic and Maternity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068950480",
        "provideremail1": "NULL",
        "address1": "15 Funtua Crescent New Layout, Katsina. ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18681,
        "ProviderName": "Amina Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033109883",
        "provideremail1": "NULL",
        "address1": "47, Gwari Avenue, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19845,
        "ProviderName": "Aminci Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035092283",
        "provideremail1": "amincihospital@gmail.com",
        "address1": "60, BWANDANG ROAD BUKURU",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18682,
        "ProviderName": "Amuwo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08064571074",
        "provideremail1": "amcmedical@yahoo.com",
        "address1": "Plot 343, Block 19, Durbar Road, Amuwo Odofin, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18683,
        "ProviderName": "Anchor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08168670731",
        "provideremail1": "anchorhospital@yahoo.com",
        "address1": "Km 15, Aba/PH Expressway, Eleme Junction,",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18684,
        "ProviderName": "AnchorMed Hospital, Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033586950",
        "provideremail1": "anchormed@yahoo.com",
        "address1": "Niyi Aniyikaiye Street, Tanke, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18685,
        "ProviderName": "Anioma Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037202373",
        "provideremail1": "samuelidiaghe43@yahoo.com",
        "address1": "13, Memeh Street, Agbor",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18686,
        "ProviderName": "Aniyun Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052246001",
        "provideremail1": "aniyunhospital@yahoo.co.uk",
        "address1": "3, Femi-Aderibigbe Close, Ifako, Gbagada.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18689,
        "ProviderName": "Ansor Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033660341",
        "provideremail1": "NULL",
        "address1": "Otokpa Layout, Ntamkpo, Ugep.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19942,
        "ProviderName": "Anyimichfranze   Hospital (AMF)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09064213887",
        "provideremail1": "hello@amfhospital.com",
        "address1": "41, Wogu Street D- Line Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18690,
        "ProviderName": "Araba Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033031875",
        "provideremail1": "NULL",
        "address1": "122, Ekoro Agbelekale Road (Big Joy B/Stop), Abule Egba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18692,
        "ProviderName": "Arif Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08062200944",
        "provideremail1": "NULL",
        "address1": "Sabo Main Market, Ore, Ondo State.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18693,
        "ProviderName": "Arrive Alive Diagnostics & Imaging Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08091838812",
        "provideremail1": "NULL",
        "address1": "35, Cole Street, off Mabo Street, Ojuelegba, Surulere, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18694,
        "ProviderName": "Arubah Family Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033927737",
        "provideremail1": "NULL",
        "address1": "9, Kudirat Salami Street, Agungi, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18695,
        "ProviderName": "Arubah Specialist Hospital & Diagnostics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032398409",
        "provideremail1": "info@arubahspecialisthospital.com",
        "address1": "23, Nsefik Eyo Close, off Ndidem Usang Road, Calabar",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18696,
        "ProviderName": "Aruna Ogun Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023839210",
        "provideremail1": "arunaogunspechosp@yahoo.com",
        "address1": "214, Bamimosu Street, Ipalekodo, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18697,
        "ProviderName": "Asher Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039472783",
        "provideremail1": "NULL",
        "address1": "Plot 1197, Katampe Cadastral, Zone B07, (Nicon Junction), Berger, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19820,
        "ProviderName": "Ashmed Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07061908442",
        "provideremail1": "ashmedspecialisthospital@yahoo.com",
        "address1": "33, Power Plant Road Agwa New Extension, Kaduna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18700,
        "ProviderName": "Avenue Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08077125881",
        "provideremail1": "NULL",
        "address1": "Neogilan House, Okumagba Avenue.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18701,
        "ProviderName": "Avon Medical Practice",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "07083824058",
        "provideremail1": "hmo.office@avonmedical.com",
        "address1": "8, Adedamola Ojomo Close, Off Bode Thomas St., Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18702,
        "ProviderName": "Ayo Bello Memorial",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08051301283",
        "provideremail1": "NULL",
        "address1": "1, Ayo Bello Street, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18705,
        "ProviderName": "Ayodele Medical Centre, Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033591998",
        "provideremail1": "NULL",
        "address1": "290 Lagos Road, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18704,
        "ProviderName": "Ayodele Medical Centre, Lagos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034025182",
        "provideremail1": "ayodelemedicalcentre86@yahoo.com",
        "address1": "1, Ayodele Close, Off Jonathan Coker Road, Fagba Junction, Iju Agege, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18706,
        "ProviderName": "Ayotola Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037274221",
        "provideremail1": "ayotolahospital2019@gmail.com",
        "address1": "2, Suraju Odunuga St., Off Baruwa Eleja, Sagamu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19680,
        "ProviderName": "Azir Healthcare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09090180190",
        "provideremail1": "azirhealthcare@gmail.com",
        "address1": "47, Oduntan Street, Kosofe, Ikosi-Isheri, Ketu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18707,
        "ProviderName": "Bakor Hospital, Ikom",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069731060",
        "provideremail1": "bmc.hmo@yahoo.com",
        "address1": "Nde, Ikom",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18708,
        "ProviderName": "Bakor Medical Centre, Calabar",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069731060",
        "provideremail1": "NULL",
        "address1": "124, Murtala Mohammed High way, Opp. NNPC Station, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18709,
        "ProviderName": "Balda Hospital & Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056674902",
        "provideremail1": "baldahomes@yahoo.com",
        "address1": "31, Itsekiri Street, Iju-Ajuwon, Ogun State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18710,
        "ProviderName": "Balm Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030681861",
        "provideremail1": "NULL",
        "address1": "29b, Bishop Street, GRA, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18711,
        "ProviderName": "Balm of Gilead Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037151125",
        "provideremail1": "NULL",
        "address1": "Oko-Opo road, off Ayeso street, Ilesa",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18712,
        "ProviderName": "Barewa Clinics and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034531444",
        "provideremail1": "barewaclinics@yahoo.co.uk",
        "address1": "11, Zaria Road, Kano, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19380,
        "ProviderName": "Barnes Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08129915303",
        "provideremail1": "barnes2.hospital@gmail.com",
        "address1": "34B, Chief Abiodun Yesufu Oniru Road, Victoria Island Extension",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19936,
        "ProviderName": "Base Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033449959",
        "provideremail1": "basespecialisthospital@gmail.com",
        "address1": "22, Demurin Road By Mascara Junction Ketu - Alapere Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19446,
        "ProviderName": "Bavaria Catholic Specialst Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08126443173",
        "provideremail1": "bavariacatholic.spec.hospital@gmail.com",
        "address1": "Mary-Hill Centre Enugu Exp.Way, Opp. Umulolo Junction Okigwe ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18713,
        "ProviderName": "Bay Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039744098",
        "provideremail1": "bayhospminna@yahoo.com",
        "address1": "Plot 99, Bay Clinic road, Tunga Minna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18714,
        "ProviderName": "Beachland Specialist Hospital - Arepo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033000346",
        "provideremail1": "bshmedical@yahoo.com",
        "address1": "15A, Alfidaus Estate, Arepo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19630,
        "ProviderName": "Beachland Specialist Hospital - Mowe",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034982938",
        "provideremail1": "bshmedical1@gmail.com",
        "address1": "156, Mowe/Ofada road, Ogun state(former Ofada-Mokoloki LCDA building)",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18716,
        "ProviderName": "BeaconHill Smile Clinic, Victoria Island",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08125215208",
        "provideremail1": "admin@beaconhillsmile.com",
        "address1": "4, Adeola Odeku Street, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18717,
        "ProviderName": "Bee Hess Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023119453",
        "provideremail1": "NULL",
        "address1": "155, Akowonjo Road, Akowonjo, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19952,
        "ProviderName": "Bellaidio Medical Centre ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039192068",
        "provideremail1": "info@belladiomed.com",
        "address1": "23, Unity Estate (Now Diamond) By Yah Lateef Street , Iyana Odo, Agura Gberigbe , Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19907,
        "ProviderName": "Belmedicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08121288148",
        "provideremail1": "belmedicarehospital@gmail.com",
        "address1": "Block 25,Flat 5,Adio Olusesi Street ,Olugboroya Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18718,
        "ProviderName": "Bendel Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065215576",
        "provideremail1": "bendelclinic@gmail.com",
        "address1": "19, Kagho Street, MCC Road, Enerhen2, Omamofe Sillo St., Off Etuwewe.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19399,
        "ProviderName": "Benin Medical Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "bmc123.",
        "provideremail1": "casemanager@beninmedicalcare.com",
        "address1": "53, Adesuwa Grammar School Road, GRA, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18720,
        "ProviderName": "Benok Heroes E.N.T. Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171639139",
        "provideremail1": "NULL",
        "address1": "17A, Unity Avenue, Rumuokwuta, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18721,
        "ProviderName": "Benok Heroes E.N.T. Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171639139",
        "provideremail1": "NULL",
        "address1": "17A, Unity Avenue, Rumuokwuta, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18722,
        "ProviderName": "BenOptika Vision - Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07086292092",
        "provideremail1": "benoptikavision@yahoo.com",
        "address1": "Our Lady Queen of Nigeria Pro-Cathedral, Ibadan Street. Area 3. Garki",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18726,
        "ProviderName": "Bernice Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023248834",
        "provideremail1": "bernicehospitals@yahoo.com",
        "address1": "5, Fadare Street, Mile 12, Kosofe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19786,
        "ProviderName": "Betaglass Worksite Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09087268324",
        "provideremail1": "frigoglassclinic@metrohealthhmo.com",
        "address1": "Beta Glass PLC, Guinea Plan, Agbara",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19406,
        "ProviderName": "Bidems Victory Hospital and Diagnostic Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033515908",
        "provideremail1": "hmo_bidemshospital@yahoo.com",
        "address1": "Simco Ummemekwu street off isawo road,okiki b/Stop agric ikorodu lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19890,
        "ProviderName": "Biodex Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037216212",
        "provideremail1": "biodexmc@gmail.com",
        "address1": "Besides Onimedu Police Station, Eleko Road Ibeju -Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18730,
        "ProviderName": "Bismol  Hospital  ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08053986994",
        "provideremail1": "NULL",
        "address1": "Plot  R22,  Area  C  Phase  IV, Nyanya, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19787,
        "ProviderName": "Blooming Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023060797",
        "provideremail1": "bloomingcarehospital.hmo@gmail.com",
        "address1": "30, Baale street Animashaun Street, Alakuko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18732,
        "ProviderName": "Blue Cross Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037202934",
        "provideremail1": "bluecrosshospital43@gmail.com",
        "address1": "48, Ijaiye Road, Ogba, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19595,
        "ProviderName": "Bluelime Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08163876808",
        "provideremail1": "bluelimehospitalhmo@gmail.com",
        "address1": "House 2, I1 Close, 7th Avenue, Festac Town",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18733,
        "ProviderName": "BMM Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033301626",
        "provideremail1": "live.safely@yahoo.com",
        "address1": "8/10 Kugbuyi Street, Idi-Oro, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19381,
        "ProviderName": "Bose Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023143560",
        "provideremail1": "bosespecialisth@gmail.com",
        "address1": "7, Ilori Street, Ire-Akari Estate, Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19930,
        "ProviderName": "Boyle Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022235656",
        "provideremail1": "boylemed@gmail.com",
        "address1": "1 b, Oyewole Way ,Off, Olufemi  Peters. Ire Akari Estate , Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18735,
        "ProviderName": "Brema Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037814237",
        "provideremail1": "bremahospital@yahoo.com",
        "address1": "5/7, Ashaka Road, Kwale",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18736,
        "ProviderName": "Brickfield Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033328807",
        "provideremail1": "brickfieldmedical@yahoo.com",
        "address1": "15, Ademuyiwa Road, Ebute Metta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18737,
        "ProviderName": "Britannia Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08078704277",
        "provideremail1": "britanniahospitallekki@yahoo.com",
        "address1": "Plot 13, Block 91, Aliu Animashaun Avenue, Lekki Phase 1",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18738,
        "ProviderName": "Broad Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033290753",
        "provideremail1": "hospitalbroad@yahoo.com",
        "address1": "17, Fehintola St., Off Isijola St., NEPA B/Stop, Ikotun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19383,
        "ProviderName": "Broadland Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08072147082",
        "provideremail1": "broadlandhospitalserv@gmail.com",
        "address1": "Hallmark Street, Gas Line Junction, Magboro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18740,
        "ProviderName": "Bronk Premiere  Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035802519",
        "provideremail1": "bronila_medical@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18742,
        "ProviderName": "BSA Hearing & Speech Centre - Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034019970",
        "provideremail1": "info@bsahearing.com",
        "address1": "Peace Plaza, 35 Ajose Adeogun, Utako District, Abuja, FCT",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18741,
        "ProviderName": "BSA Hearing & Speech Centre - Ikeja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023118446",
        "provideremail1": "info@bsahearing.com",
        "address1": "49B, Oluwaleyimu Street, off Allen Avenue, Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18743,
        "ProviderName": "Budo Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08060531049",
        "provideremail1": "budospecialisthospital@yahoo.com",
        "address1": "Km 25 Lekki-Epe Expressway, By Peninsula resort, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18744,
        "ProviderName": "By-Faith Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036689287",
        "provideremail1": "NULL",
        "address1": "Along Oba Adeniyi Sonatiwo Pry Sch. Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19987,
        "ProviderName": "Calabar Women and Children Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07042213383",
        "provideremail1": "cwchospital@yahoo.com",
        "address1": "164/165 Muritala Muhammed Highway Calabar",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18747,
        "ProviderName": "Calvary Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038673376",
        "provideremail1": "NULL",
        "address1": "7, Haastrop St., Orazi Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18749,
        "ProviderName": "Capitol Hill Clinic/ Hospital - Airport Road",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034105481",
        "provideremail1": "capitolhillclinic@yahoo.co.uk",
        "address1": "38B, Airport Road, Effurun",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18752,
        "ProviderName": "Care Foundation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033394266",
        "provideremail1": "NULL",
        "address1": "11, Gabriel Nwosu Avenue, Osisioma",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19780,
        "ProviderName": "Care Givers Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039110242",
        "provideremail1": "caregivershospital@gmail.com",
        "address1": "20, Torikoh Road, Opposite Coca-Cola Depot, Torikoh , Badagry",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19983,
        "ProviderName": "Caremax  Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09061545449",
        "provideremail1": "caremaxhospitals@yahoo.com",
        "address1": "2, Asajon Street Off Lekki Epe Express Way ,, Opp Nero water B/Stop Sango Tedo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18754,
        "ProviderName": "CarePoint Clinic, Egbeda",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033018550",
        "provideremail1": "admin.egbeda@carepointhospitals.com",
        "address1": "7, Ogunlana Street, Egbeda",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19427,
        "ProviderName": "CarePoint Clinic, Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033018550",
        "provideremail1": "admin.ojo@carepointhospitals.com",
        "address1": "9, Abbi Street, Rasaki Bus-stop off Shibiri Road Ilemba Awori Ojo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19467,
        "ProviderName": "Cares & Cares Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138541111",
        "provideremail1": "careshospitalcares@gmail.com",
        "address1": "1B, olubiyi Street, Along Great Challenge Road, Via Iyana School Bus Stop, Iba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19599,
        "ProviderName": "Cedarcare Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09062875238",
        "provideremail1": "hospital@cedarcaregroup.com",
        "address1": "Ogombo Road, Off Abraham Adesayan Roudabout, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19954,
        "ProviderName": "Cedarcare Hospital Ikota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09139352537",
        "provideremail1": "cedarcarehospital@gmail.com",
        "address1": "Megamound Shopping Complex, LekkyICounty Homes Estate, Ikota, Lekki, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19970,
        "ProviderName": "Cenac Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030326132",
        "provideremail1": "cenachospital@yahoo.com",
        "address1": "old  Assembly quaters Azikoro Road Ekeki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19452,
        "ProviderName": "Central Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023372919",
        "provideremail1": "cshota@ymail.com",
        "address1": "133, Idiroko Road, Oju-Ore, Sango Ota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19804,
        "ProviderName": "Channel Clinic & Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07067434036",
        "provideremail1": "channel.clinic@gmail.com",
        "address1": "10, Hospital Road Bonny",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18757,
        "ProviderName": "Chilas Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036930169",
        "provideremail1": "NULL",
        "address1": "20, Zaria Bye-Pass, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18759,
        "ProviderName": "Christ Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033333031",
        "provideremail1": "christmedicalcenter@gmail.com",
        "address1": "28, Randle Road, Apapa, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18760,
        "ProviderName": "Christ The King Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035959117",
        "provideremail1": "japokolo@yahoo.co.uk",
        "address1": "85, Afikpo Road, Abakaliki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18761,
        "ProviderName": "Christiana & Joseph Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033371220",
        "provideremail1": "josephandchristianahhospital@gmail.com",
        "address1": "Oke - Ilewo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18762,
        "ProviderName": "Citizen Hospital, Suleja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150420995",
        "provideremail1": "citizenhospital@yahoo.com",
        "address1": "18, Saidu Alkali Street, GRA Lowcost Estate, Suleja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18763,
        "ProviderName": "Citizen Medical Centre, Ikoyi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023131126",
        "provideremail1": "citizenmedicalcentre@yahoo.com",
        "address1": "80, Norman Williams Street, Ikoyi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18764,
        "ProviderName": "City Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037135334",
        "provideremail1": "NULL",
        "address1": "101, Ndidem Usang Iso Road, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18766,
        "ProviderName": "Clear View Optics, Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028475557",
        "provideremail1": "hmo.admin@clearviewopticsng.com",
        "address1": "Transcorp Hilton, Maitama, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18767,
        "ProviderName": "Clina Lancet Laboratories",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07046181382",
        "provideremail1": "NULL",
        "address1": "3, Babatunde Jose Street, off Adetokunbo Ademola, Victoria Island, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18769,
        "ProviderName": "Clinix Healthcare Ltd Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137993338",
        "provideremail1": "enquiry@clinixhealthcare.com.ng",
        "address1": "Plot B, Block XII, Alhaji Adejumo Avenue, Off Gbagada Oshodi Expressway, Anthony, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18768,
        "ProviderName": "Clinix Healthcare Ltd, Amuwo Odofin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137993338",
        "provideremail1": "claims.clinixhealthcare@gmail.com",
        "address1": "6, Ago Palace Way, By Apple Junction, Amuwo Odofin, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18770,
        "ProviderName": "Continental Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07066527133",
        "provideremail1": "NULL",
        "address1": "Engr. Adebayo Oshinbowale Close, Opp. Globacom House, Elewura B/Stop, Challenge.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19829,
        "ProviderName": "Coptic Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09039815570",
        "provideremail1": "info.ng@coptichospitals.org",
        "address1": "Plot 1, Block 11, Abila Abiodun Road, Oniru Private Estate , Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19774,
        "ProviderName": "Cosan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060514981",
        "provideremail1": "cosanhospital@yahoo.com",
        "address1": "House 6, Ola Balogun Street, Off Degolu Road, Igbe Laara via Oreyo Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19428,
        "ProviderName": "Cottage Medicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026261632",
        "provideremail1": "cottagemedicals@gmail.com",
        "address1": "18 Iwaya Road, Onike, Yaba\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18772,
        "ProviderName": "County Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032431487",
        "provideremail1": "countyhosp@yahoo.co.uk",
        "address1": "42/45, Isheri Road, Ogba, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18774,
        "ProviderName": "Cova Care Specialist Hospital & Endoscopy Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08182731100",
        "provideremail1": "covacare@yahoo.com",
        "address1": "8, Main Street, Farm Road 2, Eliozu, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19963,
        "ProviderName": "Credible Eyecare  Magodo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037358723",
        "provideremail1": "crediblevisioncare@gmail.com",
        "address1": "18, Kasumu Street Shangisha Magodo phase ii",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19962,
        "ProviderName": "Credible Eyecare Awka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037358723",
        "provideremail1": "crediblevisioncare@gmail.com",
        "address1": "7, Onwura Street Awka  South",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18776,
        "ProviderName": "Crest Hospital, Egan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033600955",
        "provideremail1": "hospitalcresthmo@yahoo.com",
        "address1": "159, Isuti Road, Egan.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18779,
        "ProviderName": "Crystal Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07056595596",
        "provideremail1": "providerservices@crystalhospital.com",
        "address1": "168, Akowonjo Road, Akowonjo, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19914,
        "ProviderName": "Cypresshill Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09164499914",
        "provideremail1": "info@cypresshillhospitals.com",
        "address1": "14, Olanrewaju Street ,Oregun ,Ikeja Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19991,
        "ProviderName": "D Great Ark Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08100324262",
        "provideremail1": "dgreatarkhospital@gmail.com",
        "address1": "28, Modupe Ayoade Street Araromi Iyana Ipaja",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18783,
        "ProviderName": "Dako Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033280454",
        "provideremail1": "dakomedicalcentre@yahoo.com",
        "address1": "185, Muyibi Street, Olodi Apapa, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19993,
        "ProviderName": "Dalet Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023053893",
        "provideremail1": "dalethospitalhmo@gmail.com",
        "address1": "Dalet Hospital, Sun view estate Opp crown estate Sangotedo. Lekki Epe ",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19653,
        "ProviderName": "Dalhatu Araf Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138919134",
        "provideremail1": "moibra662@gmail.com",
        "address1": "Dalhatu Araf Specialist Hospital Road, Shandam, Lafia",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18784,
        "ProviderName": "Dan Egerton Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033285916",
        "provideremail1": "NULL",
        "address1": "4, Oremerin Street, Off Okuyemisi, Itire, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19403,
        "ProviderName": "Dans Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08136916174",
        "provideremail1": "dansmedicalcare@gmail.com",
        "address1": "46, Thomas Olaniyan Street, Irawo, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18785,
        "ProviderName": "Dara Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09098795744",
        "provideremail1": "daramed@yahoo.com",
        "address1": "6, Ihiala Street, Area 2, Section 2, Garki, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19911,
        "ProviderName": "Dasochris Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033638549",
        "provideremail1": "dasochrisialashe@gmail.com",
        "address1": "2nd Avenue Poboyeso Estate Odonla Odonguyan Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18787,
        "ProviderName": "Daula Hospital and Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036917466",
        "provideremail1": "daulahospital@hotmail.com",
        "address1": "Kasuwa Daji Off Kaura Namoda Road, Lebin Lebin Street, Gusau, Zamfara State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19925,
        "ProviderName": "Dayspring  Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033339347",
        "provideremail1": "dayspringspecialisthmo@gmail.com",
        "address1": "No 1, Poppola Oladunni Street Ikotun Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19941,
        "ProviderName": "Dayspring  Specialist Hospital Ajah",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033339347",
        "provideremail1": "dayspringspecialisthmo@gmail.com",
        "address1": "Remlek Bus- Stop Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18791,
        "ProviderName": "DCL Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033072123",
        "provideremail1": "dejiclinic2005@yahoo.com",
        "address1": "41, Demurin Street (Mascara Junction) Ketu Alapere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19754,
        "ProviderName": "De-Lakeland Imperial Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07089747125",
        "provideremail1": "lakelandimperial@gmail.com",
        "address1": "180 Awolowo Way, Itamaga Ikorodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19900,
        "ProviderName": "Dee-Goodnews Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07018615489",
        "provideremail1": "deegoodnewshosp@gmail.com",
        "address1": "White House by Living Stone ,Orofun Town. Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19421,
        "ProviderName": "Deelacs Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033250434",
        "provideremail1": "deelacshospital@gmail.com",
        "address1": "11 Magbon Obele rd, Magbon bus stop , badagry road",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18792,
        "ProviderName": "Delta Crown Hospital, Ketu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352195",
        "provideremail1": "deltacrownhospital2013@gmail.com",
        "address1": "14, Ajayi Street, Ketu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19816,
        "ProviderName": "Delta Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035481515",
        "provideremail1": "deltabonny@yahoo.com",
        "address1": "69,Hospital Road , Bonny Island ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19789,
        "ProviderName": "Denovo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08085089833",
        "provideremail1": "denovohospitalinfor@gmail.com",
        "address1": "3, Denovo Close by Mofijo Filling Station Enekan , Rukpokwu Road , Porthacourt , River -State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19852,
        "ProviderName": "Desol Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09055605107  ",
        "provideremail1": "info@desohealthgroup.com",
        "address1": "12, Balogun Estate Rd, OPP Fara Park Estate Majek b/Stop",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18796,
        "ProviderName": "Dialyzer Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08029460911",
        "provideremail1": "dialyzermedicalcentre@ymail.com",
        "address1": "60, Arowojobe Street, Oshodi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19957,
        "ProviderName": "Diamond Crown Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036817851",
        "provideremail1": "diamondcrownsh@gmail.com",
        "address1": "No 1 Cross Avenue, Psychiatric Road Rumnigba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18798,
        "ProviderName": "Dip Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033078763",
        "provideremail1": "diphillabvisioncare@yahoo.com",
        "address1": "38, Isawo Road, Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19045,
        "ProviderName": "Diphillab Vision Care & Optical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033078763",
        "provideremail1": "diphillabvisioncare@yahoo.com",
        "address1": "38, Isawo Road, Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19917,
        "ProviderName": "Disney Medicals Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09060008708",
        "provideremail1": "disneymedicals@gmails.com",
        "address1": "3b, Elder Nwuba Street ,Off Orchid Hotel Road ,Lekki -Epe Xpressway Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18800,
        "ProviderName": "Divine Grace Hospital, Port Harcourt",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037046319",
        "provideremail1": "NULL",
        "address1": "28B, Seaside Road, Oyigbo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18801,
        "ProviderName": "Divine Medical Centre, Ikoyi",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07087859429",
        "provideremail1": "divinemedicalcentre@yahoo.com",
        "address1": "53, Norman Williams Street, Ikoyi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18802,
        "ProviderName": "Domingo Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08027306681",
        "provideremail1": "NULL",
        "address1": "Plot 9, Unit E, Ben Udoh Street, Ewet Housing Estate, Uyo, Akwa Ibom State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19959,
        "ProviderName": "Dominion Hospital Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08098790920",
        "provideremail1": "dominionhospitalservices@yahoo.com",
        "address1": "4,  Unum Street Off Elegusi B/Stop Owode Yewa",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18803,
        "ProviderName": "Doren Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08023062100",
        "provideremail1": "doren@hotmail.co.uk",
        "address1": "1, Kemfat Road, Km 28, Lekki–Epe Expressway, Ajah, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19847,
        "ProviderName": "Doren Specialist Hospital Ibeju Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032329193",
        "provideremail1": "doren@hotmail.co.uk",
        "address1": "Imalete-Alafia, Ibeju-Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18805,
        "ProviderName": "Dove Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068177773",
        "provideremail1": "dove.hospital@yahoo.com",
        "address1": "Badore Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18807,
        "ProviderName": "Duro Soleye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171227678",
        "provideremail1": "billing_dsh@yahoo.com",
        "address1": "36, Allen Avenue, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18809,
        "ProviderName": "Ebenezar Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033908772",
        "provideremail1": "NULL",
        "address1": "17, Station Road, Osogbo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18811,
        "ProviderName": "Edi International Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08067510994",
        "provideremail1": "admin@edihospital.com",
        "address1": "1, Coronation Drive, Off Aideyan Avenue",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19596,
        "ProviderName": "Edo Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09018296396",
        "provideremail1": "hmo@edospecialisthospital.com",
        "address1": "Plot 2. Sapele road Benin city, Edo state, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19704,
        "ProviderName": "Ekiti State University Teaching Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235478",
        "provideremail1": "eksuthmhis@yahoo.com",
        "address1": "State Hospital Road, Ado Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18812,
        "ProviderName": "El-Bethel Medical Centre, Sagamu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063513",
        "provideremail1": "elbethelmedicalcentre@gmail.com",
        "address1": "31, Dantajira Street, Sabo, Sagamu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18815,
        "ProviderName": "El-shaddai Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056085300",
        "provideremail1": "NULL",
        "address1": "6, Alhaji Oshibanjo St., Off Ejirin Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19498,
        "ProviderName": "Elf Foundation Mother and Child Clinics – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "+2340802222222",
        "provideremail1": "callcenter@metrohealth.com",
        "address1": "Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18813,
        "ProviderName": "Eli Johnson Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033367491",
        "provideremail1": "ejshospital@yahoo.com",
        "address1": "13, Bori Rd, Rumuibekwe Housing Estate.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18814,
        "ProviderName": "Elphy Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033055599",
        "provideremail1": "elphyspecialisthospital@yahoo.com",
        "address1": "6, Taskforce Road, PPL, Okokomaiko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18816,
        "ProviderName": "Emel Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07046083798",
        "provideremail1": "info@emelhospital.org",
        "address1": "Z Close, 21 Road, Festac Town, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18817,
        "ProviderName": "Endoscopy de Gilead Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034476437",
        "provideremail1": "NULL",
        "address1": "1, Gilead Lane, NTA Road, off Ilawe Road, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19536,
        "ProviderName": "Esso Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09023556918 ",
        "provideremail1": "essohospital@gmail.com",
        "address1": "52, Itele Agbaku Road, Maya, Ikorodu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18818,
        "ProviderName": "Estate Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039132858",
        "provideremail1": "NULL",
        "address1": "47, Onofeghara St., By Polokor Mkt, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19948,
        "ProviderName": "Eternal Life Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09011223874",
        "provideremail1": "eternallife.hospital@gmail.com",
        "address1": "31, Iganmu Road Orile LAGOS",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19953,
        "ProviderName": "Evanson Medical Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08062494974",
        "provideremail1": "evansonmedicalcenter@gmail.com",
        "address1": "2a, Iyalde Close, Off Ajayi Road , Oke -Ira , Ogba. Lagos State ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18821,
        "ProviderName": "Ever Life Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033610434",
        "provideremail1": "everlifehospital@yahoo.com",
        "address1": "3, Edoso Street, Ijanikin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19851,
        "ProviderName": "Evolution Hospital Limited",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08077313591",
        "provideremail1": "evolutionhospitals@yahoo.com",
        "address1": "16-18 Falilat Ojolowo Street ,CPI Bus Stop, Gberigbe, Ikirodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18825,
        "ProviderName": "Ezem Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034462817",
        "provideremail1": "NULL",
        "address1": "2/4, Osuji Street, Owerri Municipal.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18826,
        "ProviderName": "Faith City Hospital Ajao",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032308282",
        "provideremail1": "faithcitylagos@gmail.com",
        "address1": "16, Asa Afariogun Street, Ajao Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18828,
        "ProviderName": "Faith Foundation Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056568291",
        "provideremail1": "NULL",
        "address1": "57, Ndidem Usang Iso Road, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19426,
        "ProviderName": "Faith Hills Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033286876",
        "provideremail1": "faithhills.hospital@gmail.com",
        "address1": "faithhills.hospital@gmail.com\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19815,
        "ProviderName": "Faithshield Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033106",
        "provideremail1": "faithshield.enquiries@gmail.com",
        "address1": "15, Gasline Road , Magboro. Ogun -State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19453,
        "ProviderName": "Family Care Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08155000405",
        "provideremail1": "familycarehospitalbys@gmail.com",
        "address1": "108, AIT Elebele Road, Opolo, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19813,
        "ProviderName": "Family Care Specialist Clinics and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033187641",
        "provideremail1": "jidegwim@yahoo.com",
        "address1": "Road 15, Federal Housing Estate, Umuguma, New Owerri, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18830,
        "ProviderName": "Famo Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033136893",
        "provideremail1": "famoclinic@gmail.com",
        "address1": "11, Egbe Street, Alesa, Eleme",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18933,
        "ProviderName": "Famous Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08184676891",
        "provideremail1": "famoushospitalfmc@gmail.com",
        "address1": "Green Roof, Opposite Lekki Free Trade Zone, Ibeju Lekki, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18831,
        "ProviderName": "Fanimed Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023131678",
        "provideremail1": "fanimed92@yahoo.com",
        "address1": "Blk 210, Lily Road, LSDPC Medium Housing Estate (Phase IV), Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18832,
        "ProviderName": "Faramed Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036368730",
        "provideremail1": "faramedclinics@gmail.com",
        "address1": "37, Akinwunmi Street, Off Ijoko Rd, Sango.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18834,
        "ProviderName": "Fasanmi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033157028",
        "provideremail1": "NULL",
        "address1": "5/7, Olori Street, Shogunle",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19679,
        "ProviderName": "Federal Medical Centre, Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "fmcasabanhisdeskoffice@gmail.com",
        "address1": "Nnebisi Road, Isieke, Asaba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19763,
        "ProviderName": "Federal Medical Centre, Ebute Metta",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "dally.babatunde@fmceb.org",
        "address1": "2, Ondo Street, Ebute Metta, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19896,
        "ProviderName": "Fertile Ground Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08161105024",
        "provideremail1": "fertileghospital@gmail.com",
        "address1": "Plot 1234 Zaramaganda ,Adjacent  Gold B Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19922,
        "ProviderName": "Fimforte Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07016357096",
        "provideremail1": "fimfortehospital@gmail.com",
        "address1": "No 1,Bloombreed Sch Road, Opposite NTA ,Mgbuoba, Porthacourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19668,
        "ProviderName": "First Care Multi Specialist Hospital - Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036183940",
        "provideremail1": "firstcaremultispecialisthospt@gmail.com",
        "address1": "4, Mariam Pam Drive, Rayfield, Jos, Plateau State.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19667,
        "ProviderName": "First Care Multi Specialist Hospital - Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07063144606",
        "provideremail1": "firstcaremultispecialisthospt@gmail.com",
        "address1": "14, Rivers Road, Water Board G.R.A, Zaria",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18838,
        "ProviderName": "First City Hospital Osapa-Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033480926",
        "provideremail1": "firstcity_hospital@yahoo.com",
        "address1": "17, Osapa Road, Osapa-London, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18839,
        "ProviderName": "First Concept Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033263849",
        "provideremail1": "NULL",
        "address1": "Plot 111, Ohaji By Iheachuwa Street, Off MCC Road, Owerri : Near Chukwama Nwoha Junction ; Behind St John's Lab",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18842,
        "ProviderName": "First Faith Medical Centre, Karimu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022239535",
        "provideremail1": "firstfaithmedical@yahoo.com",
        "address1": "5, Karimu Street, Orile Iganmu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18843,
        "ProviderName": "First Faith Medical Centre, Kekere Ekun",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022239535",
        "provideremail1": "firstfaithmedical@yahoo.com",
        "address1": "2, Kekere Ekun Street, Orile Iganmu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19709,
        "ProviderName": "First Heritage Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023090762",
        "provideremail1": "info@firstheritagehospital.com",
        "address1": "29/33 Ayantuga street Mushin Lagos ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18845,
        "ProviderName": "First Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036511840",
        "provideremail1": "firsthospitalltd@rocketmail.com",
        "address1": "Along Jikwoyi-Karshi Expressway, opposite Jikwoyi Timber Shed, Agwangade",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18846,
        "ProviderName": "First Mercy Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034741444",
        "provideremail1": "fmshla.nig@gmail.com",
        "address1": "19C, Gbogi St., Off Oyemekun Rd., Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18850,
        "ProviderName": "Foresight Specialist  Eye Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023512002",
        "provideremail1": "adeyinkaashaye@yahoo.com",
        "address1": "UI Secretariat Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18855,
        "ProviderName": "Freehand Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035861221",
        "provideremail1": "NULL",
        "address1": "NULL",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19766,
        "ProviderName": "Gaarki Shepherd Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065467140",
        "provideremail1": "shepherdspecialistclinic@gmail.com",
        "address1": "25 Shuni Road Mabera Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18858,
        "ProviderName": "Garin Alimi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035765037",
        "provideremail1": "NULL",
        "address1": "283, Umoru Saro Road, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19650,
        "ProviderName": "Garki General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08095698172",
        "provideremail1": "customerservice@garkihospital.com",
        "address1": "Tafawa Balewa Way, Area 3, Garki, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18859,
        "ProviderName": "Garkuwa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033008009",
        "provideremail1": "NULL",
        "address1": "1A, Sultan Rd, Off Issa Kuita Rd., Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18860,
        "ProviderName": "Garrison Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023130311",
        "provideremail1": "NULL",
        "address1": "10, Udom Street, D-Line, Port Harcourt.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18862,
        "ProviderName": "Gateway Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037817902",
        "provideremail1": "egatewayhospital@yahoo.com",
        "address1": "36 Abuja St, Off Donga Rd, Dorowa, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18863,
        "ProviderName": "Gbagada General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08075594468",
        "provideremail1": "gbagadagh@yahoo.com",
        "address1": "1, Hospital Road, Gbagada",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18864,
        "ProviderName": "Gbeye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07019340251",
        "provideremail1": "NULL",
        "address1": "16, Ahoada Road, Omoku, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19655,
        "ProviderName": "GCEM Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08033848577",
        "provideremail1": "gcemhospital@gmail.com",
        "address1": "20, Tokunbo Street, Off Alagomeji Bus Stop, Yaba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19725,
        "ProviderName": "General Hospital Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052076625",
        "provideremail1": "generalhospitalilorin@gmail.com",
        "address1": "Abdullazze Attah Road Tiawo, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18866,
        "ProviderName": "Geo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023050089",
        "provideremail1": "frontdesk@geomedicalcentre.com",
        "address1": "12, Subairu Street, Ladipo, Oshodi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19986,
        "ProviderName": "Geranno Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150999542",
        "provideremail1": "gerannomedicalcentre@gmail.com",
        "address1": "12 Efosa Uyi street, off Irhirhi road, off Airport road, Benin city, Edo state",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18870,
        "ProviderName": "Giwa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037874081",
        "provideremail1": "NULL",
        "address1": "2, Giwa Road, Abakpa, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19971,
        "ProviderName": "GLad Tidings Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026155457",
        "provideremail1": "gladtidingshospitalhmo@yahoo.com",
        "address1": "1, Amusa Alabi Street, OFF Gbadamosi Opesa Street , Opeki B/Stop. Ipaja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18871,
        "ProviderName": "Gloria Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08054434617",
        "provideremail1": "gloriahospital@gmail.com",
        "address1": "9, Oladehinde St., Maryland, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19794,
        "ProviderName": "Glorious Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08143703828",
        "provideremail1": "gloriousmediccentre@gmail.com",
        "address1": "No 4, Timber Market Road , Oyigbo West , Oyingbo, River State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18872,
        "ProviderName": "Glory Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033788454",
        "provideremail1": "glorymedibafo@yahoo.com",
        "address1": "16, Nurudeen Oshodi Street, Kwara Qtrs",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19405,
        "ProviderName": "Gods Apple City Hospital Asese",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038081498",
        "provideremail1": "godsapplecityhospital@gmail.com",
        "address1": "7, Glo Road, Asese bus stop, Ogun state",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18875,
        "ProviderName": "Goldie Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033329439",
        "provideremail1": "NULL",
        "address1": "4, Ekong Nyong Close, Eta Agbo Layout.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18876,
        "ProviderName": "Good Faith Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023141292",
        "provideremail1": "NULL",
        "address1": "1, Akindelu Street, Ikate, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18879,
        "ProviderName": "Good Tidings Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033153110",
        "provideremail1": "goodtidingshospital@yahoo.co.uk",
        "address1": "37-39, Fashoro Street, Ojuelegba, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19822,
        "ProviderName": "Good Times Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09131039140",
        "provideremail1": "goodtimesmedicalcentre@gmail.com",
        "address1": "7,Aladun Estate Arulogun Road , Ojoo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19469,
        "ProviderName": "Goodluck Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033317184",
        "provideremail1": "drabulu@yahoo.com",
        "address1": "4, The Light Close, Mercy Estate, Asore, Ogun State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19800,
        "ProviderName": "Goodness Medical Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033467912",
        "provideremail1": "goodnessmedicalcentre@yahoo.com",
        "address1": "No 151, Shibiri Road, Opp Council, Ojo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19866,
        "ProviderName": "Goshen Medicare Solutions and Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038161965",
        "provideremail1": "info.gfmaa@gmail.com",
        "address1": "No 1, Opara Close , Off Ada George Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18881,
        "ProviderName": "Gowon Estate Clinic Consortium",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033027785",
        "provideremail1": "gestateclinic@yahoo.com",
        "address1": "Plot 40A, 33 Road, Gowon Estate, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18884,
        "ProviderName": "Grace Touch Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037767916",
        "provideremail1": "NULL",
        "address1": "36, Azikiwe Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18885,
        "ProviderName": "Graceland Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033038626",
        "provideremail1": "gmcdrugs2002@yahoo.co.uk",
        "address1": "5, LASU-Isheri Road, Iba, Ojo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19775,
        "ProviderName": "Green Life Hospital - Ago",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033772753 ",
        "provideremail1": "greenlifehospitalagor@gmail.com",
        "address1": "28 Owolabi Street, Ago Palace Way, Oshodi-Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19712,
        "ProviderName": "Green Life Hospital - Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09060007146",
        "provideremail1": "greenlifehospital@gmail.com",
        "address1": "15 Association Avenue Ilupeju, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19782,
        "ProviderName": "Greenwood Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09037701097",
        "provideremail1": "info@greenwoodhospital.com",
        "address1": "No 1, Odunlami Street , Off oshidelum, Off Akilo Road Agege",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19892,
        "ProviderName": "Group Christian Medical Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034077945",
        "provideremail1": "gcmc_ok@yahoo.com",
        "address1": "No 3,Shirlet Onyemenam Street Oknan",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18886,
        "ProviderName": "Group Medical Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08129458349",
        "provideremail1": "groupmedical2017@gmail.com",
        "address1": "4, Queen Elizabeth Road, Mokola, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19883,
        "ProviderName": "Guardian Health Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08124317489",
        "provideremail1": "guardian_healthy@yahoo.com",
        "address1": "182, Ekoro Road Upstair b/stop Abule -Egba Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19956,
        "ProviderName": "Guscare Medical Limited ",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08022900002",
        "provideremail1": "guscaremedicalltd@gmail.com",
        "address1": "No, 35b, Orogbon Crescent  GRA ,Phase ii POrthacourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18887,
        "ProviderName": "Hamkad Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033301875",
        "provideremail1": "hamkadhospital.hmos@gmail.com",
        "address1": "39, Olawale Cole Street, U-Turn Bus-stop, Abule Egba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18891,
        "ProviderName": "Healing Field Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08080502612",
        "provideremail1": "NULL",
        "address1": "1, Ajibade Oke Street, Ajao Estate, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19666,
        "ProviderName": "Healing Touch Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034035649",
        "provideremail1": "healingtouchhospital16@gmail.com",
        "address1": "42,Oludegun Avenue, Ire-Akari, Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19656,
        "ProviderName": "Healing Virtues Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065600183",
        "provideremail1": "healingvirtueshospital2@gmail.com",
        "address1": "21, Odubakin Street, Abule Agun, Baruwa, Ipaja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18892,
        "ProviderName": "Heals Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033019832",
        "provideremail1": "NULL",
        "address1": "40b, Godwin Omonua Street, Ire-Akari Estate, Isolo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19881,
        "ProviderName": "Healthbridge Medical Consultant",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08095216350",
        "provideremail1": "healthbridgemc@gmail.com",
        "address1": "Plot F1/A-B Abacha Road GRA Phase 3 Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19869,
        "ProviderName": "Heda Hospital & Maternity ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08180142447",
        "provideremail1": "hedahospital@yahoo.com",
        "address1": "No 2,Nwabueze Close ,Off Aina Jegede close ,Ajao Estate",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18893,
        "ProviderName": "Heilung Kreuz Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030000855",
        "provideremail1": "info@heilungkreuzhospital.com",
        "address1": "Km 42, Lekki-Epe Expressway, Awoyaya",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18894,
        "ProviderName": "Hemko Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039032408",
        "provideremail1": "NULL",
        "address1": "Km 7, Makurdi-Aliade Road, Makurdi.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18897,
        "ProviderName": "Hephzibah Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08188564302",
        "provideremail1": "hephzibaheyecare@gmail.com",
        "address1": "50, Oba Akran Avenue, (Opp. UBA) Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18899,
        "ProviderName": "Hetta Medical Centre, Odan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034738466",
        "provideremail1": "hettamedicalcentre@yahoo.com",
        "address1": "2, Awene Close, Off Market Rd, Odan.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18900,
        "ProviderName": "Hetta Medical Centre, Otta",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034738466",
        "provideremail1": "hettamedicalcentre@yahoo.com",
        "address1": "15, Matina Road, Sango Ota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18901,
        "ProviderName": "Hillstar Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08168127255",
        "provideremail1": "hillstar_hospital@yahoo.com",
        "address1": "98, Palm Avenue, Papa Ajao, Mushin.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18903,
        "ProviderName": "Holy Cross Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035132717",
        "provideremail1": "NULL",
        "address1": "Ijaye.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19799,
        "ProviderName": "Holy Trinity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167599420",
        "provideremail1": "holytrinityhospitals2gmail.com",
        "address1": "110b, Obafemi Awolowo Way , Ikeja .Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18905,
        "ProviderName": "Homa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065490571",
        "provideremail1": "homa.hospital@yahoo.com",
        "address1": "Plot 18, Hudco Quarters, High Level Area, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18906,
        "ProviderName": "Hope Clinic & Maternity, Auchi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037778238",
        "provideremail1": "hopeclinic99@hotmail.com",
        "address1": "1, Whitehouse Street, Jatau, Auchi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19824,
        "ProviderName": "Hopehill Specialist Medical Centre Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030680770",
        "provideremail1": "hopehillmedical@gmail.com",
        "address1": "No 7c Hospital Place",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18907,
        "ProviderName": "Hopeland Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033521376",
        "provideremail1": "NULL",
        "address1": "1, Arikawe Street, Beside Elizade Motors, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18908,
        "ProviderName": "Horstman Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033167877",
        "provideremail1": "NULL",
        "address1": "32, Okigwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18909,
        "ProviderName": "Humanity Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033936309",
        "provideremail1": "NULL",
        "address1": "3, Humanity Hospital Close, Enerhen",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18910,
        "ProviderName": "Ibijola Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08029099204",
        "provideremail1": "ibijolahospital@yahoo.com",
        "address1": "KM 31, Lagos – Badagry Expressway, Agbara.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18911,
        "ProviderName": "Icon Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033185341",
        "provideremail1": "NULL",
        "address1": "303, Nnebisi Road, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18912,
        "ProviderName": "Ideal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035641461",
        "provideremail1": "NULL",
        "address1": "74, Emir Avenue Sabon Gari, Kano",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19974,
        "ProviderName": "Ifako Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08144932281",
        "provideremail1": "ifakomedical@gmail.com",
        "address1": "65 Oyemekun  Street Off  College  Road Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18914,
        "ProviderName": "Ifebi Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068954494",
        "provideremail1": "NULL",
        "address1": "16A, Igwebuike School Road, Awka",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18916,
        "ProviderName": "Ihenyen Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033595764",
        "provideremail1": "NULL",
        "address1": "4, Jemide Drive, Off Goodwill, Ekenhwan",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18917,
        "ProviderName": "Ikeja Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033076995",
        "provideremail1": "imc_aviation21@yahoo.com",
        "address1": "11, Ogunmodede Street, Off Allen Av., Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18918,
        "ProviderName": "Ikenegbu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028537535",
        "provideremail1": "NULL",
        "address1": "11-13, Pat Amadi Crescent, Off Okigwe Road, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18920,
        "ProviderName": "Ila Universal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036583150",
        "provideremail1": "ilauhkj@gmail.com",
        "address1": "Plot MF1, AA1 Layout, Kuje.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19598,
        "ProviderName": "Ilogbo Central Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09084975992",
        "provideremail1": "centralhospital08@rocketmail.com",
        "address1": "175 Ilogbo Road, Ilogbo, Ajangbadi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18923,
        "ProviderName": "Image Diagnostics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030731522",
        "provideremail1": "NULL",
        "address1": "9B, Eligbam Road, opp P/Harcourt Polytechnic, Rumuola, Port Harcourt, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18924,
        "ProviderName": "Immanuel Infirmary",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023315453",
        "provideremail1": "NULL",
        "address1": "1, Assemblies of God Rd., Army Junction Ikot Effang.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18925,
        "ProviderName": "Inland Medical Centre, Akoko",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09086012516",
        "provideremail1": "inlandmedic@gmail.com",
        "address1": "2, Olanipekun Lane, Ikare, Akoko.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19675,
        "ProviderName": "Inland Specialist Hospital - Ogudu",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08020638624",
        "provideremail1": "inlandspecialisthospital@yahoo.com",
        "address1": "153 Ogudu Road, Ogudu GRA",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18926,
        "ProviderName": "Inland Specialist Hospital, Ketu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08088107349",
        "provideremail1": "inlandspecialisthospital@yahoo.com",
        "address1": "11, Bola Owodunni Street, Alapere, Ketu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19518,
        "ProviderName": "Instant Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038596947",
        "provideremail1": "instantcarehospital20@gmail.com",
        "address1": "62,Oladun Street, Powerline Bus Stop, Beside Divine Emporium Event Centre, Isheri- Olofin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18927,
        "ProviderName": "International Clinics and Hospital Limited",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037877919",
        "provideremail1": "internationalclinicsh@yahoo.com",
        "address1": "40 Niger Street/2A Airport Road, Kano.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19659,
        "ProviderName": "Ire Anu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08059980066",
        "provideremail1": "infoireanuhospital@yahoo.com",
        "address1": "120 Lagos Road, Ikorodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19992,
        "ProviderName": "Isuti Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09123340215",
        "provideremail1": "isutimedicalhmoclients@gmail.com",
        "address1": "16, Steve Igbeson Street , Egan",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18931,
        "ProviderName": "Ivory Clinic and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07066410893",
        "provideremail1": "ivory.clinic@yahoo.com",
        "address1": "550 Aminu Kano Way, Kano.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18932,
        "ProviderName": "Iyalode Bisoye Tejuosho Hospital (Teju Industrial Clinic)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080913139",
        "provideremail1": "NULL",
        "address1": "20/22 Ilupeju Bye-Pass, Town Planning way, Ilupeju",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18934,
        "ProviderName": "Jamaá Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035985653",
        "provideremail1": "NULL",
        "address1": "90, Sarkin Pawa Road, Samaru, Zaria.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18935,
        "ProviderName": "Jefis Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08121443347",
        "provideremail1": "jefishospital2006@gmail.com",
        "address1": "9, Adegbola Street, Alakuko, Ijaiye Ojokoro.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18937,
        "ProviderName": "Jerab Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036208853",
        "provideremail1": "NULL",
        "address1": "Jeremiah Abutu Close, Kutunku Road, off FRCN rd, Gwagwalada, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18938,
        "ProviderName": "Jethrone Hospital & Diagnostics Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038040339",
        "provideremail1": "hjethrone24@gmail.com",
        "address1": "44, NYSC road (by FRSC office), Gbazango, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18939,
        "ProviderName": "Jicon Hospital & Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037042446",
        "provideremail1": "NULL",
        "address1": "4, Danwaire Street, Barnawa, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19395,
        "ProviderName": "JJANED Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09034291441",
        "provideremail1": "jjanedsh@gmail.com",
        "address1": "3, Jerry Nwakobi Street, Beside Lekki Gardens, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18941,
        "ProviderName": "Jobi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023193932",
        "provideremail1": "jobihospital@hotmail.com",
        "address1": "3/5, Adeyeri Owuyo Street, Ikorodu, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19430,
        "ProviderName": "Joedan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033589067",
        "provideremail1": "joedanhospitaltd@yahoo.com",
        "address1": "3, Lagos Badagry Express Road, Orile Bus Stop, Iganmu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19928,
        "ProviderName": "Jomapet Hospital & Maternity Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09020623515",
        "provideremail1": "jomahospital61@gmail.com",
        "address1": "61,Yawole Road Dreamsville Estate ,Ajuwon ,Ogun State ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18945,
        "ProviderName": "Jowako Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07088184485",
        "provideremail1": "jowako.hospital@yahoo.com",
        "address1": "F15, Jos Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18946,
        "ProviderName": "Julius Ezenyirioha Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038554523",
        "provideremail1": "NULL",
        "address1": "39, Atani Street, Abakpa-Nike, Enugu, Enugu State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19714,
        "ProviderName": "K-Dara Specialist Clinics.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035922915",
        "provideremail1": "kabirdara@yahoo.com",
        "address1": "Gidan Dawa, Katsina",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19703,
        "ProviderName": "Kanayo Specialist Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "kanayospecialisthospital@yahoo.com",
        "address1": "17 Enugu Road, GRA, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18949,
        "ProviderName": "Karaye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066024503",
        "provideremail1": "bellooricha@yahoo.com",
        "address1": "Emir Yahaya Road, Sokoto.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19809,
        "ProviderName": "Kariden Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039339144",
        "provideremail1": "karidenspecialisthospital@gmail.com",
        "address1": "7, Monago Close , Off East -West Rd, Rumuodara",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19848,
        "ProviderName": "Kasomene Faith City Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09135127425",
        "provideremail1": "kasomenefaithcity@gmail.com",
        "address1": "No 5, Shell location Road , Off Iwoje Rd , Portharcort ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19823,
        "ProviderName": "Katameya First Call Hospital Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09128781858",
        "provideremail1": "safeibro@yahoo.com",
        "address1": "Opp Forma Govt Lodge After T & T Junction before the bridge ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18951,
        "ProviderName": "Kendox Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033421678",
        "provideremail1": "NULL",
        "address1": "194, Old Refinery Road, Elelenwo, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18952,
        "ProviderName": "Kez Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033422336",
        "provideremail1": "NULL",
        "address1": "16, Wopara Street, Rumuomasi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18954,
        "ProviderName": "Kiladejo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069445717",
        "provideremail1": "kiladejohosp@yahoo.com",
        "address1": "15, PPL Road, Okokomaiko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19821,
        "ProviderName": "King’s Cross Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035734279",
        "provideremail1": "anthonyegboh@yahoo.co.uk",
        "address1": "20, Badagry Street, High Level, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18955,
        "ProviderName": "Kings Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08139734223",
        "provideremail1": "hmok57@yahoo.com",
        "address1": "Plot 2181, Ibrahim Babangida Way, Wuse 4, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19711,
        "ProviderName": "Kingsword Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032546080",
        "provideremail1": "kingswordhospital@gmail.com",
        "address1": "1 Oluwawemimo Hally/Faith Road Hally.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18956,
        "ProviderName": "KLM Muslim Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033813028",
        "provideremail1": "NULL",
        "address1": "Fesu Road, Iwo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18957,
        "ProviderName": "Korede Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037187560",
        "provideremail1": "NULL",
        "address1": "Ibara Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18959,
        "ProviderName": "Kowa Clinic, Ughelli",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033362091",
        "provideremail1": "kowaclinic@yahoo.com",
        "address1": "15, Isoko Road, Ughelli",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18961,
        "ProviderName": "Krown Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055233730",
        "provideremail1": "krown1@hotmail.com",
        "address1": "11, Alhaji Sekoni Street, Off Alimosho Rd., Iyana Ipaja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18962,
        "ProviderName": "Kubwa General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033022566",
        "provideremail1": "NULL",
        "address1": "Phase 4, opposite GSS, Kubwa, Abuja, FCT.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18964,
        "ProviderName": "Kuro Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068883476",
        "provideremail1": "NULL",
        "address1": "16, Otiotio Rd, Yenezue-Gene, Yenagoa.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18965,
        "ProviderName": "Kyutis Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023039815",
        "provideremail1": "kyutisclinic@gmail.com",
        "address1": "2A, Raymond Njoku Street, Ikoyi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18967,
        "ProviderName": "Lad Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033227534",
        "provideremail1": "ladhospital97@yahoo.com",
        "address1": "Orita Challenge, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18968,
        "ProviderName": "Ladi-Lak Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055008908",
        "provideremail1": "ladilakmedicalcentre@yahoo.com",
        "address1": "53, Igi Olugbin Street, Bariga, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18969,
        "ProviderName": "Ladiya Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039620209",
        "provideremail1": "NULL",
        "address1": "64, Benin Street, Zaria, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18971,
        "ProviderName": "Lafiya Clinics & Health Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036026349",
        "provideremail1": "lafiyaclinichealthservice@gmail.com",
        "address1": "Plot 57, Marunmarun layout, Western Bye-pass, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19909,
        "ProviderName": "Lakeland Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033802242",
        "provideremail1": "lakelandsc01@gmail.com",
        "address1": "2,Kingsley Otuaro Drive,Mosheshe Estate ,Airport Road Effurum",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18978,
        "ProviderName": "Lanark Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033643654",
        "provideremail1": "lanarkspecialisthospital@yahoo.com",
        "address1": "8, Wuraola-Esan Close, off New Adeoyo State Hospital Road, Ring Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18979,
        "ProviderName": "Lanik Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034742656",
        "provideremail1": "lanikmedicalcentre@gmail.com",
        "address1": "14, Omo Owo Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19946,
        "ProviderName": "Lantos Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08112794316",
        "provideremail1": "lantoshospital@yahoo.com",
        "address1": "54, Adesan Road , Off Ofada Road , Mowe ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19841,
        "ProviderName": "Lawrak Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142289683",
        "provideremail1": "lawrakhospital2007@yahoo.com",
        "address1": "21, Temple Road Off Miango Road Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18981,
        "ProviderName": "Lennox Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023266709",
        "provideremail1": "lennoxhospital@gmail.com",
        "address1": "Plot 7, Floodgate Street, off Lekki-Epe Expressway, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18982,
        "ProviderName": "Lex Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033071587",
        "provideremail1": "lexmediclinics@yahoo.co.uk",
        "address1": "5 Raliat Alabi St, Iyana Meiran , Ijaye Ojokoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18983,
        "ProviderName": "Liberty Hospital, Suleja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033491820",
        "provideremail1": "femiadedoye@gmail.com",
        "address1": "Behind Ecobank, Minna Road, Suleja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18984,
        "ProviderName": "Liberty Specialist Hospital, Ikeja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08089337825",
        "provideremail1": "NULL",
        "address1": "12, Ajanaku Street, Awuse Estate, Opebi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18986,
        "ProviderName": "Life Care Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033166678",
        "provideremail1": "NULL",
        "address1": "Plot 120A, Old G.R.A., Aba-Owerri Road (Opp. Abia State Polytechnic), Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19759,
        "ProviderName": "Life Fountain Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023383495",
        "provideremail1": "lifefountainmedicalclinic@gmail.com",
        "address1": "41 Oyedele Ogunniyi Street, Anthony Village, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18988,
        "ProviderName": "Life Spring Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037124056",
        "provideremail1": "lifespringrh@gmail.com",
        "address1": "Plot 49, Okigwe rd layout (opp FRSC), Ikenegbu, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18989,
        "ProviderName": "Lifegate Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033166678",
        "provideremail1": "lifegatespecialisthospital@gmail.com",
        "address1": "2B Abiodun Jagun Street, Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19663,
        "ProviderName": "Lifeshade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036246748",
        "provideremail1": "lifeshadehospital@gmail.com",
        "address1": "3, Oshonogbehin Street, Off Awolowa Way, Ota-Ona, Ikorodu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18993,
        "ProviderName": "Light Hospital & Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150731596",
        "provideremail1": "NULL",
        "address1": "15, Olumide Onanubi Street, Akowonjo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18994,
        "ProviderName": "Lily Hospital - Warri",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07053351700",
        "provideremail1": "omotayo.oshinowo@lilyhospitals.com",
        "address1": "6, Brisibe St., Off Etuwewe Rd., Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18996,
        "ProviderName": "Livia Shammah  - ERROR",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034520804",
        "provideremail1": "liviashammahfrontdesk@gmail.com",
        "address1": "Plot 5, Shammah Close, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19445,
        "ProviderName": "Livia Shammah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034520804",
        "provideremail1": "liviashammahhospitalsltd@gmail.com",
        "address1": "Plot 5, Shammah Close, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19660,
        "ProviderName": "Living Heart Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07062108881",
        "provideremail1": "livingheartspecialist@gmail.com",
        "address1": "7A Udeco Medical Road, Chevy View Estate, Chevron Drive, Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18997,
        "ProviderName": "Living Stream Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065702068",
        "provideremail1": "etalworldjoy@gmail.com",
        "address1": "Plot 42, Phase 1, Lay-out 2, opp Omoye Hostel, Gwagwalada",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18998,
        "ProviderName": "Living Word Mission Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033418349",
        "provideremail1": "livingwordmissionhospitalltd@yahoo.com",
        "address1": "5/7, Umuocham Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18999,
        "ProviderName": "Longing Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08081366069",
        "provideremail1": "longingmedicals@yahoo.co.uk",
        "address1": "1, Josepha Close, Ijaiye Ojokoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19001,
        "ProviderName": "Lota Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08174598513",
        "provideremail1": "lotamedical_2004@yahoo.com",
        "address1": "Salvation St., Powerline B/S, Isheri Olofin.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19002,
        "ProviderName": "LouisMed Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130101759",
        "provideremail1": "louismedhospital@yahoo.com",
        "address1": "Plot 8, Iron Bar Street, Lekki Phase I.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19863,
        "ProviderName": "Luscent hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023823454",
        "provideremail1": "luscenthospital33@gmail.com",
        "address1": "Plot 9 Arimi Idogun street Imaletafia Ibeju Lekki Lagos Nigeria.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19003,
        "ProviderName": "Makog Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037270210",
        "provideremail1": "makogmdls@gmail.com",
        "address1": "Makog House, along LFTZ Road, Magbon-Alade, Ibeju-Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19004,
        "ProviderName": "Maneks Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033496455",
        "provideremail1": "NULL",
        "address1": "A1, Block 5, Makera Road, Kakuri, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19005,
        "ProviderName": "Mararaba Gurku Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09055886398",
        "provideremail1": "NULL",
        "address1": "Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19973,
        "ProviderName": "Marina Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08086669491",
        "provideremail1": "marinahospital1@gmail.com",
        "address1": "Chief Odum  Close Off No 1, Ogbua Street Egbum -Na bali ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19007,
        "ProviderName": "Mary the Queen Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023117872",
        "provideremail1": "NULL",
        "address1": "29, Babalola Street, Akowonjo Shasha.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19727,
        "ProviderName": "Mascap Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167634611",
        "provideremail1": "mascaphospital@gmail.com",
        "address1": "34 Adeola Road, Sapele.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19008,
        "ProviderName": "Maternal Child Specialist Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033886173",
        "provideremail1": "motherchildhosp@yahoo.com",
        "address1": "Behinde immigration Office, Off Polythenic - ABUAD Road",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19012,
        "ProviderName": "May Clinics, Ilasamaja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096096577",
        "provideremail1": "hmo@mayclinicsltd.com",
        "address1": "24/26, Sadiku Street, Off Apapa – Isolo Expressway, Ilasamaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19013,
        "ProviderName": "May Clinics, Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096096577",
        "provideremail1": "hmo@mayclinicsltd.com",
        "address1": "20, Sura Mogaji Street, Ilupeju, Mushin, Lagos, Nigeria.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19014,
        "ProviderName": "Mayday Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037051773",
        "provideremail1": "NULL",
        "address1": "1, Pecados Street, opp Skye Bank, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19700,
        "ProviderName": "Mayflower Hospital & Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030500933",
        "provideremail1": "mayflowerhosp8@gmail.com",
        "address1": "14, Bankole Street, Isheri, Magodo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19015,
        "ProviderName": "Me-Cure Healthcare Ltd, Oshodi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000632873",
        "provideremail1": "info@mecure.com.ng",
        "address1": "Me-Cure House, Apapa-Oshodi Expressway, Oshodi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19833,
        "ProviderName": "Me-Cure Healthcare, Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000632873",
        "provideremail1": "info@mecure.com.ng",
        "address1": "Niyi Okunubi St, Lekki Phase 1, Lekki\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19788,
        "ProviderName": "Mec Health Family Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036660448",
        "provideremail1": "mechealthfamilyhospital@gmail.com",
        "address1": "90 A, Otta Road Meiran Area, Ekoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19017,
        "ProviderName": "Med-In Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023500846",
        "provideremail1": "medinhmo@gmail.com",
        "address1": "1, Osogbo Street, Off Celestial Way, Ogudu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19765,
        "ProviderName": "Medcare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030983735",
        "provideremail1": "medcarehospitals@gmail.com",
        "address1": "182, Pipeline Road, Adjacent CBN - Quarters, Gaa Akanbi Ilorin ",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19778,
        "ProviderName": "Medford Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09085856868",
        "provideremail1": "medfordhospitals.com",
        "address1": "Plot A144 Katampe Road Mpape",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19016,
        "ProviderName": "Medimax Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034959021",
        "provideremail1": "Bernice.medimax@gmail.com",
        "address1": "Plot 555, Cadastral Zone B02, Durumi District, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19840,
        "ProviderName": "Mediplus Premier Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130001789",
        "provideremail1": "medipluspremierhospital.com",
        "address1": "8, Ogbodu Avenue, Galovita, Odonla, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19018,
        "ProviderName": "Medol Consultant Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033013160",
        "provideremail1": "medolclinic01@yahoo.com",
        "address1": "54 Adaranijo Street, opposite Pedro Pry. School, Shomolu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19019,
        "ProviderName": "Medytop Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037159057",
        "provideremail1": "hmo@medytopspecialisthospital.com",
        "address1": "3, Ejimo Abebiosu St., (Off Isale Ojumele Junction).",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19021,
        "ProviderName": "Mends Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033112061",
        "provideremail1": "NULL",
        "address1": "5, Abba Kyari, Close, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19023,
        "ProviderName": "Mercy Group Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033255825",
        "provideremail1": "mercygroupclinics@yahoo.com",
        "address1": "Panseke, Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19447,
        "ProviderName": "Mercy Specialist Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07069477890",
        "provideremail1": "mshmonitsha@yahoo.com",
        "address1": "4A, New American Road, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19468,
        "ProviderName": "Mercy Thomas Oredugba Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08123933936",
        "provideremail1": "mtomedical@gmail.com",
        "address1": "8, Mogati Street, Ijeshatedo, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19832,
        "ProviderName": "Mercyland Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137748362",
        "provideremail1": "mercylandspecialisthospital200@gmail.com",
        "address1": "Opposite Conoil Petro Station , Near  Nkpolu Junction East / West Road Portharcourt  ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19661,
        "ProviderName": "Mev Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080008048",
        "provideremail1": "mevspecialisthospital@gmail.com",
        "address1": "6 Djibouti Street, Wuse 2, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19886,
        "ProviderName": "Michael Cross Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026378305",
        "provideremail1": "michcross_2005@yahoo.co.uk",
        "address1": "Plot f9 Church Road Kapa sITE",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19033,
        "ProviderName": "Midland Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065399227",
        "provideremail1": "NULL",
        "address1": "144, Karu Site, Karu",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19034,
        "ProviderName": "Miracle Seed Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08185631838",
        "provideremail1": "miracleseedclinicmaternity@yahoo.com",
        "address1": "40B, Sultan Dasuki Road, Phase 2 Site 2, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19035,
        "ProviderName": "Miyetti Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036763868",
        "provideremail1": "NULL",
        "address1": "Federal Low Cost, Gombe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19036,
        "ProviderName": "Mobolaji Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023504660",
        "provideremail1": "NULL",
        "address1": "43B, Obafemi Awolowo Way. Oke-Bola.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19037,
        "ProviderName": "Molly Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035633329",
        "provideremail1": "mollyspecialisthospital@yahoo.com",
        "address1": "6, American Quarters, Agodi Yidi, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19038,
        "ProviderName": "Momaak Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037075956",
        "provideremail1": "NULL",
        "address1": "3, Lafe Inn Way, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19043,
        "ProviderName": "Mother of Christ Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08106789768",
        "provideremail1": "motherchristnhis@gmail.com",
        "address1": "Holy Ghost Catholic Compound, Ogui.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19825,
        "ProviderName": "Mother Teresa Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167819751",
        "provideremail1": "motherteresamedicalcentre@gmail.com",
        "address1": "107,Las oRoad, Idimu -Egbe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19046,
        "ProviderName": "Mount Gilead Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056700067",
        "provideremail1": "mountgileadhospital@gmail.com",
        "address1": "1, Gilead close, off Uselu-Lagos Road (behind Zenith bank), Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19047,
        "ProviderName": "Mount Pisgah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08101859335",
        "provideremail1": "NULL",
        "address1": "1, Samuel Sode St., Off Isolo Rd, Egbe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19048,
        "ProviderName": "Mount Sinai Hospital, Egbe",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "mmountsinaigroup@gmail.com",
        "address1": "133, Isolo Road, Egbe, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19049,
        "ProviderName": "Mount Sinai Hospital, Mushin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "NULL",
        "address1": "32, Olanibi Street, Papa Ajao, Mushin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19441,
        "ProviderName": "Mount Sinai Hospital, Surulere",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "mmountsinaigroup@gmail.com",
        "address1": "30, Falolu Street, Surulere ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19051,
        "ProviderName": "Mount Zion Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033276161",
        "provideremail1": "NULL",
        "address1": "9th Mile Corner, Ngwo, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19053,
        "ProviderName": "Mucas Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052345000",
        "provideremail1": "NULL",
        "address1": "19, Ogun Street, Adeale Bus Stop, Dopemu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19054,
        "ProviderName": "Nagari Allah Magami Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035902024",
        "provideremail1": "NULL",
        "address1": "10, Abubakar Burga Rd, adjacent High Court 2, Keffi.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19055,
        "ProviderName": "Nakowa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034948118",
        "provideremail1": "NULL",
        "address1": "16, Lagos St., Old GRA (Government House), Maiduguri",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19056,
        "ProviderName": "Narrow Way Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036760899",
        "provideremail1": "NULL",
        "address1": "9, Sunday Ahanor Street, Aduwawa, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19057,
        "ProviderName": "Nasara Clinic & Maternity, Kontagora",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069080381",
        "provideremail1": "nasaraclinic@yahoo.com",
        "address1": "Maigari Godabe Road, GRA, Kontagora",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19631,
        "ProviderName": "Naval Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09054396520",
        "provideremail1": "nmc_nndmedical@yahoo.com",
        "address1": "Naval Dockyard, Wilmot Point, Ahmadu Bello Way, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19058,
        "ProviderName": "New Crescent Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034456743",
        "provideremail1": "NULL",
        "address1": "23, New Zaria Terrace, Zaria By-Pass, Jos.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19059,
        "ProviderName": "New Era Hospital & Maternity, Aba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037022637",
        "provideremail1": "NULL",
        "address1": "213/215, Nnamdi Azikiwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19060,
        "ProviderName": "New Era Hospital & Maternity, Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030525046",
        "provideremail1": "NULL",
        "address1": "13, Funtua Street, Kafancha, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19707,
        "ProviderName": "New Evolution Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09081908114",
        "provideremail1": "newevolutionhospitalhmo@gmail.com",
        "address1": "69 Ago Palace Way, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19810,
        "ProviderName": "New Mile One Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132184968",
        "provideremail1": "newmileonehospital@gmail.com",
        "address1": "2, Manila Pepple Street D/Line Porthacourt ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19063,
        "ProviderName": "Niger Optical Services, Aba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "18, Asa Road, Aba, Abia State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19064,
        "ProviderName": "Niger Optical Services, Abakaliki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "1, Water Works Road, Abakaliki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19065,
        "ProviderName": "Niger Optical Services, Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08064709457",
        "provideremail1": "NULL",
        "address1": "1st Floor, Standard Plaza, Plot 2302 Herbert Macaulay Way, Wuse Zone 6",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19066,
        "ProviderName": "Niger Optical Services, Afikpo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "10, Amachara Ukpa Road, Afikpo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19067,
        "ProviderName": "Niger Optical Services, Ahoada",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "40, Omoku Street, Ahoada, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19068,
        "ProviderName": "Niger Optical Services, Akokwa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "2, Okigwe Road, Akokwa, Imo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19069,
        "ProviderName": "Niger Optical Services, Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "155, Nnebuisi Road, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19070,
        "ProviderName": "Niger Optical Services, Awka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "43, Ziks Avenue, Awka.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19071,
        "ProviderName": "Niger Optical Services, Ayingba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "105, Idah Road, Ayingba, Kogi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19072,
        "ProviderName": "Niger Optical Services, Benin City",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "42, Mission Road, Benin City.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19073,
        "ProviderName": "Niger Optical Services, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "61, Nike Road, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19074,
        "ProviderName": "Niger Optical Services, Enugu II",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "39, Zik Avenue, Uwani, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19075,
        "ProviderName": "Niger Optical Services, Igbo-Ukwu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Igbo-Ukwu/Ekwulobia Road.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19076,
        "ProviderName": "Niger Optical Services, Ihiala",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Onitsha Owerri Road, Ihiala Park.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19077,
        "ProviderName": "Niger Optical Services, Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "10, Hill Street., Opp. St. Pauls Township Sch., Jos",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19078,
        "ProviderName": "Niger Optical Services, Lafia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Shadan Junction, Jos Road, Lafia",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19079,
        "ProviderName": "Niger Optical Services, Lokoja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "4A, Old Okene Road, Lokoja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19080,
        "ProviderName": "Niger Optical Services, Makurdi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "20, J. S. Tarka Rd, By Benue Crescent, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19081,
        "ProviderName": "Niger Optical Services, Mbaise",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Ahiara Junction, Mbaise, Imo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19082,
        "ProviderName": "Niger Optical Services, Nnewi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "8, Owerri Rd, Nnewi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19083,
        "ProviderName": "Niger Optical Services, Nsukka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "107, Enugu Road, Nsukka, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19084,
        "ProviderName": "Niger Optical Services, Ohafia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "126, Arochukwu Road, Ohafia, Abia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19085,
        "ProviderName": "Niger Optical Services, Omoku",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Omoku Ahoada Road, Omoku, Rivers.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19086,
        "ProviderName": "Niger Optical Services, Onitsha",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "160, Oguta Road, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19087,
        "ProviderName": "Niger Optical Services, Onueke",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "New Ikwo Rd Junction/Afikpo Road, Onueke",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19088,
        "ProviderName": "Niger Optical Services, Orlu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "3, Ihioma Road, Orlu, Imo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19089,
        "ProviderName": "Niger Optical Services, Oturkpo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "84, Nnamdi Azikiwe Street, Oturkpo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19090,
        "ProviderName": "Niger Optical Services, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "72, Douglas Road, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19091,
        "ProviderName": "Niger Optical Services, Port Harcourt I",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "18, Emenike Street, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19092,
        "ProviderName": "Niger Optical Services, Port Harcourt II",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Rumuibekwe Opp. Shell Quarters, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19093,
        "ProviderName": "Niger Optical Services, Umuahia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "12, Bende Road, Umuahia, Abia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19094,
        "ProviderName": "Niger Optical Services, Umunze",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Nkwo Umunze Road About, Umunze",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19095,
        "ProviderName": "Niger Optical Services, Uyo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "21, Ikot Ekpene Road, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19772,
        "ProviderName": "Nile Valley Mother & Child hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130064451",
        "provideremail1": "info@nilevalleyhospital.org.ng",
        "address1": "Plot 602, David Jemibewon Crescent, Gudu, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19096,
        "ProviderName": "Nimbus Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038099402",
        "provideremail1": "NULL",
        "address1": "6, Kola Iyaomolere St., Ogudu, Ori-Oke.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19098,
        "ProviderName": "Nissi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035881755",
        "provideremail1": "ijajaonuche@gmail.com",
        "address1": "Beside Sambridge Kiddies Academy, Area 1, Masaka",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19882,
        "ProviderName": "Nnaemeka Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037048354",
        "provideremail1": "hmcfamilydoc@yahoo.com",
        "address1": "No 53,  Hundred Foot Road Nnewi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18729,
        "ProviderName": "Nnihi Medicals",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "nnihimedicals@gmail.com",
        "address1": "3, Ibe Street, by SARS Junction, Royal Estate Road, Rumuomasi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19099,
        "ProviderName": "Nobis Optometry Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08084890923",
        "provideremail1": "NULL",
        "address1": "56, NEPA Line, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19769,
        "ProviderName": "Noble Medical Consultants & Fertility Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07057723381",
        "provideremail1": "noblefertilityhospital.com",
        "address1": "2 Love Street Off Odaranwere Road, Adjacent Larritel NTA Road Mbiloba, Port Harcourt, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19102,
        "ProviderName": "Nsukka Medical Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036899747",
        "provideremail1": "NULL",
        "address1": "7, Nru Street, Ugwu-Nkwo , Nsukka, Enugu State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19103,
        "ProviderName": "Ntasiobi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08131835522",
        "provideremail1": "ntasiobihosp@yahoo.com",
        "address1": "1, Enugu-Abor Trans Ekulu, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19116,
        "ProviderName": "O Hosp - Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056708838",
        "provideremail1": "NULL",
        "address1": "28, Dele Abubakar Rd., (Stadium Rd.)",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19104,
        "ProviderName": "Oak Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08034747112",
        "provideremail1": "oakhospitals101@yahoo.com",
        "address1": "191, Lagos Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19105,
        "ProviderName": "Oba Adenle Memorial Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132208268",
        "provideremail1": "NULL",
        "address1": "7, Oke Ayeso Street, Ilesa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19106,
        "ProviderName": "Obioma Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08025695957",
        "provideremail1": "sir.grant7@gmail.com",
        "address1": "21, School Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19107,
        "ProviderName": "Obule Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033205789",
        "provideremail1": "NULL",
        "address1": "115, Okpe Road, Sapele",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19475,
        "ProviderName": "Occupatinal Health Prime",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07059294014",
        "provideremail1": "ohp@fortics.org",
        "address1": "13A, Johnvic Igboanugo Street, Lekki Phase I",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19111,
        "ProviderName": "Ogunlade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056561155",
        "provideremail1": "NULL",
        "address1": "35, Olukoku Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19112,
        "ProviderName": "Oke Ado Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132331592",
        "provideremail1": "NULL",
        "address1": "SW7/217, Ibukun Olu St, Oke Ado, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19119,
        "ProviderName": "Ola-Oki Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033231276",
        "provideremail1": "NULL",
        "address1": "Ola-Oki Medical Complex, Along Lagos Badagry Expressway, Ibereko, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19114,
        "ProviderName": "Oladapo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026543304",
        "provideremail1": "oladapomedcentre@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19776,
        "ProviderName": "Olan Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08106510401",
        "provideremail1": "info@olanhealthcare.com",
        "address1": "119 Adeniji Jones Avenue, Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19117,
        "ProviderName": "Olaniba Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033241852",
        "provideremail1": "olanibahealth@gmail.com",
        "address1": "10A, Olatunde Onimole Street, Aguda, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19123,
        "ProviderName": "Olumorin Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036778452",
        "provideremail1": "olumorinladele@gmail.com",
        "address1": "12, Little By Little Street, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19124,
        "ProviderName": "Omega Physiotherapy & Back Pain Care Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033299222",
        "provideremail1": "NULL",
        "address1": "14, Ndele Street, D-Line, Port Harcourt, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19125,
        "ProviderName": "Omeiza Ali Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035864756",
        "provideremail1": "aliomeizahospital2017@gmail.com",
        "address1": "Behind Mobil Petrol Station, Off Old Kabba/Okene Road, Lokoja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19126,
        "ProviderName": "Omni Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030569108",
        "provideremail1": "omnimedicalcentre@yahoo.com",
        "address1": "18, Boyle Street, Onikan",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19128,
        "ProviderName": "Omo Abiyo Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08093317222",
        "provideremail1": "solojibolium@yahoo.com",
        "address1": "3, Amos Street, Agosi Area, Ifo Road.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19132,
        "ProviderName": "Onward Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060632500",
        "provideremail1": "onwardsphosp@yahoo.com",
        "address1": "Agunbelewo, Ilobu Road, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19133,
        "ProviderName": "Ore-Ofe Oluwa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033190964",
        "provideremail1": "NULL",
        "address1": "88A, Ejirin Road, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19134,
        "ProviderName": "Orire Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035062512",
        "provideremail1": "NULL",
        "address1": "15, Orire lane, Adebayo, Ado-Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19135,
        "ProviderName": "Oroki Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022338544",
        "provideremail1": "NULL",
        "address1": "27 Cemetry Road, Igboma, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19138,
        "ProviderName": "Osula Royal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023371448",
        "provideremail1": "NULL",
        "address1": "5, Idahosa Street, off Mission Road, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19139,
        "ProviderName": "Osuntuyi Medical Centre, Iju-Ishaga",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08074488383",
        "provideremail1": "osunsmed@yahoo.com",
        "address1": "255, Iju Rd., Balogun Bus Stop, Iju-Ishaga, Agege.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19407,
        "ProviderName": "Osuntuyi Medical Centre, Obanikoro",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08074488383",
        "provideremail1": "osunsmedo@yahoo.com",
        "address1": "9, Alhaji Salisu Street, Obanikoro, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19142,
        "ProviderName": "Our Lady of Apostle Hospital, Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07096265658",
        "provideremail1": "olahospjos1@yahoo.com",
        "address1": "1, Zaria Bye Pass, Jos.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19144,
        "ProviderName": "Our Lady of Fatima Catholic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08071551542",
        "provideremail1": "ourladyoffatimanhis@gmail.com",
        "address1": "Odi-olowo, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19145,
        "ProviderName": "Our Lady of Lourdes Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030884436",
        "provideremail1": "NULL",
        "address1": "P. M. B. 40, Ihiala, Anambra State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19147,
        "ProviderName": "Owokoniran Memorial Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033490452",
        "provideremail1": "mobolaji.idowu@owokoniranhospital.com",
        "address1": "3, Owokoniran St., Station Makun, Sagamu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19990,
        "ProviderName": "Owolabi specialist Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080064796",
        "provideremail1": " owolabispecialisthospital@gmail.com",
        "address1": "10 Liasu atilola street, Peaceland Estate, Ifesowapo, Akute Ogun state ",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19148,
        "ProviderName": "P & G Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033962013",
        "provideremail1": "NULL",
        "address1": "29, Sanni Labode Street, New Oko Oba, Abule Egba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19149,
        "ProviderName": "Paafag Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033335228",
        "provideremail1": "paafagdoctor@yahoo.com",
        "address1": "1J Road, off 1st Avenue (after Total Petrol Station), FHA Estate, Lugbe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19448,
        "ProviderName": "Pamo Clinics and Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033030968",
        "provideremail1": "pamoclinics@gmail.com",
        "address1": "300, PH/ABA Expressway, Rumuomasi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19152,
        "ProviderName": "Pan OJ Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066909763",
        "provideremail1": "NULL",
        "address1": "68, Mission Road, Bonny Island ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19654,
        "ProviderName": "Panox Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033235616",
        "provideremail1": "panoxhospitalhmo@gmail.com",
        "address1": "4 Raji Rasaki Estate Road, Amuwo Odofin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19153,
        "ProviderName": "Parklande Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087806941",
        "provideremail1": "parklandesh@yahoo.com",
        "address1": "13, Rasaq Balogun Crescent, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19443,
        "ProviderName": "Parkview Family Medicine Royal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037200667",
        "provideremail1": "parkviewfmrh@gmail.com",
        "address1": "Domkat Bali way, by Solomon Lar Amusement Park, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19827,
        "ProviderName": "Patnas Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08163918962",
        "provideremail1": "patnashospital@gmail.com",
        "address1": "1b, Oluwoekere Street , Bashorun , Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19891,
        "ProviderName": "Patricare Hospital Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07017001600",
        "provideremail1": "patricarehospital@gmail.com",
        "address1": "48, gbuoshimini Road ,Agip Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19454,
        "ProviderName": "Paulate Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032653263",
        "provideremail1": "paulatehospital@yahoo.com",
        "address1": "23 Peak Thomas Bright Crescent, AIT Bus Stop, Alagbado",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19422,
        "ProviderName": "Pauline Canadian Hospital & Maternity Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09068856561",
        "provideremail1": "canadianhospital@yahoo.com",
        "address1": "Idi Ope B/Stop, Benjamin opp. Electronics Market, Eleyele, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19156,
        "ProviderName": "Peace Hospital, Yola",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066851152",
        "provideremail1": "peacehospital2005@yahoo.com",
        "address1": "2, Luggere Street, Jimeta, Yola",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19157,
        "ProviderName": "Peace Medical Centre, Calabar",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036669406",
        "provideremail1": "NULL",
        "address1": "17, Efut Street, Nsidom, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19665,
        "ProviderName": "Peacefeul Health Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07037986859",
        "provideremail1": "peacefulhealthhospital@yahoo.com",
        "address1": "60 Ijagemo Road, Ijegun, Ikotun",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19818,
        "ProviderName": "Perfect Trinity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060684507",
        "provideremail1": "hmo.perfecttrinityhospital@gmail.com",
        "address1": "13, Taiwo Street , Ilasamaja, Isolo, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19160,
        "ProviderName": "Petros Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023296237",
        "provideremail1": "petroshospitals@yahoo.com",
        "address1": "20, Seinde Callisto Street, Oshodi Expressway, Oshodi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19779,
        "ProviderName": "Phoenix Medical Consults",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037114121",
        "provideremail1": "phoenixmedicalconsults@gmail.com",
        "address1": "3 Igboukwu close off 1st Artillery Bus- Stop Aba Rd Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19161,
        "ProviderName": "Pinecrest Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023100602",
        "provideremail1": "info.pinecresthospital@gmail.com",
        "address1": "83, Makinde Street, Mafoluku - Oshodi",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19838,
        "ProviderName": "Pison Hospital & Maternity Kubwa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08140823720",
        "provideremail1": "pisonhospital@yahoo.com",
        "address1": "5335 Gado Nasko Road Phase II Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19685,
        "ProviderName": "Police Training School Clinic ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060084382",
        "provideremail1": "ptsclinicilorin@gmail.com",
        "address1": "Muritala Mohammed Way, Beside A Division Police Station, Taiwo, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19802,
        "ProviderName": "Premiercare and Neuro Rehabilitation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033389693",
        "provideremail1": "premiercarerehab@gmail.com",
        "address1": "8, Life plus Avenue, Eligbolo , New Layout , East -West Road Porthacourt ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19885,
        "ProviderName": "Prestige Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167563769",
        "provideremail1": "prestige.specialisthospital@gmail.com",
        "address1": "No 13, Umuechem Street ,D -Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19168,
        "ProviderName": "Primecare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034096631",
        "provideremail1": "juliana.primecare1@gmail.com",
        "address1": "75, 321 Road, off 3rd Avenue, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19455,
        "ProviderName": "Primefort Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08170031043",
        "provideremail1": "hmo@primeforthospital.com",
        "address1": "35C Ajiran Road, Agungi, Lekki-Penisula, opposite Oba Amusa, Estate, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19169,
        "ProviderName": "Primex Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033201180",
        "provideremail1": "primexhospital2000@yahoo.com",
        "address1": "58, Igando Road, Unity Bus Stop, Ikotun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19171,
        "ProviderName": "Primus Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08102117007",
        "provideremail1": "drtundealiu@yahoo.com",
        "address1": "272, Ijesha Road, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19372,
        "ProviderName": "Prince & Princess Hospital - Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09032771987",
        "provideremail1": "pphospital@yahoo.com",
        "address1": "191 Ojo Road, Ajegunle",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19705,
        "ProviderName": "Prince and Princess Hospital - Egbeda ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08093932162",
        "provideremail1": "pphospital@yahoo.com",
        "address1": "Plot 75, 1St Avenue 12 Road, Gowon Estate, Egbeda, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19173,
        "ProviderName": "Priscillia Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035742341",
        "provideremail1": "NULL",
        "address1": "32, Bonny Street, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19174,
        "ProviderName": "Promise Hospital, Agege",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033281",
        "provideremail1": "promisemedicalcentre@gmail.com",
        "address1": "132, Dopemu Road, Agege.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19175,
        "ProviderName": "Promise Medical Centre, Ota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033281",
        "provideremail1": "NULL",
        "address1": "19, Plaza Road, Sango-Ota, Ogun State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19834,
        "ProviderName": "Queen Of Peace Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08133013525",
        "provideremail1": "hospitalqueenofpeace@gmail.com",
        "address1": "No 2, NIHI Street ,Sunlight Estate , Umuebule",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19740,
        "ProviderName": "Queen Stella Specialist Hospital.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069760376",
        "provideremail1": "info@queenstellaspecialisthospital.com",
        "address1": "20 Waidi Adeyemi Crescent Araromi, Igando",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19176,
        "ProviderName": "Queens Specialist Hospital, Magodo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030106053",
        "provideremail1": "queensspecialisthospital@yahoo.co.uk",
        "address1": "3B, CMD Road, Magodo GRA, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19189,
        "ProviderName": "R-Jolad Hospital Gbagada",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "Rjoladhosp",
        "provideremail1": "info@rjolad.com",
        "address1": "1, Akindele Street, Bariga",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19819,
        "ProviderName": "R-Jolad Hospital, Agege ([Salem House)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000756523",
        "provideremail1": "wecare@rjolad.com",
        "address1": "Plot 23/24 Omotoye Estate Road off Ipaja Road, Mulero Bus-Stop, Orile-Agege, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19179,
        "ProviderName": "Rambod Optometry Practice",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033093808",
        "provideremail1": "rambodeye1@gmail.com",
        "address1": "5, Agudama Street, D-Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19697,
        "ProviderName": "Redeemers Health Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167457045",
        "provideremail1": "rccghealthcentre@ymail.com",
        "address1": "Unique Building Concepts and Integrated Services, KM 46 Lagos - Ibadan Expressway",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19797,
        "ProviderName": "Redemption Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030505982",
        "provideremail1": "redemptionhospital@gmail.com",
        "address1": "3, Redemtion Avenue , Off Major Thompson Road, Sabo Sagamu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19593,
        "ProviderName": "Ree-Mee Medicare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08072224416",
        "provideremail1": "info@reemee-medicare.com",
        "address1": "6, Rimi Road, GRA, Bauchi ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19817,
        "ProviderName": "Regis and Reina Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033086533",
        "provideremail1": "medhmo@regisandreina.com",
        "address1": "41, Industrial Avenue, Sabo  Yabo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19184,
        "ProviderName": "Rhowil Total Care Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08071354494",
        "provideremail1": "rhowiltotalcare10@yahoo.com",
        "address1": "Plot 3, Block 7, Site G, Satellite Town, Amuwo Odofin, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19187,
        "ProviderName": "Ridcol Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033425101",
        "provideremail1": "ridcol2006@yahoo.com",
        "address1": "26, Emekuku Street, D- Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19853,
        "ProviderName": "RMC Medical Services ",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09134436472",
        "provideremail1": "rmcmedicalservices1@gmail.com",
        "address1": "House 8, Palace Road, Oniru Private Estate, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19191,
        "ProviderName": "Robertson Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063098",
        "provideremail1": "robertson_medical_centre@yahoo.co.uk",
        "address1": "3, James Robertson Street, Surulere, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19972,
        "ProviderName": "Rock Foundation",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037144615",
        "provideremail1": "rockfoundationhospitalawka@gmail.com",
        "address1": "34, Onwurah street , awka,                               Anambra state. \r\n",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19549,
        "ProviderName": "Rolakim Hospital – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "11111111111",
        "provideremail1": "callcentre@metrohealthhmo.com",
        "address1": "KWARA",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19384,
        "ProviderName": "Rolayo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08091964952",
        "provideremail1": "rolayomedics13@yahoo.com",
        "address1": "5, Sule Maito Street, Benson Bus stop, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19192,
        "ProviderName": "Rophe Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08053003030",
        "provideremail1": "rophehospitalagbara@yahoo.com",
        "address1": "23, Anambra Crescent, Agbara Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19193,
        "ProviderName": "Ropheka Medical & Dental Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037130396",
        "provideremail1": "NULL",
        "address1": "14A, Olalekan St., Opp. Bakery B/Stop, Akowonjo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19194,
        "ProviderName": "Rouz Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037870378",
        "provideremail1": "rouzhmltd@yahoo.com",
        "address1": "10, Mobolaji Johnson St., Apo Quarters, Zone D, 2nd Gate, Gudu District, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19195,
        "ProviderName": "Royal Hospital, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033126569",
        "provideremail1": "royalhospitalenugu1@gmail.com",
        "address1": "3, Aria Road, G. R. A., Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19196,
        "ProviderName": "Royal Infirmary, Ajah",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034918001",
        "provideremail1": "theroyalinfir@yahoo.com",
        "address1": "Badore Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19728,
        "ProviderName": "Royal Life Hospital & Maternity Centre - Shomolu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028768552",
        "provideremail1": "royalifehospital@gmail.com",
        "address1": "2, George Street, Beside Ecobank, Apata, Somolu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19197,
        "ProviderName": "Rubee Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08092927720",
        "provideremail1": "rubeemedicalcentre@gmail.com",
        "address1": "Km 38, Abeokuta Motor Road, Sango Otta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19198,
        "ProviderName": "Rushgreen Maternity Hospital & Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035752317",
        "provideremail1": "NULL",
        "address1": "64, Ugwunobamkpa Rd., Near Isokwe Police Post, Onitsha.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19199,
        "ProviderName": "Sabana Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035804117",
        "provideremail1": "NULL",
        "address1": "Federal Low Cost, Gombe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19651,
        "ProviderName": "Sacred Heart Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08165858128",
        "provideremail1": "sacredhearthospitalnhis@yahoo.com",
        "address1": "Oswald Madecki Road, Lantoro, Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19201,
        "ProviderName": "Safeway Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033217577",
        "provideremail1": "safewayhospital@yahoo.com",
        "address1": "Km 46, Lekki-Epe Expressway, Sangotedo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19202,
        "ProviderName": "Sage Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08187240368",
        "provideremail1": "NULL",
        "address1": "29, Okere-Ugborikoko Road, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19203,
        "ProviderName": "Sahel Specialist hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035776501",
        "provideremail1": "sahelspecialisthospital@gmail.com",
        "address1": "65, Ahmadu Bello Way, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19205,
        "ProviderName": "Salama Infirmary Hospital & Maternity, Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037013203",
        "provideremail1": "NULL",
        "address1": "Sokoto Road, Kwangla, Zaria, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19206,
        "ProviderName": "Salvation Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033450585",
        "provideremail1": "salvationhospitalmaternity@yahoo.com",
        "address1": "Along MCC Uratta Rd., Opp. Ekofe Filling Station, Beside Olive Hotel, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19207,
        "ProviderName": "Samaria Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08186007507",
        "provideremail1": "samariahospital@yahoo.com",
        "address1": "17, Debo Bashorun Street, Off Ali Dada Street, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19208,
        "ProviderName": "Samaritan Clinic/Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023347227",
        "provideremail1": "NULL",
        "address1": "7, Ekong Uko Street, Eket.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19209,
        "ProviderName": "Sandra Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09022812281",
        "provideremail1": "sandrahospital_mkd@yahoo.com",
        "address1": "24, J.S Tarka Road, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19210,
        "ProviderName": "Santa Antonio Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034020244",
        "provideremail1": "admin@samedicals.org",
        "address1": "Plot 48, Block T, OPIC Estate, Agbara Area 4, Agbara.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19211,
        "ProviderName": "Savannah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035534312",
        "provideremail1": "NULL",
        "address1": "10, David Mark Road, Tunga, Minna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19215,
        "ProviderName": "Sefa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033057195",
        "provideremail1": "sefaspecialisthospital@yahoo.com",
        "address1": "3, College Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19216,
        "ProviderName": "Seriki Abimbola Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08085411481",
        "provideremail1": "NULL",
        "address1": "1, Seriki Abimbola Street, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19217,
        "ProviderName": "Shalom Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08139397292",
        "provideremail1": "NULL",
        "address1": "1, Shalom Medical Centre St. (Behind Olowolagba Microfinance Bank), Sabo, Ogbomosho.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19975,
        "ProviderName": "Sharyor Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09050907110",
        "provideremail1": "sharyormedicalcentre@gmail.com",
        "address1": "8, Olusegun Badejo Street Bada  Ayobola",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19218,
        "ProviderName": "Shawsand Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19219,
        "ProviderName": "Shepherd Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037169423",
        "provideremail1": "shepherdspecialisthospital@yahoo.com",
        "address1": "4th Avenue, E Close, Festac Town, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19220,
        "ProviderName": "Shield Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033122337",
        "provideremail1": "NULL",
        "address1": "Plot 4, Close D, Peace Valley Estate, Trans Woji Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19221,
        "ProviderName": "Shirish Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023415493",
        "provideremail1": "shirishclinic@gmail.com",
        "address1": "102, Joju Road, Sango Otta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19223,
        "ProviderName": "Sijuwade Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033534474",
        "provideremail1": "sijuwadesha@yahoo.com",
        "address1": "10, Sijuwade BAA-Segun Road, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19964,
        "ProviderName": "Sinachi Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060081204",
        "provideremail1": "sinachihospitalandmaternity@gmail.com",
        "address1": "11, Balogun Compound Obadore Ajah Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19929,
        "ProviderName": "Sleek Vision",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08143077400",
        "provideremail1": "sleekvisioncare@gmail.com",
        "address1": "10,Oweh Street Fadeyi Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19225,
        "ProviderName": "Smith Medical Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07061690765",
        "provideremail1": "olatokunboh.balogun@smithmedicalcentre.com",
        "address1": "8, Afric Road, Olaleye Estate, Iponri.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19226,
        "ProviderName": "Sokoto Clinics Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036042840",
        "provideremail1": "NULL",
        "address1": "46, Abdullahi Fodio Road, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19228,
        "ProviderName": "Solad Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08054083061",
        "provideremail1": "NULL",
        "address1": "16, Maria Road, Off Candos Road, Baruwa, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19229,
        "ProviderName": "Solamek Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023072262",
        "provideremail1": "NULL",
        "address1": "8, Jonathan Street, off Lawrence Adedara Street, Temidire Estate, Lambe, Akute.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19230,
        "ProviderName": "Solat Women Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032852515",
        "provideremail1": "NULL",
        "address1": "4A Kasim Ibrahim Street, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19231,
        "ProviderName": "Solid Rock Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023229973",
        "provideremail1": "solidrockh@yahoo.com",
        "address1": "6 Akinsanya Street, Off Ogunnusi (Isheri) Road, Ojodu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19232,
        "ProviderName": "Sparkview Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033024653",
        "provideremail1": "NULL",
        "address1": "20A, Wilmer Rd, Isheri-Oke, Ojodu Berger.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19233,
        "ProviderName": "SpringHill Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030712509",
        "provideremail1": "springhillhospital20@gmail.com",
        "address1": "19, Olorunsogo Estate, Ogunrun, Mowe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19234,
        "ProviderName": "Springs Hospital, Port Harcourt",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08030891309",
        "provideremail1": "eddyspringshospital@yahoo.com",
        "address1": "458, Ikwere Rd., By Kala B/Stop, Trans-Amadi Layout, (Rain-Bow Town), PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19915,
        "ProviderName": "St Angelas Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07015367068",
        "provideremail1": "stangelashospital@gmail.com",
        "address1": "Igbojia Road ,Shapati Ibeju Lekki Lagos -State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19943,
        "ProviderName": "St Benedict Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08084459565",
        "provideremail1": "saintbenedictmedicalcentre@gmail.com",
        "address1": "44, Frazaki Street bucknor Estate ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19458,
        "ProviderName": "St. Anthony Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033161431",
        "provideremail1": "dibieanthony@yahoo.com",
        "address1": "33, Igbolomu Road, Isawo, Agric Bus Stop, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19236,
        "ProviderName": "St. Athanasius Hospital Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037920924",
        "provideremail1": "stathanasiushospital@yahoo.com",
        "address1": "1, Ufeh Street, FHE, Abak Rd., Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19519,
        "ProviderName": "St. Catherine’s Hospital - Abuja",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087830739",
        "provideremail1": "stcatherinesabj@gmail.com",
        "address1": "6, Odienna Close, Libreville Street, Off Aminu Kano Crescent, Wuse 2",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19520,
        "ProviderName": "St. Catherine’s Hospital - Port Harcourt",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087830739",
        "provideremail1": "stcatherinesph@yahoo.com",
        "address1": "14, Trans-Woji Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19237,
        "ProviderName": "St. Charles Borromeo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030574747",
        "provideremail1": "icepacel@gmail.com",
        "address1": "1, Limca Road, Onitsha, Anambra.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19240,
        "ProviderName": "St. Daniel’s Hospital, Oworonshoki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023231111",
        "provideremail1": "sdhospital12@yahoo.com",
        "address1": "14, Oduduwa Road, Car Wash B/Stop, Oworonsoki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19242,
        "ProviderName": "St. David Hospital, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035448660",
        "provideremail1": "stdavidhospital@yahoo.com",
        "address1": "12, Mbari Street, Ikenegbu Layout, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19243,
        "ProviderName": "St. Edwards Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08020951924",
        "provideremail1": "info@stedwardhospital.com",
        "address1": "4, Dolapo Oshinaike Street, S.Fort Estate, Off Addo Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19244,
        "ProviderName": "St. Elizabeth Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035029802",
        "provideremail1": "NULL",
        "address1": "10, Government House Road (off Orlu Rd), Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19245,
        "ProviderName": "St. Gerard Catholic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039493449",
        "provideremail1": "NULL",
        "address1": "Kachia Road, Kakuri, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19246,
        "ProviderName": "St. Gregory’s Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068200014",
        "provideremail1": "NULL",
        "address1": "Iyin Road (Textile Rd.) Ado Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19247,
        "ProviderName": "St. Joseph Hospital & Maternity, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "9, Anokwu Street, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19249,
        "ProviderName": "St. Jude’s Hospital, Irewole",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034756833",
        "provideremail1": "NULL",
        "address1": "Ikire, Off Fatima College Rd., Laakosun, Iki Irewole",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19250,
        "ProviderName": "St. Kizito Clinic",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08038547416",
        "provideremail1": "customercare@lovingaze.org",
        "address1": "Ilasan Housing Estate, Jakande, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19251,
        "ProviderName": "St. Louis Medical Centre, Apapa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033125589",
        "provideremail1": "stlouismedics@yahoo.com",
        "address1": "3, Ado Avenue Apapa.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19254,
        "ProviderName": "St. Martins Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063661668",
        "provideremail1": "stmartinshospital@yahoo.com",
        "address1": "21, Stadium Road, Port Harcourt.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19255,
        "ProviderName": "St. Mary Mother of Mercy Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033103282",
        "provideremail1": "NULL",
        "address1": "68, Ogbatai Road, Woji, PH.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19256,
        "ProviderName": "St. Mary’s Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08061666862",
        "provideremail1": "stmarysspecialisthospital2@yahoo.com",
        "address1": "458, Titilayo Adedoyin Street, Omole Phase I, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19261,
        "ProviderName": "St. Patricks Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167526735",
        "provideremail1": "stpatricks_01@yahoo.com",
        "address1": "3, Eastern By-Pass, Ogbunabali Rd., PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19235,
        "ProviderName": "St. Peters Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063302645",
        "provideremail1": "NULL",
        "address1": "27 St Peter’s Hospital Street, Amarata, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19262,
        "ProviderName": "St. Raphael Divine Mercy Specialist Hospital, Ikorodu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08155140646",
        "provideremail1": "straphaeldivinehmocare@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19263,
        "ProviderName": "St. Raphael Hospital, Okota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08123772066",
        "provideremail1": "straphael.hospital@yahoo.com",
        "address1": "114, Lateef Adegboyega Street, Okota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19264,
        "ProviderName": "St. Rebecca Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035422884",
        "provideremail1": "strebeccashospital@yahoo.com",
        "address1": "40, Denis Osadebe Way, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19265,
        "ProviderName": "St. Shiloh  Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08184678276",
        "provideremail1": "st.shiloh1@gmail.com",
        "address1": "2, Let Them Say St.,Igbala B/S, Sango Ota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19266,
        "ProviderName": "St. Victoria Specialist Hospital, Ile-Ife",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033567451",
        "provideremail1": "eoorji11@yahoo.com",
        "address1": "Opposite NTA Mokuro Road, Ile-Ife.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19267,
        "ProviderName": "St. Vincent Hospital, Umuahia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030901233",
        "provideremail1": "NULL",
        "address1": "9, Old Timber Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19268,
        "ProviderName": "Standard Life Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033287669",
        "provideremail1": "NULL",
        "address1": "6, Oreniwon Street, Off Agbe Road, Abule Egba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19761,
        "ProviderName": "Standard Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08108751568",
        "provideremail1": "standardspecialisthospital@yahoo.com",
        "address1": "144 Lautai Road Tarauni, Kano",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19269,
        "ProviderName": "State Specialist Hospital, Maiduguri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07031536929",
        "provideremail1": "NULL",
        "address1": "Along Shehu Laminu Way, Maiduguri",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19270,
        "ProviderName": "State Specialist Hospital, Okitipupa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "sshokitipupa@hotmail.com",
        "address1": "Igbokoda Rd, Okitipupa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19271,
        "ProviderName": "State Specialist Hospital, Yola",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036239191",
        "provideremail1": "NULL",
        "address1": "Hospital Rd., Inieta, Yola.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19836,
        "ProviderName": "Stedmed Specialist Medical Centre & Children's Hos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056181901",
        "provideremail1": "stedmedmedicalcentre@gmail.com",
        "address1": "NC3, Karu Neighbourhood Way Karu Site",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19272,
        "ProviderName": "Sterling Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08185645145",
        "provideremail1": "sterlingspecialistclinic@yahoo.com",
        "address1": "5 King Jaja Street, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19762,
        "ProviderName": "Stevans Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07045664356",
        "provideremail1": "stevansmedicalcentre@gmail.com",
        "address1": "17 Ori - okuta Road off Isawo Road Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19273,
        "ProviderName": "Strong Tower Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056568949",
        "provideremail1": "strongtowerhospital@yahoo.com",
        "address1": "100, Agbado Road, Off Iju Ishaga.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19274,
        "ProviderName": "Subol Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033445559",
        "provideremail1": "info@subolhospital.com",
        "address1": "16/17, Oba Amusa Avenue, Idimu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19275,
        "ProviderName": "Sunshine Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063426514",
        "provideremail1": "NULL",
        "address1": "2, Road, A, Off Ikot Ekpene Road, World Bank Housing Estate, Umuahia North.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19449,
        "ProviderName": "Sure Hope Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033890981",
        "provideremail1": "sure2017@yahoo.com",
        "address1": "5, Carpenter Bus Stop, Ologuneru Eruwa Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19276,
        "ProviderName": "Surgicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033258190",
        "provideremail1": "NULL",
        "address1": "Ibara Housing Estate",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19277,
        "ProviderName": "Suzan Memorial Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065327408",
        "provideremail1": "suzan.mem.clinic@gmail.com",
        "address1": "Uphill Suleja, Near Suleja Club, G.R.A. Suleja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19278,
        "ProviderName": "Sybron Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037863416",
        "provideremail1": "NULL",
        "address1": "25, Mungo Park Close, Asokoro",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19472,
        "ProviderName": "SYD-Monic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038883752",
        "provideremail1": "syd_monichospital@yahoo.com",
        "address1": "6, Alhaji Moshobalaje Street, Ali Dada Bus Stop, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19871,
        "ProviderName": "Taraba State Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138000116",
        "provideremail1": "nhisspecialisthospital@gmail.com",
        "address1": "No 1, Rev  Joly Nyame Way, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19279,
        "ProviderName": "Teju Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056046243",
        "provideremail1": "NULL",
        "address1": "6, Animashaun St., Ajeigbe B/S., Ring Rd.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19281,
        "ProviderName": "The Chasel Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08058196064",
        "provideremail1": "NULL",
        "address1": "15, Issa Kaita Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19285,
        "ProviderName": "The Great Physician (Gadol Rapha) Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033011650",
        "provideremail1": "gadolrapha@yahoo.com",
        "address1": "3, Niyi Adebule Street, Badagry, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19204,
        "ProviderName": "The Peake Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036421369",
        "provideremail1": "thepeakespecialisthospital@gmail.com",
        "address1": "24, 64 Crescent, off Charley Boy Boulevard, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19288,
        "ProviderName": "The Vine Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07040423841",
        "provideremail1": "NULL",
        "address1": "Plot 10, Block 24, Alaafin Avenue, Oluyole Extension, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19290,
        "ProviderName": "Tim Unity Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030990471",
        "provideremail1": "NULL",
        "address1": "BZ 5, Sardauna Crescent, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19982,
        "ProviderName": "Tip -Top Solution Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069098086",
        "provideremail1": "tiptophospital@gmail.com",
        "address1": "4, Kwara wAY Custom Bus/Stop  Ikotun",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19291,
        "ProviderName": "TMAC Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096314722",
        "provideremail1": "Tmac_specialisthospital@outlook.com",
        "address1": "10, Irewole Street, Off Opebi Link Road, Opebi",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19292,
        "ProviderName": "Tobi Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08104400044",
        "provideremail1": "NULL",
        "address1": "S7/453, Felele Layout, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19293,
        "ProviderName": "Tobiloba Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033211040",
        "provideremail1": "NULL",
        "address1": "1, Tobiloba Way, Off Kuforiji Olubi Drive.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19529,
        "ProviderName": "Tobis Clinic and Consultants",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033300839",
        "provideremail1": "info@tobisclinic.com",
        "address1": "Km 14, Chief Melford Okilo Road, Opp Sobaz Filling Station, Akenfa, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19534,
        "ProviderName": "Todac Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08180204742",
        "provideremail1": "todacgroup@gmail.com",
        "address1": "61, Okigwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19294,
        "ProviderName": "Tolbert Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037587905",
        "provideremail1": "tolbertspecialisthospital@yahoo.com",
        "address1": "17, Democracy Crescent, Gaduwa Estate, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19296,
        "ProviderName": "Tona Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08073199524",
        "provideremail1": "tonahospital84@gmail.com",
        "address1": "1, Ighodalo St., Off Agbor Road, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19865,
        "ProviderName": "Tonajib Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142595974",
        "provideremail1": "tonajibhospitallimited@gmail.com",
        "address1": "46, Maria Ibironke Street , Ikotun , Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19297,
        "ProviderName": "Top Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039097750",
        "provideremail1": "topmedicalmx@yahoo.co.uk",
        "address1": "4, Top Medical road, Tunga, Minna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19298,
        "ProviderName": "Topaz Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023123352",
        "provideremail1": "topazclinic@yahoo.com",
        "address1": "12, Adeniji Street, Off Municipal Library, Surulere, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19300,
        "ProviderName": "Topcare Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033508608",
        "provideremail1": "NULL",
        "address1": "118 Hadijia Road, Kano, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19439,
        "ProviderName": "Toptee Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023257273",
        "provideremail1": "info@topteemedical.com",
        "address1": "40, Ogunlana Drive, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19301,
        "ProviderName": "Toronto Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033733133",
        "provideremail1": "NULL",
        "address1": "Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19302,
        "ProviderName": "Total Care Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035786149",
        "provideremail1": "NULL",
        "address1": "5, Mosunmola Ashaye St., Makun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19303,
        "ProviderName": "Toun Memorial Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07042139279",
        "provideremail1": "admin@tounhospital.com",
        "address1": "Km 50, New Ife Expressway, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19435,
        "ProviderName": "Trans-Aquatic Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07087605852",
        "provideremail1": "transaquatichospital@gmail.com",
        "address1": "Km 35, Lekki-Epe Expressway, Bogije, Opp Toyota Auto Nation, Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19305,
        "ProviderName": "Trauma & Multispecialist Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09070102900",
        "provideremail1": "tmc@crystalhorpe.org",
        "address1": "UATH Compound, Specialist Hospital Road, Gwagwalada",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19826,
        "ProviderName": "Tru -Care Medicare Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09139361696",
        "provideremail1": "truecaremedical@gmail.com",
        "address1": "Zarmaganna Rayfield Road, Adjacent Silk Suit Hotel",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19306,
        "ProviderName": "True Care Model Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08170000217",
        "provideremail1": "truecaremodelhospital1@gmail.com",
        "address1": "19, Adeyemi Apena Street, Papa, Epe.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19918,
        "ProviderName": "Trust Charitos Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "080325859299",
        "provideremail1": "hmotrustcharitoshospital@hotmail.com",
        "address1": "Plot 879/880, Idris Ibrahim Off Obafemi Awolowo Creacent Jabi Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19307,
        "ProviderName": "Tunde Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065819918",
        "provideremail1": "NULL",
        "address1": "22, Atani Street, Abakpa, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19308,
        "ProviderName": "Tunrayo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023297812",
        "provideremail1": "NULL",
        "address1": "8, Ajibade St, Off Olajuwon St., Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19309,
        "ProviderName": "Twins Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063415845",
        "provideremail1": "twinsclinicwarri@yahoo.co.uk",
        "address1": "1, Twins Clinic Drive, Off Deco Rd, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19310,
        "ProviderName": "Uche Medicare Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026116653",
        "provideremail1": "NULL",
        "address1": "C 25 World Bank Housing Estate",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19311,
        "ProviderName": "Uchenna Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037082045",
        "provideremail1": "NULL",
        "address1": "10/12 Enwereuzor Crescent, Umuoyo, Irete, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19312,
        "ProviderName": "Ufor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023835392",
        "provideremail1": "NULL",
        "address1": "19, Uloho Avenue, Ughelli",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19314,
        "ProviderName": "Ukponmwan Foundation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037583742",
        "provideremail1": "NULL",
        "address1": "53, Idahosa Street, Off Dawson Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19315,
        "ProviderName": "Ultima Medicare Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022907595",
        "provideremail1": "ultima_medicare88@yahoo.com",
        "address1": "3, Cappa Avenue, Palmgrove Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19321,
        "ProviderName": "Umezuruike Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037239260",
        "provideremail1": "umezhospital@yahoo.com",
        "address1": "21/23, Umezuruike Street, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19342,
        "ProviderName": "Unity Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063122",
        "provideremail1": "NULL",
        "address1": "Plot D1, Phase 2 Site 1, Gado Nasko Road, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19343,
        "ProviderName": "Unity Hospital & Maternity, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063581650",
        "provideremail1": "NULL",
        "address1": "Ngwo Hilltop, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19344,
        "ProviderName": "Unity Medical Centre, Ibadan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038359848",
        "provideremail1": "umcib@yahoo.com",
        "address1": "Eleyele, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19511,
        "ProviderName": "University of Medical Science Technology Hospital – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "+2340802222222",
        "provideremail1": "callcenter@metrohealth.com",
        "address1": "Abuja",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19793,
        "ProviderName": "University Teaching Hospital, Ado Ekiti",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033470483",
        "provideremail1": "adoekitihospital@yahoo.com",
        "address1": "ADO, Ekiti,",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19345,
        "ProviderName": "Usy Medicals",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "3, Bassey Ntui Street, Isong Inyang, Akamkpa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19346,
        "ProviderName": "Uwakmfon Specialist Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08061224288",
        "provideremail1": "NULL",
        "address1": "27, Nwaniba Road, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19347,
        "ProviderName": "Vcare Diagnostics Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080631400",
        "provideremail1": "customerservice@vcarediagnosticsltd.com",
        "address1": "100, Adetokundo Ademola Street, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19349,
        "ProviderName": "Veenell Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037172019",
        "provideremail1": "veeneell_hospital@yahoo.com",
        "address1": "61, Okumagba Avenue",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19350,
        "ProviderName": "Vertimon Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033882397",
        "provideremail1": "NULL",
        "address1": "3, Merogun Road, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19688,
        "ProviderName": "Vicamen Academy",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022220432",
        "provideremail1": "vicamen@gmail.com",
        "address1": "150 Ago Palace Way, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19351,
        "ProviderName": "Victoria Memorial Hospital, Morogbo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352572",
        "provideremail1": "dr.chukwudiwilliams@yahoo.com",
        "address1": "Behind Fousquare Church, Morogbo, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19352,
        "ProviderName": "Victoria Vestige & Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036097476",
        "provideremail1": "henrypam007@vvmh.net",
        "address1": "26D, D.B Zang Way, G.R.A, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19437,
        "ProviderName": "Victory Life Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037218094",
        "provideremail1": "devictorylifespechospital@gmail.com",
        "address1": "15, Ilupeju Estate, Ijede, Ikorodu Road, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19690,
        "ProviderName": "Vigor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07062710020",
        "provideremail1": "info@vigorhospitals.com",
        "address1": "10 Kingdom Hall Street Off Fidiso Estate Abijo, Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19745,
        "ProviderName": "Vinca Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07040231426",
        "provideremail1": "oakfield.specialist@gmail.com",
        "address1": "12 Remkom Street Behind NNPC Filling Station, Kubwa, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19354,
        "ProviderName": "Vine Branch Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142723134",
        "provideremail1": "NULL",
        "address1": "2, Olubadan Palace Road, Mokola, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19474,
        "ProviderName": "Vineyard Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036930347",
        "provideremail1": "vineyardhospital@gmail.com",
        "address1": "4, Gbemi Ogundeyi Street, Idimu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19357,
        "ProviderName": "Warshu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069101237",
        "provideremail1": "NULL",
        "address1": "Kwaji, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19358,
        "ProviderName": "Welfare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036156368",
        "provideremail1": "NULL",
        "address1": "17, Ikuehi Close, GRA, Okene.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19359,
        "ProviderName": "Well Point Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023401325",
        "provideremail1": "NULL",
        "address1": "19, 661 Road, Galadima, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19360,
        "ProviderName": "WellMed Healthcare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09073019435",
        "provideremail1": "NULL",
        "address1": "Km 52, Lekki-Epe Expressway, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19773,
        "ProviderName": "Westcare  Specialist Hospital - Ejigbo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033245769",
        "provideremail1": "westcarehospital@yahoo.com",
        "address1": "31, Ifoshi Road, Pipeline Bus Stop, Near Orilowo Estate, Ejigbo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19362,
        "ProviderName": "Westcare Specialist Hospital - Egbeda",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033245769",
        "provideremail1": "westcarehospital@yahoo.com",
        "address1": "32, Samuel Street, Akowonjo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19363,
        "ProviderName": "Westend Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037078988",
        "provideremail1": "westendhosp_warri@yahoo.co.uk",
        "address1": "1, 25th Street, Westend Hospital Road, DDPA",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19898,
        "ProviderName": "White Dove Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08067770402",
        "provideremail1": "whitedovehospitalkuje@gmail.com",
        "address1": "No 1 Ademoh Isah Ovunvu Street SSQ, Kuje ,Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19365,
        "ProviderName": "Winrose Clinic Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023438780",
        "provideremail1": "winrose_clinic@yahoo.com",
        "address1": "7, Winrose Clinic Road, Off Enerhen Road.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19366,
        "ProviderName": "Woji Cottage Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037678693",
        "provideremail1": "NULL",
        "address1": "Alcon Road, Woji Town, Woji, PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19367,
        "ProviderName": "Wuse District Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033733217",
        "provideremail1": "fctwuse_hospital@yahoo.com",
        "address1": "15, Conakry Street, Wuse Zone 3.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19741,
        "ProviderName": "Yabisan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08062422353",
        "provideremail1": "yaqubik14@gmail.com",
        "address1": "House 42 KL 14 Kubuwa Lugbe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19368,
        "ProviderName": "Zaman Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08051095478",
        "provideremail1": "zamanclinicltd@gmail.com",
        "address1": "25, Road 21B, Babangida Road, FHA Phase 1, Lugbe, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19870,
        "ProviderName": "Zefafi Women's Specialist Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036060067",
        "provideremail1": "doctorkonstance@gmailcom",
        "address1": "No 1, Maikahon Karo Road Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19370,
        "ProviderName": "Zoe Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033284404",
        "provideremail1": "zoemedical48@gmail.com",
        "address1": "20, Gbemisola St., Off Adeleke St., Off Allen, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    }
];


const providerDataMaxi = [
    {
        "ProviderID": 19814,
        "ProviderName": " Fitness Factory Ltd",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08151009472",
        "provideremail1": "akash@fitnessfactory.ng",
        "address1": "Block 114, Plot 5 Akiogun Rd, Maroko, Oniru,",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19920,
        "ProviderName": " Perez Medcare Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08090662602",
        "provideremail1": "info@perezmedcare.com",
        "address1": "43,Castle Rock Avenue, Osapa London Opposite Cardogan Estate ,Jakande Roundabout Lekki Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19921,
        "ProviderName": " Perez Medcare Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08090662602",
        "provideremail1": "info@perezmedcare.com",
        "address1": "43,Castle Rock Avenue, Osapa London Opposite Cardogan Estate ,Jakande Roundabout Lekki Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18739,
        "ProviderName": "...",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352195",
        "provideremail1": "deltacrownhospital2013@gmail.com",
        "address1": "14, Ajayi Street, Ketu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18638,
        "ProviderName": "A. Harmony Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033838599",
        "provideremail1": "aharmonyhospital1@gmail.com",
        "address1": "10, Samuel Ekundayo Road, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19450,
        "ProviderName": "AB Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023183747",
        "provideremail1": "absp80@yahoo.com",
        "address1": "87, Randle Avenue, Surulere, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18640,
        "ProviderName": "Abdul Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036861006",
        "provideremail1": "NULL",
        "address1": "5 Donga Road, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19737,
        "ProviderName": "Abundant Health Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09037147632",
        "provideremail1": "Abundant.h@yahoo.com",
        "address1": "No 9 Aleruchi Close Off 5 Ada George Road, Port Harcourt, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18641,
        "ProviderName": "Abundant Life Hospital Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023537448",
        "provideremail1": "abundantlifehospital@gmail.com",
        "address1": "1, Idahosa Street, Olope-Meji, Mowe.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18644,
        "ProviderName": "Ace Medicare Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08098432614",
        "provideremail1": "medicareclinicsota@yahoo.com",
        "address1": "Km 4 Idiroko Road, Ota, Ogun State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18645,
        "ProviderName": "Acron Medical Consultants",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033421872",
        "provideremail1": "NULL",
        "address1": "1, Acron Avenue, off Stadium Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18852,
        "ProviderName": "Ade Vision Optical Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033334850",
        "provideremail1": "adevision20@gmail.com",
        "address1": "14, Kayode Street, off Ijaiye Road, Ogba",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18652,
        "ProviderName": "Ade-Tade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034753037",
        "provideremail1": "NULL",
        "address1": "11, Okebola Street, Ado Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18646,
        "ProviderName": "Adebare Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033596164",
        "provideremail1": "NULL",
        "address1": "Km 4, Gbongan road, Osogbo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18647,
        "ProviderName": "Adebayo Ogunsanya Memorial Hospital  ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023070698",
        "provideremail1": "NULL",
        "address1": "8, Eniasoro Beyioku Street, Ajao Road, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18648,
        "ProviderName": "Adefemi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08024308447",
        "provideremail1": "adefemihospital@rocketmail.com",
        "address1": "49, Seriki Aro St., Off Ajao Road, Off Obafemi St., Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18649,
        "ProviderName": "Ademola Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033360135",
        "provideremail1": "ademolahospital@yahoo.com",
        "address1": "1, Omowale Street, KM 28 Lagos Badagry Expressway, Ijanikin, Ojo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18650,
        "ProviderName": "Adeoye Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08042127443",
        "provideremail1": "NULL",
        "address1": "NW4/183 George Street, Idi Oro, Ekotado, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18653,
        "ProviderName": "Adetoyin Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060351244",
        "provideremail1": "NULL",
        "address1": "Secretariat road, Opp NNPC petrol station, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18654,
        "ProviderName": "Afolabi Hospital, Ado-Ekiti",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039737773",
        "provideremail1": "NULL",
        "address1": "off Opopogbooro Road, Ado- Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18655,
        "ProviderName": "Afolabi Memorial Hospital, Offa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08104398292",
        "provideremail1": "NULL",
        "address1": "Offa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18849,
        "ProviderName": "Afolabi Vincent Memorial Hospital Oworonshoki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08109210100",
        "provideremail1": "folabimedicalcentreoworo@yahoo.com",
        "address1": "78, Oworo Road, Oworonshoki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18656,
        "ProviderName": "AfriGlobal Medicare Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032127570",
        "provideremail1": "info@afriglobalmedicare.com",
        "address1": "8 Mobolaji Bank Anthony Way, Ikeja, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19781,
        "ProviderName": "Afrimed Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022837554",
        "provideremail1": "afrimedhospital@yahoo.com",
        "address1": "1B Williams Street Off DNA Street Ifako Gbagada, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18657,
        "ProviderName": "Agape Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023039306",
        "provideremail1": "agapemedicalcentre@yahoo.com",
        "address1": "16, Ogunbiyi Ilo St, Olude, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19390,
        "ProviderName": "Ago Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08183057999",
        "provideremail1": "agomedicalcentre@yahoo.com",
        "address1": "6, Oyinlola Close, Ago, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18661,
        "ProviderName": "Ajara Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033233169",
        "provideremail1": "NULL",
        "address1": "3, Dr. Dauda Bioku St., Ajara-Topa, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18662,
        "ProviderName": "Ajiko Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036064326",
        "provideremail1": "ajikomedicalcentre2018@gmail.com",
        "address1": "Along Gashua Road, Damaturu.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18663,
        "ProviderName": "Akobi Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033527927",
        "provideremail1": "drayindeae@yahoo.com",
        "address1": "Plot 5, Blk WV Molipa Comm. Layout.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18664,
        "ProviderName": "Alafia Hospital (1938) Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033701690",
        "provideremail1": "alafiahospitalbrcr@gmail.com",
        "address1": "72, Adekunle Fajuyi Road, Adamasingba.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18665,
        "ProviderName": "Alba Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052727600",
        "provideremail1": "albaclinics@yahoo.com",
        "address1": "25, Constitution Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18666,
        "ProviderName": "Albarka Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034457903",
        "provideremail1": "albarkaclinic@gmail.com",
        "address1": "Beside Estate Qtrs, Dutse.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18669,
        "ProviderName": "All Souls Hospital, Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033142354",
        "provideremail1": "allsoulshosp@yahoo.com",
        "address1": "255, Ojo Road, Suru Alaba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18670,
        "ProviderName": "All Souls Infirmary, Agege",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034962603",
        "provideremail1": "NULL",
        "address1": "8 Church Street, Elere, Agege",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18672,
        "ProviderName": "Alma Clinics & Surgery",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07065113966",
        "provideremail1": "NULL",
        "address1": "19, Ikot Udoro Street, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18675,
        "ProviderName": "Alphonso Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033107798",
        "provideremail1": "NULL",
        "address1": "8, Trans-Woji Road, Elelenwo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19456,
        "ProviderName": "Alverna Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033393321",
        "provideremail1": "alvernamedical@gmail.com",
        "address1": "15, Olowotabutabu Street, Agric Bus Stop, Ojo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18676,
        "ProviderName": "Amana Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08043272013",
        "provideremail1": "amanamedicalcentre@yahoo.com",
        "address1": "5, Ilorin Street, Off Ogbomosho Street, Area 8, Garki.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18677,
        "ProviderName": "Amanda Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08162418899",
        "provideremail1": "NULL",
        "address1": "Plot 412/415, Works Layout, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19388,
        "ProviderName": "Amara Medicare Multi Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07064189209",
        "provideremail1": "eemenike@amaramedi.care",
        "address1": "Plot 76B, Eleganza Garden, Opp. VGC, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18678,
        "ProviderName": "Amazing Grace Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033595524",
        "provideremail1": "NULL",
        "address1": "7, Iloro Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18679,
        "ProviderName": "Ameso Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055675758",
        "provideremail1": "amesoclinic@yahoo.com",
        "address1": "7, Jumat Olukoya St., Off Ogudu Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18680,
        "ProviderName": "Amfani Clinic and Maternity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068950480",
        "provideremail1": "NULL",
        "address1": "15 Funtua Crescent New Layout, Katsina. ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18681,
        "ProviderName": "Amina Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033109883",
        "provideremail1": "NULL",
        "address1": "47, Gwari Avenue, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19845,
        "ProviderName": "Aminci Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035092283",
        "provideremail1": "amincihospital@gmail.com",
        "address1": "60, BWANDANG ROAD BUKURU",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18682,
        "ProviderName": "Amuwo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08064571074",
        "provideremail1": "amcmedical@yahoo.com",
        "address1": "Plot 343, Block 19, Durbar Road, Amuwo Odofin, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18683,
        "ProviderName": "Anchor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08168670731",
        "provideremail1": "anchorhospital@yahoo.com",
        "address1": "Km 15, Aba/PH Expressway, Eleme Junction,",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18684,
        "ProviderName": "AnchorMed Hospital, Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033586950",
        "provideremail1": "anchormed@yahoo.com",
        "address1": "Niyi Aniyikaiye Street, Tanke, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18685,
        "ProviderName": "Anioma Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037202373",
        "provideremail1": "samuelidiaghe43@yahoo.com",
        "address1": "13, Memeh Street, Agbor",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18686,
        "ProviderName": "Aniyun Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052246001",
        "provideremail1": "aniyunhospital@yahoo.co.uk",
        "address1": "3, Femi-Aderibigbe Close, Ifako, Gbagada.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18689,
        "ProviderName": "Ansor Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033660341",
        "provideremail1": "NULL",
        "address1": "Otokpa Layout, Ntamkpo, Ugep.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19942,
        "ProviderName": "Anyimichfranze   Hospital (AMF)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09064213887",
        "provideremail1": "hello@amfhospital.com",
        "address1": "41, Wogu Street D- Line Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18690,
        "ProviderName": "Araba Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033031875",
        "provideremail1": "NULL",
        "address1": "122, Ekoro Agbelekale Road (Big Joy B/Stop), Abule Egba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18692,
        "ProviderName": "Arif Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08062200944",
        "provideremail1": "NULL",
        "address1": "Sabo Main Market, Ore, Ondo State.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18693,
        "ProviderName": "Arrive Alive Diagnostics & Imaging Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08091838812",
        "provideremail1": "NULL",
        "address1": "35, Cole Street, off Mabo Street, Ojuelegba, Surulere, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18694,
        "ProviderName": "Arubah Family Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033927737",
        "provideremail1": "NULL",
        "address1": "9, Kudirat Salami Street, Agungi, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18695,
        "ProviderName": "Arubah Specialist Hospital & Diagnostics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032398409",
        "provideremail1": "info@arubahspecialisthospital.com",
        "address1": "23, Nsefik Eyo Close, off Ndidem Usang Road, Calabar",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18696,
        "ProviderName": "Aruna Ogun Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023839210",
        "provideremail1": "arunaogunspechosp@yahoo.com",
        "address1": "214, Bamimosu Street, Ipalekodo, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18697,
        "ProviderName": "Asher Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039472783",
        "provideremail1": "NULL",
        "address1": "Plot 1197, Katampe Cadastral, Zone B07, (Nicon Junction), Berger, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19820,
        "ProviderName": "Ashmed Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07061908442",
        "provideremail1": "ashmedspecialisthospital@yahoo.com",
        "address1": "33, Power Plant Road Agwa New Extension, Kaduna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18700,
        "ProviderName": "Avenue Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08077125881",
        "provideremail1": "NULL",
        "address1": "Neogilan House, Okumagba Avenue.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18701,
        "ProviderName": "Avon Medical Practice",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "07083824058",
        "provideremail1": "hmo.office@avonmedical.com",
        "address1": "8, Adedamola Ojomo Close, Off Bode Thomas St., Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18702,
        "ProviderName": "Ayo Bello Memorial",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08051301283",
        "provideremail1": "NULL",
        "address1": "1, Ayo Bello Street, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18705,
        "ProviderName": "Ayodele Medical Centre, Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033591998",
        "provideremail1": "NULL",
        "address1": "290 Lagos Road, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18704,
        "ProviderName": "Ayodele Medical Centre, Lagos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034025182",
        "provideremail1": "ayodelemedicalcentre86@yahoo.com",
        "address1": "1, Ayodele Close, Off Jonathan Coker Road, Fagba Junction, Iju Agege, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18706,
        "ProviderName": "Ayotola Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037274221",
        "provideremail1": "ayotolahospital2019@gmail.com",
        "address1": "2, Suraju Odunuga St., Off Baruwa Eleja, Sagamu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19680,
        "ProviderName": "Azir Healthcare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09090180190",
        "provideremail1": "azirhealthcare@gmail.com",
        "address1": "47, Oduntan Street, Kosofe, Ikosi-Isheri, Ketu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18707,
        "ProviderName": "Bakor Hospital, Ikom",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069731060",
        "provideremail1": "bmc.hmo@yahoo.com",
        "address1": "Nde, Ikom",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18708,
        "ProviderName": "Bakor Medical Centre, Calabar",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069731060",
        "provideremail1": "NULL",
        "address1": "124, Murtala Mohammed High way, Opp. NNPC Station, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18709,
        "ProviderName": "Balda Hospital & Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056674902",
        "provideremail1": "baldahomes@yahoo.com",
        "address1": "31, Itsekiri Street, Iju-Ajuwon, Ogun State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18710,
        "ProviderName": "Balm Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030681861",
        "provideremail1": "NULL",
        "address1": "29b, Bishop Street, GRA, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18711,
        "ProviderName": "Balm of Gilead Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037151125",
        "provideremail1": "NULL",
        "address1": "Oko-Opo road, off Ayeso street, Ilesa",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18712,
        "ProviderName": "Barewa Clinics and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034531444",
        "provideremail1": "barewaclinics@yahoo.co.uk",
        "address1": "11, Zaria Road, Kano, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19380,
        "ProviderName": "Barnes Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08129915303",
        "provideremail1": "barnes2.hospital@gmail.com",
        "address1": "34B, Chief Abiodun Yesufu Oniru Road, Victoria Island Extension",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19936,
        "ProviderName": "Base Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033449959",
        "provideremail1": "basespecialisthospital@gmail.com",
        "address1": "22, Demurin Road By Mascara Junction Ketu - Alapere Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19446,
        "ProviderName": "Bavaria Catholic Specialst Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08126443173",
        "provideremail1": "bavariacatholic.spec.hospital@gmail.com",
        "address1": "Mary-Hill Centre Enugu Exp.Way, Opp. Umulolo Junction Okigwe ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18713,
        "ProviderName": "Bay Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039744098",
        "provideremail1": "bayhospminna@yahoo.com",
        "address1": "Plot 99, Bay Clinic road, Tunga Minna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18714,
        "ProviderName": "Beachland Specialist Hospital - Arepo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033000346",
        "provideremail1": "bshmedical@yahoo.com",
        "address1": "15A, Alfidaus Estate, Arepo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19630,
        "ProviderName": "Beachland Specialist Hospital - Mowe",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034982938",
        "provideremail1": "bshmedical1@gmail.com",
        "address1": "156, Mowe/Ofada road, Ogun state(former Ofada-Mokoloki LCDA building)",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18716,
        "ProviderName": "BeaconHill Smile Clinic, Victoria Island",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08125215208",
        "provideremail1": "admin@beaconhillsmile.com",
        "address1": "4, Adeola Odeku Street, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18717,
        "ProviderName": "Bee Hess Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023119453",
        "provideremail1": "NULL",
        "address1": "155, Akowonjo Road, Akowonjo, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19952,
        "ProviderName": "Bellaidio Medical Centre ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039192068",
        "provideremail1": "info@belladiomed.com",
        "address1": "23, Unity Estate (Now Diamond) By Yah Lateef Street , Iyana Odo, Agura Gberigbe , Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19907,
        "ProviderName": "Belmedicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08121288148",
        "provideremail1": "belmedicarehospital@gmail.com",
        "address1": "Block 25,Flat 5,Adio Olusesi Street ,Olugboroya Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18718,
        "ProviderName": "Bendel Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065215576",
        "provideremail1": "bendelclinic@gmail.com",
        "address1": "19, Kagho Street, MCC Road, Enerhen2, Omamofe Sillo St., Off Etuwewe.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19399,
        "ProviderName": "Benin Medical Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "bmc123.",
        "provideremail1": "casemanager@beninmedicalcare.com",
        "address1": "53, Adesuwa Grammar School Road, GRA, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18720,
        "ProviderName": "Benok Heroes E.N.T. Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171639139",
        "provideremail1": "NULL",
        "address1": "17A, Unity Avenue, Rumuokwuta, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18721,
        "ProviderName": "Benok Heroes E.N.T. Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171639139",
        "provideremail1": "NULL",
        "address1": "17A, Unity Avenue, Rumuokwuta, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18722,
        "ProviderName": "BenOptika Vision - Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07086292092",
        "provideremail1": "benoptikavision@yahoo.com",
        "address1": "Our Lady Queen of Nigeria Pro-Cathedral, Ibadan Street. Area 3. Garki",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18726,
        "ProviderName": "Bernice Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023248834",
        "provideremail1": "bernicehospitals@yahoo.com",
        "address1": "5, Fadare Street, Mile 12, Kosofe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19786,
        "ProviderName": "Betaglass Worksite Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09087268324",
        "provideremail1": "frigoglassclinic@metrohealthhmo.com",
        "address1": "Beta Glass PLC, Guinea Plan, Agbara",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19406,
        "ProviderName": "Bidems Victory Hospital and Diagnostic Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033515908",
        "provideremail1": "hmo_bidemshospital@yahoo.com",
        "address1": "Simco Ummemekwu street off isawo road,okiki b/Stop agric ikorodu lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19890,
        "ProviderName": "Biodex Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037216212",
        "provideremail1": "biodexmc@gmail.com",
        "address1": "Besides Onimedu Police Station, Eleko Road Ibeju -Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18730,
        "ProviderName": "Bismol  Hospital  ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08053986994",
        "provideremail1": "NULL",
        "address1": "Plot  R22,  Area  C  Phase  IV, Nyanya, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19787,
        "ProviderName": "Blooming Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023060797",
        "provideremail1": "bloomingcarehospital.hmo@gmail.com",
        "address1": "30, Baale street Animashaun Street, Alakuko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18732,
        "ProviderName": "Blue Cross Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037202934",
        "provideremail1": "bluecrosshospital43@gmail.com",
        "address1": "48, Ijaiye Road, Ogba, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19595,
        "ProviderName": "Bluelime Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08163876808",
        "provideremail1": "bluelimehospitalhmo@gmail.com",
        "address1": "House 2, I1 Close, 7th Avenue, Festac Town",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18733,
        "ProviderName": "BMM Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033301626",
        "provideremail1": "live.safely@yahoo.com",
        "address1": "8/10 Kugbuyi Street, Idi-Oro, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19381,
        "ProviderName": "Bose Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023143560",
        "provideremail1": "bosespecialisth@gmail.com",
        "address1": "7, Ilori Street, Ire-Akari Estate, Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19930,
        "ProviderName": "Boyle Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022235656",
        "provideremail1": "boylemed@gmail.com",
        "address1": "1 b, Oyewole Way ,Off, Olufemi  Peters. Ire Akari Estate , Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18735,
        "ProviderName": "Brema Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037814237",
        "provideremail1": "bremahospital@yahoo.com",
        "address1": "5/7, Ashaka Road, Kwale",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18736,
        "ProviderName": "Brickfield Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033328807",
        "provideremail1": "brickfieldmedical@yahoo.com",
        "address1": "15, Ademuyiwa Road, Ebute Metta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18737,
        "ProviderName": "Britannia Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08078704277",
        "provideremail1": "britanniahospitallekki@yahoo.com",
        "address1": "Plot 13, Block 91, Aliu Animashaun Avenue, Lekki Phase 1",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18738,
        "ProviderName": "Broad Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033290753",
        "provideremail1": "hospitalbroad@yahoo.com",
        "address1": "17, Fehintola St., Off Isijola St., NEPA B/Stop, Ikotun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19383,
        "ProviderName": "Broadland Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08072147082",
        "provideremail1": "broadlandhospitalserv@gmail.com",
        "address1": "Hallmark Street, Gas Line Junction, Magboro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18740,
        "ProviderName": "Bronk Premiere  Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035802519",
        "provideremail1": "bronila_medical@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18742,
        "ProviderName": "BSA Hearing & Speech Centre - Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034019970",
        "provideremail1": "info@bsahearing.com",
        "address1": "Peace Plaza, 35 Ajose Adeogun, Utako District, Abuja, FCT",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18741,
        "ProviderName": "BSA Hearing & Speech Centre - Ikeja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023118446",
        "provideremail1": "info@bsahearing.com",
        "address1": "49B, Oluwaleyimu Street, off Allen Avenue, Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18743,
        "ProviderName": "Budo Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08060531049",
        "provideremail1": "budospecialisthospital@yahoo.com",
        "address1": "Km 25 Lekki-Epe Expressway, By Peninsula resort, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18744,
        "ProviderName": "By-Faith Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036689287",
        "provideremail1": "NULL",
        "address1": "Along Oba Adeniyi Sonatiwo Pry Sch. Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19987,
        "ProviderName": "Calabar Women and Children Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07042213383",
        "provideremail1": "cwchospital@yahoo.com",
        "address1": "164/165 Muritala Muhammed Highway Calabar",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18747,
        "ProviderName": "Calvary Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038673376",
        "provideremail1": "NULL",
        "address1": "7, Haastrop St., Orazi Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18749,
        "ProviderName": "Capitol Hill Clinic/ Hospital - Airport Road",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034105481",
        "provideremail1": "capitolhillclinic@yahoo.co.uk",
        "address1": "38B, Airport Road, Effurun",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18752,
        "ProviderName": "Care Foundation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033394266",
        "provideremail1": "NULL",
        "address1": "11, Gabriel Nwosu Avenue, Osisioma",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19780,
        "ProviderName": "Care Givers Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039110242",
        "provideremail1": "caregivershospital@gmail.com",
        "address1": "20, Torikoh Road, Opposite Coca-Cola Depot, Torikoh , Badagry",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19983,
        "ProviderName": "Caremax  Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09061545449",
        "provideremail1": "caremaxhospitals@yahoo.com",
        "address1": "2, Asajon Street Off Lekki Epe Express Way ,, Opp Nero water B/Stop Sango Tedo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18754,
        "ProviderName": "CarePoint Clinic, Egbeda",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033018550",
        "provideremail1": "admin.egbeda@carepointhospitals.com",
        "address1": "7, Ogunlana Street, Egbeda",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19427,
        "ProviderName": "CarePoint Clinic, Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033018550",
        "provideremail1": "admin.ojo@carepointhospitals.com",
        "address1": "9, Abbi Street, Rasaki Bus-stop off Shibiri Road Ilemba Awori Ojo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19467,
        "ProviderName": "Cares & Cares Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138541111",
        "provideremail1": "careshospitalcares@gmail.com",
        "address1": "1B, olubiyi Street, Along Great Challenge Road, Via Iyana School Bus Stop, Iba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19599,
        "ProviderName": "Cedarcare Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09062875238",
        "provideremail1": "hospital@cedarcaregroup.com",
        "address1": "Ogombo Road, Off Abraham Adesayan Roudabout, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19954,
        "ProviderName": "Cedarcare Hospital Ikota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09139352537",
        "provideremail1": "cedarcarehospital@gmail.com",
        "address1": "Megamound Shopping Complex, LekkyICounty Homes Estate, Ikota, Lekki, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19970,
        "ProviderName": "Cenac Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030326132",
        "provideremail1": "cenachospital@yahoo.com",
        "address1": "old  Assembly quaters Azikoro Road Ekeki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19452,
        "ProviderName": "Central Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023372919",
        "provideremail1": "cshota@ymail.com",
        "address1": "133, Idiroko Road, Oju-Ore, Sango Ota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19804,
        "ProviderName": "Channel Clinic & Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07067434036",
        "provideremail1": "channel.clinic@gmail.com",
        "address1": "10, Hospital Road Bonny",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18757,
        "ProviderName": "Chilas Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036930169",
        "provideremail1": "NULL",
        "address1": "20, Zaria Bye-Pass, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18759,
        "ProviderName": "Christ Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033333031",
        "provideremail1": "christmedicalcenter@gmail.com",
        "address1": "28, Randle Road, Apapa, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18760,
        "ProviderName": "Christ The King Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035959117",
        "provideremail1": "japokolo@yahoo.co.uk",
        "address1": "85, Afikpo Road, Abakaliki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18761,
        "ProviderName": "Christiana & Joseph Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033371220",
        "provideremail1": "josephandchristianahhospital@gmail.com",
        "address1": "Oke - Ilewo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18762,
        "ProviderName": "Citizen Hospital, Suleja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150420995",
        "provideremail1": "citizenhospital@yahoo.com",
        "address1": "18, Saidu Alkali Street, GRA Lowcost Estate, Suleja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18763,
        "ProviderName": "Citizen Medical Centre, Ikoyi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023131126",
        "provideremail1": "citizenmedicalcentre@yahoo.com",
        "address1": "80, Norman Williams Street, Ikoyi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18764,
        "ProviderName": "City Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037135334",
        "provideremail1": "NULL",
        "address1": "101, Ndidem Usang Iso Road, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18766,
        "ProviderName": "Clear View Optics, Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028475557",
        "provideremail1": "hmo.admin@clearviewopticsng.com",
        "address1": "Transcorp Hilton, Maitama, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18767,
        "ProviderName": "Clina Lancet Laboratories",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07046181382",
        "provideremail1": "NULL",
        "address1": "3, Babatunde Jose Street, off Adetokunbo Ademola, Victoria Island, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18769,
        "ProviderName": "Clinix Healthcare Ltd Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137993338",
        "provideremail1": "enquiry@clinixhealthcare.com.ng",
        "address1": "Plot B, Block XII, Alhaji Adejumo Avenue, Off Gbagada Oshodi Expressway, Anthony, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18768,
        "ProviderName": "Clinix Healthcare Ltd, Amuwo Odofin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137993338",
        "provideremail1": "claims.clinixhealthcare@gmail.com",
        "address1": "6, Ago Palace Way, By Apple Junction, Amuwo Odofin, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18770,
        "ProviderName": "Continental Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07066527133",
        "provideremail1": "NULL",
        "address1": "Engr. Adebayo Oshinbowale Close, Opp. Globacom House, Elewura B/Stop, Challenge.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19829,
        "ProviderName": "Coptic Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09039815570",
        "provideremail1": "info.ng@coptichospitals.org",
        "address1": "Plot 1, Block 11, Abila Abiodun Road, Oniru Private Estate , Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19774,
        "ProviderName": "Cosan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060514981",
        "provideremail1": "cosanhospital@yahoo.com",
        "address1": "House 6, Ola Balogun Street, Off Degolu Road, Igbe Laara via Oreyo Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19428,
        "ProviderName": "Cottage Medicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026261632",
        "provideremail1": "cottagemedicals@gmail.com",
        "address1": "18 Iwaya Road, Onike, Yaba\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18772,
        "ProviderName": "County Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032431487",
        "provideremail1": "countyhosp@yahoo.co.uk",
        "address1": "42/45, Isheri Road, Ogba, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18774,
        "ProviderName": "Cova Care Specialist Hospital & Endoscopy Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08182731100",
        "provideremail1": "covacare@yahoo.com",
        "address1": "8, Main Street, Farm Road 2, Eliozu, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19963,
        "ProviderName": "Credible Eyecare  Magodo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037358723",
        "provideremail1": "crediblevisioncare@gmail.com",
        "address1": "18, Kasumu Street Shangisha Magodo phase ii",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19962,
        "ProviderName": "Credible Eyecare Awka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037358723",
        "provideremail1": "crediblevisioncare@gmail.com",
        "address1": "7, Onwura Street Awka  South",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18776,
        "ProviderName": "Crest Hospital, Egan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033600955",
        "provideremail1": "hospitalcresthmo@yahoo.com",
        "address1": "159, Isuti Road, Egan.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18779,
        "ProviderName": "Crystal Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07056595596",
        "provideremail1": "providerservices@crystalhospital.com",
        "address1": "168, Akowonjo Road, Akowonjo, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19914,
        "ProviderName": "Cypresshill Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09164499914",
        "provideremail1": "info@cypresshillhospitals.com",
        "address1": "14, Olanrewaju Street ,Oregun ,Ikeja Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19991,
        "ProviderName": "D Great Ark Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08100324262",
        "provideremail1": "dgreatarkhospital@gmail.com",
        "address1": "28, Modupe Ayoade Street Araromi Iyana Ipaja",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18783,
        "ProviderName": "Dako Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033280454",
        "provideremail1": "dakomedicalcentre@yahoo.com",
        "address1": "185, Muyibi Street, Olodi Apapa, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19993,
        "ProviderName": "Dalet Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023053893",
        "provideremail1": "dalethospitalhmo@gmail.com",
        "address1": "Dalet Hospital, Sun view estate Opp crown estate Sangotedo. Lekki Epe ",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19653,
        "ProviderName": "Dalhatu Araf Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138919134",
        "provideremail1": "moibra662@gmail.com",
        "address1": "Dalhatu Araf Specialist Hospital Road, Shandam, Lafia",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18784,
        "ProviderName": "Dan Egerton Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033285916",
        "provideremail1": "NULL",
        "address1": "4, Oremerin Street, Off Okuyemisi, Itire, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19403,
        "ProviderName": "Dans Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08136916174",
        "provideremail1": "dansmedicalcare@gmail.com",
        "address1": "46, Thomas Olaniyan Street, Irawo, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18785,
        "ProviderName": "Dara Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09098795744",
        "provideremail1": "daramed@yahoo.com",
        "address1": "6, Ihiala Street, Area 2, Section 2, Garki, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19911,
        "ProviderName": "Dasochris Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033638549",
        "provideremail1": "dasochrisialashe@gmail.com",
        "address1": "2nd Avenue Poboyeso Estate Odonla Odonguyan Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18786,
        "ProviderName": "Daughters of Charity of St. Vincent de Paul Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033804146",
        "provideremail1": "dcstvinhosp@gmail.com",
        "address1": "Plot 505 Cadastral Zone FO1, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18787,
        "ProviderName": "Daula Hospital and Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036917466",
        "provideremail1": "daulahospital@hotmail.com",
        "address1": "Kasuwa Daji Off Kaura Namoda Road, Lebin Lebin Street, Gusau, Zamfara State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19925,
        "ProviderName": "Dayspring  Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033339347",
        "provideremail1": "dayspringspecialisthmo@gmail.com",
        "address1": "No 1, Poppola Oladunni Street Ikotun Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19941,
        "ProviderName": "Dayspring  Specialist Hospital Ajah",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033339347",
        "provideremail1": "dayspringspecialisthmo@gmail.com",
        "address1": "Remlek Bus- Stop Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18791,
        "ProviderName": "DCL Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033072123",
        "provideremail1": "dejiclinic2005@yahoo.com",
        "address1": "41, Demurin Street (Mascara Junction) Ketu Alapere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19754,
        "ProviderName": "De-Lakeland Imperial Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07089747125",
        "provideremail1": "lakelandimperial@gmail.com",
        "address1": "180 Awolowo Way, Itamaga Ikorodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19900,
        "ProviderName": "Dee-Goodnews Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07018615489",
        "provideremail1": "deegoodnewshosp@gmail.com",
        "address1": "White House by Living Stone ,Orofun Town. Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19421,
        "ProviderName": "Deelacs Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033250434",
        "provideremail1": "deelacshospital@gmail.com",
        "address1": "11 Magbon Obele rd, Magbon bus stop , badagry road",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18792,
        "ProviderName": "Delta Crown Hospital, Ketu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352195",
        "provideremail1": "deltacrownhospital2013@gmail.com",
        "address1": "14, Ajayi Street, Ketu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19816,
        "ProviderName": "Delta Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035481515",
        "provideremail1": "deltabonny@yahoo.com",
        "address1": "69,Hospital Road , Bonny Island ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19789,
        "ProviderName": "Denovo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08085089833",
        "provideremail1": "denovohospitalinfor@gmail.com",
        "address1": "3, Denovo Close by Mofijo Filling Station Enekan , Rukpokwu Road , Porthacourt , River -State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19852,
        "ProviderName": "Desol Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09055605107  ",
        "provideremail1": "info@desohealthgroup.com",
        "address1": "12, Balogun Estate Rd, OPP Fara Park Estate Majek b/Stop",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18796,
        "ProviderName": "Dialyzer Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08029460911",
        "provideremail1": "dialyzermedicalcentre@ymail.com",
        "address1": "60, Arowojobe Street, Oshodi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19957,
        "ProviderName": "Diamond Crown Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036817851",
        "provideremail1": "diamondcrownsh@gmail.com",
        "address1": "No 1 Cross Avenue, Psychiatric Road Rumnigba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18798,
        "ProviderName": "Dip Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033078763",
        "provideremail1": "diphillabvisioncare@yahoo.com",
        "address1": "38, Isawo Road, Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19045,
        "ProviderName": "Diphillab Vision Care & Optical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033078763",
        "provideremail1": "diphillabvisioncare@yahoo.com",
        "address1": "38, Isawo Road, Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19917,
        "ProviderName": "Disney Medicals Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09060008708",
        "provideremail1": "disneymedicals@gmails.com",
        "address1": "3b, Elder Nwuba Street ,Off Orchid Hotel Road ,Lekki -Epe Xpressway Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19995,
        "ProviderName": "Divine Grace Healing Strippes Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "07085599520",
        "provideremail1": "divinegracemedical@gmail.com",
        "address1": "3, Shobogun Rofa Street Mafoluku , Oshodi",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18800,
        "ProviderName": "Divine Grace Hospital, Port Harcourt",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037046319",
        "provideremail1": "NULL",
        "address1": "28B, Seaside Road, Oyigbo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18801,
        "ProviderName": "Divine Medical Centre, Ikoyi",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07087859429",
        "provideremail1": "divinemedicalcentre@yahoo.com",
        "address1": "53, Norman Williams Street, Ikoyi, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18802,
        "ProviderName": "Domingo Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08027306681",
        "provideremail1": "NULL",
        "address1": "Plot 9, Unit E, Ben Udoh Street, Ewet Housing Estate, Uyo, Akwa Ibom State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19959,
        "ProviderName": "Dominion Hospital Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08098790920",
        "provideremail1": "dominionhospitalservices@yahoo.com",
        "address1": "4,  Unum Street Off Elegusi B/Stop Owode Yewa",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18803,
        "ProviderName": "Doren Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08023062100",
        "provideremail1": "doren@hotmail.co.uk",
        "address1": "1, Kemfat Road, Km 28, Lekki–Epe Expressway, Ajah, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19847,
        "ProviderName": "Doren Specialist Hospital Ibeju Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032329193",
        "provideremail1": "doren@hotmail.co.uk",
        "address1": "Imalete-Alafia, Ibeju-Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18805,
        "ProviderName": "Dove Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068177773",
        "provideremail1": "dove.hospital@yahoo.com",
        "address1": "Badore Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18806,
        "ProviderName": "Dr. Hammond’s Clinic",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08061293047",
        "provideremail1": "NULL",
        "address1": "2, Ajose Adeogun Street, Victoria Island.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18807,
        "ProviderName": "Duro Soleye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08171227678",
        "provideremail1": "billing_dsh@yahoo.com",
        "address1": "36, Allen Avenue, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18809,
        "ProviderName": "Ebenezar Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033908772",
        "provideremail1": "NULL",
        "address1": "17, Station Road, Osogbo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18811,
        "ProviderName": "Edi International Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08067510994",
        "provideremail1": "admin@edihospital.com",
        "address1": "1, Coronation Drive, Off Aideyan Avenue",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19596,
        "ProviderName": "Edo Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09018296396",
        "provideremail1": "hmo@edospecialisthospital.com",
        "address1": "Plot 2. Sapele road Benin city, Edo state, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19704,
        "ProviderName": "Ekiti State University Teaching Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235478",
        "provideremail1": "eksuthmhis@yahoo.com",
        "address1": "State Hospital Road, Ado Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18812,
        "ProviderName": "El-Bethel Medical Centre, Sagamu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063513",
        "provideremail1": "elbethelmedicalcentre@gmail.com",
        "address1": "31, Dantajira Street, Sabo, Sagamu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18815,
        "ProviderName": "El-shaddai Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056085300",
        "provideremail1": "NULL",
        "address1": "6, Alhaji Oshibanjo St., Off Ejirin Rd.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19498,
        "ProviderName": "Elf Foundation Mother and Child Clinics – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "+2340802222222",
        "provideremail1": "callcenter@metrohealth.com",
        "address1": "Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18813,
        "ProviderName": "Eli Johnson Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033367491",
        "provideremail1": "ejshospital@yahoo.com",
        "address1": "13, Bori Rd, Rumuibekwe Housing Estate.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18814,
        "ProviderName": "Elphy Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033055599",
        "provideremail1": "elphyspecialisthospital@yahoo.com",
        "address1": "6, Taskforce Road, PPL, Okokomaiko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18816,
        "ProviderName": "Emel Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07046083798",
        "provideremail1": "info@emelhospital.org",
        "address1": "Z Close, 21 Road, Festac Town, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18817,
        "ProviderName": "Endoscopy de Gilead Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034476437",
        "provideremail1": "NULL",
        "address1": "1, Gilead Lane, NTA Road, off Ilawe Road, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19536,
        "ProviderName": "Esso Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09023556918 ",
        "provideremail1": "essohospital@gmail.com",
        "address1": "52, Itele Agbaku Road, Maya, Ikorodu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18818,
        "ProviderName": "Estate Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039132858",
        "provideremail1": "NULL",
        "address1": "47, Onofeghara St., By Polokor Mkt, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19948,
        "ProviderName": "Eternal Life Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09011223874",
        "provideremail1": "eternallife.hospital@gmail.com",
        "address1": "31, Iganmu Road Orile LAGOS",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19953,
        "ProviderName": "Evanson Medical Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08062494974",
        "provideremail1": "evansonmedicalcenter@gmail.com",
        "address1": "2a, Iyalde Close, Off Ajayi Road , Oke -Ira , Ogba. Lagos State ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18821,
        "ProviderName": "Ever Life Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033610434",
        "provideremail1": "everlifehospital@yahoo.com",
        "address1": "3, Edoso Street, Ijanikin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19851,
        "ProviderName": "Evolution Hospital Limited",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08077313591",
        "provideremail1": "evolutionhospitals@yahoo.com",
        "address1": "16-18 Falilat Ojolowo Street ,CPI Bus Stop, Gberigbe, Ikirodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18825,
        "ProviderName": "Ezem Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034462817",
        "provideremail1": "NULL",
        "address1": "2/4, Osuji Street, Owerri Municipal.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18827,
        "ProviderName": "Faith City Hospital  Victoria Island",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08142078678",
        "provideremail1": "faithcitylagos@gmail.com",
        "address1": "9, Oju Olobun Close, off Bishop Oluwole Street, Victoria Island.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18826,
        "ProviderName": "Faith City Hospital Ajao",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032308282",
        "provideremail1": "faithcitylagos@gmail.com",
        "address1": "16, Asa Afariogun Street, Ajao Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18828,
        "ProviderName": "Faith Foundation Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056568291",
        "provideremail1": "NULL",
        "address1": "57, Ndidem Usang Iso Road, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19426,
        "ProviderName": "Faith Hills Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033286876",
        "provideremail1": "faithhills.hospital@gmail.com",
        "address1": "faithhills.hospital@gmail.com\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19815,
        "ProviderName": "Faithshield Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033106",
        "provideremail1": "faithshield.enquiries@gmail.com",
        "address1": "15, Gasline Road , Magboro. Ogun -State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19453,
        "ProviderName": "Family Care Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08155000405",
        "provideremail1": "familycarehospitalbys@gmail.com",
        "address1": "108, AIT Elebele Road, Opolo, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19813,
        "ProviderName": "Family Care Specialist Clinics and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033187641",
        "provideremail1": "jidegwim@yahoo.com",
        "address1": "Road 15, Federal Housing Estate, Umuguma, New Owerri, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18830,
        "ProviderName": "Famo Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033136893",
        "provideremail1": "famoclinic@gmail.com",
        "address1": "11, Egbe Street, Alesa, Eleme",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18933,
        "ProviderName": "Famous Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08184676891",
        "provideremail1": "famoushospitalfmc@gmail.com",
        "address1": "Green Roof, Opposite Lekki Free Trade Zone, Ibeju Lekki, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18831,
        "ProviderName": "Fanimed Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023131678",
        "provideremail1": "fanimed92@yahoo.com",
        "address1": "Blk 210, Lily Road, LSDPC Medium Housing Estate (Phase IV), Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18832,
        "ProviderName": "Faramed Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036368730",
        "provideremail1": "faramedclinics@gmail.com",
        "address1": "37, Akinwunmi Street, Off Ijoko Rd, Sango.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18834,
        "ProviderName": "Fasanmi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033157028",
        "provideremail1": "NULL",
        "address1": "5/7, Olori Street, Shogunle",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19679,
        "ProviderName": "Federal Medical Centre, Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "fmcasabanhisdeskoffice@gmail.com",
        "address1": "Nnebisi Road, Isieke, Asaba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19763,
        "ProviderName": "Federal Medical Centre, Ebute Metta",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "dally.babatunde@fmceb.org",
        "address1": "2, Ondo Street, Ebute Metta, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19896,
        "ProviderName": "Fertile Ground Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08161105024",
        "provideremail1": "fertileghospital@gmail.com",
        "address1": "Plot 1234 Zaramaganda ,Adjacent  Gold B Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19922,
        "ProviderName": "Fimforte Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07016357096",
        "provideremail1": "fimfortehospital@gmail.com",
        "address1": "No 1,Bloombreed Sch Road, Opposite NTA ,Mgbuoba, Porthacourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19668,
        "ProviderName": "First Care Multi Specialist Hospital - Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036183940",
        "provideremail1": "firstcaremultispecialisthospt@gmail.com",
        "address1": "4, Mariam Pam Drive, Rayfield, Jos, Plateau State.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19667,
        "ProviderName": "First Care Multi Specialist Hospital - Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07063144606",
        "provideremail1": "firstcaremultispecialisthospt@gmail.com",
        "address1": "14, Rivers Road, Water Board G.R.A, Zaria",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18838,
        "ProviderName": "First City Hospital Osapa-Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033480926",
        "provideremail1": "firstcity_hospital@yahoo.com",
        "address1": "17, Osapa Road, Osapa-London, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18839,
        "ProviderName": "First Concept Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033263849",
        "provideremail1": "NULL",
        "address1": "Plot 111, Ohaji By Iheachuwa Street, Off MCC Road, Owerri : Near Chukwama Nwoha Junction ; Behind St John's Lab",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18842,
        "ProviderName": "First Faith Medical Centre, Karimu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022239535",
        "provideremail1": "firstfaithmedical@yahoo.com",
        "address1": "5, Karimu Street, Orile Iganmu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18843,
        "ProviderName": "First Faith Medical Centre, Kekere Ekun",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022239535",
        "provideremail1": "firstfaithmedical@yahoo.com",
        "address1": "2, Kekere Ekun Street, Orile Iganmu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19709,
        "ProviderName": "First Heritage Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023090762",
        "provideremail1": "info@firstheritagehospital.com",
        "address1": "29/33 Ayantuga street Mushin Lagos ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18845,
        "ProviderName": "First Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036511840",
        "provideremail1": "firsthospitalltd@rocketmail.com",
        "address1": "Along Jikwoyi-Karshi Expressway, opposite Jikwoyi Timber Shed, Agwangade",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18846,
        "ProviderName": "First Mercy Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034741444",
        "provideremail1": "fmshla.nig@gmail.com",
        "address1": "19C, Gbogi St., Off Oyemekun Rd., Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18847,
        "ProviderName": "First Rivers Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08158493640",
        "provideremail1": "NULL",
        "address1": "250, Port Harcourt-Aba Expressway, Rumuomasi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18850,
        "ProviderName": "Foresight Specialist  Eye Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023512002",
        "provideremail1": "adeyinkaashaye@yahoo.com",
        "address1": "UI Secretariat Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18855,
        "ProviderName": "Freehand Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035861221",
        "provideremail1": "NULL",
        "address1": "NULL",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19766,
        "ProviderName": "Gaarki Shepherd Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065467140",
        "provideremail1": "shepherdspecialistclinic@gmail.com",
        "address1": "25 Shuni Road Mabera Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18858,
        "ProviderName": "Garin Alimi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035765037",
        "provideremail1": "NULL",
        "address1": "283, Umoru Saro Road, Ilorin.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19650,
        "ProviderName": "Garki General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08095698172",
        "provideremail1": "customerservice@garkihospital.com",
        "address1": "Tafawa Balewa Way, Area 3, Garki, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18859,
        "ProviderName": "Garkuwa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033008009",
        "provideremail1": "NULL",
        "address1": "1A, Sultan Rd, Off Issa Kuita Rd., Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18860,
        "ProviderName": "Garrison Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023130311",
        "provideremail1": "NULL",
        "address1": "10, Udom Street, D-Line, Port Harcourt.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18862,
        "ProviderName": "Gateway Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037817902",
        "provideremail1": "egatewayhospital@yahoo.com",
        "address1": "36 Abuja St, Off Donga Rd, Dorowa, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18863,
        "ProviderName": "Gbagada General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08075594468",
        "provideremail1": "gbagadagh@yahoo.com",
        "address1": "1, Hospital Road, Gbagada",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18864,
        "ProviderName": "Gbeye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07019340251",
        "provideremail1": "NULL",
        "address1": "16, Ahoada Road, Omoku, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19655,
        "ProviderName": "GCEM Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08033848577",
        "provideremail1": "gcemhospital@gmail.com",
        "address1": "20, Tokunbo Street, Off Alagomeji Bus Stop, Yaba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19725,
        "ProviderName": "General Hospital Ilorin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052076625",
        "provideremail1": "generalhospitalilorin@gmail.com",
        "address1": "Abdullazze Attah Road Tiawo, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18866,
        "ProviderName": "Geo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023050089",
        "provideremail1": "frontdesk@geomedicalcentre.com",
        "address1": "12, Subairu Street, Ladipo, Oshodi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18867,
        "ProviderName": "George’s Memorial Medical Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08078191600",
        "provideremail1": "practicemanager@georgesmedical.com",
        "address1": "6, Rasheed Alaba Williams Street, Lekki Phase I, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19986,
        "ProviderName": "Geranno Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150999542",
        "provideremail1": "gerannomedicalcentre@gmail.com",
        "address1": "12 Efosa Uyi street, off Irhirhi road, off Airport road, Benin city, Edo state",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18870,
        "ProviderName": "Giwa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037874081",
        "provideremail1": "NULL",
        "address1": "2, Giwa Road, Abakpa, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19971,
        "ProviderName": "GLad Tidings Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026155457",
        "provideremail1": "gladtidingshospitalhmo@yahoo.com",
        "address1": "1, Amusa Alabi Street, OFF Gbadamosi Opesa Street , Opeki B/Stop. Ipaja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18871,
        "ProviderName": "Gloria Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08054434617",
        "provideremail1": "gloriahospital@gmail.com",
        "address1": "9, Oladehinde St., Maryland, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19794,
        "ProviderName": "Glorious Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08143703828",
        "provideremail1": "gloriousmediccentre@gmail.com",
        "address1": "No 4, Timber Market Road , Oyigbo West , Oyingbo, River State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18872,
        "ProviderName": "Glory Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033788454",
        "provideremail1": "glorymedibafo@yahoo.com",
        "address1": "16, Nurudeen Oshodi Street, Kwara Qtrs",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19405,
        "ProviderName": "Gods Apple City Hospital Asese",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038081498",
        "provideremail1": "godsapplecityhospital@gmail.com",
        "address1": "7, Glo Road, Asese bus stop, Ogun state",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18875,
        "ProviderName": "Goldie Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033329439",
        "provideremail1": "NULL",
        "address1": "4, Ekong Nyong Close, Eta Agbo Layout.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18876,
        "ProviderName": "Good Faith Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023141292",
        "provideremail1": "NULL",
        "address1": "1, Akindelu Street, Ikate, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18879,
        "ProviderName": "Good Tidings Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033153110",
        "provideremail1": "goodtidingshospital@yahoo.co.uk",
        "address1": "37-39, Fashoro Street, Ojuelegba, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19822,
        "ProviderName": "Good Times Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09131039140",
        "provideremail1": "goodtimesmedicalcentre@gmail.com",
        "address1": "7,Aladun Estate Arulogun Road , Ojoo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19469,
        "ProviderName": "Goodluck Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033317184",
        "provideremail1": "drabulu@yahoo.com",
        "address1": "4, The Light Close, Mercy Estate, Asore, Ogun State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19800,
        "ProviderName": "Goodness Medical Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033467912",
        "provideremail1": "goodnessmedicalcentre@yahoo.com",
        "address1": "No 151, Shibiri Road, Opp Council, Ojo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19866,
        "ProviderName": "Goshen Medicare Solutions and Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038161965",
        "provideremail1": "info.gfmaa@gmail.com",
        "address1": "No 1, Opara Close , Off Ada George Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18881,
        "ProviderName": "Gowon Estate Clinic Consortium",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033027785",
        "provideremail1": "gestateclinic@yahoo.com",
        "address1": "Plot 40A, 33 Road, Gowon Estate, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19897,
        "ProviderName": "Grabbo Fertility Clinic & Diagnostic Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08080679501",
        "provideremail1": "suf",
        "address1": "Plot 83, 5212 Road ,Gwarinpa ii Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18884,
        "ProviderName": "Grace Touch Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037767916",
        "provideremail1": "NULL",
        "address1": "36, Azikiwe Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18885,
        "ProviderName": "Graceland Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033038626",
        "provideremail1": "gmcdrugs2002@yahoo.co.uk",
        "address1": "5, LASU-Isheri Road, Iba, Ojo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19775,
        "ProviderName": "Green Life Hospital - Ago",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033772753 ",
        "provideremail1": "greenlifehospitalagor@gmail.com",
        "address1": "28 Owolabi Street, Ago Palace Way, Oshodi-Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19712,
        "ProviderName": "Green Life Hospital - Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09060007146",
        "provideremail1": "greenlifehospital@gmail.com",
        "address1": "15 Association Avenue Ilupeju, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19782,
        "ProviderName": "Greenwood Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09037701097",
        "provideremail1": "info@greenwoodhospital.com",
        "address1": "No 1, Odunlami Street , Off oshidelum, Off Akilo Road Agege",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19892,
        "ProviderName": "Group Christian Medical Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034077945",
        "provideremail1": "gcmc_ok@yahoo.com",
        "address1": "No 3,Shirlet Onyemenam Street Oknan",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18886,
        "ProviderName": "Group Medical Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08129458349",
        "provideremail1": "groupmedical2017@gmail.com",
        "address1": "4, Queen Elizabeth Road, Mokola, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19883,
        "ProviderName": "Guardian Health Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08124317489",
        "provideremail1": "guardian_healthy@yahoo.com",
        "address1": "182, Ekoro Road Upstair b/stop Abule -Egba Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19956,
        "ProviderName": "Guscare Medical Limited ",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08022900002",
        "provideremail1": "guscaremedicalltd@gmail.com",
        "address1": "No, 35b, Orogbon Crescent  GRA ,Phase ii POrthacourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18887,
        "ProviderName": "Hamkad Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033301875",
        "provideremail1": "hamkadhospital.hmos@gmail.com",
        "address1": "39, Olawale Cole Street, U-Turn Bus-stop, Abule Egba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18891,
        "ProviderName": "Healing Field Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08080502612",
        "provideremail1": "NULL",
        "address1": "1, Ajibade Oke Street, Ajao Estate, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19666,
        "ProviderName": "Healing Touch Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034035649",
        "provideremail1": "healingtouchhospital16@gmail.com",
        "address1": "42,Oludegun Avenue, Ire-Akari, Isolo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19656,
        "ProviderName": "Healing Virtues Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065600183",
        "provideremail1": "healingvirtueshospital2@gmail.com",
        "address1": "21, Odubakin Street, Abule Agun, Baruwa, Ipaja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18892,
        "ProviderName": "Heals Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033019832",
        "provideremail1": "NULL",
        "address1": "40b, Godwin Omonua Street, Ire-Akari Estate, Isolo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19881,
        "ProviderName": "Healthbridge Medical Consultant",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08095216350",
        "provideremail1": "healthbridgemc@gmail.com",
        "address1": "Plot F1/A-B Abacha Road GRA Phase 3 Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19869,
        "ProviderName": "Heda Hospital & Maternity ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08180142447",
        "provideremail1": "hedahospital@yahoo.com",
        "address1": "No 2,Nwabueze Close ,Off Aina Jegede close ,Ajao Estate",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18893,
        "ProviderName": "Heilung Kreuz Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030000855",
        "provideremail1": "info@heilungkreuzhospital.com",
        "address1": "Km 42, Lekki-Epe Expressway, Awoyaya",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18894,
        "ProviderName": "Hemko Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07039032408",
        "provideremail1": "NULL",
        "address1": "Km 7, Makurdi-Aliade Road, Makurdi.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18897,
        "ProviderName": "Hephzibah Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08188564302",
        "provideremail1": "hephzibaheyecare@gmail.com",
        "address1": "50, Oba Akran Avenue, (Opp. UBA) Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19783,
        "ProviderName": "Heritage Medicare Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08068223046",
        "provideremail1": "heritagemedicare@gmail.com",
        "address1": "Plot 3, Heritage Avenue, Ndubuisi, Estate ByAfam Road Oyigbo River -state",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18899,
        "ProviderName": "Hetta Medical Centre, Odan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034738466",
        "provideremail1": "hettamedicalcentre@yahoo.com",
        "address1": "2, Awene Close, Off Market Rd, Odan.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18900,
        "ProviderName": "Hetta Medical Centre, Otta",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034738466",
        "provideremail1": "hettamedicalcentre@yahoo.com",
        "address1": "15, Matina Road, Sango Ota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18901,
        "ProviderName": "Hillstar Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08168127255",
        "provideremail1": "hillstar_hospital@yahoo.com",
        "address1": "98, Palm Avenue, Papa Ajao, Mushin.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18903,
        "ProviderName": "Holy Cross Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035132717",
        "provideremail1": "NULL",
        "address1": "Ijaye.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19799,
        "ProviderName": "Holy Trinity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167599420",
        "provideremail1": "holytrinityhospitals2gmail.com",
        "address1": "110b, Obafemi Awolowo Way , Ikeja .Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18905,
        "ProviderName": "Homa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065490571",
        "provideremail1": "homa.hospital@yahoo.com",
        "address1": "Plot 18, Hudco Quarters, High Level Area, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18906,
        "ProviderName": "Hope Clinic & Maternity, Auchi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037778238",
        "provideremail1": "hopeclinic99@hotmail.com",
        "address1": "1, Whitehouse Street, Jatau, Auchi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19824,
        "ProviderName": "Hopehill Specialist Medical Centre Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030680770",
        "provideremail1": "hopehillmedical@gmail.com",
        "address1": "No 7c Hospital Place",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18907,
        "ProviderName": "Hopeland Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033521376",
        "provideremail1": "NULL",
        "address1": "1, Arikawe Street, Beside Elizade Motors, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18908,
        "ProviderName": "Horstman Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033167877",
        "provideremail1": "NULL",
        "address1": "32, Okigwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18909,
        "ProviderName": "Humanity Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033936309",
        "provideremail1": "NULL",
        "address1": "3, Humanity Hospital Close, Enerhen",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18910,
        "ProviderName": "Ibijola Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08029099204",
        "provideremail1": "ibijolahospital@yahoo.com",
        "address1": "KM 31, Lagos – Badagry Expressway, Agbara.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18911,
        "ProviderName": "Icon Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033185341",
        "provideremail1": "NULL",
        "address1": "303, Nnebisi Road, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18912,
        "ProviderName": "Ideal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035641461",
        "provideremail1": "NULL",
        "address1": "74, Emir Avenue Sabon Gari, Kano",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19974,
        "ProviderName": "Ifako Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08144932281",
        "provideremail1": "ifakomedical@gmail.com",
        "address1": "65 Oyemekun  Street Off  College  Road Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18914,
        "ProviderName": "Ifebi Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068954494",
        "provideremail1": "NULL",
        "address1": "16A, Igwebuike School Road, Awka",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18915,
        "ProviderName": "IFPF Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08037272520",
        "provideremail1": "ifpf_cmd@yahoo.co.uk",
        "address1": "5, Oki Lane, Maryland, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18916,
        "ProviderName": "Ihenyen Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033595764",
        "provideremail1": "NULL",
        "address1": "4, Jemide Drive, Off Goodwill, Ekenhwan",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18917,
        "ProviderName": "Ikeja Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033076995",
        "provideremail1": "imc_aviation21@yahoo.com",
        "address1": "11, Ogunmodede Street, Off Allen Av., Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18918,
        "ProviderName": "Ikenegbu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028537535",
        "provideremail1": "NULL",
        "address1": "11-13, Pat Amadi Crescent, Off Okigwe Road, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18920,
        "ProviderName": "Ila Universal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036583150",
        "provideremail1": "ilauhkj@gmail.com",
        "address1": "Plot MF1, AA1 Layout, Kuje.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19598,
        "ProviderName": "Ilogbo Central Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09084975992",
        "provideremail1": "centralhospital08@rocketmail.com",
        "address1": "175 Ilogbo Road, Ilogbo, Ajangbadi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18923,
        "ProviderName": "Image Diagnostics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030731522",
        "provideremail1": "NULL",
        "address1": "9B, Eligbam Road, opp P/Harcourt Polytechnic, Rumuola, Port Harcourt, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18924,
        "ProviderName": "Immanuel Infirmary",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023315453",
        "provideremail1": "NULL",
        "address1": "1, Assemblies of God Rd., Army Junction Ikot Effang.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18925,
        "ProviderName": "Inland Medical Centre, Akoko",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09086012516",
        "provideremail1": "inlandmedic@gmail.com",
        "address1": "2, Olanipekun Lane, Ikare, Akoko.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19675,
        "ProviderName": "Inland Specialist Hospital - Ogudu",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08020638624",
        "provideremail1": "inlandspecialisthospital@yahoo.com",
        "address1": "153 Ogudu Road, Ogudu GRA",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18926,
        "ProviderName": "Inland Specialist Hospital, Ketu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08088107349",
        "provideremail1": "inlandspecialisthospital@yahoo.com",
        "address1": "11, Bola Owodunni Street, Alapere, Ketu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19518,
        "ProviderName": "Instant Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038596947",
        "provideremail1": "instantcarehospital20@gmail.com",
        "address1": "62,Oladun Street, Powerline Bus Stop, Beside Divine Emporium Event Centre, Isheri- Olofin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18927,
        "ProviderName": "International Clinics and Hospital Limited",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037877919",
        "provideremail1": "internationalclinicsh@yahoo.com",
        "address1": "40 Niger Street/2A Airport Road, Kano.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19659,
        "ProviderName": "Ire Anu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08059980066",
        "provideremail1": "infoireanuhospital@yahoo.com",
        "address1": "120 Lagos Road, Ikorodu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18929,
        "ProviderName": "Isalu Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033089592",
        "provideremail1": "hmo@isaluhospitals.com",
        "address1": "349B, Odusanmi Street, Off WEMPCO Rd., Ogba, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19992,
        "ProviderName": "Isuti Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09123340215",
        "provideremail1": "isutimedicalhmoclients@gmail.com",
        "address1": "16, Steve Igbeson Street , Egan",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18931,
        "ProviderName": "Ivory Clinic and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07066410893",
        "provideremail1": "ivory.clinic@yahoo.com",
        "address1": "550 Aminu Kano Way, Kano.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18932,
        "ProviderName": "Iyalode Bisoye Tejuosho Hospital (Teju Industrial Clinic)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080913139",
        "provideremail1": "NULL",
        "address1": "20/22 Ilupeju Bye-Pass, Town Planning way, Ilupeju",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18934,
        "ProviderName": "Jamaá Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035985653",
        "provideremail1": "NULL",
        "address1": "90, Sarkin Pawa Road, Samaru, Zaria.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18935,
        "ProviderName": "Jefis Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08121443347",
        "provideremail1": "jefishospital2006@gmail.com",
        "address1": "9, Adegbola Street, Alakuko, Ijaiye Ojokoro.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18937,
        "ProviderName": "Jerab Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036208853",
        "provideremail1": "NULL",
        "address1": "Jeremiah Abutu Close, Kutunku Road, off FRCN rd, Gwagwalada, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18938,
        "ProviderName": "Jethrone Hospital & Diagnostics Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038040339",
        "provideremail1": "hjethrone24@gmail.com",
        "address1": "44, NYSC road (by FRSC office), Gbazango, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18939,
        "ProviderName": "Jicon Hospital & Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037042446",
        "provideremail1": "NULL",
        "address1": "4, Danwaire Street, Barnawa, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19395,
        "ProviderName": "JJANED Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09034291441",
        "provideremail1": "jjanedsh@gmail.com",
        "address1": "3, Jerry Nwakobi Street, Beside Lekki Gardens, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18941,
        "ProviderName": "Jobi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023193932",
        "provideremail1": "jobihospital@hotmail.com",
        "address1": "3/5, Adeyeri Owuyo Street, Ikorodu, Lagos State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19430,
        "ProviderName": "Joedan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033589067",
        "provideremail1": "joedanhospitaltd@yahoo.com",
        "address1": "3, Lagos Badagry Express Road, Orile Bus Stop, Iganmu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19928,
        "ProviderName": "Jomapet Hospital & Maternity Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09020623515",
        "provideremail1": "jomahospital61@gmail.com",
        "address1": "61,Yawole Road Dreamsville Estate ,Ajuwon ,Ogun State ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18945,
        "ProviderName": "Jowako Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07088184485",
        "provideremail1": "jowako.hospital@yahoo.com",
        "address1": "F15, Jos Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18946,
        "ProviderName": "Julius Ezenyirioha Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038554523",
        "provideremail1": "NULL",
        "address1": "39, Atani Street, Abakpa-Nike, Enugu, Enugu State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19714,
        "ProviderName": "K-Dara Specialist Clinics.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035922915",
        "provideremail1": "kabirdara@yahoo.com",
        "address1": "Gidan Dawa, Katsina",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18947,
        "ProviderName": "KAAF Maternity & Laboratory Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08170596351",
        "provideremail1": "kaafmaternityunilag@gmail.com",
        "address1": "Beside Medical Centre, UNILAG, Akoka.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19703,
        "ProviderName": "Kanayo Specialist Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "kanayospecialisthospital@yahoo.com",
        "address1": "17 Enugu Road, GRA, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18949,
        "ProviderName": "Karaye Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066024503",
        "provideremail1": "bellooricha@yahoo.com",
        "address1": "Emir Yahaya Road, Sokoto.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19809,
        "ProviderName": "Kariden Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039339144",
        "provideremail1": "karidenspecialisthospital@gmail.com",
        "address1": "7, Monago Close , Off East -West Rd, Rumuodara",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19848,
        "ProviderName": "Kasomene Faith City Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09135127425",
        "provideremail1": "kasomenefaithcity@gmail.com",
        "address1": "No 5, Shell location Road , Off Iwoje Rd , Portharcort ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19823,
        "ProviderName": "Katameya First Call Hospital Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09128781858",
        "provideremail1": "safeibro@yahoo.com",
        "address1": "Opp Forma Govt Lodge After T & T Junction before the bridge ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18951,
        "ProviderName": "Kendox Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033421678",
        "provideremail1": "NULL",
        "address1": "194, Old Refinery Road, Elelenwo, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18952,
        "ProviderName": "Kez Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033422336",
        "provideremail1": "NULL",
        "address1": "16, Wopara Street, Rumuomasi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18954,
        "ProviderName": "Kiladejo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069445717",
        "provideremail1": "kiladejohosp@yahoo.com",
        "address1": "15, PPL Road, Okokomaiko",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19821,
        "ProviderName": "King’s Cross Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035734279",
        "provideremail1": "anthonyegboh@yahoo.co.uk",
        "address1": "20, Badagry Street, High Level, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18955,
        "ProviderName": "Kings Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08139734223",
        "provideremail1": "hmok57@yahoo.com",
        "address1": "Plot 2181, Ibrahim Babangida Way, Wuse 4, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19711,
        "ProviderName": "Kingsword Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032546080",
        "provideremail1": "kingswordhospital@gmail.com",
        "address1": "1 Oluwawemimo Hally/Faith Road Hally.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18956,
        "ProviderName": "KLM Muslim Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033813028",
        "provideremail1": "NULL",
        "address1": "Fesu Road, Iwo.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18957,
        "ProviderName": "Korede Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037187560",
        "provideremail1": "NULL",
        "address1": "Ibara Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18959,
        "ProviderName": "Kowa Clinic, Ughelli",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033362091",
        "provideremail1": "kowaclinic@yahoo.com",
        "address1": "15, Isoko Road, Ughelli",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18961,
        "ProviderName": "Krown Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055233730",
        "provideremail1": "krown1@hotmail.com",
        "address1": "11, Alhaji Sekoni Street, Off Alimosho Rd., Iyana Ipaja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18962,
        "ProviderName": "Kubwa General Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033022566",
        "provideremail1": "NULL",
        "address1": "Phase 4, opposite GSS, Kubwa, Abuja, FCT.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18964,
        "ProviderName": "Kuro Specialist Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068883476",
        "provideremail1": "NULL",
        "address1": "16, Otiotio Rd, Yenezue-Gene, Yenagoa.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18965,
        "ProviderName": "Kyutis Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023039815",
        "provideremail1": "kyutisclinic@gmail.com",
        "address1": "2A, Raymond Njoku Street, Ikoyi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18967,
        "ProviderName": "Lad Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033227534",
        "provideremail1": "ladhospital97@yahoo.com",
        "address1": "Orita Challenge, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18968,
        "ProviderName": "Ladi-Lak Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08055008908",
        "provideremail1": "ladilakmedicalcentre@yahoo.com",
        "address1": "53, Igi Olugbin Street, Bariga, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18969,
        "ProviderName": "Ladiya Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039620209",
        "provideremail1": "NULL",
        "address1": "64, Benin Street, Zaria, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18971,
        "ProviderName": "Lafiya Clinics & Health Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036026349",
        "provideremail1": "lafiyaclinichealthservice@gmail.com",
        "address1": "Plot 57, Marunmarun layout, Western Bye-pass, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19909,
        "ProviderName": "Lakeland Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033802242",
        "provideremail1": "lakelandsc01@gmail.com",
        "address1": "2,Kingsley Otuaro Drive,Mosheshe Estate ,Airport Road Effurum",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18978,
        "ProviderName": "Lanark Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033643654",
        "provideremail1": "lanarkspecialisthospital@yahoo.com",
        "address1": "8, Wuraola-Esan Close, off New Adeoyo State Hospital Road, Ring Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18979,
        "ProviderName": "Lanik Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034742656",
        "provideremail1": "lanikmedicalcentre@gmail.com",
        "address1": "14, Omo Owo Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19946,
        "ProviderName": "Lantos Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08112794316",
        "provideremail1": "lantoshospital@yahoo.com",
        "address1": "54, Adesan Road , Off Ofada Road , Mowe ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19841,
        "ProviderName": "Lawrak Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142289683",
        "provideremail1": "lawrakhospital2007@yahoo.com",
        "address1": "21, Temple Road Off Miango Road Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18980,
        "ProviderName": "Lekki Medical Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08166229488",
        "provideremail1": "NULL",
        "address1": "10/12, Agboola Gomez St., Lekki – Epe Expressway, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18981,
        "ProviderName": "Lennox Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023266709",
        "provideremail1": "lennoxhospital@gmail.com",
        "address1": "Plot 7, Floodgate Street, off Lekki-Epe Expressway, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18982,
        "ProviderName": "Lex Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033071587",
        "provideremail1": "lexmediclinics@yahoo.co.uk",
        "address1": "5 Raliat Alabi St, Iyana Meiran , Ijaye Ojokoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18983,
        "ProviderName": "Liberty Hospital, Suleja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033491820",
        "provideremail1": "femiadedoye@gmail.com",
        "address1": "Behind Ecobank, Minna Road, Suleja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18984,
        "ProviderName": "Liberty Specialist Hospital, Ikeja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08089337825",
        "provideremail1": "NULL",
        "address1": "12, Ajanaku Street, Awuse Estate, Opebi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18986,
        "ProviderName": "Life Care Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033166678",
        "provideremail1": "NULL",
        "address1": "Plot 120A, Old G.R.A., Aba-Owerri Road (Opp. Abia State Polytechnic), Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19759,
        "ProviderName": "Life Fountain Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023383495",
        "provideremail1": "lifefountainmedicalclinic@gmail.com",
        "address1": "41 Oyedele Ogunniyi Street, Anthony Village, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18988,
        "ProviderName": "Life Spring Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037124056",
        "provideremail1": "lifespringrh@gmail.com",
        "address1": "Plot 49, Okigwe rd layout (opp FRSC), Ikenegbu, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18989,
        "ProviderName": "Lifegate Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033166678",
        "provideremail1": "lifegatespecialisthospital@gmail.com",
        "address1": "2B Abiodun Jagun Street, Ogba",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18991,
        "ProviderName": "Lifeline Hospital, Surulere",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08022907306",
        "provideremail1": "lchnigerr@gmail.com",
        "address1": "133, Ogunlana Drive, Surulere, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19663,
        "ProviderName": "Lifeshade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036246748",
        "provideremail1": "lifeshadehospital@gmail.com",
        "address1": "3, Oshonogbehin Street, Off Awolowa Way, Ota-Ona, Ikorodu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18993,
        "ProviderName": "Light Hospital & Maternity Home",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08150731596",
        "provideremail1": "NULL",
        "address1": "15, Olumide Onanubi Street, Akowonjo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18994,
        "ProviderName": "Lily Hospital - Warri",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07053351700",
        "provideremail1": "omotayo.oshinowo@lilyhospitals.com",
        "address1": "6, Brisibe St., Off Etuwewe Rd., Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18995,
        "ProviderName": "Limi Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08023616686",
        "provideremail1": "limiclaims@gmail.com",
        "address1": "1487, Off Constitution Avenue Behind ICPC/NDIC, Central Area, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18996,
        "ProviderName": "Livia Shammah  - ERROR",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034520804",
        "provideremail1": "liviashammahfrontdesk@gmail.com",
        "address1": "Plot 5, Shammah Close, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19445,
        "ProviderName": "Livia Shammah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034520804",
        "provideremail1": "liviashammahhospitalsltd@gmail.com",
        "address1": "Plot 5, Shammah Close, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19660,
        "ProviderName": "Living Heart Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07062108881",
        "provideremail1": "livingheartspecialist@gmail.com",
        "address1": "7A Udeco Medical Road, Chevy View Estate, Chevron Drive, Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18997,
        "ProviderName": "Living Stream Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065702068",
        "provideremail1": "etalworldjoy@gmail.com",
        "address1": "Plot 42, Phase 1, Lay-out 2, opp Omoye Hostel, Gwagwalada",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18998,
        "ProviderName": "Living Word Mission Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033418349",
        "provideremail1": "livingwordmissionhospitalltd@yahoo.com",
        "address1": "5/7, Umuocham Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18999,
        "ProviderName": "Longing Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08081366069",
        "provideremail1": "longingmedicals@yahoo.co.uk",
        "address1": "1, Josepha Close, Ijaiye Ojokoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19001,
        "ProviderName": "Lota Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08174598513",
        "provideremail1": "lotamedical_2004@yahoo.com",
        "address1": "Salvation St., Powerline B/S, Isheri Olofin.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19002,
        "ProviderName": "LouisMed Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130101759",
        "provideremail1": "louismedhospital@yahoo.com",
        "address1": "Plot 8, Iron Bar Street, Lekki Phase I.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19863,
        "ProviderName": "Luscent hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023823454",
        "provideremail1": "luscenthospital33@gmail.com",
        "address1": "Plot 9 Arimi Idogun street Imaletafia Ibeju Lekki Lagos Nigeria.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19003,
        "ProviderName": "Makog Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037270210",
        "provideremail1": "makogmdls@gmail.com",
        "address1": "Makog House, along LFTZ Road, Magbon-Alade, Ibeju-Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19004,
        "ProviderName": "Maneks Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033496455",
        "provideremail1": "NULL",
        "address1": "A1, Block 5, Makera Road, Kakuri, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19005,
        "ProviderName": "Mararaba Gurku Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09055886398",
        "provideremail1": "NULL",
        "address1": "Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19973,
        "ProviderName": "Marina Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08086669491",
        "provideremail1": "marinahospital1@gmail.com",
        "address1": "Chief Odum  Close Off No 1, Ogbua Street Egbum -Na bali ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19007,
        "ProviderName": "Mary the Queen Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023117872",
        "provideremail1": "NULL",
        "address1": "29, Babalola Street, Akowonjo Shasha.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19727,
        "ProviderName": "Mascap Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167634611",
        "provideremail1": "mascaphospital@gmail.com",
        "address1": "34 Adeola Road, Sapele.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19008,
        "ProviderName": "Maternal Child Specialist Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033886173",
        "provideremail1": "motherchildhosp@yahoo.com",
        "address1": "Behinde immigration Office, Off Polythenic - ABUAD Road",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19012,
        "ProviderName": "May Clinics, Ilasamaja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096096577",
        "provideremail1": "hmo@mayclinicsltd.com",
        "address1": "24/26, Sadiku Street, Off Apapa – Isolo Expressway, Ilasamaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19013,
        "ProviderName": "May Clinics, Ilupeju",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096096577",
        "provideremail1": "hmo@mayclinicsltd.com",
        "address1": "20, Sura Mogaji Street, Ilupeju, Mushin, Lagos, Nigeria.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19014,
        "ProviderName": "Mayday Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037051773",
        "provideremail1": "NULL",
        "address1": "1, Pecados Street, opp Skye Bank, Mararaba",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19700,
        "ProviderName": "Mayflower Hospital & Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030500933",
        "provideremail1": "mayflowerhosp8@gmail.com",
        "address1": "14, Bankole Street, Isheri, Magodo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19015,
        "ProviderName": "Me-Cure Healthcare Ltd, Oshodi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000632873",
        "provideremail1": "info@mecure.com.ng",
        "address1": "Me-Cure House, Apapa-Oshodi Expressway, Oshodi, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19833,
        "ProviderName": "Me-Cure Healthcare, Lekki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000632873",
        "provideremail1": "info@mecure.com.ng",
        "address1": "Niyi Okunubi St, Lekki Phase 1, Lekki\r\n",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19788,
        "ProviderName": "Mec Health Family Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036660448",
        "provideremail1": "mechealthfamilyhospital@gmail.com",
        "address1": "90 A, Otta Road Meiran Area, Ekoro",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19017,
        "ProviderName": "Med-In Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023500846",
        "provideremail1": "medinhmo@gmail.com",
        "address1": "1, Osogbo Street, Off Celestial Way, Ogudu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19765,
        "ProviderName": "Medcare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09030983735",
        "provideremail1": "medcarehospitals@gmail.com",
        "address1": "182, Pipeline Road, Adjacent CBN - Quarters, Gaa Akanbi Ilorin ",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19778,
        "ProviderName": "Medford Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09085856868",
        "provideremail1": "medfordhospitals.com",
        "address1": "Plot A144 Katampe Road Mpape",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19016,
        "ProviderName": "Medimax Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034959021",
        "provideremail1": "Bernice.medimax@gmail.com",
        "address1": "Plot 555, Cadastral Zone B02, Durumi District, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19840,
        "ProviderName": "Mediplus Premier Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130001789",
        "provideremail1": "medipluspremierhospital.com",
        "address1": "8, Ogbodu Avenue, Galovita, Odonla, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19018,
        "ProviderName": "Medol Consultant Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033013160",
        "provideremail1": "medolclinic01@yahoo.com",
        "address1": "54 Adaranijo Street, opposite Pedro Pry. School, Shomolu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19019,
        "ProviderName": "Medytop Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037159057",
        "provideremail1": "hmo@medytopspecialisthospital.com",
        "address1": "3, Ejimo Abebiosu St., (Off Isale Ojumele Junction).",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19021,
        "ProviderName": "Mends Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033112061",
        "provideremail1": "NULL",
        "address1": "5, Abba Kyari, Close, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19023,
        "ProviderName": "Mercy Group Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033255825",
        "provideremail1": "mercygroupclinics@yahoo.com",
        "address1": "Panseke, Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19447,
        "ProviderName": "Mercy Specialist Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07069477890",
        "provideremail1": "mshmonitsha@yahoo.com",
        "address1": "4A, New American Road, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19468,
        "ProviderName": "Mercy Thomas Oredugba Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08123933936",
        "provideremail1": "mtomedical@gmail.com",
        "address1": "8, Mogati Street, Ijeshatedo, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19832,
        "ProviderName": "Mercyland Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08137748362",
        "provideremail1": "mercylandspecialisthospital200@gmail.com",
        "address1": "Opposite Conoil Petro Station , Near  Nkpolu Junction East / West Road Portharcourt  ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19661,
        "ProviderName": "Mev Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080008048",
        "provideremail1": "mevspecialisthospital@gmail.com",
        "address1": "6 Djibouti Street, Wuse 2, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19886,
        "ProviderName": "Michael Cross Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026378305",
        "provideremail1": "michcross_2005@yahoo.co.uk",
        "address1": "Plot f9 Church Road Kapa sITE",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19033,
        "ProviderName": "Midland Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065399227",
        "provideremail1": "NULL",
        "address1": "144, Karu Site, Karu",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19884,
        "ProviderName": "Minimally Invasive Surgical Solutions",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08177788223",
        "provideremail1": "info@miss.com.ng",
        "address1": "33 f, Oba Akinjobi Way , G. R. A Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19034,
        "ProviderName": "Miracle Seed Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08185631838",
        "provideremail1": "miracleseedclinicmaternity@yahoo.com",
        "address1": "40B, Sultan Dasuki Road, Phase 2 Site 2, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19035,
        "ProviderName": "Miyetti Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036763868",
        "provideremail1": "NULL",
        "address1": "Federal Low Cost, Gombe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19036,
        "ProviderName": "Mobolaji Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023504660",
        "provideremail1": "NULL",
        "address1": "43B, Obafemi Awolowo Way. Oke-Bola.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19037,
        "ProviderName": "Molly Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035633329",
        "provideremail1": "mollyspecialisthospital@yahoo.com",
        "address1": "6, American Quarters, Agodi Yidi, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19038,
        "ProviderName": "Momaak Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037075956",
        "provideremail1": "NULL",
        "address1": "3, Lafe Inn Way, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19040,
        "ProviderName": "Morning Star Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033130778",
        "provideremail1": "morningstarhospital@yahoo.com",
        "address1": "19/21, Isiokpo Street, D-Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19041,
        "ProviderName": "Motayo Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08090235470",
        "provideremail1": "motayo_hospital@yahoo.com",
        "address1": "3 Owodunni Street, Off Amore Street, Off Toyin Street, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19042,
        "ProviderName": "Mother & Child Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033053923",
        "provideremail1": "NULL",
        "address1": "39A, Adeniyi Jones Avenue, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19043,
        "ProviderName": "Mother of Christ Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08106789768",
        "provideremail1": "motherchristnhis@gmail.com",
        "address1": "Holy Ghost Catholic Compound, Ogui.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19825,
        "ProviderName": "Mother Teresa Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167819751",
        "provideremail1": "motherteresamedicalcentre@gmail.com",
        "address1": "107,Las oRoad, Idimu -Egbe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19046,
        "ProviderName": "Mount Gilead Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056700067",
        "provideremail1": "mountgileadhospital@gmail.com",
        "address1": "1, Gilead close, off Uselu-Lagos Road (behind Zenith bank), Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19047,
        "ProviderName": "Mount Pisgah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08101859335",
        "provideremail1": "NULL",
        "address1": "1, Samuel Sode St., Off Isolo Rd, Egbe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19048,
        "ProviderName": "Mount Sinai Hospital, Egbe",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "mmountsinaigroup@gmail.com",
        "address1": "133, Isolo Road, Egbe, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19049,
        "ProviderName": "Mount Sinai Hospital, Mushin",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "NULL",
        "address1": "32, Olanibi Street, Papa Ajao, Mushin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19441,
        "ProviderName": "Mount Sinai Hospital, Surulere",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036522205",
        "provideremail1": "mmountsinaigroup@gmail.com",
        "address1": "30, Falolu Street, Surulere ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19051,
        "ProviderName": "Mount Zion Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033276161",
        "provideremail1": "NULL",
        "address1": "9th Mile Corner, Ngwo, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19053,
        "ProviderName": "Mucas Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08052345000",
        "provideremail1": "NULL",
        "address1": "19, Ogun Street, Adeale Bus Stop, Dopemu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19054,
        "ProviderName": "Nagari Allah Magami Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035902024",
        "provideremail1": "NULL",
        "address1": "10, Abubakar Burga Rd, adjacent High Court 2, Keffi.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19055,
        "ProviderName": "Nakowa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07034948118",
        "provideremail1": "NULL",
        "address1": "16, Lagos St., Old GRA (Government House), Maiduguri",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19056,
        "ProviderName": "Narrow Way Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036760899",
        "provideremail1": "NULL",
        "address1": "9, Sunday Ahanor Street, Aduwawa, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19057,
        "ProviderName": "Nasara Clinic & Maternity, Kontagora",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069080381",
        "provideremail1": "nasaraclinic@yahoo.com",
        "address1": "Maigari Godabe Road, GRA, Kontagora",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19631,
        "ProviderName": "Naval Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09054396520",
        "provideremail1": "nmc_nndmedical@yahoo.com",
        "address1": "Naval Dockyard, Wilmot Point, Ahmadu Bello Way, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19058,
        "ProviderName": "New Crescent Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034456743",
        "provideremail1": "NULL",
        "address1": "23, New Zaria Terrace, Zaria By-Pass, Jos.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19059,
        "ProviderName": "New Era Hospital & Maternity, Aba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037022637",
        "provideremail1": "NULL",
        "address1": "213/215, Nnamdi Azikiwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19060,
        "ProviderName": "New Era Hospital & Maternity, Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030525046",
        "provideremail1": "NULL",
        "address1": "13, Funtua Street, Kafancha, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19707,
        "ProviderName": "New Evolution Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09081908114",
        "provideremail1": "newevolutionhospitalhmo@gmail.com",
        "address1": "69 Ago Palace Way, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19810,
        "ProviderName": "New Mile One Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132184968",
        "provideremail1": "newmileonehospital@gmail.com",
        "address1": "2, Manila Pepple Street D/Line Porthacourt ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19063,
        "ProviderName": "Niger Optical Services, Aba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "18, Asa Road, Aba, Abia State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19064,
        "ProviderName": "Niger Optical Services, Abakaliki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "1, Water Works Road, Abakaliki",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19065,
        "ProviderName": "Niger Optical Services, Abuja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08064709457",
        "provideremail1": "NULL",
        "address1": "1st Floor, Standard Plaza, Plot 2302 Herbert Macaulay Way, Wuse Zone 6",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19066,
        "ProviderName": "Niger Optical Services, Afikpo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "10, Amachara Ukpa Road, Afikpo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19067,
        "ProviderName": "Niger Optical Services, Ahoada",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "40, Omoku Street, Ahoada, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19068,
        "ProviderName": "Niger Optical Services, Akokwa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "2, Okigwe Road, Akokwa, Imo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19069,
        "ProviderName": "Niger Optical Services, Asaba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "155, Nnebuisi Road, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19070,
        "ProviderName": "Niger Optical Services, Awka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "43, Ziks Avenue, Awka.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19071,
        "ProviderName": "Niger Optical Services, Ayingba",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "105, Idah Road, Ayingba, Kogi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19072,
        "ProviderName": "Niger Optical Services, Benin City",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "42, Mission Road, Benin City.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19073,
        "ProviderName": "Niger Optical Services, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "61, Nike Road, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19074,
        "ProviderName": "Niger Optical Services, Enugu II",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "39, Zik Avenue, Uwani, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19075,
        "ProviderName": "Niger Optical Services, Igbo-Ukwu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Igbo-Ukwu/Ekwulobia Road.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19076,
        "ProviderName": "Niger Optical Services, Ihiala",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Onitsha Owerri Road, Ihiala Park.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19077,
        "ProviderName": "Niger Optical Services, Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "10, Hill Street., Opp. St. Pauls Township Sch., Jos",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19078,
        "ProviderName": "Niger Optical Services, Lafia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Shadan Junction, Jos Road, Lafia",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19079,
        "ProviderName": "Niger Optical Services, Lokoja",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "4A, Old Okene Road, Lokoja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19080,
        "ProviderName": "Niger Optical Services, Makurdi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "20, J. S. Tarka Rd, By Benue Crescent, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19081,
        "ProviderName": "Niger Optical Services, Mbaise",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Ahiara Junction, Mbaise, Imo",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19082,
        "ProviderName": "Niger Optical Services, Nnewi",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "8, Owerri Rd, Nnewi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19083,
        "ProviderName": "Niger Optical Services, Nsukka",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "107, Enugu Road, Nsukka, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19084,
        "ProviderName": "Niger Optical Services, Ohafia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "126, Arochukwu Road, Ohafia, Abia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19085,
        "ProviderName": "Niger Optical Services, Omoku",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Omoku Ahoada Road, Omoku, Rivers.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19086,
        "ProviderName": "Niger Optical Services, Onitsha",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "160, Oguta Road, Onitsha",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19087,
        "ProviderName": "Niger Optical Services, Onueke",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "New Ikwo Rd Junction/Afikpo Road, Onueke",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19088,
        "ProviderName": "Niger Optical Services, Orlu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "3, Ihioma Road, Orlu, Imo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19089,
        "ProviderName": "Niger Optical Services, Oturkpo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "84, Nnamdi Azikiwe Street, Oturkpo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19090,
        "ProviderName": "Niger Optical Services, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "72, Douglas Road, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19091,
        "ProviderName": "Niger Optical Services, Port Harcourt I",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "18, Emenike Street, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19092,
        "ProviderName": "Niger Optical Services, Port Harcourt II",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Rumuibekwe Opp. Shell Quarters, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19093,
        "ProviderName": "Niger Optical Services, Umuahia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "12, Bende Road, Umuahia, Abia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19094,
        "ProviderName": "Niger Optical Services, Umunze",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "Nkwo Umunze Road About, Umunze",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19095,
        "ProviderName": "Niger Optical Services, Uyo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056000174",
        "provideremail1": "NULL",
        "address1": "21, Ikot Ekpene Road, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19772,
        "ProviderName": "Nile Valley Mother & Child hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08130064451",
        "provideremail1": "info@nilevalleyhospital.org.ng",
        "address1": "Plot 602, David Jemibewon Crescent, Gudu, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19096,
        "ProviderName": "Nimbus Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038099402",
        "provideremail1": "NULL",
        "address1": "6, Kola Iyaomolere St., Ogudu, Ori-Oke.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19098,
        "ProviderName": "Nissi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035881755",
        "provideremail1": "ijajaonuche@gmail.com",
        "address1": "Beside Sambridge Kiddies Academy, Area 1, Masaka",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19882,
        "ProviderName": "Nnaemeka Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037048354",
        "provideremail1": "hmcfamilydoc@yahoo.com",
        "address1": "No 53,  Hundred Foot Road Nnewi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18729,
        "ProviderName": "Nnihi Medicals",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "nnihimedicals@gmail.com",
        "address1": "3, Ibe Street, by SARS Junction, Royal Estate Road, Rumuomasi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19099,
        "ProviderName": "Nobis Optometry Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08084890923",
        "provideremail1": "NULL",
        "address1": "56, NEPA Line, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19769,
        "ProviderName": "Noble Medical Consultants & Fertility Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07057723381",
        "provideremail1": "noblefertilityhospital.com",
        "address1": "2 Love Street Off Odaranwere Road, Adjacent Larritel NTA Road Mbiloba, Port Harcourt, Rivers State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19102,
        "ProviderName": "Nsukka Medical Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036899747",
        "provideremail1": "NULL",
        "address1": "7, Nru Street, Ugwu-Nkwo , Nsukka, Enugu State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19103,
        "ProviderName": "Ntasiobi Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08131835522",
        "provideremail1": "ntasiobihosp@yahoo.com",
        "address1": "1, Enugu-Abor Trans Ekulu, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19116,
        "ProviderName": "O Hosp - Error",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056708838",
        "provideremail1": "NULL",
        "address1": "28, Dele Abubakar Rd., (Stadium Rd.)",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19104,
        "ProviderName": "Oak Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08034747112",
        "provideremail1": "oakhospitals101@yahoo.com",
        "address1": "191, Lagos Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19105,
        "ProviderName": "Oba Adenle Memorial Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132208268",
        "provideremail1": "NULL",
        "address1": "7, Oke Ayeso Street, Ilesa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19106,
        "ProviderName": "Obioma Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08025695957",
        "provideremail1": "sir.grant7@gmail.com",
        "address1": "21, School Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19107,
        "ProviderName": "Obule Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033205789",
        "provideremail1": "NULL",
        "address1": "115, Okpe Road, Sapele",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19475,
        "ProviderName": "Occupatinal Health Prime",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07059294014",
        "provideremail1": "ohp@fortics.org",
        "address1": "13A, Johnvic Igboanugo Street, Lekki Phase I",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19111,
        "ProviderName": "Ogunlade Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056561155",
        "provideremail1": "NULL",
        "address1": "35, Olukoku Street, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19112,
        "ProviderName": "Oke Ado Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08132331592",
        "provideremail1": "NULL",
        "address1": "SW7/217, Ibukun Olu St, Oke Ado, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19119,
        "ProviderName": "Ola-Oki Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033231276",
        "provideremail1": "NULL",
        "address1": "Ola-Oki Medical Complex, Along Lagos Badagry Expressway, Ibereko, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19114,
        "ProviderName": "Oladapo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026543304",
        "provideremail1": "oladapomedcentre@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19776,
        "ProviderName": "Olan Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08106510401",
        "provideremail1": "info@olanhealthcare.com",
        "address1": "119 Adeniji Jones Avenue, Ikeja",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19117,
        "ProviderName": "Olaniba Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033241852",
        "provideremail1": "olanibahealth@gmail.com",
        "address1": "10A, Olatunde Onimole Street, Aguda, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19123,
        "ProviderName": "Olumorin Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036778452",
        "provideremail1": "olumorinladele@gmail.com",
        "address1": "12, Little By Little Street, Ado-Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19124,
        "ProviderName": "Omega Physiotherapy & Back Pain Care Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033299222",
        "provideremail1": "NULL",
        "address1": "14, Ndele Street, D-Line, Port Harcourt, Rivers State",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19125,
        "ProviderName": "Omeiza Ali Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035864756",
        "provideremail1": "aliomeizahospital2017@gmail.com",
        "address1": "Behind Mobil Petrol Station, Off Old Kabba/Okene Road, Lokoja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19126,
        "ProviderName": "Omni Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030569108",
        "provideremail1": "omnimedicalcentre@yahoo.com",
        "address1": "18, Boyle Street, Onikan",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19128,
        "ProviderName": "Omo Abiyo Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08093317222",
        "provideremail1": "solojibolium@yahoo.com",
        "address1": "3, Amos Street, Agosi Area, Ifo Road.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19132,
        "ProviderName": "Onward Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060632500",
        "provideremail1": "onwardsphosp@yahoo.com",
        "address1": "Agunbelewo, Ilobu Road, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19133,
        "ProviderName": "Ore-Ofe Oluwa Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033190964",
        "provideremail1": "NULL",
        "address1": "88A, Ejirin Road, Ijebu Ode.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19134,
        "ProviderName": "Orire Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035062512",
        "provideremail1": "NULL",
        "address1": "15, Orire lane, Adebayo, Ado-Ekiti",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19135,
        "ProviderName": "Oroki Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022338544",
        "provideremail1": "NULL",
        "address1": "27 Cemetry Road, Igboma, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19138,
        "ProviderName": "Osula Royal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023371448",
        "provideremail1": "NULL",
        "address1": "5, Idahosa Street, off Mission Road, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19139,
        "ProviderName": "Osuntuyi Medical Centre, Iju-Ishaga",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08074488383",
        "provideremail1": "osunsmed@yahoo.com",
        "address1": "255, Iju Rd., Balogun Bus Stop, Iju-Ishaga, Agege.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19407,
        "ProviderName": "Osuntuyi Medical Centre, Obanikoro",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08074488383",
        "provideremail1": "osunsmedo@yahoo.com",
        "address1": "9, Alhaji Salisu Street, Obanikoro, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19142,
        "ProviderName": "Our Lady of Apostle Hospital, Jos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07096265658",
        "provideremail1": "olahospjos1@yahoo.com",
        "address1": "1, Zaria Bye Pass, Jos.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19144,
        "ProviderName": "Our Lady of Fatima Catholic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08071551542",
        "provideremail1": "ourladyoffatimanhis@gmail.com",
        "address1": "Odi-olowo, Osogbo",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19145,
        "ProviderName": "Our Lady of Lourdes Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030884436",
        "provideremail1": "NULL",
        "address1": "P. M. B. 40, Ihiala, Anambra State.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19147,
        "ProviderName": "Owokoniran Memorial Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033490452",
        "provideremail1": "mobolaji.idowu@owokoniranhospital.com",
        "address1": "3, Owokoniran St., Station Makun, Sagamu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19990,
        "ProviderName": "Owolabi specialist Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080064796",
        "provideremail1": " owolabispecialisthospital@gmail.com",
        "address1": "10 Liasu atilola street, Peaceland Estate, Ifesowapo, Akute Ogun state ",
        "region": null,
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19148,
        "ProviderName": "P & G Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033962013",
        "provideremail1": "NULL",
        "address1": "29, Sanni Labode Street, New Oko Oba, Abule Egba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19149,
        "ProviderName": "Paafag Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033335228",
        "provideremail1": "paafagdoctor@yahoo.com",
        "address1": "1J Road, off 1st Avenue (after Total Petrol Station), FHA Estate, Lugbe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19448,
        "ProviderName": "Pamo Clinics and Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033030968",
        "provideremail1": "pamoclinics@gmail.com",
        "address1": "300, PH/ABA Expressway, Rumuomasi",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19152,
        "ProviderName": "Pan OJ Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066909763",
        "provideremail1": "NULL",
        "address1": "68, Mission Road, Bonny Island ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19654,
        "ProviderName": "Panox Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033235616",
        "provideremail1": "panoxhospitalhmo@gmail.com",
        "address1": "4 Raji Rasaki Estate Road, Amuwo Odofin",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19153,
        "ProviderName": "Parklande Specialist Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087806941",
        "provideremail1": "parklandesh@yahoo.com",
        "address1": "13, Rasaq Balogun Crescent, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19443,
        "ProviderName": "Parkview Family Medicine Royal Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037200667",
        "provideremail1": "parkviewfmrh@gmail.com",
        "address1": "Domkat Bali way, by Solomon Lar Amusement Park, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19827,
        "ProviderName": "Patnas Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08163918962",
        "provideremail1": "patnashospital@gmail.com",
        "address1": "1b, Oluwoekere Street , Bashorun , Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19891,
        "ProviderName": "Patricare Hospital Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07017001600",
        "provideremail1": "patricarehospital@gmail.com",
        "address1": "48, gbuoshimini Road ,Agip Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19154,
        "ProviderName": "Patrick Otiji Specialist Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08063463116",
        "provideremail1": "poshspecialisthospital@gmail.com",
        "address1": "100 Chime Avenue, New Haven, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19454,
        "ProviderName": "Paulate Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032653263",
        "provideremail1": "paulatehospital@yahoo.com",
        "address1": "23 Peak Thomas Bright Crescent, AIT Bus Stop, Alagbado",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19422,
        "ProviderName": "Pauline Canadian Hospital & Maternity Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09068856561",
        "provideremail1": "canadianhospital@yahoo.com",
        "address1": "Idi Ope B/Stop, Benjamin opp. Electronics Market, Eleyele, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19156,
        "ProviderName": "Peace Hospital, Yola",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08066851152",
        "provideremail1": "peacehospital2005@yahoo.com",
        "address1": "2, Luggere Street, Jimeta, Yola",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19157,
        "ProviderName": "Peace Medical Centre, Calabar",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036669406",
        "provideremail1": "NULL",
        "address1": "17, Efut Street, Nsidom, Calabar.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19665,
        "ProviderName": "Peacefeul Health Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07037986859",
        "provideremail1": "peacefulhealthhospital@yahoo.com",
        "address1": "60 Ijagemo Road, Ijegun, Ikotun",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19158,
        "ProviderName": "Peachcare Medical Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08037709110",
        "provideremail1": "info.hmopeachcare@gmail.com",
        "address1": "43A, Ogudu Road, GRA, Ogudu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19818,
        "ProviderName": "Perfect Trinity Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060684507",
        "provideremail1": "hmo.perfecttrinityhospital@gmail.com",
        "address1": "13, Taiwo Street , Ilasamaja, Isolo, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19160,
        "ProviderName": "Petros Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023296237",
        "provideremail1": "petroshospitals@yahoo.com",
        "address1": "20, Seinde Callisto Street, Oshodi Expressway, Oshodi.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19779,
        "ProviderName": "Phoenix Medical Consults",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037114121",
        "provideremail1": "phoenixmedicalconsults@gmail.com",
        "address1": "3 Igboukwu close off 1st Artillery Bus- Stop Aba Rd Portharcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19161,
        "ProviderName": "Pinecrest Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023100602",
        "provideremail1": "info.pinecresthospital@gmail.com",
        "address1": "83, Makinde Street, Mafoluku - Oshodi",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19838,
        "ProviderName": "Pison Hospital & Maternity Kubwa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08140823720",
        "provideremail1": "pisonhospital@yahoo.com",
        "address1": "5335 Gado Nasko Road Phase II Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19685,
        "ProviderName": "Police Training School Clinic ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060084382",
        "provideremail1": "ptsclinicilorin@gmail.com",
        "address1": "Muritala Mohammed Way, Beside A Division Police Station, Taiwo, Ilorin",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19802,
        "ProviderName": "Premiercare and Neuro Rehabilitation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033389693",
        "provideremail1": "premiercarerehab@gmail.com",
        "address1": "8, Life plus Avenue, Eligbolo , New Layout , East -West Road Porthacourt ",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19885,
        "ProviderName": "Prestige Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167563769",
        "provideremail1": "prestige.specialisthospital@gmail.com",
        "address1": "No 13, Umuechem Street ,D -Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19168,
        "ProviderName": "Primecare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034096631",
        "provideremail1": "juliana.primecare1@gmail.com",
        "address1": "75, 321 Road, off 3rd Avenue, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19455,
        "ProviderName": "Primefort Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08170031043",
        "provideremail1": "hmo@primeforthospital.com",
        "address1": "35C Ajiran Road, Agungi, Lekki-Penisula, opposite Oba Amusa, Estate, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19169,
        "ProviderName": "Primex Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033201180",
        "provideremail1": "primexhospital2000@yahoo.com",
        "address1": "58, Igando Road, Unity Bus Stop, Ikotun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19170,
        "ProviderName": "Primrose Specialist Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08035696980",
        "provideremail1": "primrosehospital@outlook.com",
        "address1": "308, Lanre Awolokun Street, Gbagada Phase II",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19171,
        "ProviderName": "Primus Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08102117007",
        "provideremail1": "drtundealiu@yahoo.com",
        "address1": "272, Ijesha Road, Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19372,
        "ProviderName": "Prince & Princess Hospital - Ojo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09032771987",
        "provideremail1": "pphospital@yahoo.com",
        "address1": "191 Ojo Road, Ajegunle",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19705,
        "ProviderName": "Prince and Princess Hospital - Egbeda ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08093932162",
        "provideremail1": "pphospital@yahoo.com",
        "address1": "Plot 75, 1St Avenue 12 Road, Gowon Estate, Egbeda, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19172,
        "ProviderName": "Princess Medical Centre, Port Harcourt",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08146489530",
        "provideremail1": "pmc4health1@gmail.com",
        "address1": "8, National Supply Road, Trans-Amadi, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19173,
        "ProviderName": "Priscillia Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035742341",
        "provideremail1": "NULL",
        "address1": "32, Bonny Street, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19174,
        "ProviderName": "Promise Hospital, Agege",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033281",
        "provideremail1": "promisemedicalcentre@gmail.com",
        "address1": "132, Dopemu Road, Agege.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19175,
        "ProviderName": "Promise Medical Centre, Ota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033033281",
        "provideremail1": "NULL",
        "address1": "19, Plaza Road, Sango-Ota, Ogun State.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19834,
        "ProviderName": "Queen Of Peace Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08133013525",
        "provideremail1": "hospitalqueenofpeace@gmail.com",
        "address1": "No 2, NIHI Street ,Sunlight Estate , Umuebule",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19740,
        "ProviderName": "Queen Stella Specialist Hospital.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069760376",
        "provideremail1": "info@queenstellaspecialisthospital.com",
        "address1": "20 Waidi Adeyemi Crescent Araromi, Igando",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19397,
        "ProviderName": "Queens Specialist Hospital, Abuja",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08112828723",
        "provideremail1": "queensspecialithospital@gmail.com",
        "address1": "Plot 224, Martin Ejembi Crescent, Apo, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19176,
        "ProviderName": "Queens Specialist Hospital, Magodo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030106053",
        "provideremail1": "queensspecialisthospital@yahoo.co.uk",
        "address1": "3B, CMD Road, Magodo GRA, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19189,
        "ProviderName": "R-Jolad Hospital Gbagada",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "Rjoladhosp",
        "provideremail1": "info@rjolad.com",
        "address1": "1, Akindele Street, Bariga",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19819,
        "ProviderName": "R-Jolad Hospital, Agege ([Salem House)",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07000756523",
        "provideremail1": "wecare@rjolad.com",
        "address1": "Plot 23/24 Omotoye Estate Road off Ipaja Road, Mulero Bus-Stop, Orile-Agege, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 18963,
        "ProviderName": "R-Jolad Kupa Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08188017155",
        "provideremail1": "ifeanyi.godwin@kupamedicals.com",
        "address1": "6, Olabode Street, Behind Sterling Bank, Off M/M Int.l Airport Rd., Ajao Estate, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19179,
        "ProviderName": "Rambod Optometry Practice",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033093808",
        "provideremail1": "rambodeye1@gmail.com",
        "address1": "5, Agudama Street, D-Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19697,
        "ProviderName": "Redeemers Health Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167457045",
        "provideremail1": "rccghealthcentre@ymail.com",
        "address1": "Unique Building Concepts and Integrated Services, KM 46 Lagos - Ibadan Expressway",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19797,
        "ProviderName": "Redemption Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030505982",
        "provideremail1": "redemptionhospital@gmail.com",
        "address1": "3, Redemtion Avenue , Off Major Thompson Road, Sabo Sagamu ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19593,
        "ProviderName": "Ree-Mee Medicare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08072224416",
        "provideremail1": "info@reemee-medicare.com",
        "address1": "6, Rimi Road, GRA, Bauchi ",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19817,
        "ProviderName": "Regis and Reina Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033086533",
        "provideremail1": "medhmo@regisandreina.com",
        "address1": "41, Industrial Avenue, Sabo  Yabo Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19184,
        "ProviderName": "Rhowil Total Care Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08071354494",
        "provideremail1": "rhowiltotalcare10@yahoo.com",
        "address1": "Plot 3, Block 7, Site G, Satellite Town, Amuwo Odofin, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19187,
        "ProviderName": "Ridcol Medical Services",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033425101",
        "provideremail1": "ridcol2006@yahoo.com",
        "address1": "26, Emekuku Street, D- Line, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19853,
        "ProviderName": "RMC Medical Services ",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09134436472",
        "provideremail1": "rmcmedicalservices1@gmail.com",
        "address1": "House 8, Palace Road, Oniru Private Estate, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19191,
        "ProviderName": "Robertson Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063098",
        "provideremail1": "robertson_medical_centre@yahoo.co.uk",
        "address1": "3, James Robertson Street, Surulere, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19972,
        "ProviderName": "Rock Foundation",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037144615",
        "provideremail1": "rockfoundationhospitalawka@gmail.com",
        "address1": "34, Onwurah street , awka,                               Anambra state. \r\n",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19549,
        "ProviderName": "Rolakim Hospital – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "11111111111",
        "provideremail1": "callcentre@metrohealthhmo.com",
        "address1": "KWARA",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19384,
        "ProviderName": "Rolayo Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08091964952",
        "provideremail1": "rolayomedics13@yahoo.com",
        "address1": "5, Sule Maito Street, Benson Bus stop, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19192,
        "ProviderName": "Rophe Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08053003030",
        "provideremail1": "rophehospitalagbara@yahoo.com",
        "address1": "23, Anambra Crescent, Agbara Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19193,
        "ProviderName": "Ropheka Medical & Dental Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037130396",
        "provideremail1": "NULL",
        "address1": "14A, Olalekan St., Opp. Bakery B/Stop, Akowonjo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19194,
        "ProviderName": "Rouz Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037870378",
        "provideremail1": "rouzhmltd@yahoo.com",
        "address1": "10, Mobolaji Johnson St., Apo Quarters, Zone D, 2nd Gate, Gudu District, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19195,
        "ProviderName": "Royal Hospital, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033126569",
        "provideremail1": "royalhospitalenugu1@gmail.com",
        "address1": "3, Aria Road, G. R. A., Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19196,
        "ProviderName": "Royal Infirmary, Ajah",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034918001",
        "provideremail1": "theroyalinfir@yahoo.com",
        "address1": "Badore Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19728,
        "ProviderName": "Royal Life Hospital & Maternity Centre - Shomolu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08028768552",
        "provideremail1": "royalifehospital@gmail.com",
        "address1": "2, George Street, Beside Ecobank, Apata, Somolu, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19197,
        "ProviderName": "Rubee Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08092927720",
        "provideremail1": "rubeemedicalcentre@gmail.com",
        "address1": "Km 38, Abeokuta Motor Road, Sango Otta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19198,
        "ProviderName": "Rushgreen Maternity Hospital & Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035752317",
        "provideremail1": "NULL",
        "address1": "64, Ugwunobamkpa Rd., Near Isokwe Police Post, Onitsha.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19199,
        "ProviderName": "Sabana Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035804117",
        "provideremail1": "NULL",
        "address1": "Federal Low Cost, Gombe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19651,
        "ProviderName": "Sacred Heart Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08165858128",
        "provideremail1": "sacredhearthospitalnhis@yahoo.com",
        "address1": "Oswald Madecki Road, Lantoro, Abeokuta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19201,
        "ProviderName": "Safeway Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033217577",
        "provideremail1": "safewayhospital@yahoo.com",
        "address1": "Km 46, Lekki-Epe Expressway, Sangotedo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19202,
        "ProviderName": "Sage Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08187240368",
        "provideremail1": "NULL",
        "address1": "29, Okere-Ugborikoko Road, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19203,
        "ProviderName": "Sahel Specialist hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035776501",
        "provideremail1": "sahelspecialisthospital@gmail.com",
        "address1": "65, Ahmadu Bello Way, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19205,
        "ProviderName": "Salama Infirmary Hospital & Maternity, Kaduna",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037013203",
        "provideremail1": "NULL",
        "address1": "Sokoto Road, Kwangla, Zaria, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19206,
        "ProviderName": "Salvation Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033450585",
        "provideremail1": "salvationhospitalmaternity@yahoo.com",
        "address1": "Along MCC Uratta Rd., Opp. Ekofe Filling Station, Beside Olive Hotel, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19207,
        "ProviderName": "Samaria Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08186007507",
        "provideremail1": "samariahospital@yahoo.com",
        "address1": "17, Debo Bashorun Street, Off Ali Dada Street, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19208,
        "ProviderName": "Samaritan Clinic/Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023347227",
        "provideremail1": "NULL",
        "address1": "7, Ekong Uko Street, Eket.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19209,
        "ProviderName": "Sandra Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09022812281",
        "provideremail1": "sandrahospital_mkd@yahoo.com",
        "address1": "24, J.S Tarka Road, Makurdi",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19210,
        "ProviderName": "Santa Antonio Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034020244",
        "provideremail1": "admin@samedicals.org",
        "address1": "Plot 48, Block T, OPIC Estate, Agbara Area 4, Agbara.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19211,
        "ProviderName": "Savannah Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035534312",
        "provideremail1": "NULL",
        "address1": "10, David Mark Road, Tunga, Minna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19215,
        "ProviderName": "Sefa Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033057195",
        "provideremail1": "sefaspecialisthospital@yahoo.com",
        "address1": "3, College Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19216,
        "ProviderName": "Seriki Abimbola Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08085411481",
        "provideremail1": "NULL",
        "address1": "1, Seriki Abimbola Street, Badagry.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19217,
        "ProviderName": "Shalom Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08139397292",
        "provideremail1": "NULL",
        "address1": "1, Shalom Medical Centre St. (Behind Olowolagba Microfinance Bank), Sabo, Ogbomosho.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19975,
        "ProviderName": "Sharyor Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09050907110",
        "provideremail1": "sharyormedicalcentre@gmail.com",
        "address1": "8, Olusegun Badejo Street Bada  Ayobola",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19218,
        "ProviderName": "Shawsand Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19219,
        "ProviderName": "Shepherd Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037169423",
        "provideremail1": "shepherdspecialisthospital@yahoo.com",
        "address1": "4th Avenue, E Close, Festac Town, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19220,
        "ProviderName": "Shield Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033122337",
        "provideremail1": "NULL",
        "address1": "Plot 4, Close D, Peace Valley Estate, Trans Woji Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19221,
        "ProviderName": "Shirish Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023415493",
        "provideremail1": "shirishclinic@gmail.com",
        "address1": "102, Joju Road, Sango Otta",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19223,
        "ProviderName": "Sijuwade Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033534474",
        "provideremail1": "sijuwadesha@yahoo.com",
        "address1": "10, Sijuwade BAA-Segun Road, Akure.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19964,
        "ProviderName": "Sinachi Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08060081204",
        "provideremail1": "sinachihospitalandmaternity@gmail.com",
        "address1": "11, Balogun Compound Obadore Ajah Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19929,
        "ProviderName": "Sleek Vision",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08143077400",
        "provideremail1": "sleekvisioncare@gmail.com",
        "address1": "10,Oweh Street Fadeyi Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19225,
        "ProviderName": "Smith Medical Centre",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07061690765",
        "provideremail1": "olatokunboh.balogun@smithmedicalcentre.com",
        "address1": "8, Afric Road, Olaleye Estate, Iponri.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19226,
        "ProviderName": "Sokoto Clinics Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036042840",
        "provideremail1": "NULL",
        "address1": "46, Abdullahi Fodio Road, Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19228,
        "ProviderName": "Solad Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08054083061",
        "provideremail1": "NULL",
        "address1": "16, Maria Road, Off Candos Road, Baruwa, Ipaja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19229,
        "ProviderName": "Solamek Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023072262",
        "provideremail1": "NULL",
        "address1": "8, Jonathan Street, off Lawrence Adedara Street, Temidire Estate, Lambe, Akute.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19230,
        "ProviderName": "Solat Women Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08032852515",
        "provideremail1": "NULL",
        "address1": "4A Kasim Ibrahim Street, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19231,
        "ProviderName": "Solid Rock Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023229973",
        "provideremail1": "solidrockh@yahoo.com",
        "address1": "6 Akinsanya Street, Off Ogunnusi (Isheri) Road, Ojodu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19232,
        "ProviderName": "Sparkview Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033024653",
        "provideremail1": "NULL",
        "address1": "20A, Wilmer Rd, Isheri-Oke, Ojodu Berger.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19233,
        "ProviderName": "SpringHill Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030712509",
        "provideremail1": "springhillhospital20@gmail.com",
        "address1": "19, Olorunsogo Estate, Ogunrun, Mowe",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19234,
        "ProviderName": "Springs Hospital, Port Harcourt",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08030891309",
        "provideremail1": "eddyspringshospital@yahoo.com",
        "address1": "458, Ikwere Rd., By Kala B/Stop, Trans-Amadi Layout, (Rain-Bow Town), PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19915,
        "ProviderName": "St Angelas Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07015367068",
        "provideremail1": "stangelashospital@gmail.com",
        "address1": "Igbojia Road ,Shapati Ibeju Lekki Lagos -State",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19943,
        "ProviderName": "St Benedict Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08084459565",
        "provideremail1": "saintbenedictmedicalcentre@gmail.com",
        "address1": "44, Frazaki Street bucknor Estate ",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19458,
        "ProviderName": "St. Anthony Hospital and Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033161431",
        "provideremail1": "dibieanthony@yahoo.com",
        "address1": "33, Igbolomu Road, Isawo, Agric Bus Stop, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19236,
        "ProviderName": "St. Athanasius Hospital Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037920924",
        "provideremail1": "stathanasiushospital@yahoo.com",
        "address1": "1, Ufeh Street, FHE, Abak Rd., Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19519,
        "ProviderName": "St. Catherine’s Hospital - Abuja",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087830739",
        "provideremail1": "stcatherinesabj@gmail.com",
        "address1": "6, Odienna Close, Libreville Street, Off Aminu Kano Crescent, Wuse 2",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19520,
        "ProviderName": "St. Catherine’s Hospital - Port Harcourt",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "09087830739",
        "provideremail1": "stcatherinesph@yahoo.com",
        "address1": "14, Trans-Woji Road, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19237,
        "ProviderName": "St. Charles Borromeo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08030574747",
        "provideremail1": "icepacel@gmail.com",
        "address1": "1, Limca Road, Onitsha, Anambra.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19240,
        "ProviderName": "St. Daniel’s Hospital, Oworonshoki",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023231111",
        "provideremail1": "sdhospital12@yahoo.com",
        "address1": "14, Oduduwa Road, Car Wash B/Stop, Oworonsoki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19242,
        "ProviderName": "St. David Hospital, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035448660",
        "provideremail1": "stdavidhospital@yahoo.com",
        "address1": "12, Mbari Street, Ikenegbu Layout, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19243,
        "ProviderName": "St. Edwards Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08020951924",
        "provideremail1": "info@stedwardhospital.com",
        "address1": "4, Dolapo Oshinaike Street, S.Fort Estate, Off Addo Road, Ajah.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19244,
        "ProviderName": "St. Elizabeth Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035029802",
        "provideremail1": "NULL",
        "address1": "10, Government House Road (off Orlu Rd), Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19245,
        "ProviderName": "St. Gerard Catholic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039493449",
        "provideremail1": "NULL",
        "address1": "Kachia Road, Kakuri, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19246,
        "ProviderName": "St. Gregory’s Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08068200014",
        "provideremail1": "NULL",
        "address1": "Iyin Road (Textile Rd.) Ado Ekiti.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19247,
        "ProviderName": "St. Joseph Hospital & Maternity, Owerri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "9, Anokwu Street, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19249,
        "ProviderName": "St. Jude’s Hospital, Irewole",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08034756833",
        "provideremail1": "NULL",
        "address1": "Ikire, Off Fatima College Rd., Laakosun, Iki Irewole",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19250,
        "ProviderName": "St. Kizito Clinic",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "08038547416",
        "provideremail1": "customercare@lovingaze.org",
        "address1": "Ilasan Housing Estate, Jakande, Lekki.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19251,
        "ProviderName": "St. Louis Medical Centre, Apapa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033125589",
        "provideremail1": "stlouismedics@yahoo.com",
        "address1": "3, Ado Avenue Apapa.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19253,
        "ProviderName": "St. Lukes Hospital Yaba",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08033229899",
        "provideremail1": "clients@stlukeshospitalltd.com",
        "address1": "17A, Commercial Avenue, Sabo – Yaba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19254,
        "ProviderName": "St. Martins Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063661668",
        "provideremail1": "stmartinshospital@yahoo.com",
        "address1": "21, Stadium Road, Port Harcourt.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19255,
        "ProviderName": "St. Mary Mother of Mercy Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033103282",
        "provideremail1": "NULL",
        "address1": "68, Ogbatai Road, Woji, PH.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19256,
        "ProviderName": "St. Mary’s Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08061666862",
        "provideremail1": "stmarysspecialisthospital2@yahoo.com",
        "address1": "458, Titilayo Adedoyin Street, Omole Phase I, Ikeja.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19257,
        "ProviderName": "St. Nicholas Hospital, Ibeju-Lekki",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08172011578",
        "provideremail1": "lfzclinics@saintnicholashospital.com",
        "address1": "Ibeju Lekki, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19261,
        "ProviderName": "St. Patricks Hospital Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08167526735",
        "provideremail1": "stpatricks_01@yahoo.com",
        "address1": "3, Eastern By-Pass, Ogbunabali Rd., PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19235,
        "ProviderName": "St. Peters Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063302645",
        "provideremail1": "NULL",
        "address1": "27 St Peter’s Hospital Street, Amarata, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19262,
        "ProviderName": "St. Raphael Divine Mercy Specialist Hospital, Ikorodu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08155140646",
        "provideremail1": "straphaeldivinehmocare@yahoo.com",
        "address1": "Ijede Road, Ikorodu.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19263,
        "ProviderName": "St. Raphael Hospital, Okota",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08123772066",
        "provideremail1": "straphael.hospital@yahoo.com",
        "address1": "114, Lateef Adegboyega Street, Okota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19264,
        "ProviderName": "St. Rebecca Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035422884",
        "provideremail1": "strebeccashospital@yahoo.com",
        "address1": "40, Denis Osadebe Way, Asaba.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19265,
        "ProviderName": "St. Shiloh  Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08184678276",
        "provideremail1": "st.shiloh1@gmail.com",
        "address1": "2, Let Them Say St.,Igbala B/S, Sango Ota.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19266,
        "ProviderName": "St. Victoria Specialist Hospital, Ile-Ife",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033567451",
        "provideremail1": "eoorji11@yahoo.com",
        "address1": "Opposite NTA Mokuro Road, Ile-Ife.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19267,
        "ProviderName": "St. Vincent Hospital, Umuahia",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030901233",
        "provideremail1": "NULL",
        "address1": "9, Old Timber Road, Umuahia",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19268,
        "ProviderName": "Standard Life Care Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033287669",
        "provideremail1": "NULL",
        "address1": "6, Oreniwon Street, Off Agbe Road, Abule Egba.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19761,
        "ProviderName": "Standard Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08108751568",
        "provideremail1": "standardspecialisthospital@yahoo.com",
        "address1": "144 Lautai Road Tarauni, Kano",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19269,
        "ProviderName": "State Specialist Hospital, Maiduguri",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07031536929",
        "provideremail1": "NULL",
        "address1": "Along Shehu Laminu Way, Maiduguri",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19270,
        "ProviderName": "State Specialist Hospital, Okitipupa",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08090235470",
        "provideremail1": "sshokitipupa@hotmail.com",
        "address1": "Igbokoda Rd, Okitipupa.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19271,
        "ProviderName": "State Specialist Hospital, Yola",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036239191",
        "provideremail1": "NULL",
        "address1": "Hospital Rd., Inieta, Yola.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19836,
        "ProviderName": "Stedmed Specialist Medical Centre & Children's Hos",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056181901",
        "provideremail1": "stedmedmedicalcentre@gmail.com",
        "address1": "NC3, Karu Neighbourhood Way Karu Site",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19272,
        "ProviderName": "Sterling Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08185645145",
        "provideremail1": "sterlingspecialistclinic@yahoo.com",
        "address1": "5 King Jaja Street, Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19762,
        "ProviderName": "Stevans Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07045664356",
        "provideremail1": "stevansmedicalcentre@gmail.com",
        "address1": "17 Ori - okuta Road off Isawo Road Agric, Ikorodu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19273,
        "ProviderName": "Strong Tower Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056568949",
        "provideremail1": "strongtowerhospital@yahoo.com",
        "address1": "100, Agbado Road, Off Iju Ishaga.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19274,
        "ProviderName": "Subol Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033445559",
        "provideremail1": "info@subolhospital.com",
        "address1": "16/17, Oba Amusa Avenue, Idimu, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19275,
        "ProviderName": "Sunshine Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063426514",
        "provideremail1": "NULL",
        "address1": "2, Road, A, Off Ikot Ekpene Road, World Bank Housing Estate, Umuahia North.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19449,
        "ProviderName": "Sure Hope Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033890981",
        "provideremail1": "sure2017@yahoo.com",
        "address1": "5, Carpenter Bus Stop, Ologuneru Eruwa Road, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19276,
        "ProviderName": "Surgicare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033258190",
        "provideremail1": "NULL",
        "address1": "Ibara Housing Estate",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19277,
        "ProviderName": "Suzan Memorial Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065327408",
        "provideremail1": "suzan.mem.clinic@gmail.com",
        "address1": "Uphill Suleja, Near Suleja Club, G.R.A. Suleja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19278,
        "ProviderName": "Sybron Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037863416",
        "provideremail1": "NULL",
        "address1": "25, Mungo Park Close, Asokoro",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19472,
        "ProviderName": "SYD-Monic Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07038883752",
        "provideremail1": "syd_monichospital@yahoo.com",
        "address1": "6, Alhaji Moshobalaje Street, Ali Dada Bus Stop, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19871,
        "ProviderName": "Taraba State Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08138000116",
        "provideremail1": "nhisspecialisthospital@gmail.com",
        "address1": "No 1, Rev  Joly Nyame Way, Jalingo",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19895,
        "ProviderName": "Tehilah Children Hospital",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "08077333091",
        "provideremail1": "info@tehilahchildrenhospital.com",
        "address1": "10 a,Maxwell Adoki Street ,Off Peter Odili Road ,Port Harcourt",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19279,
        "ProviderName": "Teju Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08056046243",
        "provideremail1": "NULL",
        "address1": "6, Animashaun St., Ajeigbe B/S., Ring Rd.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19281,
        "ProviderName": "The Chasel Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08058196064",
        "provideremail1": "NULL",
        "address1": "15, Issa Kaita Road, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19285,
        "ProviderName": "The Great Physician (Gadol Rapha) Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033011650",
        "provideremail1": "gadolrapha@yahoo.com",
        "address1": "3, Niyi Adebule Street, Badagry, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19204,
        "ProviderName": "The Peake Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07036421369",
        "provideremail1": "thepeakespecialisthospital@gmail.com",
        "address1": "24, 64 Crescent, off Charley Boy Boulevard, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19924,
        "ProviderName": "The Pearls Specialist Medical Centre",
        "provcatid": 1002,
        "categoryname": "B",
        "providerphone1": "09154379132",
        "provideremail1": "info@thepearlsmedical.com",
        "address1": "9a, Olawale Onitiri Cole Avenue ,Off Dmiralty Road ,Lekki Phase 1",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19288,
        "ProviderName": "The Vine Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07040423841",
        "provideremail1": "NULL",
        "address1": "Plot 10, Block 24, Alaafin Avenue, Oluyole Extension, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19290,
        "ProviderName": "Tim Unity Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07030990471",
        "provideremail1": "NULL",
        "address1": "BZ 5, Sardauna Crescent, Kaduna.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19982,
        "ProviderName": "Tip -Top Solution Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069098086",
        "provideremail1": "tiptophospital@gmail.com",
        "address1": "4, Kwara wAY Custom Bus/Stop  Ikotun",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19291,
        "ProviderName": "TMAC Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08096314722",
        "provideremail1": "Tmac_specialisthospital@outlook.com",
        "address1": "10, Irewole Street, Off Opebi Link Road, Opebi",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19292,
        "ProviderName": "Tobi Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08104400044",
        "provideremail1": "NULL",
        "address1": "S7/453, Felele Layout, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19293,
        "ProviderName": "Tobiloba Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033211040",
        "provideremail1": "NULL",
        "address1": "1, Tobiloba Way, Off Kuforiji Olubi Drive.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19529,
        "ProviderName": "Tobis Clinic and Consultants",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033300839",
        "provideremail1": "info@tobisclinic.com",
        "address1": "Km 14, Chief Melford Okilo Road, Opp Sobaz Filling Station, Akenfa, Yenagoa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19534,
        "ProviderName": "Todac Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08180204742",
        "provideremail1": "todacgroup@gmail.com",
        "address1": "61, Okigwe Road, Aba",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19294,
        "ProviderName": "Tolbert Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037587905",
        "provideremail1": "tolbertspecialisthospital@yahoo.com",
        "address1": "17, Democracy Crescent, Gaduwa Estate, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19296,
        "ProviderName": "Tona Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08073199524",
        "provideremail1": "tonahospital84@gmail.com",
        "address1": "1, Ighodalo St., Off Agbor Road, Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19865,
        "ProviderName": "Tonajib Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142595974",
        "provideremail1": "tonajibhospitallimited@gmail.com",
        "address1": "46, Maria Ibironke Street , Ikotun , Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19297,
        "ProviderName": "Top Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08039097750",
        "provideremail1": "topmedicalmx@yahoo.co.uk",
        "address1": "4, Top Medical road, Tunga, Minna",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19298,
        "ProviderName": "Topaz Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023123352",
        "provideremail1": "topazclinic@yahoo.com",
        "address1": "12, Adeniji Street, Off Municipal Library, Surulere, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19300,
        "ProviderName": "Topcare Specialist Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07033508608",
        "provideremail1": "NULL",
        "address1": "118 Hadijia Road, Kano, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19439,
        "ProviderName": "Toptee Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023257273",
        "provideremail1": "info@topteemedical.com",
        "address1": "40, Ogunlana Drive, Surulere",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19301,
        "ProviderName": "Toronto Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033733133",
        "provideremail1": "NULL",
        "address1": "Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19302,
        "ProviderName": "Total Care Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08035786149",
        "provideremail1": "NULL",
        "address1": "5, Mosunmola Ashaye St., Makun.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19303,
        "ProviderName": "Toun Memorial Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07042139279",
        "provideremail1": "admin@tounhospital.com",
        "address1": "Km 50, New Ife Expressway, Ibadan.",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19435,
        "ProviderName": "Trans-Aquatic Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "07087605852",
        "provideremail1": "transaquatichospital@gmail.com",
        "address1": "Km 35, Lekki-Epe Expressway, Bogije, Opp Toyota Auto Nation, Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19305,
        "ProviderName": "Trauma & Multispecialist Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09070102900",
        "provideremail1": "tmc@crystalhorpe.org",
        "address1": "UATH Compound, Specialist Hospital Road, Gwagwalada",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19826,
        "ProviderName": "Tru -Care Medicare Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09139361696",
        "provideremail1": "truecaremedical@gmail.com",
        "address1": "Zarmaganna Rayfield Road, Adjacent Silk Suit Hotel",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19306,
        "ProviderName": "True Care Model Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08170000217",
        "provideremail1": "truecaremodelhospital1@gmail.com",
        "address1": "19, Adeyemi Apena Street, Papa, Epe.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19918,
        "ProviderName": "Trust Charitos Hospital",
        "provcatid": 1003,
        "categoryname": "C",
        "providerphone1": "080325859299",
        "provideremail1": "hmotrustcharitoshospital@hotmail.com",
        "address1": "Plot 879/880, Idris Ibrahim Off Obafemi Awolowo Creacent Jabi Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19307,
        "ProviderName": "Tunde Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08065819918",
        "provideremail1": "NULL",
        "address1": "22, Atani Street, Abakpa, Enugu.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19308,
        "ProviderName": "Tunrayo Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023297812",
        "provideremail1": "NULL",
        "address1": "8, Ajibade St, Off Olajuwon St., Surulere.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19309,
        "ProviderName": "Twins Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063415845",
        "provideremail1": "twinsclinicwarri@yahoo.co.uk",
        "address1": "1, Twins Clinic Drive, Off Deco Rd, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19310,
        "ProviderName": "Uche Medicare Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08026116653",
        "provideremail1": "NULL",
        "address1": "C 25 World Bank Housing Estate",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19311,
        "ProviderName": "Uchenna Hospital & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037082045",
        "provideremail1": "NULL",
        "address1": "10/12 Enwereuzor Crescent, Umuoyo, Irete, Owerri",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19312,
        "ProviderName": "Ufor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023835392",
        "provideremail1": "NULL",
        "address1": "19, Uloho Avenue, Ughelli",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19314,
        "ProviderName": "Ukponmwan Foundation Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037583742",
        "provideremail1": "NULL",
        "address1": "53, Idahosa Street, Off Dawson Benin City",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19315,
        "ProviderName": "Ultima Medicare Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022907595",
        "provideremail1": "ultima_medicare88@yahoo.com",
        "address1": "3, Cappa Avenue, Palmgrove Estate.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19321,
        "ProviderName": "Umezuruike Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037239260",
        "provideremail1": "umezhospital@yahoo.com",
        "address1": "21/23, Umezuruike Street, Owerri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19342,
        "ProviderName": "Unity Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033063122",
        "provideremail1": "NULL",
        "address1": "Plot D1, Phase 2 Site 1, Gado Nasko Road, Kubwa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19343,
        "ProviderName": "Unity Hospital & Maternity, Enugu",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08063581650",
        "provideremail1": "NULL",
        "address1": "Ngwo Hilltop, Enugu",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19344,
        "ProviderName": "Unity Medical Centre, Ibadan",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08038359848",
        "provideremail1": "umcib@yahoo.com",
        "address1": "Eleyele, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19511,
        "ProviderName": "University of Medical Science Technology Hospital – NHIS",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "+2340802222222",
        "provideremail1": "callcenter@metrohealth.com",
        "address1": "Abuja",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19793,
        "ProviderName": "University Teaching Hospital, Ado Ekiti",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033470483",
        "provideremail1": "adoekitihospital@yahoo.com",
        "address1": "ADO, Ekiti,",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19345,
        "ProviderName": "Usy Medicals",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": null,
        "provideremail1": "NULL",
        "address1": "3, Bassey Ntui Street, Isong Inyang, Akamkpa",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19346,
        "ProviderName": "Uwakmfon Specialist Clinics",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08061224288",
        "provideremail1": "NULL",
        "address1": "27, Nwaniba Road, Uyo.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19347,
        "ProviderName": "Vcare Diagnostics Ltd",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07080631400",
        "provideremail1": "customerservice@vcarediagnosticsltd.com",
        "address1": "100, Adetokundo Ademola Street, Victoria Island",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19349,
        "ProviderName": "Veenell Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037172019",
        "provideremail1": "veeneell_hospital@yahoo.com",
        "address1": "61, Okumagba Avenue",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19350,
        "ProviderName": "Vertimon Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033882397",
        "provideremail1": "NULL",
        "address1": "3, Merogun Road, Warri.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19688,
        "ProviderName": "Vicamen Academy",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08022220432",
        "provideremail1": "vicamen@gmail.com",
        "address1": "150 Ago Palace Way, Okota",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19351,
        "ProviderName": "Victoria Memorial Hospital, Morogbo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033352572",
        "provideremail1": "dr.chukwudiwilliams@yahoo.com",
        "address1": "Behind Fousquare Church, Morogbo, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19352,
        "ProviderName": "Victoria Vestige & Memorial Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036097476",
        "provideremail1": "henrypam007@vvmh.net",
        "address1": "26D, D.B Zang Way, G.R.A, Jos",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19437,
        "ProviderName": "Victory Life Specialist Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037218094",
        "provideremail1": "devictorylifespechospital@gmail.com",
        "address1": "15, Ilupeju Estate, Ijede, Ikorodu Road, Lagos",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19690,
        "ProviderName": "Vigor Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07062710020",
        "provideremail1": "info@vigorhospitals.com",
        "address1": "10 Kingdom Hall Street Off Fidiso Estate Abijo, Ibeju Lekki",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19745,
        "ProviderName": "Vinca Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "07040231426",
        "provideremail1": "oakfield.specialist@gmail.com",
        "address1": "12 Remkom Street Behind NNPC Filling Station, Kubwa, Abuja.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19354,
        "ProviderName": "Vine Branch Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08142723134",
        "provideremail1": "NULL",
        "address1": "2, Olubadan Palace Road, Mokola, Ibadan",
        "region": "ILORIN",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19474,
        "ProviderName": "Vineyard Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036930347",
        "provideremail1": "vineyardhospital@gmail.com",
        "address1": "4, Gbemi Ogundeyi Street, Idimu",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19357,
        "ProviderName": "Warshu Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08069101237",
        "provideremail1": "NULL",
        "address1": "Kwaji, Kano State",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19358,
        "ProviderName": "Welfare Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036156368",
        "provideremail1": "NULL",
        "address1": "17, Ikuehi Close, GRA, Okene.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19359,
        "ProviderName": "Well Point Clinic",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023401325",
        "provideremail1": "NULL",
        "address1": "19, 661 Road, Galadima, Gwarinpa",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19360,
        "ProviderName": "WellMed Healthcare",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "09073019435",
        "provideremail1": "NULL",
        "address1": "Km 52, Lekki-Epe Expressway, Ajah",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19773,
        "ProviderName": "Westcare  Specialist Hospital - Ejigbo",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033245769",
        "provideremail1": "westcarehospital@yahoo.com",
        "address1": "31, Ifoshi Road, Pipeline Bus Stop, Near Orilowo Estate, Ejigbo",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19362,
        "ProviderName": "Westcare Specialist Hospital - Egbeda",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033245769",
        "provideremail1": "westcarehospital@yahoo.com",
        "address1": "32, Samuel Street, Akowonjo.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19363,
        "ProviderName": "Westend Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037078988",
        "provideremail1": "westendhosp_warri@yahoo.co.uk",
        "address1": "1, 25th Street, Westend Hospital Road, DDPA",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19898,
        "ProviderName": "White Dove Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08067770402",
        "provideremail1": "whitedovehospitalkuje@gmail.com",
        "address1": "No 1 Ademoh Isah Ovunvu Street SSQ, Kuje ,Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19365,
        "ProviderName": "Winrose Clinic Ltd.",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08023438780",
        "provideremail1": "winrose_clinic@yahoo.com",
        "address1": "7, Winrose Clinic Road, Off Enerhen Road.",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19366,
        "ProviderName": "Woji Cottage Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08037678693",
        "provideremail1": "NULL",
        "address1": "Alcon Road, Woji Town, Woji, PH",
        "region": "PH",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19367,
        "ProviderName": "Wuse District Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033733217",
        "provideremail1": "fctwuse_hospital@yahoo.com",
        "address1": "15, Conakry Street, Wuse Zone 3.",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19741,
        "ProviderName": "Yabisan Hospital",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08062422353",
        "provideremail1": "yaqubik14@gmail.com",
        "address1": "House 42 KL 14 Kubuwa Lugbe",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19368,
        "ProviderName": "Zaman Clinic & Maternity",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08051095478",
        "provideremail1": "zamanclinicltd@gmail.com",
        "address1": "25, Road 21B, Babangida Road, FHA Phase 1, Lugbe, Abuja",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19870,
        "ProviderName": "Zefafi Women's Specialist Hospital ",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08036060067",
        "provideremail1": "doctorkonstance@gmailcom",
        "address1": "No 1, Maikahon Karo Road Sokoto",
        "region": "ABUJA",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    },
    {
        "ProviderID": 19370,
        "ProviderName": "Zoe Medical Centre",
        "provcatid": 1004,
        "categoryname": "D",
        "providerphone1": "08033284404",
        "provideremail1": "zoemedical48@gmail.com",
        "address1": "20, Gbemisola St., Off Adeleke St., Off Allen, Ikeja, Lagos.",
        "region": "LAGOS",
        "address2": null,
        "coveragetypename": null,
        "coveragetypeid": 0,
        "stateid": null,
        "statename": null
    }
];



export { providerDataMidi, providerDataMaxi };