import axios from "axios";

const SEND_INVITE_TO_RETAIL_USER =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/register/retail";

const CREATE_PASSWORD =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/auth/reset-password/";

const VERIFY_OTP =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/register/verify/?token=";
const SEND_INVITE_TO_CORPORATE_USER =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/register/business";

class registrationService {
  registerIndividual(dto) {
    return axios.post(SEND_INVITE_TO_RETAIL_USER, dto, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }


  verifyOtp(dto, verificationToken) {
    return axios.post(VERIFY_OTP + verificationToken, dto, {});
  }
  registerBusiness(dto) {
    return axios.post(SEND_INVITE_TO_CORPORATE_USER, dto, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // Implement other methods with similar changes...
}

export const RegistrationService = new registrationService();
