import axios from "axios";
import jwtDecode from "jwt-decode"; 

const GET_ALL_PAID_CORPORATEPROFILE = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/customers/?category=Awaiting Onboarding";

const GET_ALL_CORPORATEPROFILE = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/admin/corporate";

const GET_ALL_RETAIL_PROFILE = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/admin/retail";

const APPROVE_BUSINESS_PLAN = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/admin/approve/group";
const APPROVE_RETAIL_PLAN = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/admin/approve/retail";
class adminService {

    // Check if the token is expired
    isTokenExpired (token) {
        if (token === null) {
            return true; 
        }
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decoded.exp < currentTime;
    }

    handleTokenExpiration(token) {
        if (this.isTokenExpired(token)) {
            // Handle token expiration on the client side, e.g., redirect to a login page
            window.location.href = '/login/popup';
            return Promise.reject("Token expired");
        }
        return Promise.resolve();
    }

    getAllCorporateProfilesPaid(token) {
     return this.handleTokenExpiration(token)
        .then(() => {
        return axios.get(GET_ALL_PAID_CORPORATEPROFILE, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    });
    }


    approveBusinessUserPlan(token, dto) {
        return this.handleTokenExpiration(token)
           .then(() => {
           return axios.post(APPROVE_BUSINESS_PLAN, dto, {
               headers: {
                   Authorization: `Bearer ${token}`,
                   "Content-Type": "application/json"
               }
           });
       });
       }
       approveRetailPlan(token, dto) {
        return this.handleTokenExpiration(token)
           .then(() => {
           return axios.post(APPROVE_RETAIL_PLAN, dto, {
               headers: {
                   Authorization: `Bearer ${token}`,
                   "Content-Type": "application/json"
               }
           });
       });
       }

 getAllCorporateProfiles(token) {
    return this.handleTokenExpiration(token)
       .then(() => {
       return axios.get(GET_ALL_CORPORATEPROFILE, {
           headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json"
           }
       });
   });
   }

   getAllCustomersWhoHaveNotPaid(token) {
    return this.handleTokenExpiration(token)
       .then(() => {
       return axios.get("https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/admin/leads", {
           headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json"
           }
       });
   });
   }
   getAllRetailProfiles(token) {
    return this.handleTokenExpiration(token)
       .then(() => {
       return axios.get(GET_ALL_RETAIL_PROFILE, {
           headers: {
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json"
           }
       });
   });
   }
}

export const AdminService = new adminService();