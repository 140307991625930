import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import Signin from "./pages/Signin";


import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";



// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
      <React.Fragment>
        <BrowserRouter>
     
          <Routes>
            <Route path="/" element={<Signin />}>
              {/* This route is for the root path ("/") */}
            </Route>
              <Route path="/" element={<Main />}>
                  {protectedRoutes.map((route, index) => (
                      <Route
                          path={route.path}
                          element={route.element}
                          key={index}
                      />
                  ))}
              </Route>
            {publicRoutes.map((route, index) => (
                <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                />
            ))}
            <Route path="*" element={<NotFound />} />
          </Routes>
      
        </BrowserRouter>
      </React.Fragment>
  );

}