import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  // const footerStyle = {
  //   position: "absolute",
  //   bottom: 0,
  //   // width: "100%",
  //   // backgroundColor: "white",
  //   // padding: "10px", // Adjust padding as needed
  //   // borderTop: "1px solid #ddd", // Optional: Add a border at the top
  // };

  return (
    <div className="main-footer">
      <span>&copy; 2023. MetroHealth HMO Ltd. All Rights Reserved.</span>
      <span>
        Created by: <Link to="#" target="_blank">MetroHealth ICT</Link>
      </span>
    </div>
  );
}
