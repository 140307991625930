import React, { useEffect, useState } from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import UploadWidget from "../../components/utils/UploadWidget";
import DatePicker from 'react-datepicker';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {format} from "date-fns";
import { EnrolleeService } from "../../service/EnrolleeService";
import Swal from "sweetalert2";

export default function IndividualSettings() {
    const [selectedDay, setSelectedDay] = useState(null);
    const [url, updateUrl] = useState();
    const [error, updateError] = useState();
    const [houseAddress, setHouseAddress] = useState("");
    const [provider, setProvider] = useState("");
    const [state, setState] = useState("");
    const [gender, setGender] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [imageUrl, setImageUrl] = useState ("");



    const showAlert = () => {
        Swal.fire({
          title: 'Are you sure?',
          text: ' Kindly ensure that your details are correct',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
             handleSubmit();
             console.log("confirmed")
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Action canceled', 'Your action was canceled.', 'error');
          }
        });
      };

    /**
     * handleOnUpload
     */

    function handleOnUpload(error, result, widget) {
        if ( error ) {
            updateError(error);
            widget.close({
                quiet: true
            });
            return;
        }
        updateUrl(result?.info?.secure_url);
    }

    const stateOptions = [
        { value: 'Abia', label: 'Abia' },
        { value: 'Adamawa', label: 'Adamawa' },
        { value: 'Akwa-ibom', label: 'Akwa Ibom' },
        { value: 'Anambra', label: 'Anambra' },
        { value: 'Bauchi', label: 'Bauchi' },
        { value: 'Bayelsa', label: 'Bayelsa' },
        { value: 'Benue', label: 'Benue' },
        { value: 'Borno', label: 'Borno' },
        { value: 'Cross-river', label: 'Cross River' },
        { value: 'Delta', label: 'Delta' },
        { value: 'Ebonyi', label: 'Ebonyi' },
        { value: 'Edo', label: 'Edo' },
        { value: 'Ekiti', label: 'Ekiti' },
        { value: 'Enugu', label: 'Enugu' },
        { value: 'Gombe', label: 'Gombe' },
        { value: 'Imo', label: 'Imo' },
        { value: 'jigawa', label: 'Jigawa' },
        { value: 'Kaduna', label: 'Kaduna' },
        { value: 'Kano', label: 'Kano' },
        { value: 'Katsina', label: 'Katsina' },
        { value: 'kebbi', label: 'Kebbi' },
        { value: 'kogi', label: 'Kogi' },
        { value: 'kwara', label: 'Kwara' },
        { value: 'lagos', label: 'Lagos' },
        { value: 'nasarawa', label: 'Nasarawa' },
        { value: 'niger', label: 'Niger' },
        { value: 'ogun', label: 'Ogun' },
        { value: 'ondo', label: 'Ondo' },
        { value: 'osun', label: 'Osun' },
        { value: 'oyo', label: 'Oyo' },
        { value: 'plateau', label: 'Plateau' },
        { value: 'rivers', label: 'Rivers' },
        { value: 'sokoto', label: 'Sokoto' },
        { value: 'taraba', label: 'Taraba' },
        { value: 'yobe', label: 'Yobe' },
        { value: 'zamfara', label: 'Zamfara' },
    ];

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];



    const footer = selectedDay ? (

        <h6 style={{ marginTop: "15px",alignContent:"" }} >You selected {format(selectedDay, 'PPP')}.</h6>
    ) : (
        <h6 style={{ marginTop: "15px",alignContent:"" }}>Please pick a day.</h6>
    );




    function handleSubmit() {
       

        const formDto = {
            address: houseAddress + "," + state,
            provider:"" ,
            gender: gender,
            dateOfBirth: selectedDay,
            imageUrl: url,
        };

        EnrolleeService.editProfile(localStorage.getItem("token"),formDto)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                Swal.fire('Action successful', 'Profile has been updated', 'success');
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire('Action failed', error.response.data.result, 'error');
          });

           

    }

    return(
        <React.Fragment>

            <Card className="card-post mt-4">
                <Card.Header>
                    <Card.Title>Personal Information</Card.Title>
                    {/* <Card.Text>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit.
                    </Card.Text> */}
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="setting-item">
                        <Row className="g-2 align-items-center">
                            <Col md="5">
                                <h6>Address</h6>
                                <p>Your current location</p>
                            </Col>
                            <Col md>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address"
                                    value={houseAddress}
                                    onChange={(event) => setHouseAddress( event.target.value)}
                                />
                                <br/>
                                <Form.Select
                                    value={state}
                                    onChange={(event) => setState( event.target.value)}
                                >
                                    <option value="">Select State</option>
                                    {stateOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </div>
                    <div className="setting-item">
                        <Row className="g-2">
                            <Col md="5">
                                <h6>Gender</h6>
                                <p>Choose an Option</p>
                            </Col>
                            <Col md>
                                <Form.Select
                                    value={gender}
                                    onChange={(event) => setGender( event.target.value)}
                                >
                                    <option value="">Choose an option</option>
                                    {genderOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </div>

                    <div className="setting-item">
                        <Row className="g-2 align-items-center">
                            <Col md="5">
                                <h6>Date of Birth</h6>
                                <p>Choose an Option</p>
                            </Col>
                            <Col md>
                                <DayPicker
                                    selected={dateOfBirth}
                                    onSelect={setSelectedDay}
                                    mode="single"
                                    placeholder="Select Date"
                                    captionLayout="dropdown-buttons"
                                    fromYear={1923}
                                    toYear={2005}
                                    footer={footer}/>

                            </Col>
                        </Row>
                    </div>

                    <div className="setting-item">
                        <Row className="g-2 align-items-center">
                            <Col md="5">


                                <h6>Your Photo</h6>
                                <p>Upload a recent headshot photograph</p>
                                <br/>
                                {url && (
                                    <>
                                        <p>
                                            <img src={url} alt="Uploaded" style={{ width: "50%",alignContent:"" }}/>
                                        </p>

                                    </>
                                )}
                            </Col>
                            <Col md>
                                <UploadWidget onUpload={handleOnUpload}>
                                    {({ open }) => {
                                        function handleOnClick(e) {
                                            e.preventDefault();
                                            open();
                                        }
                                        return (
                                            <Button variant={"secondary"} onClick={handleOnClick} className="btn-sign">Upload your photo</Button>
                                        )
                                    }}
                                </UploadWidget>
                                {error && <p>{ error }</p>}
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            <Card.Footer>
                <Row className="g-2 align-items-center">
                    <Col md="5">
                    </Col>
                    <Col md>
                        <Button onClick={showAlert} className="btn-sign">Submit</Button>

                    </Col>
                </Row>

            </Card.Footer>
            </Card>

        </React.Fragment>
    );
}