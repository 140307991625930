
import React, {useState} from "react";
import { Button, Card, Col, Form, Spinner,Alert , Image,Row} from "react-bootstrap";
import favicon from "../assets/img/metro health hmo final.png";
import {Link, useNavigate} from "react-router-dom";
import {AuthService} from "../service/AuthService";
import jwt_decode from "jwt-decode";

export default function Signin(props) {
  const handleSuccess = props.successAction;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigate = useNavigate();
  const requestData = {
    email: email,
    password: password
  };



  // }, [navigate(), isLoggedIn,isLogged]);
  // Perform form validation and authentication logic here

   const clickSubmit = () => {
     setIsLoading(true);
     setPasswordError(false);
   }

    const intendedUrl = localStorage.getItem('intendedUrl');

  const handleSubmit = async (e) => {
    e.preventDefault();

    clickSubmit();
    AuthService.login(requestData)
        .then((response) => {

          //Navigate to the Dashboard page & set context to true
          if (response.status === 200) {
            const decodedToken = jwt_decode(response.data.result.token)

            const newDetails = {
              firstName: decodedToken.firstName,
              lastName: decodedToken.lastName,
              email: decodedToken.sub,
              role: decodedToken.roles[0],
              imageUrl:response.data.result.imageUrl,
              verifiedStatus:decodedToken.verifiedStatus,
              // Add more fields as needed
            };

            localStorage.setItem('token', response.data.result.token)
            localStorage.setItem('userDetails', JSON.stringify(newDetails));
            const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
            localStorage.setItem("expirationTime", expirationTime.toString());

         

            // updateUserDetails(newDetails);
            console.log("login success");
            console.log(decodedToken.roles[0]);
            let destination = "/dashboard/business";

          if (decodedToken.roles[0] === "ROLE_ENROLLEE") {
            destination = "/dashboard/individual";
          }

          if (decodedToken.roles[0] === "ROLE_OPERATIONS") {
            destination = "/dashboard/admin/business";
          }


              if (intendedUrl) {
                  destination = intendedUrl;
                  console.log(intendedUrl);
                  localStorage.removeItem('intendedUrl');
              }

            setTimeout(() => {

                navigate(destination, { replace: true });
                setIsLoading(false);
                handleSuccess();

            }, 2000); // Delay of 1 second (1000 milliseconds)
          }

          if (response.status === 400) {
            setPasswordError("Please reset your password to continue.");
            setIsLoading(false);
            // navigate("/forgot-password", { replace: true })
          }

        })
        .catch((error) => {
          setPasswordError("Invalid email or password, please try again or reset your password.");
          // navigate("/forgot-password", { replace: true });
          setIsLoading(false);
        });


  };





  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
        <Image
                src={favicon}
                fluid={true}
                width="250px"
                alt="..."
                className="mx-auto d-block text-center"
                style={{ marginBottom: "30px",alignContent:"" }}
              />
          <Card.Title className="mx-auto d-block text-center">Welcome back! </Card.Title>
          <Card.Text className="mx-auto d-block text-center" >Please signin to continue.</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter your email address"
                            value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Password <Link to="/forgot-password">Forgot password?</Link>
              </Form.Label>
              <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                  <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Check
                      type="switch"
                      checked={showPassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                      }}
                      onChange={togglePasswordVisibility}
                  />
              </div>
            </div>

            {!isLoading && (
                <Button type="submit" className="btn-sign">Sign In</Button>
            )}
            {isLoading && (
                <Button  className="btn-sign">
                  Signing you in...<span/><Spinner  size="sm" animation="border" /></Button>
            )}

          </Form>
        </Card.Body>
        <Card.Footer>
          Don't have an account? 
          <br/>
          <div className="divider"><span>Create a</span></div>

<Row className="gx-2">
  <Col>
  <Link to="/register/individual">  
  
  <Button variant="" className="btn-facebook"><Link to="/register/individual"></Link> Personal Account</Button>
  </Link>
  
  </Col>
  <Col>
  
  <Link to="/register/business">
  <Button variant="" className="btn-google" >

 Business Account</Button>

  </Link></Col>
</Row>
        </Card.Footer>
        {passwordError && (
            <Alert key={"warning"} variant={"warning"} className="alert-solid">
              {passwordError}
            </Alert>
        )}
      </Card>
    </div>
  )
}