import React from 'react';
import { Container, Table } from 'react-bootstrap';

const RetailInvoices = ({ retailInvoiceList }) => {

const formatDate = (dateString) =>{

    if (!dateString) {return null;}
    const dateObject = new Date(dateString);

   return dateObject.toLocaleString();
}


  return (
    <Container>
      <h2>Pending Invoices</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Description</th>
            <th>Invoice Date</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
    
          {retailInvoiceList.map((invoice) => (
  // Check if invoice is not null
  invoice ? (
    <tr key={invoice.id}>
      <td>{invoice.id}</td>
      <td>{invoice.amount}</td>
      <td>{invoice.description}</td>
      <td>{formatDate(invoice.createdDate)}</td>
      {/* Add more cells as needed */}
    </tr>
  ) : null
))}

        </tbody>
      </Table>
    </Container>
  );
};

export default RetailInvoices;
