import axios from "axios";
import jwtDecode from "jwt-decode";


const GET_ALL_RETAIL_PLANS = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/plan/fetch/filter/?planType=RETAIL_STANDARD";
const INITALIZE_PAYMENT = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/initialize";
const VERIFY_PAYMENT = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/callback/zest/";
const INITALIZE_CORPORATE_PAYMENT = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/initialize/business";

class planService {
      // Check if the token is expired
  isTokenExpired(token) {
    if (token === null) {
        return true; 
    }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;
  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

    initializePayment(paymentRequest, token) {
        return this.handleTokenExpiration(token).then(() => {
        return axios.post(INITALIZE_PAYMENT, paymentRequest, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    });
    }
    initializeCorporatePayment(paymentRequest, token) {
        return this.handleTokenExpiration(token).then(() => {
        return axios.post(INITALIZE_CORPORATE_PAYMENT, paymentRequest, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    });

    }

    confirmPayment(verificationRequest, token) {
            return this.handleTokenExpiration(token).then(() => {
        return axios.post(VERIFY_PAYMENT, verificationRequest, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
          });
    }

    getAllRetailPlans(token) {

        return this.handleTokenExpiration(token).then(() => {
        return axios.get(GET_ALL_RETAIL_PLANS, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    });
    }
   

    // Implement other methods with similar changes...
}

export const PlanService = new planService();
