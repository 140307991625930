import React, { useState, useEffect } from "react";
import HeaderMobile from "../../layouts/HeaderMobile";
import {
  Spinner,
  Modal,
  Button,
  Card,
  Row,
  Col,
  Form,
  Table,
} from "react-bootstrap";


import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { EnrolleeService } from "../../service/EnrolleeService";
import { OnboardingService } from "../../service/OnboardingService";

const StaffUploadWizard = (props) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [planData, setPlanData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Define a regular expression to match the 'token' parameter in the URL
    const tokenRegex = /state=([^&]+)/;

    // Get the current URL from the browser
    const currentUrl = window.location.href;

    // Use the regular expression to extract the token from the URL
    const tokenMatch = currentUrl.match(tokenRegex);

    // Check if a token was found and extract it from the match
    const token = tokenMatch ? tokenMatch[1] : null;

    // Do something with the token (e.g., assign it to a variable)
    if (token === "open") {
      setShowModal(true);
    }
  }, []);

  const handleClose2 = () => {
    setShowModal(false);
    navigate("/profile/business");
  };

  const handleEmployeeFormSubmit = () => {
    // Check if any form fields are blank
    const isAnyFieldBlank = formData.some(
      (data) =>
        !data.firstName || !data.lastName || !data.email || !data.phoneNumber
    );

    if (isAnyFieldBlank) {
      Swal.fire("Action failed", "Please fill in all fields", "error");
      return;
    }


    if ((formData.length + selectedPlan.numberOfEnrolleesUsed) > selectedPlan.numberOfEnrollees){
      Swal.fire("Invalid Employee Upload Number", "The number of employees uploaded is more than the quota for your plan", "error");
      return;
    }

    const formDTO = {
      userSelectionId: selectedPlan.id,
      formData: formData,
    };

    console.log(formDTO)

    setIsLoading(true);

    OnboardingService.uploadViaFOrm(localStorage.getItem("token"), formDTO)
    .then((response) => {
      if (response.status === 200) {
        setIsLoading(false);

        Swal.fire('Upload successful', "Onboarding links will be sent to each individual in order to access care", 'success');

        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error);
      Swal.fire('Action failed', error.response.data.result, 'error');
      setIsLoading(false);
    });
  };

  const showAlert = () => {
    Swal.fire({
      title: "Are you sure?",
      text: " Kindly ensure that your details are correct",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("confirmed");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Action canceled", "Your action was canceled.", "error");
      }
    });
  };

  const [formData, setFormData] = useState([
    { firstName: "", lastName: "", email: "", phoneNumber: "" },
  ]);

  useEffect(() => {
    EnrolleeService.getCorporateProfile(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          const plans = response.data.result.corporateUserPlanSelectionList;
          const filteredPlans = plans.filter((plan) => plan.isActive == true);
          setPlanData(filteredPlans);
          setSelectedPlan(filteredPlans[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleAddForm = () => {
    setFormData([
      ...formData,
      { firstName: "", lastName: "", email: "", phoneNumber: "" },
    ]);
  };

  const handleRemoveForm = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };
  // Handler to update the selected plan
  const handlePlanSelect = (planId) => {

    const selected = planData.find((plan) => plan.id == planId);
    setSelectedPlan(selected);

  };
  const handleNext1 = () => {
    setStep(1);
  };


  const handleNext = () => {

    if (step == 1 && planData.length == 0){
      return;
    }
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  return (
    <React.Fragment>
     <div>
       {/* <LogoutAndRedirect/> */}
       </div>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={showModal} centered size="xl">
          <Modal.Header closeButton onHide={handleClose2}></Modal.Header>

          <Modal.Body>
            {step === 0 && (
              <div className="text-center mb-4">
                <h5>Select Upload Mode</h5>
                <br />
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={handleNext1}
                    >
                      Upload an CSV file
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      variant="primary"
                      className="mx-2"
                      onClick={handleNext1}
                    >
                      Use a Form
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

            {step === 1 && (
              <div className="text-center mb-4">
                <h5>Select Health Plan</h5>
                <br />

                {planData.length > 0 && (
                  <Row style={{ paddingLeft: "25%", paddingRight: "25%" }}>
                 
                    <Form.Select
                      onChange={(e) => handlePlanSelect(e.target.value)}
                    >
                      {planData.map((plan) => (
                        <option key={plan.id} value={plan.id}>
                          {plan.plan.planName}
                        </option>
                      ))}
                    </Form.Select>

                    {selectedPlan && (
                      <Row className="mt-3">
                        <Col>
                          <h4>Selected Plan Details</h4>
                          <p>Plan Name: {selectedPlan.plan.planName}</p>
                          <p>Plan Type: {selectedPlan.isFamilyType? "Family Plan": "Individual Plan"}</p>
                          {
                            selectedPlan.isFamilyType && ( <p>Family Size: {selectedPlan.familySize}</p>)
                          }
                    
                          <p>
                            Maximum Number of Enrollees:{" "}
                            {selectedPlan.numberOfEnrollees}
                          </p>
                          <p>
                            Number of Slots available:{" "}
                            {selectedPlan.numberOfEnrollees - selectedPlan.numberOfEnrolleesUsed}
                          </p>
                          {/* Add more details as needed */}
                        </Col>
                      </Row>
                    )}
                  </Row>
                )}
              <div>  {planData.length == 0 && (
                  <h5>You have no active plan at the moment</h5>
                )}</div>
              </div>
            )}

            {step === 2 && (
              <Card
                className="card-post mt-4"
                style={{ paddingLeft: "5%", paddingRight: "5%" }}
              >
                <Card.Header>
                  <Card.Title>Staff Upload</Card.Title>
                  <Card.Text>You can add employee information here.</Card.Text>
                </Card.Header>
                <Card.Body className="p-0">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              name="firstName"
                              value={data.firstName}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              name="lastName"
                              value={data.lastName}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              name="email"
                              value={data.email}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              value={data.phoneNumber}
                              onChange={(e) => handleInputChange(index, e)}
                            />
                          </td>
                          <td>
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveForm(index)}
                            >
                              Remove
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <Row className="mt-3">
                    <Col>
                      <p>Total Employees Added: {formData.length}</p>
                    </Col>
                  </Row>
                </Card.Body>

                <Card.Footer>
                  <Row className="justify-content-center">
                    <Col md="auto">
                      <Button variant="primary" onClick={handleAddForm}>
                        Add Form
                      </Button>
                    </Col>
                    <Col md="auto">

                    {!isLoading && (
                      <Button
                        onClick={handleEmployeeFormSubmit}
                        className="btn-sign"
                      >
                        Submit Employee Form
                      </Button>
                      )}
                      {isLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}
                     
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            )}
          </Modal.Body>

          <Modal.Footer>
           {
            step > 0 && (
              <Button variant="primary" onClick={handlePrev}>
              Previous Step
            </Button>
            )
           }
           {step < 2 && (
            <Button variant="primary" onClick={handleNext}>
              Next Step
            </Button>
           )}
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default StaffUploadWizard;
