import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row,Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import pageSvg from "../assets/svg/forgot_password.svg";
import { AuthService } from "../service/AuthService";

import Swal from "sweetalert2";

export default function ForgotPassword() {
  document.body.classList.remove("sidebar-show");

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const passwordDto = {
    email,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // post dto

    AuthService.forgotPassword(passwordDto)
      .then((response) => {
         setIsLoading(false);
        if (response.status === 200) {
          Swal.fire(
            "Successful",
            "A reset link has been sent to your mail",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/login";
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        Swal.fire("Action failed", error.response.data.result, "error").then(
          (result) => {
            if (result.isConfirmed) {
              window.location.href = "/login";
            }
          }
        );
      });
  };

  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
            MetroHealth
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-facebook-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-whatsapp-line"></i>
            </Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-sign">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"
                ></object>
              </div>
              <Card.Title>Reset your password</Card.Title>
              <Card.Text className="mb-5">
                Enter your email address and we will send you a link to reset
                your password.
              </Card.Text>

              <Row className="g-2">
               
                  <Row><div className="mb-4">
                    {/*<Form.Label className="d-flex justify-content-between">New Password </Form.Label>*/}
                    <Form.Control
                      onChange={(event) => setEmail(event.target.value)}
                      type="text"
                      placeholder="Enter your MetroHealth Email"
                    />
                  </div></Row>
               
                <Row>
                 


                  {!isLoading && (
                    <Button
                    onClick={handleSubmit}
                    type="submit"
                    variant="primary"
                    className="btn-sign"
                  >
                    Send Reset Link{" "}
                  </Button>
            )}
            {isLoading && (
              <Button className="btn-sign">
                Processing...
                <span />
                <Spinner size="sm" animation="border" />
              </Button>
            )}
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
