import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Row,  } from "react-bootstrap";
import { Link } from "react-router-dom";
import { retailPlans } from "../../data/ProductData";
import PlanPurchaseModal from "../../components/PlanPurchaseModal";


function PlanCard({ plan, handlePlanClick }) {
  return (
    <Col sm="6" md="4">
      <Card className="card-one">
        <Card.Body className="p-3 d-flex flex-column">
          <div className="text-center p-3 bg-white rounded mb-3">
            <img src={plan.img} className="img-fluid" alt={plan.planName} />
          </div>
          <h6 className="fw-semibold text-dark lh-4">{plan.planName}</h6>
          <p className="mb-3 fs-sm text-secondary">{plan.description}</p>
          <div className="mt-auto">
            <Button onClick={() => handlePlanClick(plan)}>Purchase Plan</Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default function IndividualPlans() {


  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

      const [showPaymentModal,setShowPaymentModal] = React.useState(false);
      const [selectedPlan, setSelectedPlan] = useState(null);


  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


    // useEffect(() => {
    //     setIsLoading(true);
    //     PlanService.getAllRetailPlans(localStorage.getItem("token"))
    //     .then((res) => {
    //         if (res.status===200){
    //             setHealthPlans(res.data.result);    
    //             setIsLoading(false);
    //             console.log( res.data.result );
    
    //             }

            
    //     })
    //     .catch((err) => {
    //         setIsLoading(false);
    //         // Handle errors, e.g., show an error message
    //         console.log("Upload error:", err);
    //     })

    // },[])

    const handlePlanClick = (plan) => {
      setSelectedPlan(plan);
      setShowPaymentModal(true);
  };
  


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">My Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Health Plans</li>
            </ol>
          </div>

        </div>
       
        <h1 className="pricing-title mb-2">Choose a plan that's best for you.</h1>
        <br/>
        {/* <p className="pricing-text mb-4">The total price includes the item price and a buyer fee.</p> */}

        {/* <div className="d-flex gap-2">
          <Button variant="primary" className="fw-medium">Start Free Trial</Button>
          <Button variant="" className="btn-white fw-medium">Learn more</Button>
        </div> */}

  

        <Row className="g-3 equal-height">
      {retailPlans.map((plan) => (
        <PlanCard key={plan.id} plan={plan} handlePlanClick={handlePlanClick} />
      ))}
    </Row>

        {
          showPaymentModal && (

            <PlanPurchaseModal
              plan={selectedPlan}
              onClose={() => setShowPaymentModal(false)}
            />
          )
        }

        <hr className="my-4 opacity-0" />




        <Footer />
      </div>
    </React.Fragment>
  )
}