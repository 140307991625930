import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import favicon from "../assets/img/metro health hmo final.png";
import { Image } from "react-bootstrap";

import {
  dashboardMenu,
  applicationsMenu,
  pagesMenu,
  applicationsCorporate,
  dashboardMenuCorporate,
  adminMenu
} from "../data/Menu";

export default class Sidebar extends Component {
  toggleFooterMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };

  render() {
    let imageUrl = undefined;
    let role = undefined;
    let userDetails = {};
    const userDetailsJSON = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetailsJSON);

    if (userDetails != null) {
      imageUrl = userDetails.imageUrl;
      role = userDetails.role;
    }
    return (
      <div className="sidebar">
        <div className="sidebar-header">
          {/* <Link to="/" className="sidebar-logo">MetroHealth</Link> */}
          <Image
            src={favicon}
            fluid={true}
            width="150px"
            alt="..."
            className="mx-auto d-block text-center"
            style={{
              marginBottom: "30px",
              alignContent: "",
              marginTop: "30px",
            }}
          />
        </div>
        <PerfectScrollbar
          className="sidebar-body"
          ref={(ref) => (this._scrollBarRef = ref)}
        >
          <SidebarMenu
            onUpdateSize={() => this._scrollBarRef.updateScroll()}
            role={role}
          />
        </PerfectScrollbar>
        <div className="sidebar-footer">
          <div className="sidebar-footer-top">
            <div className="sidebar-footer-thumb">
              <img
                src={imageUrl === undefined ? userAvatar : imageUrl}
                alt=""
              />
            </div>
            <div className="sidebar-footer-body">
              <h6>
                <Link to="../pages/profile.html">
                  {" "}
                  {userDetails
                    ? userDetails.firstName + " " + userDetails.lastName
                    : ""}{" "}
                </Link>
              </h6>
              <p>
                {" "}
                {userDetails
                  ? userDetails.role === "ROLE_CORPORATE"
                    ? "BUSINESS ACCOUNT"
                    : "INDIVIDUAL ACCOUNT"
                  : " "}
              </p>
            </div>
            <Link
              onClick={this.toggleFooterMenu}
              to=""
              className="dropdown-link"
            >
              <i className="ri-arrow-down-s-line"></i>
            </Link>
          </div>
          <div className="sidebar-footer-menu">
            <nav className="nav">
              <Link to="">
                <i className="ri-edit-2-line"></i> Edit Profile
              </Link>
              <Link to="">
                <i className="ri-profile-line"></i> View Profile
              </Link>
            </nav>
            <hr />
            <nav className="nav">
              <Link to="">
                <i className="ri-question-line"></i> Contact Center
              </Link>
              <Link to="">
                <i className="ri-lock-line"></i> Report Delay
              </Link>
              <Link to="">
                <i className="ri-user-settings-line"></i> Account Settings
              </Link>
              <Link to="">
                <i className="ri-logout-box-r-line"></i> Log Out
              </Link>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

class SidebarMenu extends Component {
  populateMenu = (m) => {
    const menu = m.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" key={key}>
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={this.toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    this.props.onUpdateSize();
  };

  render() {
    const { role } = this.props;
    const isCorporateRole = role === "ROLE_CORPORATE";
    const isEnroleeRole = role === "ROLE_ENROLLEE";
  
    return (
      <React.Fragment>
        {(isCorporateRole || isEnroleeRole) && (
          <div>
            <div className="nav-group show">
              <div className="nav-label" onClick={this.toggleMenu}>
                Dashboard
              </div>
              {isCorporateRole
                ? this.populateMenu(dashboardMenuCorporate)
                : this.populateMenu(dashboardMenu)}
            </div>
            <div className="nav-group show">
              <div className="nav-label" onClick={this.toggleMenu}>
                Metro Links
              </div>
              {isCorporateRole
                ? this.populateMenu(applicationsCorporate)
                : this.populateMenu(applicationsMenu)}
            </div>
          </div>
        )}
  
        {!isCorporateRole && !isEnroleeRole && (
          <div>
            <div className="nav-group show">
              <div className="nav-label" onClick={this.toggleMenu}>
                Dashboard
              </div>
              {this.populateMenu(adminMenu)}
            </div>
          </div>
        )}
  
        <div className="nav-group show">
          <div className="nav-label" onClick={this.toggleMenu}>
            My Account Settings
          </div>
          {this.populateMenu(pagesMenu)}
        </div>
      </React.Fragment>
    );
  }
  
}

window.addEventListener("click", function (e) {
  // Close sidebar footer menu when clicked outside of it
  let tar = e.target;
  let sidebar = document.querySelector(".sidebar");
  if (!tar.closest(".sidebar-footer") && sidebar) {
    sidebar.classList.remove("footer-menu-show");
  }

  // Hide sidebar offset when clicked outside of sidebar
  if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
    document.querySelector("body").classList.remove("sidebar-show");
  }
});

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("sidebar-skin");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-sidebar", skinMode);
  }
});
