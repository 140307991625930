import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Table,
  Row,
  Col,
  Card,
  Nav,
  Form,
} from "react-bootstrap";
import HeaderMobile from "../../layouts/HeaderMobile";
import Prism from "prismjs";
import Avatar from "../../components/Avatar";
import img6 from "../../assets/img/img6.jpg";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AdminService } from "../../service/AdminService";

const RetailProfileModal = (props) => {
  const onClose = props.onClose;
  const planData = props.planData;
  const retailProfile = props.retailProfile;

  const [showModal, setShowModal] = useState(true);
  const [showSection, setShowSection] = useState(false);

  const [planName, setPlanName] = useState("");



  const [formData, setFormData] = useState({
    groupCode: "",
    planCode: "",
    effectiveDate: "",
    terminationDate: "",
    enrolleeProfileId: retailProfile.enrolleeProfileId,
  });

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    onClose();
  };


  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
    
      confirmButtonText: "OK",
      allowOutsideClick: true, // Prevent dismissing the alert by clicking outside
    });
  };
  const handleApproval = (item) => {
    handleChange("corporateUserSelectionId", item.id)
    setPlanName(item.plan.planName);
    setShowSection(true);
  };
  const handleSubmit = () => {


    console.log(formData);

    AdminService.approveRetailPlan( localStorage.getItem("token"),formData)
    .then((response) => {
      if (response.status === 200) {
        // setIsLoading(false);
        showAlert("Success", "Approval successful", "success");
      }
    })
    .catch((error) => {
      console.log(error);
      // setIsLoading(false);

      showAlert("Error", error.response.data.result, "error");
    });
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={showModal} centered size="xl">
          <Modal.Header closeButton onHide={handleClose2}></Modal.Header>

          <Modal.Body>
            
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5>{retailProfile.fullName}</h5>
                      <h5>{retailProfile.planName}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Group Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type in the Group Code from Medicloud"
                        name="Group Code"
                        value={formData.groupCode}
                        onChange={(event) =>
                        handleChange("groupCode", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Plan Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type in the Plan Code from Medicloud"
                        name="Plan Code"
                        value={formData.planCode}
                        onChange={(event) =>
                        handleChange("planCode", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Effective Date</Form.Label>
                      <Form.Control
                        name="effectiveDate"
                        type="date"
                        id="effectiveDate"
                        class="form-control input-class"
                        onChange={(event) =>
                        handleChange("effectiveDate", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Termination Date</Form.Label>
                      <Form.Control
                        name="terminationDate"
                        type="date"
                        id="terminationDate"
                        class="form-control input-class"
                        onChange={(event) =>
                        handleChange("terminationDate", event.target.value)
                      }
                      />
                    </Col>
                    <Row style={{ paddingTop: "15px", paddingLeft: "15%", paddingRight: "15%"  }}>
                      <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                      </Button>
                    </Row>
                  </Row>
                </Card.Body>
              </Card>
        
          </Modal.Body>

          <Modal.Footer>
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default RetailProfileModal;
