import React, { useEffect, useState } from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import UploadWidget from "../../components/utils/UploadWidget";
import DatePicker from 'react-datepicker';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import {format} from "date-fns";
import { EnrolleeService } from "../../service/EnrolleeService";
import Swal from "sweetalert2";
import { stateOptions } from "../../data/StatesAndCities";

export default function CorporateSettings() {
    const [selectedDay, setSelectedDay] = useState(null);
    const [url, updateUrl] = useState();
    const [error, updateError] = useState();
    const [houseAddress, setHouseAddress] = useState("");
    const [state, setState] = useState("");
    const [gender, setGender] = useState("");

    const [formData, setFormData] = useState([{ firstName: '', lastName: '', email: '', phoneNumber: '' }]);

    const handleInputChange = (index, e) => {
      const { name, value } = e.target;
      const updatedFormData = [...formData];
      updatedFormData[index][name] = value;
      setFormData(updatedFormData);
    };
  
    const handleAddForm = () => {
      setFormData([...formData, { firstName: '', lastName: '', email: '', phoneNumber: '' }]);
    };
  
    const handleRemoveForm = (index) => {
      const updatedFormData = [...formData];
      updatedFormData.splice(index, 1);
      setFormData(updatedFormData);
    };




    

    const showAlert = () => {
        Swal.fire({
          title: 'Are you sure?',
          text: ' Kindly ensure that your details are correct',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {
             handleSubmit();
             console.log("confirmed")
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Action canceled', 'Your action was canceled.', 'error');
          }
        });
      };


  

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ];





    function handleSubmit() {
       

        const formDto = {
            address: houseAddress + "," + state,
            provider:"" ,
            gender: gender,
            dateOfBirth: selectedDay,
            imageUrl: url,
        };

        EnrolleeService.editCorporateProfile(localStorage.getItem("token"),formDto)
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                Swal.fire('Action successful', 'Profile has been updated', 'success');
            }
          })
          .catch((error) => {
            console.log(error);
            Swal.fire('Action failed', error.response.data.result, 'error');
          });

           

    }
    const handleEmployeeFormSubmit = () => {
       // Check if any form fields are blank
  const isAnyFieldBlank = formData.some(
    (data) => !data.firstName || !data.lastName || !data.email || !data.phoneNumber
  );

  if (isAnyFieldBlank) {
    Swal.fire('Action failed', "Please fill in all fields", 'error');
    return;
  }

  // Continue with the submission logic
  console.log(formData);
      };
    return(
        <React.Fragment>


            <Card className="card-post mt-4">
                <Card.Header>
                    <Card.Title>Company Information</Card.Title>
                    {/* <Card.Text>
                        Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                        aut odit aut fugit.
                    </Card.Text> */}
                </Card.Header>
                <Card.Body className="p-0">
                    <div className="setting-item">
                        <Row className="g-2 align-items-center">
                            <Col md="5">
                                <h6>Address</h6>
                                <p>Your current location</p>
                            </Col>
                            <Col md>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address"
                                    value={houseAddress}
                                    onChange={(event) => setHouseAddress( event.target.value)}
                                />
                                <br/>
                                <Form.Select
                                    value={state}
                                    onChange={(event) => setState( event.target.value)}
                                >
                                    <option value="">Select State</option>
                                    {stateOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            <Card.Footer>
                <Row className="g-2 align-items-center">
                    <Col md="5">
                    </Col>
                    <Col md>
                        <Button onClick={showAlert} className="btn-sign">Submit</Button>

                    </Col>
                </Row>

            </Card.Footer>
            </Card>

        </React.Fragment>
    );
}