import React, { useEffect, useState } from "react";
import { Modal,Button,Row, Col } from "react-bootstrap";
import HeaderMobile from "../../layouts/HeaderMobile";
import Prism from "prismjs";
import BusinessPlanCart from "./BusinessPlanCart";

const  CorporateDialog = (props) => {
    const onClose = props.onClose;
    const [businessCategory,setBusinessCategory] = useState("");
    const [showModal, setShowModal] = useState(true);
    const [showCanvas, setShowCanvas] = useState(false);

    useEffect(() => {
        Prism.highlightAll();
      }, []);
    
      const handleClose2 = () => {
       onClose ();
      };


      const handleBusinessSelection = (category) => {
        setBusinessCategory(category);
        setShowCanvas(true);
      }
      
    
    return (
        <React.Fragment>

<HeaderMobile />
      <div className="main main-docs">
      {!showCanvas &&  (
        <Modal show={showModal} centered size="m">
          <Modal.Header closeButton onHide={handleClose2}>
            
          </Modal.Header>

          <Modal.Body>
           
              <div className="text-center mb-4">
                <h5>Select Business Category</h5>
                <br />
                <Row>
                 <Col>
                 <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => handleBusinessSelection("sme")}
                  >
                    Workforce below 20
                  </Button>
                 </Col>

                 <Col>
                 <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => handleBusinessSelection("Corporate Category")}
                  >
                    Workforce above 20
                  </Button>
                 </Col>

                </Row>
            
              </div>
       

      
          </Modal.Body>

          <Modal.Footer>
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>)}
      {showCanvas &&  (<BusinessPlanCart   onClose={() => handleClose2()} businessCategory= {businessCategory}/>)}
      </div>

        </React.Fragment>
    )

   
}
export default CorporateDialog;