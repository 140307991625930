import React from "react";
import { Link } from "react-router-dom";

import Img1 from "../assets/img/img10.jpg";
import Img2 from "../assets/img/img11.jpg";
import Img3 from "../assets/img/img14.jpg";
import Img4 from "../assets/img/img15.jpg";

// eslint-disable-next-line
export default Notification = [
  {
    "avatar": (<img src={Img1} alt="Your Avatar"/>),
    "text": (
      <React.Fragment>
        <strong>Hello!</strong> We are MetroHealth, and we care deeply about your well-being. 
        <br/>
        We're here to support you every step of the way. Please don't hesitate to contact us if you have any questions or concerns.
      </React.Fragment>
    ),
    "date": "Aug 20 08:55am",
    "status": "online"
  }
];

