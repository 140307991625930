import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Prism from "prismjs";
import HeaderMobile from "../layouts/HeaderMobile";
import { Image, Modal, Button, Spinner } from "react-bootstrap";
import success from "../assets/img/success_icon.png";
import Signin from "../pages/Signin";
import { PlanService } from "../service/PlanService";
import { Form } from "react-bootstrap";
import {replaceBehavior} from "@testing-library/user-event/dist/keyboard/plugins";
import Swal from "sweetalert2";


export default function PlanPurchaseModal(props) {
  // let ZestPayPop;
  const plan = props.plan;
  const onClose = props.onClose;
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(true);
  const [gatewayResponse, setGatewayResponse] = useState(null);
  const [zestData, setZestData] = useState(null); //
  const [step, setStep] = useState(0);
  const [dependantSize, setDependantSize] = useState(0);
  const [selectedRecipient, setSelectedRecipient] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
     const destinaltion = 'payment-history';





  const handleSuccessfulPayment= () => {
      const destinaltion = 'payment-history';
    navigate(`/profile/individual?tab=${destinaltion}`, { replace: true });
  }

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: icon === "success", // Show the "Login" button only if the icon is "success"
      confirmButtonText: "OK",
      allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the "OK" button click (if needed)
        if (icon === "success") {
          handleSuccessfulPayment();
        }

      } else if (result.dismiss === Swal.DismissReason.cancel) {
      
        if (icon === "success") {
          handleSuccessfulPayment();
        }
      }
    });
  };

  const handleFailedPayment = () => {
    onClose();
  }



  const handleRecipientSelection = (recipient) => {
    setSelectedRecipient(recipient);
    if (recipient === "family") {
      setStep(1);
    } else {
      setStep(2);
    }
  };

  const handleBack = () => {
    if (selectedRecipient === "family") {
      setStep(step - 1);
    } else {
      setStep(0);
    }
  };

  const handleNext = () => {
    setStep(2);
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    onClose();
    // navigate("/dashboard"); // Redirect to dashboard or the desired page after closing the modal
  };

  const handlePayment = async () => {


  

    setIsLoading(true);
    const proposedAmount = dependantSize > 0 && selectedRecipient === "family"
    ? (plan.premium * dependantSize) + plan.premium
    : plan.premium;
    const paymentRequest = {
        planId: plan.id,
        paymentGateway: "ZEST",
        numberOfDependants: dependantSize,
        amount:proposedAmount
      };

  
      
    PlanService.initializePayment(paymentRequest, localStorage.getItem("token"))
      .then((res) => {
        if (res.status === 200) {
          setGatewayResponse(res.data.result);

          

          try {
            // const newZestData = {
            //     email: res.data.result.customerEmail,
            //     amount: res.data.result.amount,
            //     publicKey: res.data.result.publicKey,
            //     // Add other relevant data as required by Zest
            // };

            // setZestData(newZestData);

            // Call the Zest payment function and handle payment processing
            let handler = window.ZestPayPop.setup({
              key: res.data.result.gatewayPublicKey,
              email: res.data.result.customerEmail,
              amount: res.data.result.amount * 100,
              onClose: function () {
                setIsLoading(false);
               
              },
              callback: function (response) {
               

                // Extract the transaction status
                const status = response.status;

                if (status ==="success"){
                  onClose();
                  showAlert(
                    "Payment has been successfully logged",
                    "You can check the status via the transaction history tab",
                    "success"
                  );

                  setIsLoading(false);

                }
                else
                {
                  handleFailedPayment();
                  setIsLoading(false);
                }
              },
            });
            handler.openIframe();
          } catch (error) {
            // Handle errors, if any
            console.error("Payment error:", error);
          }
        }
      })
      .catch((err) => {
        // Handle errors, e.g., show an error message
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={showModal} centered size="m">
          <Modal.Header closeButton onHide={handleClose2}>
            <Modal.Title>Purchase A Health Plan</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {step === 0 && (
              <div className="text-center mb-4">
                <h5>Select Recipient</h5>
                <br />
                <div className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => handleRecipientSelection("self")}
                  >
                    Purchase for Myself
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => handleRecipientSelection("family")}
                  >
                    Purchase for Family/Group
                  </Button>
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => handleRecipientSelection("other")}
                  >
                    Purchase for Someone Else
                  </Button>
                </div>
              </div>
            )}

            {step === 1 && selectedRecipient === "family" && (
              <div>
                <h5 className="text-center mb-4">
                  Beneficiaries 
                </h5>
                <Form.Group controlId="numDependents">
                  <Form.Label>Specify Number of Beneficiaries to add:</Form.Label>
                  <Form.Control
                    type="number"
                    value={dependantSize}
                    onChange={(e) => setDependantSize(e.target.value)}
                  />
                </Form.Group>
                <br />
                <div className="d-flex justify-content-between">
                  <Button
                    variant="secondary"
                    className="w-40"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    className="w-40"
                    onClick={handleNext}
                  >
                    Proceed
                  </Button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                <h5 className="text-center mb-4">Checkout Summary</h5>
                <p>Recipient: {selectedRecipient}</p>
                {selectedRecipient === "family" && (
                  <p>Number of Beneficiaries: {dependantSize}</p>
                )}
                <p>Plan Name: {plan.planName}</p>

                <p>
                  Plan Cost:{" "}
                  {dependantSize > 0 && selectedRecipient === "family"
                    ? (plan.premium * dependantSize) + plan.premium
                    : plan.premium}
                </p>

                {/* ... more plan details ... */}

                <div className="d-flex justify-content-between">
                  <Button
                    variant="secondary"
                    className="w-40"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  
                  {!isLoading && (
                    <Button
                    variant="primary"
                    className="w-40"
                    onClick={handlePayment}
                  >
                    Pay Online
                  </Button>
                  )}
                  {isLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}
