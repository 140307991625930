import React from "react";
import { Card, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { EnrolleeService } from "../service/EnrolleeService";

export default function PaymentHistory(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [paymentData, setPaymentData] = React.useState([]);
  const [showPaymentModal, setShowPaymentModal] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    EnrolleeService.getPaymentHistory(localStorage.getItem("token"))
      .then((res) => {
        if (res.status === 200) {
          setPaymentData(res.data.result);
          setIsLoading(false);
          console.log(res.data.result);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // Handle errors, e.g., show an error message
        console.log("Upload error:", err);
      });
  }, []);

  function getColorClass(transactionStatus) {
    switch (transactionStatus) {
      case "SUCCESS":
        return "success"; // Make sure you have a CSS class called "text-green" for this
      case "PENDING":
        return "info"; // Make sure you have a CSS class called "text-warning" for this
      case "FAILED":
        return "danger"; // Make sure you have a CSS class called "text-danger" for this
      default:
        return ""; // Default color or no color class
    }
  }

  return (
    <React.Fragment>
      <Card className="card-post mt-4 card-one">
        <Card.Header>
          <Card.Title as="h6">Transaction History</Card.Title>
          <Nav className="nav-icon nav-icon-sm ms-auto">
            <Nav.Link href="">
              <i className="ri-refresh-line"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-more-2-fill"></i>
            </Nav.Link>
          </Nav>
        </Card.Header>
        <Card.Body className="p-0">
          <ul className="people-group">
            {paymentData.map((item, index) => (
              <li className="people-item" key={index}>
                <div className="avatar">
                  <span className={"avatar-initial fs-20 bg-teal"}>
                    <i className="ri-coins-line"></i>
                  </span>
                </div>
                <div className="people-body">
                  <h6>
                    <Link to="">{item.invoiceDescription}</Link>
                  </h6>
                  <span>{item.dateInitiated}</span>
                </div>
                <div className="text-end">
                  <div className="fs-sm">{item.amount}</div>
                  <span
                    className={`d-block fs-xs text-${getColorClass(
                      item.transactionStatus
                    )}`}
                  >
                    {item.transactionStatus}
                  </span>
                </div>
              </li>
            ))}
          </ul>

          {/* {paymentData.length > 0 && (
            <div style={{ overflowX: 'auto' }}>
            <Table className="table-payment-history mb-0" responsive>
              <thead>
                <tr>
                  <th>&nbsp;</th>

                  <th>Description</th>
                  <th>Amount</th>
                  <th> Date Initiated </th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
              {paymentData.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-teal"}>
                          <i className="ri-coins-line"></i>
                        </span>
                      </div>
                      
                    </td>

                    <td>{item.invoiceDescription}</td>
                    <td>
                      <span className="ff-numerals">{item.amount}</span>
                    </td>
                    <td>
                      <span>{item.dateInitiated}</span>
                      
                    </td>
                    <td>
                      <span
                        className={`d-block fs-xs text-${getColorClass(
                          item.transactionStatus
                        )}`}
                      >
                        {item.transactionStatus}
                      </span>

                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
          )} */}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-center">
          <Link to="" className="fs-sm">
            Manage Transactions
          </Link>
        </Card.Footer>
      </Card>
    </React.Fragment>
  );
}
