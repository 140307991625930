import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Prism from "prismjs";
import HeaderMobile from "../layouts/HeaderMobile";
import { Image, Modal } from "react-bootstrap";
import success from "../assets/img/success_icon.png";
import Signin2 from "../pages/Signin2";

export default function SigninModal(props) {
  const destination = props.destination;

  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    setShowModal(false);
    window.location.reload();

    // navigate(destination, { replace: true });
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal   style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
            }}show={showModal} centered size="m">
          <Modal.Body
          
          >
            <Signin2 successAction={handleClose2} />
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
