import Swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import {
    Spinner,
  } from "react-bootstrap";

const showLoadingModal = () => {
    // Convert the React component to an HTML string
    const spinnerHtml = ReactDOMServer.renderToString(
      <Spinner animation="border" />
    );

    // Open Swal modal with the Bootstrap Spinner component
    Swal.fire({
      title: "Processing",
      html: `
            <div class="text-center">
              ${spinnerHtml}
            </div>
          `,
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  // Function to hide the loading modal
  const hideLoadingModal = () => {
    // Close the Swal modal
    Swal.close();
  };


  const test = [
    {
        "customerName": "BLESSING CHUKWUBUOGUM",
        "customerEmail": "mlmlmlm@metrohealthhmo.com",
        "customerPhoneNumber": "08134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 124,
                "createdDate": "2023-10-24T07:53:38.845+00:00",
                "updatedDate": null,
                "createdBy": "bchukwubuogum@metrohealthhmo.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 105,
                    "createdDate": "2023-10-24T07:50:43.760+00:00",
                    "updatedDate": "2023-10-24T07:51:26.963+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "mlmlmlm@metrohealthhmo.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-23T08:53:38.772079",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Temitope Adelaja",
        "customerEmail": "tadelaja@metrohealthhmo.com",
        "customerPhoneNumber": "08099452729",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 123,
                "createdDate": "2023-10-18T08:01:14.209+00:00",
                "updatedDate": null,
                "createdBy": "tadelaja@metrohealthhmo.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 101,
                    "createdDate": "2023-10-18T07:43:35.207+00:00",
                    "updatedDate": "2023-12-28T13:18:27.051+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 2,
                    "firstName": "Temitope",
                    "lastName": "Adelaja",
                    "email": "tadelaja@metrohealthhmo.com",
                    "phoneNumber": "08099452729",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-17T09:01:14.145837",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Adewale Oguntade",
        "customerEmail": "dewale1024@gmail.com",
        "customerPhoneNumber": "08076055967",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 153,
                "createdDate": "2023-11-08T16:05:52.928+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 140000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 1",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 126,
                    "createdDate": "2023-11-08T16:05:51.981+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Adewale",
                    "lastName": "Oguntade",
                    "email": "dewale1024@gmail.com",
                    "phoneNumber": "08076055967",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T17:05:52.925109",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 1,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Charles Yiase",
        "customerEmail": "yiasecharles@gmail.com",
        "customerPhoneNumber": "08188845461",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 182,
                "createdDate": "2023-11-26T12:11:50.683+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 350000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 4",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 162,
                    "createdDate": "2023-11-26T12:11:49.870+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Charles",
                    "lastName": "Yiase",
                    "email": "yiasecharles@gmail.com",
                    "phoneNumber": "08188845461",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-26T13:11:50.682998",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 4,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Zuwaira Ibrahim",
        "customerEmail": "Fadagafai@gmail.com",
        "customerPhoneNumber": "07063931493",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 165,
                "createdDate": "2023-11-19T13:10:42.970+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 156,
                    "createdDate": "2023-11-19T13:10:41.912+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Zuwaira",
                    "lastName": "Ibrahim",
                    "email": "Fadagafai@gmail.com",
                    "phoneNumber": "07063931493",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-19T14:10:42.966754",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Mfon Mfon",
        "customerEmail": "Mfon@gmail.com",
        "customerPhoneNumber": "07064827389",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 127,
                "createdDate": "2023-11-01T11:44:08.608+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 140000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 1",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 120,
                    "createdDate": "2023-11-01T11:44:07.521+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Mfon",
                    "lastName": "Mfon",
                    "email": "Mfon@gmail.com",
                    "phoneNumber": "07064827389",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T12:44:08.607306",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 1,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 128,
                "createdDate": "2023-11-01T11:45:26.765+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 2800000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 6",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 120,
                    "createdDate": "2023-11-01T11:44:07.521+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Mfon",
                    "lastName": "Mfon",
                    "email": "Mfon@gmail.com",
                    "phoneNumber": "07064827389",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T12:45:26.765041",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 6,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 129,
                "createdDate": "2023-11-01T11:47:37.209+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 4000000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 9",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 120,
                    "createdDate": "2023-11-01T11:44:07.521+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Mfon",
                    "lastName": "Mfon",
                    "email": "Mfon@gmail.com",
                    "phoneNumber": "07064827389",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T12:47:37.208806",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 9,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 154,
                "createdDate": "2023-11-08T16:07:04.029+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 650000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 4",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 120,
                    "createdDate": "2023-11-01T11:44:07.521+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Mfon",
                    "lastName": "Mfon",
                    "email": "Mfon@gmail.com",
                    "phoneNumber": "07064827389",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T17:07:04.028517",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 4,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 179,
                "createdDate": "2023-11-22T09:53:27.053+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 350000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 4",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 120,
                    "createdDate": "2023-11-01T11:44:07.521+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Mfon",
                    "lastName": "Mfon",
                    "email": "Mfon@gmail.com",
                    "phoneNumber": "07064827389",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-22T10:53:27.052936",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 4,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Precious Magege",
        "customerEmail": "pearlita.pm@gmail.com",
        "customerPhoneNumber": "09059178068",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 183,
                "createdDate": "2023-11-29T02:59:33.911+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 163,
                    "createdDate": "2023-11-29T02:59:32.902+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Precious",
                    "lastName": "Magege",
                    "email": "pearlita.pm@gmail.com",
                    "phoneNumber": "09059178068",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-29T03:59:33.907668",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Operations Department",
        "customerEmail": "bchukwubuogum@metrohealthhmo.com",
        "customerPhoneNumber": "2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": null,
        "corporate": false
    },
    {
        "customerName": "OLANIKE ADETUWO",
        "customerEmail": "adenijiolanike@yahoo.com",
        "customerPhoneNumber": "07033204949",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 136,
                "createdDate": "2023-11-03T20:19:32.691+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 123,
                    "createdDate": "2023-11-03T20:19:31.577+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "OLANIKE",
                    "lastName": "ADETUWO",
                    "email": "adenijiolanike@yahoo.com",
                    "phoneNumber": "07033204949",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-03T21:19:32.690873",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "BLESSING CHUKWUBUOGUM",
        "customerEmail": "blessing.kokok@elixonnltd.co.uk",
        "customerPhoneNumber": "08134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 119,
                "createdDate": "2023-10-03T06:56:06.914+00:00",
                "updatedDate": null,
                "createdBy": "blessing.chukwubuogum@elixonnltd.co.uk",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 39,
                    "createdDate": "2023-09-24T17:27:56.987+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "blessing.kokok@elixonnltd.co.uk",
                    "phoneNumber": "08134144985",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T07:56:06.848059",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 121,
                "createdDate": "2023-10-09T12:13:38.527+00:00",
                "updatedDate": null,
                "createdBy": "blessing.chukwubuogum@elixonnltd.co.uk",
                "updatedBy": null,
                "version": 0,
                "amount": 60000,
                "transactionDate": null,
                "description": "Premium for METRO MINI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 39,
                    "createdDate": "2023-09-24T17:27:56.987+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "blessing.kokok@elixonnltd.co.uk",
                    "phoneNumber": "08134144985",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-08T13:13:38.454364",
                "plan": {
                    "id": 3,
                    "createdDate": "2023-08-22T13:11:03.189+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 60000,
                    "description": "Coverage for essential medical services.",
                    "planName": "METRO MINI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MN-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "BLESSING CHUKWUBUOGUM",
        "customerEmail": "joseladweb@outlook.com",
        "customerPhoneNumber": "447470800093",
        "corporateInvoiceList": [
            {
                "id": 7,
                "createdDate": "2023-10-03T01:53:36.134+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 11570000,
                "transactionDate": null,
                "description": "Corporate User Plan Selections:\n===================================\nPlan: METRO MINI\nNumber of Enrollees: 88\n===================================\nPlan: METRO MIDI\nNumber of Enrollees: 88\n",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T02:53:35.830326",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 176,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 8,
                "createdDate": "2023-10-03T02:12:42.477+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 3540000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:12:42.122928",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 16,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 9,
                "createdDate": "2023-10-03T02:13:50.604+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1320000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:13:50.392268",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 4,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 10,
                "createdDate": "2023-10-03T02:15:10.352+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 800000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:15:10.101763",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 1,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 11,
                "createdDate": "2023-10-03T02:19:51.220+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:19:50.945642",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 1,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 12,
                "createdDate": "2023-10-03T02:39:35.969+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 960000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:39:35.314433",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 9,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 13,
                "createdDate": "2023-10-03T02:41:02.434+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 960000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:41:02.232435",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 9,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 14,
                "createdDate": "2023-10-03T02:41:15.862+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 960000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:41:15.662572",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 9,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 15,
                "createdDate": "2023-10-03T02:45:05.777+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 960000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T03:45:05.573042",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 9,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 16,
                "createdDate": "2023-10-03T13:35:10.788+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 120000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-02T14:35:10.713204",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 2,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 17,
                "createdDate": "2023-10-07T15:25:30.307+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 0,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-06T16:25:30.215216",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 0,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 18,
                "createdDate": "2023-10-08T07:40:39.877+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 300000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T08:40:39.35674",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 7,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 19,
                "createdDate": "2023-10-08T07:41:09.414+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 300000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T08:41:09.2053",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 7,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 20,
                "createdDate": "2023-10-08T07:46:36.443+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 300000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T08:46:36.197864",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 7,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 21,
                "createdDate": "2023-10-08T12:54:36.121+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 241000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T13:54:35.85311",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 5,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 22,
                "createdDate": "2023-10-08T13:01:10.416+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 241000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T14:01:09.956797",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 5,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 23,
                "createdDate": "2023-10-08T13:07:51.240+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 241000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T14:07:50.936043",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 5,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 24,
                "createdDate": "2023-10-08T13:48:44.584+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 393000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T14:48:44.309864",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 9,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 25,
                "createdDate": "2023-10-08T14:14:17.436+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 76000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T15:14:17.22489",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 2,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 26,
                "createdDate": "2023-10-08T14:16:45.895+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 38000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-07T15:16:45.672839",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 1,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 29,
                "createdDate": "2023-10-09T12:58:38.345+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 760000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-08T13:58:38.276186",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 20,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 30,
                "createdDate": "2023-10-09T13:00:33.125+00:00",
                "updatedDate": "2023-10-09T13:00:56.124+00:00",
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": "Anonymous",
                "version": 1,
                "amount": 2000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-08T14:00:33.059564",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 20,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 35,
                "createdDate": "2023-10-18T19:22:05.055+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 4939999.7,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-17T20:22:04.599521",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 40,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 36,
                "createdDate": "2023-10-18T19:32:32.816+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1919000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-17T20:32:32.040714",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 28,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 37,
                "createdDate": "2023-10-20T08:36:56.573+00:00",
                "updatedDate": null,
                "createdBy": "joseladweb@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1437667,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 43,
                    "createdDate": "2023-10-01T12:06:29.733+00:00",
                    "updatedDate": "2023-10-01T14:25:42.330+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 7,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "joseladweb@outlook.com",
                    "phoneNumber": "447470800093",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-19T09:36:56.50129",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 22,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            null
        ],
        "retailInvoiceList": null,
        "corporate": true
    },
    {
        "customerName": "Essu Blessing",
        "customerEmail": "bleckchufks@gmail.com",
        "customerPhoneNumber": "+2348134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 109,
                "createdDate": "2023-09-12T10:39:57.473+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 520000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 4",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 6,
                    "createdDate": "2023-08-20T21:31:16.706+00:00",
                    "updatedDate": "2023-08-20T21:31:38.681+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Essu",
                    "lastName": "Blessing",
                    "email": "bleckchufks@gmail.com",
                    "phoneNumber": "+2348134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T11:39:57.409987",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 4,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 110,
                "createdDate": "2023-09-12T10:41:01.094+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 2000000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 5",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 6,
                    "createdDate": "2023-08-20T21:31:16.706+00:00",
                    "updatedDate": "2023-08-20T21:31:38.681+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Essu",
                    "lastName": "Blessing",
                    "email": "bleckchufks@gmail.com",
                    "phoneNumber": "+2348134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T11:41:01.030321",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 5,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 115,
                "createdDate": "2023-09-15T10:00:27.133+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 6,
                    "createdDate": "2023-08-20T21:31:16.706+00:00",
                    "updatedDate": "2023-08-20T21:31:38.681+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Essu",
                    "lastName": "Blessing",
                    "email": "bleckchufks@gmail.com",
                    "phoneNumber": "+2348134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-15T11:00:27.05617",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 116,
                "createdDate": "2023-09-15T10:00:40.190+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1600000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 3",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 6,
                    "createdDate": "2023-08-20T21:31:16.706+00:00",
                    "updatedDate": "2023-08-20T21:31:38.681+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Essu",
                    "lastName": "Blessing",
                    "email": "bleckchufks@gmail.com",
                    "phoneNumber": "+2348134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-15T11:00:40.126256",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 3,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Y H",
        "customerEmail": "G@gmail.com",
        "customerPhoneNumber": "7064949852",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 131,
                "createdDate": "2023-11-01T11:51:25.028+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 520000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 3",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 121,
                    "createdDate": "2023-11-01T11:51:24.093+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Y",
                    "lastName": "H",
                    "email": "G@gmail.com",
                    "phoneNumber": "7064949852",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T12:51:25.028095",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 3,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Ndu Ivok",
        "customerEmail": "dunnimail2015@gmail.com",
        "customerPhoneNumber": "08065688383",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 125,
                "createdDate": "2023-10-30T04:21:16.858+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 114,
                    "createdDate": "2023-10-30T04:21:14.990+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Ndu",
                    "lastName": "Ivok",
                    "email": "dunnimail2015@gmail.com",
                    "phoneNumber": "08065688383",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-11-29T05:21:16.838481",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Malak Mroud",
        "customerEmail": "Ahmad.chalhoubb@gmail.com",
        "customerPhoneNumber": "08103284690",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 186,
                "createdDate": "2023-12-06T17:51:27.763+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 166,
                    "createdDate": "2023-12-06T17:51:26.663+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Malak",
                    "lastName": "Mroud",
                    "email": "Ahmad.chalhoubb@gmail.com",
                    "phoneNumber": "08103284690",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2024-01-05T18:51:27.760566",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "yalaju foluke",
        "customerEmail": "FOLUXIE@GMAIL.COM",
        "customerPhoneNumber": "07032694059",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 167,
                "createdDate": "2023-11-20T12:25:18.035+00:00",
                "updatedDate": null,
                "createdBy": "FOLUXIE@GMAIL.COM",
                "updatedBy": null,
                "version": 0,
                "amount": 7000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 6",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 157,
                    "createdDate": "2023-11-20T12:20:13.911+00:00",
                    "updatedDate": "2023-11-20T12:21:26.560+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "yalaju",
                    "lastName": "foluke",
                    "email": "FOLUXIE@GMAIL.COM",
                    "phoneNumber": "07032694059",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T13:25:18.006039",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 6,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 175,
                "createdDate": "2023-11-20T12:37:42.653+00:00",
                "updatedDate": null,
                "createdBy": "FOLUXIE@GMAIL.COM",
                "updatedBy": null,
                "version": 0,
                "amount": 1000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 157,
                    "createdDate": "2023-11-20T12:20:13.911+00:00",
                    "updatedDate": "2023-11-20T12:21:26.560+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "yalaju",
                    "lastName": "foluke",
                    "email": "FOLUXIE@GMAIL.COM",
                    "phoneNumber": "07032694059",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T13:37:42.622135",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Israel Ogbeide",
        "customerEmail": "joelogbe@gmail.com",
        "customerPhoneNumber": "08038612586",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 189,
                "createdDate": "2023-12-23T00:28:41.468+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 280000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 3",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 169,
                    "createdDate": "2023-12-23T00:28:40.135+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Israel",
                    "lastName": "Ogbeide",
                    "email": "joelogbe@gmail.com",
                    "phoneNumber": "08038612586",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2024-01-22T01:28:41.463458",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 3,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Faith Kivunza",
        "customerEmail": "faithmawia16@gmail.com",
        "customerPhoneNumber": "0795126462",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 135,
                "createdDate": "2023-11-03T15:31:05.538+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 140000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 1",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 122,
                    "createdDate": "2023-11-03T15:31:03.665+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Faith",
                    "lastName": "Kivunza",
                    "email": "faithmawia16@gmail.com",
                    "phoneNumber": "0795126462",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-03T16:31:05.533375",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 1,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Michael Akinyomi",
        "customerEmail": "bleckcorp@gmail.com",
        "customerPhoneNumber": "2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 141,
                "createdDate": "2023-11-08T09:24:44.151+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T10:24:44.121225",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 142,
                "createdDate": "2023-11-08T09:25:04.485+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 26000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 25",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T10:25:04.45485",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 25,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 147,
                "createdDate": "2023-11-08T10:26:56.792+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T11:26:56.761135",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 148,
                "createdDate": "2023-11-08T10:27:04.251+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T11:27:04.221344",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 151,
                "createdDate": "2023-11-08T10:34:30.313+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for SWEET SIXTIES- PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T11:34:30.282819",
                "plan": {
                    "id": 20,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": null,
                    "planName": "SWEET SIXTIES- PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 152,
                "createdDate": "2023-11-08T10:34:41.647+00:00",
                "updatedDate": null,
                "createdBy": "bleckcorp@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 300000,
                "transactionDate": null,
                "description": "Premium for SWEET SIXTIES- MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 125,
                    "createdDate": "2023-11-08T10:03:11.162+00:00",
                    "updatedDate": "2023-11-08T09:11:01.590+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "bleckcorp@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-08T11:34:41.617234",
                "plan": {
                    "id": 19,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 300000,
                    "description": null,
                    "planName": "SWEET SIXTIES- MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Jide Omiyale",
        "customerEmail": "jidolconsul005@yahoo.com",
        "customerPhoneNumber": "+2348034241050",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 185,
                "createdDate": "2023-12-04T16:50:03.607+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 165,
                    "createdDate": "2023-12-04T16:50:02.513+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Jide",
                    "lastName": "Omiyale",
                    "email": "jidolconsul005@yahoo.com",
                    "phoneNumber": "+2348034241050",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2024-01-03T17:50:03.604617",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Testing Test",
        "customerEmail": "orientglam@outlook.com",
        "customerPhoneNumber": "+2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 158,
                "createdDate": "2023-11-09T10:21:42.868+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 8000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 7",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 128,
                    "createdDate": "2023-11-09T10:21:40.870+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Testing",
                    "lastName": "Test",
                    "email": "orientglam@outlook.com",
                    "phoneNumber": "+2348177294631",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-09T11:21:42.868142",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 7,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Olubunmi Olayiwola",
        "customerEmail": "Opebunmi85@gmail.com",
        "customerPhoneNumber": "08156110415",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 163,
                "createdDate": "2023-11-14T23:11:23.983+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 154,
                    "createdDate": "2023-11-14T23:11:19.700+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Olubunmi",
                    "lastName": "Olayiwola",
                    "email": "Opebunmi85@gmail.com",
                    "phoneNumber": "08156110415",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-15T00:11:23.979375",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Michael Akinyomi",
        "customerEmail": "ioio@exquistrains.com",
        "customerPhoneNumber": "2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 103,
                "createdDate": "2023-09-12T11:08:34.479+00:00",
                "updatedDate": "2023-09-12T11:08:34.851+00:00",
                "createdBy": "support@exquistrains.com",
                "updatedBy": "support@exquistrains.com",
                "version": 1,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 37,
                    "createdDate": "2023-09-12T09:22:07.578+00:00",
                    "updatedDate": "2023-09-12T09:36:11.878+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "ioio@exquistrains.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T10:47:11.73016",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 105,
                "createdDate": "2023-09-12T10:16:03.156+00:00",
                "updatedDate": "2023-09-12T10:16:03.294+00:00",
                "createdBy": "support@exquistrains.com",
                "updatedBy": "support@exquistrains.com",
                "version": 1,
                "amount": 60000,
                "transactionDate": null,
                "description": "Premium for METRO MINI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 37,
                    "createdDate": "2023-09-12T09:22:07.578+00:00",
                    "updatedDate": "2023-09-12T09:36:11.878+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "ioio@exquistrains.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T12:13:07.284176",
                "plan": {
                    "id": 3,
                    "createdDate": "2023-08-22T13:11:03.189+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 60000,
                    "description": "Coverage for essential medical services.",
                    "planName": "METRO MINI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MN-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 107,
                "createdDate": "2023-09-12T10:17:14.111+00:00",
                "updatedDate": "2023-09-12T10:17:14.241+00:00",
                "createdBy": "support@exquistrains.com",
                "updatedBy": "support@exquistrains.com",
                "version": 1,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 37,
                    "createdDate": "2023-09-12T09:22:07.578+00:00",
                    "updatedDate": "2023-09-12T09:36:11.878+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "ioio@exquistrains.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T11:16:22.824432",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 108,
                "createdDate": "2023-09-12T10:17:46.178+00:00",
                "updatedDate": null,
                "createdBy": "support@exquistrains.com",
                "updatedBy": null,
                "version": 0,
                "amount": 800000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 2",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 37,
                    "createdDate": "2023-09-12T09:22:07.578+00:00",
                    "updatedDate": "2023-09-12T09:36:11.878+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "ioio@exquistrains.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-12T11:17:46.114826",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 2,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Omokemi Ezeudu",
        "customerEmail": "Kemmyj8@gmail.com",
        "customerPhoneNumber": "09136590372",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 160,
                "createdDate": "2023-11-09T11:21:06.541+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 131,
                    "createdDate": "2023-11-09T11:21:05.750+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Omokemi",
                    "lastName": "Ezeudu",
                    "email": "Kemmyj8@gmail.com",
                    "phoneNumber": "09136590372",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-09T12:21:06.54159",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Bisayoolawuyi@gmail.com Olawuyi",
        "customerEmail": "Bisayoolawuyi@gmail.com",
        "customerPhoneNumber": "07055496813",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 181,
                "createdDate": "2023-11-23T21:28:43.006+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 161,
                    "createdDate": "2023-11-23T21:28:42.187+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Bisayoolawuyi@gmail.com",
                    "lastName": "Olawuyi",
                    "email": "Bisayoolawuyi@gmail.com",
                    "phoneNumber": "07055496813",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-23T22:28:43.00585",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Michael Akinyomi",
        "customerEmail": "essublessing1@gmail.com",
        "customerPhoneNumber": "2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 132,
                "createdDate": "2023-11-01T12:12:54.742+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 650000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 4",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 12,
                    "createdDate": "2023-09-04T18:07:04.872+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "essublessing1@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T13:12:54.742485",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 4,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 134,
                "createdDate": "2023-11-01T12:16:32.996+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 12,
                    "createdDate": "2023-09-04T18:07:04.872+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "essublessing1@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-01T13:16:32.9955",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Aderonke Solesi",
        "customerEmail": "Royalgold@gmail.com",
        "customerPhoneNumber": "07031963949",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 184,
                "createdDate": "2023-11-30T17:39:35.551+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 164,
                    "createdDate": "2023-11-30T17:39:34.735+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Aderonke",
                    "lastName": "Solesi",
                    "email": "Royalgold@gmail.com",
                    "phoneNumber": "07031963949",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-30T18:39:35.551038",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Robert Rob",
        "customerEmail": "Carl969650@gmail.com",
        "customerPhoneNumber": "2169781368",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 187,
                "createdDate": "2023-12-15T16:05:43.469+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 130000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 167,
                    "createdDate": "2023-12-15T16:05:42.249+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Robert",
                    "lastName": "Rob",
                    "email": "Carl969650@gmail.com",
                    "phoneNumber": "2169781368",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2024-01-14T17:05:43.46517",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Blessing Chukwubuogum",
        "customerEmail": "essuchristian@gmail.com",
        "customerPhoneNumber": "08134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 96,
                "createdDate": "2023-09-08T21:08:38.604+00:00",
                "updatedDate": null,
                "createdBy": "essuchristian@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 120000,
                "transactionDate": null,
                "description": "Premium for METRO MINI\nPlan Type: RETAIL_STANDARD\nDependant Count: 2",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 34,
                    "createdDate": "2023-09-08T21:03:16.626+00:00",
                    "updatedDate": "2023-09-08T21:07:10.170+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Blessing",
                    "lastName": "Chukwubuogum",
                    "email": "essuchristian@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-08T22:08:38.537693",
                "plan": {
                    "id": 3,
                    "createdDate": "2023-08-22T13:11:03.189+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 60000,
                    "description": "Coverage for essential medical services.",
                    "planName": "METRO MINI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MN-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 2,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Michael Akinyomi",
        "customerEmail": "dd@gmail.com",
        "customerPhoneNumber": "2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 95,
                "createdDate": "2023-09-08T14:58:03.296+00:00",
                "updatedDate": null,
                "createdBy": "gadartech@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 33,
                    "createdDate": "2023-09-08T14:34:22.093+00:00",
                    "updatedDate": "2023-09-08T14:56:43.493+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "dd@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-08T15:58:03.098017",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 112,
                "createdDate": "2023-09-13T19:22:18.781+00:00",
                "updatedDate": null,
                "createdBy": "gadartech@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 60000,
                "transactionDate": null,
                "description": "Premium for METRO MINI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 33,
                    "createdDate": "2023-09-08T14:34:22.093+00:00",
                    "updatedDate": "2023-09-08T14:56:43.493+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "dd@gmail.com",
                    "phoneNumber": "2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-13T20:22:18.71705",
                "plan": {
                    "id": 3,
                    "createdDate": "2023-08-22T13:11:03.189+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 60000,
                    "description": "Coverage for essential medical services.",
                    "planName": "METRO MINI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MN-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "BLESSING CHUKWUBUOGUM",
        "customerEmail": "dfdf@metrohealthhmo.com",
        "customerPhoneNumber": "08134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 94,
                "createdDate": "2023-09-06T10:14:19.960+00:00",
                "updatedDate": null,
                "createdBy": "bchukwubuogum@metrohealthhmo.com",
                "updatedBy": null,
                "version": 0,
                "amount": 60000,
                "transactionDate": null,
                "description": "Premium for METRO MINI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 30,
                    "createdDate": "2023-09-06T09:57:11.281+00:00",
                    "updatedDate": "2023-09-06T09:57:50.547+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "BLESSING",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "dfdf@metrohealthhmo.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-06T11:14:19.71596",
                "plan": {
                    "id": 3,
                    "createdDate": "2023-08-22T13:11:03.189+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 60000,
                    "description": "Coverage for essential medical services.",
                    "planName": "METRO MINI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MN-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Timothy Xavier",
        "customerEmail": "txavier@metrohealthhmo.com",
        "customerPhoneNumber": "08139453988",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 156,
                "createdDate": "2023-11-09T09:30:16.638+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 390000,
                "transactionDate": null,
                "description": "Premium for METRO MAXI\nPlan Type: RETAIL_STANDARD\nDependant Count: 2",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 127,
                    "createdDate": "2023-11-09T09:02:51.682+00:00",
                    "updatedDate": "2023-11-09T09:03:18.810+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Timothy",
                    "lastName": "Xavier",
                    "email": "txavier@metrohealthhmo.com",
                    "phoneNumber": "08139453988",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-09T10:30:16.638236",
                "plan": {
                    "id": 4,
                    "createdDate": "2023-08-22T13:11:27.969+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 130000,
                    "description": "Premium Coverage for your medical needs.",
                    "planName": "METRO MAXI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MX-RETAIL",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 2,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 157,
                "createdDate": "2023-11-09T10:19:14.205+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 4000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 3",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 127,
                    "createdDate": "2023-11-09T09:02:51.682+00:00",
                    "updatedDate": "2023-11-09T09:03:18.810+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Timothy",
                    "lastName": "Xavier",
                    "email": "txavier@metrohealthhmo.com",
                    "phoneNumber": "08139453988",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-09T11:19:14.195993",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 3,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "JACKSON CHUKWUBUOGUM",
        "customerEmail": "bleckchuks@gmail.com",
        "customerPhoneNumber": "08134144985",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 137,
                "createdDate": "2023-11-06T23:00:10.067+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 6000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 5",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 124,
                    "createdDate": "2023-11-06T22:33:09.392+00:00",
                    "updatedDate": "2023-11-14T10:53:24.421+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 3,
                    "firstName": "JACKSON",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "bleckchuks@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-07T00:00:10.023936",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 5,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 170,
                "createdDate": "2023-11-20T12:31:03.378+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 770000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 10",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 124,
                    "createdDate": "2023-11-06T22:33:09.392+00:00",
                    "updatedDate": "2023-11-14T10:53:24.421+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 3,
                    "firstName": "JACKSON",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "bleckchuks@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T13:31:03.345961",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 10,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 174,
                "createdDate": "2023-11-20T12:37:34.272+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 1000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 124,
                    "createdDate": "2023-11-06T22:33:09.392+00:00",
                    "updatedDate": "2023-11-14T10:53:24.421+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 3,
                    "firstName": "JACKSON",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "bleckchuks@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T13:37:34.240913",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 180,
                "createdDate": "2023-11-23T08:51:05.756+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 2400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 5",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 124,
                    "createdDate": "2023-11-06T22:33:09.392+00:00",
                    "updatedDate": "2023-11-14T10:53:24.421+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 3,
                    "firstName": "JACKSON",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "bleckchuks@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-23T09:51:05.645275",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 5,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 188,
                "createdDate": "2023-12-20T08:16:03.314+00:00",
                "updatedDate": null,
                "createdBy": "bleckchuks@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 124,
                    "createdDate": "2023-11-06T22:33:09.392+00:00",
                    "updatedDate": "2023-11-14T10:53:24.421+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 3,
                    "firstName": "JACKSON",
                    "lastName": "CHUKWUBUOGUM",
                    "email": "bleckchuks@gmail.com",
                    "phoneNumber": "08134144985",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2024-01-19T09:16:03.20058",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Adeolu Adepoyigi",
        "customerEmail": "aadepoyigi@metrohealthhmo.com",
        "customerPhoneNumber": "08083810712",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 162,
                "createdDate": "2023-11-10T09:20:04.524+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 6000,
                "transactionDate": null,
                "description": "Premium for TEST PLAN\nPlan Type: SME_STANDARD\nDependant Count: 5",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 152,
                    "createdDate": "2023-11-10T09:08:31.830+00:00",
                    "updatedDate": "2023-11-10T09:09:33.866+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Adeolu",
                    "lastName": "Adepoyigi",
                    "email": "aadepoyigi@metrohealthhmo.com",
                    "phoneNumber": "08083810712",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-10T10:20:04.523704",
                "plan": {
                    "id": 18,
                    "createdDate": "2023-10-08T14:23:22.617+00:00",
                    "updatedDate": null,
                    "createdBy": "bleckchuks@gmail.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 1000,
                    "description": null,
                    "planName": "TEST PLAN",
                    "planType": "SME_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": null,
                    "planCode": null,
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "FAMILY",
                "dependentCapacity": 5,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "TXG TXG",
        "customerEmail": "reachtxg@gmail.com",
        "customerPhoneNumber": "08139453988",
        "corporateInvoiceList": [
            {
                "id": 48,
                "createdDate": "2023-11-20T13:06:58.323+00:00",
                "updatedDate": null,
                "createdBy": "reachtxg@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 2000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 159,
                    "createdDate": "2023-11-20T13:04:36.269+00:00",
                    "updatedDate": "2023-11-20T13:05:00.013+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "TXG",
                    "lastName": "TXG",
                    "email": "reachtxg@gmail.com",
                    "phoneNumber": "08139453988",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": true,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T14:06:58.295042",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 2,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            },
            {
                "id": 49,
                "createdDate": "2023-11-20T13:10:43.460+00:00",
                "updatedDate": null,
                "createdBy": "reachtxg@gmail.com",
                "updatedBy": null,
                "version": 0,
                "amount": 2000,
                "transactionDate": null,
                "description": null,
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 159,
                    "createdDate": "2023-11-20T13:04:36.269+00:00",
                    "updatedDate": "2023-11-20T13:05:00.013+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "TXG",
                    "lastName": "TXG",
                    "email": "reachtxg@gmail.com",
                    "phoneNumber": "08139453988",
                    "isVerified": true,
                    "role": "ROLE_CORPORATE",
                    "isUnderCorporate": true,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-20T14:10:43.432313",
                "plan": null,
                "paymentType": null,
                "totalEmployeeCount": 2,
                "paymentLog": [],
                "receipt": [],
                "corporateUserPlanSelectionList": []
            }
        ],
        "retailInvoiceList": null,
        "corporate": true
    },
    {
        "customerName": "Olga Lungin",
        "customerEmail": "Olga.lungin@outlook.com",
        "customerPhoneNumber": "7325709149",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 164,
                "createdDate": "2023-11-15T18:50:50.878+00:00",
                "updatedDate": null,
                "createdBy": "anonymousUser",
                "updatedBy": null,
                "version": 0,
                "amount": 400000,
                "transactionDate": null,
                "description": "Premium for METRO PPP\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 155,
                    "createdDate": "2023-11-15T18:50:46.289+00:00",
                    "updatedDate": null,
                    "createdBy": "anonymousUser",
                    "updatedBy": null,
                    "version": 0,
                    "firstName": "Olga",
                    "lastName": "Lungin",
                    "email": "Olga.lungin@outlook.com",
                    "phoneNumber": "7325709149",
                    "isVerified": false,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": false,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-12-15T19:50:50.874111",
                "plan": {
                    "id": 5,
                    "createdDate": "2023-08-22T13:14:47.029+00:00",
                    "updatedDate": null,
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 400000,
                    "description": "Topnotch Coverage for your Family",
                    "planName": "METRO PPP",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "P-R",
                    "principalMaxAge": null,
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    },
    {
        "customerName": "Michael Akinyomi",
        "customerEmail": "orientglamhairs@outlook.com",
        "customerPhoneNumber": "+2348177294631",
        "corporateInvoiceList": null,
        "retailInvoiceList": [
            {
                "id": 91,
                "createdDate": "2023-09-01T00:17:11.364+00:00",
                "updatedDate": "2023-09-01T00:17:11.980+00:00",
                "createdBy": "orientglamhairs@outlook.com",
                "updatedBy": "orientglamhairs@outlook.com",
                "version": 1,
                "amount": 70000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 0",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 7,
                    "createdDate": "2023-08-31T11:52:39.482+00:00",
                    "updatedDate": "2023-08-31T11:53:00.516+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "orientglamhairs@outlook.com",
                    "phoneNumber": "+2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-01T01:16:26.836894",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 0,
                "paymentLog": [],
                "receipt": []
            },
            {
                "id": 92,
                "createdDate": "2023-09-01T00:17:43.617+00:00",
                "updatedDate": null,
                "createdBy": "orientglamhairs@outlook.com",
                "updatedBy": null,
                "version": 0,
                "amount": 420000,
                "transactionDate": null,
                "description": "Premium for METRO MIDI\nPlan Type: RETAIL_STANDARD\nDependant Count: 6",
                "invoiceStatus": "PENDING",
                "appUser": {
                    "id": 7,
                    "createdDate": "2023-08-31T11:52:39.482+00:00",
                    "updatedDate": "2023-08-31T11:53:00.516+00:00",
                    "createdBy": "anonymousUser",
                    "updatedBy": "anonymousUser",
                    "version": 1,
                    "firstName": "Michael",
                    "lastName": "Akinyomi",
                    "email": "orientglamhairs@outlook.com",
                    "phoneNumber": "+2348177294631",
                    "isVerified": true,
                    "role": "ROLE_ENROLLEE",
                    "isUnderCorporate": null,
                    "enrolleeProfile": null,
                    "corporateProfile": null
                },
                "dueDate": "2023-10-01T01:17:43.291208",
                "plan": {
                    "id": 2,
                    "createdDate": "2023-08-22T13:09:33.933+00:00",
                    "updatedDate": "2023-08-22T13:52:05.000+00:00",
                    "createdBy": "ict@metrohealth.com",
                    "updatedBy": null,
                    "version": 0,
                    "premium": 70000,
                    "description": "Comprehensive coverage with additional benefits.",
                    "planName": "METRO MIDI",
                    "planType": "RETAIL_STANDARD",
                    "invoice": [],
                    "legacyId": null,
                    "groupName": "RETAIL_PRIVATE_HEALTH_PLAN",
                    "planCode": "MD-RETAIL",
                    "principalMaxAge": "METRO MIDI",
                    "principalMinAge": null,
                    "dependantMaxAge": null,
                    "dependantMinAge": null,
                    "groupId": null
                },
                "paymentType": "SINGLE",
                "dependentCapacity": 6,
                "paymentLog": [],
                "receipt": []
            }
        ],
        "corporate": false
    }
];



  export {showLoadingModal,hideLoadingModal,test};