import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Image,
  Alert,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import favicon from "../assets/img/metro health hmo final.png";
import { useState, useEffect } from "react";
import { RegistrationService } from "../service/RegistrationService";
import { useNavigate } from "react-router-dom";
import bg1 from "../assets/img/metrobackground.jpeg";
import { AuthService } from "../service/AuthService";
import Swal from "sweetalert2";
import { lgaList } from "../data/StatesAndCities";
import jwt_decode from "jwt-decode";

export default function BusinessRegistrationPage() {
  const navigate = useNavigate;
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVLoading, setIsVLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [automaticLogin, setAutomaticLogin] = useState(false);   

  
  useEffect(() => {
    const tokenRegex = /referrer=([^&]+)/;
    const currentUrl = window.location.href;
    const tokenMatch = currentUrl.match(tokenRegex);
    const match = tokenMatch ? tokenMatch[1] : null;
  
    if (match === "website-form") {
      setAutomaticLogin(true);
    }
  }, []);
  



  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    nameOfClient: "",
    industrySector: "",
    lga: "",
    state: "",
    addressOfClient: "",
  });

  // Define error message mapping
  const errorMessages = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Please provide a valid email address",
    phoneNumber: "A Valid Phone Number is required",
    password:
      " A Valid Password is required (between 6 to 20 alphanumeric characters)",
    confirmPassword: "Passwords do not match",
  };

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  // Validation functions for the current step
  const validateStep = () => {
    const errors = {};

    if (step === 0) {
      if (!formData.firstName.trim()) {
        errors.firstName = "First Name is required";
      }

      if (!formData.lastName.trim()) {
        errors.lastName = "Last Name is required";
      }

      if (!formData.email.trim()) {
        errors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        errors.email = "Invalid email format";
      }

      if (!formData.phoneNumber.trim()) {
        errors.phoneNumber = "Phone Number is required";
      }
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber = "Phone Number should only be digits";
      }
      if (formData.phoneNumber.trim().length < 11) {
        errors.phoneNumber = "Phone Number length must be more than 11 digits";
      }
    } else if (step === 3) {
      if (!formData.password.trim()) {
        errors.password = "Password is required";
      }

      if (!isValidPassword(formData.password.trim())) {
        errors.password = "Password is required";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Regular expression for a basic email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Test the email against the regular expression
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match only numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test if the phoneNumber contains only numeric characters
    return numericRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    // Check if the password length is between 6 and 20 characters
    return password.length >= 6 && password.length <= 20;
  };

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handlePrev = () => {
    prevStep();
  };


  const handleNext = () => {
    setShowError(true);
    if (validateStep()) {
      setStep(step + 1);

      return true;
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleStep = () => {
    setStep(0);
  }

  const handleRegister = (e) => {
    e.preventDefault();

  

    setIsLoading(true);

    // if (handleNext()) {
    RegistrationService.registerBusiness(formData)
      .then((response) => {
        //Navigate to the Dashboard page & set context to true
        if (response.status === 201) {
          setIsLoading(false);
          handleNext();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);

        showAlert("Error", error.response.data.result, "error");
      });
    // }
  };

  const handleVerification = (e) => {
    setIsLoading(true);
    RegistrationService.verifyOtp(formData, otp)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
      

          if (automaticLogin){
            showAlert(
              "Verification Successful",
              "Use the button below to select your plans",
              "success"
            );
          }
          else{
            showAlert(
              "Verification Successful",
              "Use the button below to login",
              "success"
            );
          }

          setIsLoading(false);
        }
      })
      .catch((error) => {
        showAlert("Verification Failed", error.response.data.result, "error");
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleResend = (e) => {
    setIsVLoading(true);
    AuthService.resendOTP(formData.email)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          showAlert("Sent Successfully", "Check your email", "info");

          setIsVLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsVLoading(false);
      });
  };

  const handleStateChange = (event) => {
    const newState = event;

    // Update the LGAs based on the selected state
    const newLGAs = lgaList[newState] || [];
    const lgaSelect = document.getElementById("lga");
    lgaSelect.innerHTML = ""; // Clear existing options

    // Add default option
    const defaultOption = document.createElement("option");
    defaultOption.value = "";
    defaultOption.text = "- Select -";
    lgaSelect.add(defaultOption);

    // Add LGAs to the select element
    newLGAs.forEach((lga) => {
      const option = document.createElement("option");
      option.value = lga;
      option.text = lga;
      lgaSelect.add(option);
    });
  };

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showConfirmButton:icon !== "success",
      showCancelButton: icon === "success", // Show the "Login" button only if the icon is "success"
      confirmButtonText: "OK",
      cancelButtonText: automaticLogin === true? "Select Plan" : "Login", // Label for the login button
      allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the "OK" button click (if needed)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle the "Login" button click (if the icon is "success")
        if (icon === "success") {

          if (automaticLogin) {  handleAutomaticLogin();}
         else{ // Redirect the user to the login page or perform the login action here
          window.location.href = "/login"; }
        }
      }
    });
  };


  const handleAutomaticLogin = async (e) => {

      const requestData = {
    email: formData.email,
    password: formData.password
  };

    AuthService.login(requestData)
        .then((response) => {

          //Navigate to the Dashboard page & set context to true
          if (response.status === 200) {
            const decodedToken = jwt_decode(response.data.result.token)

            const newDetails = {
              firstName: decodedToken.firstName,
              lastName: decodedToken.lastName,
              email: decodedToken.sub,
              role: decodedToken.roles[0],
              imageUrl:response.data.result.imageUrl,
              verifiedStatus:decodedToken.verifiedStatus,
              // Add more fields as needed
            };

            localStorage.setItem('token', response.data.result.token)
            localStorage.setItem('userDetails', JSON.stringify(newDetails));
            const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
            localStorage.setItem("expirationTime", expirationTime.toString());

            console.log(newDetails);

            // updateUserDetails(newDetails);
            console.log("login success");
            let destination = "/plans/business";

            if(decodedToken.roles[0]==="ROLE_ENROLLEE"){
               destination = "/dashboard/individual";
            }
                // navigate(destination, { replace: true });
                // setIsLoading(false);

                window.location.href =destination;

          }


        })
        .catch((error) => {
  
        });


  };



  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="6" xl="7" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Image
                src={favicon}
                fluid={true}
                width="250px"
                alt="..."
                className="mx-auto d-block text-center"
                style={{ marginBottom: "30px", alignContent: "" }}
              />

              {/* <Card.Title>Sign Up</Card.Title> */}
              <Card.Text className="mx-auto d-block text-center">
                It's easy to signup and only takes a minute.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              {step == 0 && (
                <div>
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          aria-label="First name"
                          value={formData.firstName} // Use the value from formData
                          onChange={(event) =>
                            handleChange("firstName", event.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          aria-label="Last name"
                          value={formData.lastName} // Use the value from formData
                          onChange={(event) =>
                            handleChange("lastName", event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Business Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your business email address"
                      value={formData.email} // Use the value from formData
                      onChange={(event) =>
                        handleChange("email", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Business Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your business phone number"
                      value={formData.phoneNumber} // Use the value from formData
                      onChange={(event) =>
                        handleChange("phoneNumber", event.target.value)
                      }
                    />
                  </div>

                  <Button
                    onClick={handleNext}
                    variant="primary"
                    className="btn-sign"
                  >
                    Proceed
                  </Button>
                </div>
              )}

              {step == 1 && (
                <div>
                  <div className="mb-3">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your business name"
                      aria-label="Business name"
                      value={formData.nameOfClient} // Use the value from formData
                      onChange={(event) =>
                        handleChange("nameOfClient", event.target.value)
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <Form.Label>Business Sector</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        handleChange("industrySector", e.target.value);
                      }}
                    >
                      <option value="">Choose an Option</option>
                      <option value="Information Technology">
                        Information Technology
                      </option>
                      <option value="Finance">Finance</option>
                      <option value="Healthcare">
                        Pharmaceutical/Healthcare
                      </option>
                      <option value="Education">Education</option>
                      <option value="Manufacturing">Manufacturing</option>
                      <option value="Retail">Retail</option>
                      <option value="Hospitality">Hospitality</option>
                      <option value="Real Estate">Real Estate</option>
                      <option value="Automotive">Automotive</option>
                      <option value="Telecommunications">
                        Telecommunications
                      </option>
                      <option value="Energy">Energy</option>
                      <option value="Entertainment">Media/Entertainment</option>
                      <option value="Construction">Construction</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Pharmaceutical">Pharmaceutical</option>
                      <option value="Transportation">Transportation</option>
                      <option value="Consulting">Consulting</option>

                      <option value="Non-Profit">Non-Profit</option>
                      <option value="Government">Government</option>
                      <option value="E-commerce">E-commerce</option>

                      <option value="Legal">Legal</option>
                    </Form.Select>
                  </div>

                  <Row className="gx-4">
                    <Col>
                      <Button onClick={handlePrev} variant="secondary">
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={handleNext} variant="primary">
                        Proceed
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}

              {step == 2 && (
                <div>
                  <div className="mb-3">
                    <Form.Label>
                      What state is {formData.nameOfClient} located?
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        handleChange("state", e.target.value);
                        handleStateChange(e.target.value);
                      }}
                    >
                      <option value="">Choose an Option</option>
                      {Object.keys(lgaList).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="mb-3">
                    <Form.Label>
                      What area is {formData.businessName} located?
                    </Form.Label>
                    <select
                      name="lga"
                      id="lga"
                      className="form-control select-lga"
                       onChange=
                      {(e) => {
                        handleChange("lga", e.target.value);
                      }}
                    >
                    
                     
                    </select>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Business Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your business address"
                      aria-label="Business address"
                      value={formData.addressOfClient} // Use the value from formData
                      onChange={(event) =>
                        handleChange("addressOfClient", event.target.value)
                      }
                    />
                  </div>

                  <Row className="gx-4">
                    <Col>
                      <Button onClick={handlePrev} variant="secondary">
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Button onClick={handleNext} variant="primary">
                        Proceed
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}

              {step == 3 && (
                <div>
                  <div className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <div
                      className="d-flex align-items-center"
                      style={{ position: "relative" }}
                    >
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        value={formData.password} // Use the value from formData
                        onChange={(event) =>
                          handleChange("password", event.target.value)
                        }
                      />
                      <Form.Check
                        type="switch"
                        checked={showPassword}
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          zIndex: 1,
                        }}
                        onChange={togglePasswordVisibility}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm your password"
                      value={formData.confirmPassword} // Use the value from formData
                      onChange={(event) =>
                        handleChange("confirmPassword", event.target.value)
                      }
                    />
                  </div>
                  <Row className="gx-4">
                    <Col>
                      <Button onClick={handlePrev} variant="secondary">
                        Back
                      </Button>
                    </Col>
                    <Col>
                      {!isLoading && (
                        <Button onClick={handleRegister} variant="primary">
                          Proceed
                        </Button>
                      )}
                      {isLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              {step == 4 && (
                <div className="text-center">
                  {" "}
                  {/* Center the OTP VERIFICATION */}
                  <div className="mb-3">
                    <Form.Label>OTP VERIFICATION</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the OTP sent to your email"
                      onChange={(event) => setOtp(event.target.value)}
                    />
                  </div>
                  <Row className="gx-4">
                    <Col>
                      <Button
                        onClick={handleStep}
                        variant="secondary"
                        size="sm"
                      >
                        Change my Email
                      </Button>
                    </Col>
                    <Col>
                      {!isVLoading && (
                        <Button onClick={handleResend} variant="info" size="sm">
                          RESEND OTP
                        </Button>
                      )}
                      {isVLoading && (
                        <Button className="btn-sign">
                          Processing...
                          <span />
                          <Spinner size="sm" animation="border" />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <br />
                  {!isLoading && (
                    <Button
                      onClick={handleVerification}
                      variant="primary"
                      className="btn-sign"
                    >
                      Verify OTP
                    </Button>
                  )}
                  {isLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                </div>
              )}
              <br />

              {showError && (
                <div>
                  {Object.keys(formErrors).map((field) => (
                    <Alert
                      variant="danger"
                      className="alert-outline d-flex align-items-center "
                    >
                      <i className="ri-information-line"></i>
                      <div key={field}>
                        {formErrors[field] && errorMessages[field]}
                      </div>
                    </Alert>
                  ))}
                </div>
              )}
            </Card.Body>

            <Card.Footer>
              Already have a business account? <Link to="/login">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          {/* <img src={bg1} className="auth-img" alt="" /> */}
          <Image
            src={
              "https://res.cloudinary.com/dpjni3o5l/image/upload/v1699864341/Metrohealth_akcziu.jpg"
            }
            fluid={true}
            // width="250px"
            alt="..."
            className="auth-img"
            // style={{ marginBottom: "30px",alignContent:"" }}
          ></Image>
        </Col>
      </Row>
    </div>
  );
}
