import React from "react";
import { useNavigate } from "react-router-dom";

function GetApp( ) {

    const navigate= useNavigate();

  // Your WhatsApp function
  function getMobileApp() {
    const url = `https://play.google.com/store/apps/details?id=com.metrohealth.mobile&pcampaignid=web_share`;
    window.open(url, "_blank");
  }

  React.useEffect(() => {
     getMobileApp();


    navigate("/dashboard/individual", { replace: true });
    
  }, []);

  return (
    <div>
    
    </div>
  );
}

export default GetApp;