import axios from "axios";
import jwtDecode from "jwt-decode";

const GETPROFILE = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/enrollee/info";
const GETCORPORATEPROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/info";
const GET_PAYMENT_HISTORY =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/history";
const VERIFY_PAYMENT =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/payments/callback/zest/";
const UPDATE_PROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/enrollee/edit/profile";
const UPDATE_CORPORATE_PROFILE =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/edit/profile";
const GETCORPORATEPLANS =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/plans";
class enrolleeService {
  // Check if the token is expired
  isTokenExpired(token) {
    if (token === null) {
      return true; 
  }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;


  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

  getProfile(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETPROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  getPlansForAProfile(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETCORPORATEPLANS, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }
  getCorporateProfile(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GETCORPORATEPROFILE, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  getPaymentHistory(token) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.get(GET_PAYMENT_HISTORY, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  editProfile(token, enrolleeRequest) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(UPDATE_PROFILE, enrolleeRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  editCorporateProfile(token, request) {
    return this.handleTokenExpiration(token).then(() => {
      return axios.post(UPDATE_CORPORATE_PROFILE, request, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

}

export const EnrolleeService = new enrolleeService();
