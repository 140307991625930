import axios from "axios";
import jwtDecode from "jwt-decode";

const ForgotPassword_API_BASE_URL = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/auth/forget-password";
const ResetPassword_API_BASE_URL = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/auth/reset-password/";
const LOGIN_API_BASE_URL = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/auth/login";
const LOGOUT_URL = "https://metroflowapp.azurewebsites.net/logout";
class authService{
    

    login (data) {
        return axios.post(LOGIN_API_BASE_URL, data, {
            headers: {
                "Content-Type": "application/json"
            }
        });

    }

    resendOTP (email) {
        return axios.get(`https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/register/resend-otp/?email=${email}`, {
            headers: {
                "Content-Type": "application/json"
            }
        });

    }

    forgotPassword(email) {
        return axios.post(ForgotPassword_API_BASE_URL, email,{
            headers: {
                "Content-Type": "application/json"
            }
        });
    }

    logout(token) {
        return axios({
            method: "post",
            url: LOGOUT_URL,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });
    }

    resetPassword(dto, verificationToken) {
        return axios.post(ResetPassword_API_BASE_URL + verificationToken, dto, {});
      }

    isTokenExpired (token) {
        const decodedToken = jwtDecode(token);
        if (decodedToken.exp) {
          const expirationTime = decodedToken.exp * 1000;
          const currentTime = new Date().getTime();
          return currentTime > expirationTime;
        }
        return true;
      }
}

export const AuthService = new authService();