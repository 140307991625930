import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Card, Col, Nav, Row, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EnrolleeService } from "../../service/EnrolleeService";

import Avatar from "../../components/Avatar";
import img6 from "../../assets/img/img6.jpg";
import blog from "../../assets/img/blog.png";
import ppp from "../../assets/img/ppp.jpg";
import Lottie from "lottie-react";
import animationData from "../../assets/animation/animation_lmawejkm.json";
import { AdminService } from "../../service/AdminService";
import CorporatePlanlistModal from "./CorporatePlanlistModal";
import RetailProfileModal from "./RetailProfileModal";

export default function RetailCustomers() {
  const [greeting, setGreeting] = useState("");
  const userDetailsJSON = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(userDetailsJSON);
  const [profileData, setProfileData] = useState([]);
    const [retailData, setRetailData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showRetailModal, setShowRetailModal] = useState(false);
  const [corporateprofile, setCorporateprofile] = useState(null);
   const [retailProfile, setRetailProfile] = useState(null);




    useEffect(() => {
    AdminService.getAllRetailProfiles(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          setRetailData(response.data.result);
          console.log(retailData);
          // setTimeout(() => {
          setLoading(false); // Set loading to false when your data is fetched
          // }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Emoji component to display emoji
  const Emoji = ({ symbol, label }) => (
    <span role="img" aria-label={label}>
      {symbol}
    </span>
  );

  useEffect(() => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour < 12) {
      setGreeting(`Good Morning`);
    } else if (currentHour < 18) {
      setGreeting(`Good Afternoon`);
    } else {
      setGreeting(`Good Evening`);
    }
  }, []);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  const [filteredData, setFilteredData] = useState([]);

  const handleViewPlans = (item) => {
    setFilteredData(item.corporateUserPlanSelectionList);
    setCorporateprofile(item);
    setShowModal(true);
  };
  const handleRetailViewPlan = (item) => {
    setRetailProfile(item);
    setShowRetailModal(true);
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Admin Dashboard
              </li>
            </ol>
            <br />
            <h4 className="main-title mb-0">
              {greeting} {userDetails ? userDetails.firstName : ""}{" "}
              <Emoji symbol="👋" label="smiley" />
            </h4>
            <br />
            {/* <h6>Welcome to your Dashboard</h6> */}
          </div>
        </div>

        {loading && (
          <div className={`fullscreen-loader ${loading ? "show" : "hide"}`}>
            <Lottie animationData={animationData} loop={true} autoPlay={true} />
          </div>
        )}

        {!loading && (
          <Row className="g-3">
            <Col sm="4">
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-primary"}>
                      <i className={"ri-bell-line"}></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">
                        {profileData.totalStaffCovered}
                      </h2>
                      <label className="card-label fs-sm fw-medium mb-1">
                        Total Corporate Customers{" "}
                      </label>
                      <div className="mutual-badge">
                        <ul>
                          {[img6, img6, img6, img6].map((avatar, ind) => (
                            <li key={ind}>
                              <Avatar img={avatar} />
                            </li>
                          ))}
                        </ul>
                        <label>
                          {profileData.totalStaffOnboarded} have been onboarded
                        </label>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="4">
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-primary"}>
                      <i className={"ri-bell-line"}></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">
                        {profileData.totalFamiliesCovered}
                      </h2>
                      <label className="card-label fs-sm fw-medium mb-1">
                        Total Retail Customers Covered{" "}
                      </label>
                      <div className="mutual-badge">
                        <ul>
                          {[img6, img6, img6, img6].map((avatar, ind) => (
                            <li key={ind}>
                              <Avatar img={avatar} />
                            </li>
                          ))}
                        </ul>
                        <label>
                          {profileData.familiesOnboarded} have been onboarded
                        </label>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="4">
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-primary"}>
                      <i className={"ri-bell-line"}></i>
                    </div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h4 className="card-value d-flex align-items-baseline mb-0">
                        {profileData.mostUsedPlan}
                      </h4>
                      <label className="card-label fs-sm fw-medium mb-1">
                        Most Used Plan{" "}
                      </label>
                      <div className="mutual-badge">
                        <ul>
                          {[img6, img6, img6, img6].map((avatar, ind) => (
                            <li key={ind}>
                              <Avatar img={avatar} />
                            </li>
                          ))}
                        </ul>
                        <label>
                          {profileData.mostUsedPlanNumber} staff use this plan
                        </label>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12">
        
              <Card className="card-one">
                <Card.Header>
                  <Card.Title as="h6">Retail Customer List </Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  {retailData.length > 0 && (
                    <Table className="table-agent mb-0" responsive>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th> Enrollee ID</th>
                         
                          <th>Plans Purchased</th>
                          <th>Customer Full Name</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {retailData.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              <span className="badge bg-success">
                                {item.isActive ? "Active" : "Not Active"}
                              </span>
                            </td>

                            <td>
                              <span className="ff-numerals">
                                {item.enrolleeId=== null? "NOT SET": item.enrolleeId }
                              </span>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                <Avatar img={img6} />
                                <div>
                                  <h6 className="mb-0">{item.fullName}</h6>
                                  <span className="fs-xs text-secondary">
                                    {item.email}
                                  </span>
                                </div>
                              </div>
                            </td>
                           
                            <td>
                              <span className="ff-numerals">
                                {item.planName}
                              </span>
                            </td>
                             <td>
                              <span className="ff-numerals">
                                {item.contactPersonFullName}
                              </span>
                            </td>
                        
                            <td>
                              <span className="fs-xs text-secondary">
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    handleRetailViewPlan(
                                      item
                                    )
                                  }
                                >
                                  VIEW PLAN
                                </Button>
                              </span>
                            </td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        {showModal && (
          <CorporatePlanlistModal
            onClose={() => setShowModal(false)}
            planData={filteredData}
            corporateProfile={corporateprofile}
          />
        )}

        {showRetailModal && (
          <RetailProfileModal
            onClose={() => setShowRetailModal(false)}
            planData={filteredData}
            retailProfile={retailProfile}
          />
        )}
      </div>
    </React.Fragment>
  );
}
