
import mini from "../assets/img/mini.jpg";
import midi from "../assets/img/midi.jpg";
import maxi from "../assets/img/maxi.jpg";
import ppp from "../assets/img/ppp.jpg";
import sweet from "../assets/img/Sweet-Sixties-Cover-1536x1060.jpeg"

const retailPlans = [
  
 
  // {
  //   id: 18,
  //   premium: 1000,
  //   img: mini,
  //   planName: 'Test Plan',
  //   description: 'Coverage for essential medical services.',
  //   planType: 'RETAIL_STANDARD',
  // },
  {
    id: 2,
    premium: 70000,
    img: midi,
    planName: 'METRO MIDI',
    description: 'Comprehensive coverage with additional benefits.',
    planType: 'RETAIL_STANDARD',
  },
  {
    id: 4,
    premium: 130000,
    img: maxi,
    planName: 'METRO MAXI',
    description: 'Premium Coverage for your medical needs.',
    planType: 'RETAIL_STANDARD',
  },
  {
    id: 5,
    premium: 400000,
    img: ppp,
    planName: 'METRO PPP',
    description: 'Topnotch Coverage for your Family',
    planType: 'RETAIL_STANDARD',
  },
  {
    id: 19,
    premium: 300000,
    img: maxi,
    planName: 'SWEET SIXTIES - MAXI',
    description: 'Health Plan tailored for individuals and couples within the age range of 60 – 69 years.',
    planType: 'RETAIL_STANDARD',
  },
  {
    id: 20,
    premium: 400000,
    img: ppp,
    planName: 'SWEET SIXTIES - PPP',
    description: 'Health Plan tailored for individuals and couples within the age range of 60 – 69 years.',
    planType: 'RETAIL_STANDARD',
  },
];

const corporatePlansIndividual = [
    {
      id: 3,
      premium: 38000,
      img: mini,
      planName: "METRO MINI",
      description: "Coverage for essential medical services.",
      planType: "CORPORATE_STANDARD",
      planId:6 
    },
    // {
    //   id: 48,
    //   premium: 1000,
    //   img: mini,
    //   planName: "TEST PLAN",
    //   description: "Coverage for essential medical services.",
    //   planType: "CORPORATE_STANDARD",
    //   planId:18 
    // },
    {
      id: 2,
      premium: 55000,
      img: midi,
      planName: "METRO MIDI",
      description: "Comprehensive coverage with additional benefits.",
      planType: "CORPORATE_STANDARD",
      planId:7 
    },
    {
      id: 4,
      premium: 90000,
      img: maxi,
      planName: "METRO MAXI",
      description: "Premium Coverage for your medical needs.",
      planType: "CORPORATE_STANDARD",
      planId:8
    },
    {
      id: 5,
      premium: 250000,
      img: ppp,
      planName: "METRO PPP",
      description: "Topnotch Coverage for your Family",
      planType: "CORPORATE_STANDARD",
      planId:9 
    },
    
  ];




  const corporatePlansFamily = [
    {
      id: 9,
      premium: 38000,
      img: mini,
      planName: "METRO MINI",
      description: "Coverage for essential medical services.",
      planType: "CORPORATE_STANDARD",
      planId:6
    },
    // {
    //   id: 92,
    //   premium: 1000,
    //   img: mini,
    //   planName: "TEST PLAN",
    //   description: "Coverage for essential medical services.",
    //   planType: "CORPORATE_STANDARD",
    //   planId:18 
    // },
    {
      id: 10,
      premium: 55000,
      img: midi,
      planName: "METRO MIDI",
      description: "Comprehensive coverage with additional benefits.",
      planType: "CORPORATE_STANDARD",
      planId:7
    },
    {
      id: 11,
      premium: 90000,
      img: maxi,
      planName: "METRO MAXI",
      description: "Premium Coverage for your medical needs.",
      planType: "RETAIL_STANDARD",
      planId:8 
    },
    {
      id: 12,
      premium: 250000,
      img: ppp,
      planName: "METRO PPP",
      description: "Topnotch Coverage for your Family",
      planType: "CORPORATE_STANDARD",
      planId:9
    },

  ];


  const corporatePlansSpecialProducts = [
    {
      id: 6,
      premium: 160000,
      img: maxi,
      planName: "SWEET SIXTIES- MAXI",
      description: "Coverage for essential medical services.",
      planType: "CORPORATE_STANDARD",
      planId:14
    },
    {
      id: 7,
      premium: 400000,
      img: ppp,
      planName: "SWEET SIXTIES- PPP",
      description: "Comprehensive coverage with additional benefits.",
      planType: "CORPORATE_STANDARD",
       planId:15
    },
  
  ];

  const familySmePlans = [
    {
      id: 19,
      premium: 40000,
      img: mini,
      planName: "METRO MINI",
      description: "Coverage for essential medical services.",
      planType: "SME_STANDARD",
       planId:13
    },
    {
      id: 20,
      premium: 62500,
      img: midi,
      planName: "METRO MIDI",
      description: "Comprehensive coverage with additional benefits.",
      planType: "SME_STANDARD",
      planId:12
    },
    // {
    //   id: 29,
    //   premium: 1000,
    //   img: mini,
    //   planName: "TEST PLAN",
    //   description: "Coverage for essential medical services.",
    //   planType: "CORPORATE_STANDARD",
    //   planId:18 
    // },
    {
      id: 21,
      premium: 99000,
      img: maxi,
      planName: "METRO MAXI",
      description: "Premium Coverage for your medical needs.",
      planType: "SME_STANDARD",
      planId:11
    },
    {
      id: 22,
      premium: 325000,
      img: ppp,
      planName: "METRO PPP",
      description: "Topnotch Coverage for your Family SME",
      planType: "SME_STANDARD",
      planId:10
    },
  
  ];




  const individualSmePlans = [
    {
      id: 13,
      premium: 40000,
      img: mini,
      planName: "METRO MINI",
      description: "Coverage for essential medical services SME.",
      planType: "SME_STANDARD",
      planId:13
    },
    // {
    //   id: 84,
    //   premium: 1000,
    //   img: mini,
    //   planName: "TEST PLAN",
    //   description: "Coverage for essential medical services.",
    //   planType: "CORPORATE_STANDARD",
    //   planId:18 
    // },
    {
      id: 14,
      premium: 62500,
      img: midi,
      planName: "METRO MIDI",
      description: "Comprehensive coverage with additional benefits SME.",
      planType: "SME_STANDARD",
      planId:12
    },
    {
      id: 15,
      premium: 99000,
      img: maxi,
      planName: "METRO MAXI",
      description: "Premium Coverage for your medical needs SME.",
      planType: "SME_STANDARD",
      planId:11
    },
    {
      id: 16,
      premium: 325000,
      img: ppp,
      planName: "METRO PPP",
      description: "Topnotch Coverage for your Family",
      planType: "SME_STANDARD",
      planId:10
    },
  ];


  const specialSmePlans = [
    {
      id: 17,
      premium: 300000,
      img: maxi,
      planName: "SWEET SIXTIES- MAXI",
      description: "Coverage for essential medical services.",
      planType: "RETAIL_STANDARD",
      planId:17
    },
    {
      id: 18,
      premium: 400000,
      img: ppp,
      planName: "SWEET SIXTIES- PPP",
      description: "Comprehensive coverage with additional benefits.",
      planType: "RETAIL_STANDARD",
      planId:16
    },
  ];


  export{retailPlans,corporatePlansFamily,corporatePlansIndividual,corporatePlansSpecialProducts,individualSmePlans, familySmePlans, specialSmePlans}