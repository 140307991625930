
const dashboardMenu = [
  {
    "label": "My Dashboard",
    "link": "/dashboard/individual",
    "icon": "ri-suitcase-2-line"
  },
  {
    "label": "My Profile",
    "icon": "ri-account-circle-line",
    "link": "/profile/individual"
  },
  // {
  //   "label": "My Beneficiary List",
  //   "link": "/pages/people",
  //   "icon": "ri-contacts-book-line"
  // },
  {
    "label": "Report Delay",
    "link": "/report-delay",
    "icon": "ri-error-warning-line"
  },

];

const applicationsMenu = [

  {
    "label": "Health Plans",
    "link": "plans/individual",
    "icon": "ri-checkbox-multiple-line"
  },
  {
    "label": "Get our Mobile App",
    "icon": "ri-gallery-line",
    "link": "/get-app"
  },
  {
    "label": "Contact us",
    "link": "/apps/chat",
    "icon": "ri-question-answer-line"
  },
  {
    "label": "Our Blog",
    "link": "/blog",
    "icon": "ri-file-text-line",
    "target": "_blank"
  },
];

const pagesMenu = [

  {
    "label": "Change Password",
    "link": "/pages/forgot",
    "icon": "ri-lock-2-line",
  },


];

const uiElementsMenu = [
  {
    "label": "Getting Started",
    "icon": "ri-pencil-ruler-2-line",
    "submenu": [
      {
        "label": "Grid System",
        "link": "/docs/layout/grid"
      },
      {
        "label": "Columns",
        "link": "/docs/layout/columns"
      },
      {
        "label": "Gutters",
        "link": "/docs/layout/gutters"
      }
    ]
  },
  {
    "label": "Components",
    "icon": "ri-suitcase-line",
    "submenu": [
      {
        "label": "Accordion",
        "link": "/docs/com/accordions"
      },
      {
        "label": "Alerts",
        "link": "/docs/com/alerts"
      },
      {
        "label": "Avatars",
        "link": "/docs/com/avatars"
      },
      {
        "label": "Badge",
        "link": "/docs/com/badge"
      },
      {
        "label": "Breadcrumbs",
        "link": "/docs/com/breadcrumbs"
      },
      {
        "label": "Buttons",
        "link": "/docs/com/buttons"
      },
      {
        "label": "Cards",
        "link": "/docs/com/cards"
      },
      {
        "label": "Carousel",
        "link": "/docs/com/carousel"
      },
      {
        "label": "Dropdown",
        "link": "/docs/com/dropdown"
      },
      {
        "label": "Images",
        "link": "/docs/com/images"
      },
      {
        "label": "List Group",
        "link": "/docs/com/listgroup"
      },
      {
        "label": "Markers",
        "link": "/docs/com/markers"
      },
      {
        "label": "Modal",
        "link": "/docs/com/modal"
      },
      {
        "label": "Nav & Tabs",
        "link": "/docs/com/navtabs"
      },
      {
        "label": "Offcanvas",
        "link": "/docs/com/offcanvas"
      },
      {
        "label": "Pagination",
        "link": "/docs/com/pagination"
      },
      {
        "label": "Placeholders",
        "link": "/docs/com/placeholders"
      },
      {
        "label": "Popovers",
        "link": "/docs/com/popovers"
      },
      {
        "label": "Progress",
        "link": "/docs/com/progress"
      },
      {
        "label": "Spinners",
        "link": "/docs/com/spinners"
      },
      {
        "label": "Toast",
        "link": "/docs/com/toasts"
      },
      {
        "label": "Tooltips",
        "link": "/docs/com/tooltips"
      },
      {
        "label": "Tables",
        "link": "/docs/com/tables"
      }
    ]
  },
  {
    "label": "Forms",
    "icon": "ri-list-check-2",
    "submenu": [
      {
        "label": "Text Elements",
        "link": "/docs/form/elements"
      },
      {
        "label": "Selects",
        "link": "/docs/form/selects"
      },
      {
        "label": "Checks & Radios",
        "link": "/docs/form/checksradios"
      },
      {
        "label": "Range",
        "link": "/docs/form/range"
      },
      {
        "label": "Pickers",
        "link": "/docs/form/pickers"
      },
      {
        "label": "Layouts",
        "link": "/docs/form/layouts"
      }
    ]
  },
  {
    "label": "Charts & Graphs",
    "icon": "ri-bar-chart-2-line",
    "submenu": [
      {
        "label": "ApexCharts",
        "link": "/docs/chart/apex"
      },
      {
        "label": "Chartjs",
        "link": "/docs/chart/chartjs"
      }
    ]
  },
  {
    "label": "Maps & Icons",
    "icon": "ri-stack-line",
    "submenu": [
      {
        "label": "Leaflet Maps",
        "link": "/docs/map/leaflet"
      },
      {
        "label": "Vector Maps",
        "link": "/docs/map/vector"
      },
      {
        "label": "Remixicon",
        "link": "/docs/icon/remix"
      },
      {
        "label": "Feathericons",
        "link": "/docs/icon/feather"
      }
    ]
  },
  {
    "label": "Utilities",
    "icon": "ri-briefcase-4-line",
    "submenu": [
      {
        "label": "Background",
        "link": "/docs/util/background"
      },
      {
        "label": "Border",
        "link": "/docs/util/border"
      },
      {
        "label": "Colors",
        "link": "/docs/util/colors"
      },
      {
        "label": "Divider",
        "link": "/docs/util/divider"
      },
      {
        "label": "Flex",
        "link": "/docs/util/flex"
      },
      {
        "label": "Sizing",
        "link": "/docs/util/sizing"
      },
      {
        "label": "Spacing",
        "link": "/docs/util/spacing"
      },
      {
        "label": "Opacity",
        "link": "/docs/util/opacity"
      },
      {
        "label": "Position",
        "link": "/docs/util/position"
      },
      {
        "label": "Typography",
        "link": "/docs/util/typography"
      },
      {
        "label": "Shadows",
        "link": "/docs/util/shadows"
      },
      {
        "label": "Extras",
        "link": "/docs/util/extras"
      }
    ]
  }
];



const dashboardMenuCorporate = [
  {
    "label": "My Dashboard",
    "link": "/dashboard/business",
    "icon": "ri-suitcase-2-line"
  },
  {
    "label": "My Profile",
    "icon": "ri-account-circle-line",
    "link": "/profile/business"
  },
  // {
  //   "label": "My Beneficiary List",
  //   "link": "/pages/people",
  //   "icon": "ri-contacts-book-line"
  // },
  {
    "label": "Report Delay",
    "link": "/report-delay",
    "icon": "ri-error-warning-line"
  },

];

const applicationsCorporate = [

  {
    "label": "Health Plans",
    "link": "plans/business",
    "icon": "ri-checkbox-multiple-line"
  },
  {
    "label": "Get our Mobile App",
    "icon": "ri-gallery-line",
    "link": "/get-app"
  },
  {
    "label": "Contact us",
    "link": "/apps/chat",
    "icon": "ri-question-answer-line"
  },
  {
    "label": "Our Blog",
    "link": "/blog",
    "icon": "ri-file-text-line",
    "target": "_blank"
  },
];


const adminMenu = [

  {
    "label": "Corporate Customers",
    "link": "/dashboard/admin/business",
    "icon": "ri-checkbox-multiple-line"
  },
  {
    "label": "Retail Customers",
    "icon": "ri-checkbox-multiple-line",
    "link": "/dashboard/admin/retail"
  },
  {
    "label": "Sales Prospects/Leads",
    "link": "/admin/crm",
    "icon": "ri-question-answer-line"
  },
];





export { adminMenu,dashboardMenu, applicationsMenu, pagesMenu, uiElementsMenu, dashboardMenuCorporate, applicationsCorporate };