import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Modal,
  Table,
  Button,
  Tab,
  Spinner,
} from "react-bootstrap";

import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { showLoadingModal, hideLoadingModal, test } from "../../logic/Utils";

import { AdminService } from "../../service/AdminService";
import RetailInvoices from "./RetailInvoices";
import CorporateInvoices from "./CorporateInvoices";

export default function CustomerCRM() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [corporateData, setCorporateData] = React.useState([]);
  const [retailData, setRetailData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isProcessed, setIsProcessed] = React.useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleView = (row) => {
    // Implement your edit logic here
    setShowModal(true);
    setSelectedCustomer(row);
  };
  const columns = [
    {
      field: "id",
      headerName: "S/N",
      flex: 0.5,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    { field: "customerEmail", headerName: "Email", flex: 1 },
    { field: "customerPhoneNumber", headerName: "Phone Number", flex: 1 },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="primary"
            // startIcon={<EditIcon />}
            onClick={() => handleView(params.row)}
          >
            VIEW INVOICE
          </Button>
        </>
      ),
    },
  ];

  const getAllCustomersWhoHaveNotPaid = () => {
    showLoadingModal();
    AdminService.getAllCustomersWhoHaveNotPaid(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          const corporate = response.data.result
            .filter((item) => item.corporate === true)
            .map((item, index) => ({ id: index + 1, ...item }));
          const retail = response.data.result
            .filter((item) => item.corporate === false)
            .map((item, index) => ({ id: index + 1, ...item }));

          setCorporateData(corporate);
          setRetailData(retail);

          hideLoadingModal();
        }
      })
      .catch((error) => {
        console.log(error);
        hideLoadingModal();
      });
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedCustomer(null);
  };

  return (
    <React.Fragment>
      <Sidebar />
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <Container>
          <label className="main-title-label">Customer CRM</label>
          <h2 className="main-title">Customer CRM</h2>

          <Button
            onClick={getAllCustomersWhoHaveNotPaid}
            style={{ marginBottom: "20px" }}
          >
            GET CUSTOMERS
          </Button>
          <Tab.Container defaultActiveKey={"Corporate"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="Corporate">
                  Corporate Customers
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={{ marginRight: "20px" }} eventKey="Retail">
                  Retail Customers
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="Corporate">
                <div style={{ height: 600, width: "100%" }}>
                  {corporateData.length > 0 ? (
                    <div style={{ height: 600, width: "100%" }}>
                      <DataGrid
                        rows={corporateData}
                        columns={columns}
                        pageSize={20}
                        onSelectionModelChange={(selection) =>
                          setSelectedCustomer(selection.selectionModel[0])
                        }
                        search
                      />
                    </div>
                  ) : (
                    <h3>No records from the database</h3>
                  )}
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="Retail">
                {retailData.length > 0 ? (
                  <div style={{ height: 600, width: "100%" }}>
                    <DataGrid
                      rows={retailData}
                      columns={columns}
                      pageSize={20}
                      onSelectionModelChange={(selection) =>
                        setSelectedCustomer(selection.selectionModel[0])
                      }
                      search
                    />
                  </div>
                ) : (
                  <h3>No records from the database</h3>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
        {selectedCustomer && (
          <div>
            {showModal && (
              <Modal size="xl" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Invoice Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {selectedCustomer.corporate === false ? (
                    <RetailInvoices
                      retailInvoiceList={selectedCustomer.retailInvoiceList}
                    />
                  ) : (
                    <CorporateInvoices
                      corporateInvoicesList={
                        selectedCustomer.corporateInvoiceList
                      }
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
