import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Table,
  Row,
  Col,
  Card,
  Nav,
  Form,
} from "react-bootstrap";
import HeaderMobile from "../../layouts/HeaderMobile";
import Prism from "prismjs";
import Avatar from "../../components/Avatar";
import img6 from "../../assets/img/img6.jpg";
import Swal from "sweetalert2";
import { AdminService } from "../../service/AdminService";

const CorporatePlanlistModal = (props) => {
  const onClose = props.onClose;
  const planData = props.planData.filter((plan) => plan.isActive != true);
  const corporateProfile = props.corporateProfile;

  const [showModal, setShowModal] = useState(true);
  const [showSection, setShowSection] = useState(false);

  const [planName, setPlanName] = useState("");



  const [formData, setFormData] = useState({
    groupCode: "",
    planCode: "",
    effectiveDate: "",
    terminationDate: "",
    corporateProfileId: corporateProfile.id,
    corporateUserSelectionId: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handleClose2 = () => {
    onClose();
  };


  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
    
      confirmButtonText: "OK",
      allowOutsideClick: true, // Prevent dismissing the alert by clicking outside
    });
  };
  const handleApproval = (item) => {
    handleChange("corporateUserSelectionId", item.id)
    setPlanName(item.plan.planName);
    setShowSection(true);
  };
  const handleSubmit = () => {


    console.log(formData);

    AdminService.approveBusinessUserPlan( localStorage.getItem("token"),formData)
    .then((response) => {
      if (response.status === 200) {
        // setIsLoading(false);
        showAlert("Success", "Approval successful", "success");
      }
    })
    .catch((error) => {
      console.log(error);
      // setIsLoading(false);

      showAlert("Error", error.response.data.result, "error");
    });
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Modal show={showModal} centered size="xl">
          <Modal.Header closeButton onHide={handleClose2}></Modal.Header>

          <Modal.Body>
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Plan Details</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="">
                    <i className="ri-refresh-line"></i>
                  </Nav.Link>
                  <Nav.Link href="">
                    <i className="ri-more-2-fill"></i>
                  </Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                {planData.length > 0 && (
                  <Table className="table-agent mb-0" responsive>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Staff Quota</th>
                        <th>Plan Name</th>

                        <th>Family Size</th>
                        <th>Date Purchased</th>
                        <th>Renewal Date</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {planData.map((item) => (
                        <tr key={item.id}>
                          <td>
                          {item.isActive == "true"? (
                                      <span className="badge bg-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge bg-warning">
                                        Inactive
                                      </span>
                                    )}
                          </td>

                          <td>
                            <span className="ff-numerals">
                              {item.numberOfEnrollees}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-2">
                              <Avatar img={img6} />
                              <div>
                                <h6 className="mb-0">{item.plan.planName}</h6>
                                <span className="fs-xs text-secondary">
                                  {item.plan.planType}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className="ff-numerals">
                              {item.familySize != null
                                ? item.familySize
                                : "N/A"}
                            </span>
                          </td>
                          <td>
                            <span className="fs-xs text-secondary">
                              {new Intl.DateTimeFormat("en-US", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }).format(new Date(item.datePurchased))}
                            </span>
                          </td>
                          <td>
                            {item.effectiveDate !== null && (
                              <span className="fs-xs text-secondary">
                                {new Intl.DateTimeFormat("en-US", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                }).format(new Date(item.effectiveDate))}
                              </span>
                            )}
                            {item.effectiveDate == null && (
                              <span className="fs-xs text-secondary">
                                Awaiting Onboarding
                              </span>
                            )}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => handleApproval(item)}
                            >
                              APPROVE PLAN
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>

            {showSection && (
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5>{corporateProfile.nameOfClient}</h5>
                      <h5>{planName}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Group Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type in the Group Code from Medicloud"
                        name="Group Code"
                        value={formData.groupCode}
                        onChange={(event) =>
                        handleChange("groupCode", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Plan Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type in the Plan Code from Medicloud"
                        name="Plan Code"
                        value={formData.planCode}
                        onChange={(event) =>
                        handleChange("planCode", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Effective Date</Form.Label>
                      <Form.Control
                        name="effectiveDate"
                        type="date"
                        id="effectiveDate"
                        class="form-control input-class"
                        onChange={(event) =>
                        handleChange("effectiveDate", event.target.value)
                      }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Termination Date</Form.Label>
                      <Form.Control
                        name="terminationDate"
                        type="date"
                        id="terminationDate"
                        class="form-control input-class"
                        onChange={(event) =>
                        handleChange("terminationDate", event.target.value)
                      }
                      />
                    </Col>
                    <Row style={{ paddingTop: "15px", paddingLeft: "15%", paddingRight: "15%"  }}>
                      <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                      </Button>
                    </Row>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Modal.Body>

          <Modal.Footer>
            {/* You can add additional buttons or controls here */}
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default CorporatePlanlistModal;
