import React, { useState } from "react";
import HeaderMobile from "../../layouts/HeaderMobile";
import {
  Spinner,
  Modal,
  Button,
  Card,
  Row,
  Col,
  Form,
  Tab,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PlanService } from "../../service/PlanService";
import {
  corporatePlansIndividual,
  individualSmePlans,
  familySmePlans,
  specialSmePlans,
} from "../../data/ProductData";
import { corporatePlansFamily } from "../../data/ProductData";
import { corporatePlansSpecialProducts } from "../../data/ProductData";
import CorporateDialog from "./CorporateDialog";

const BusinessPlanCart = (props) => {
  const onClose = props.onClose;

  const businessCategory = props.businessCategory;
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);

  const [gatewayResponse, setGatewayResponse] = useState(null);
  const [showRight, setShowRight] = useState(true);
  const [familyCounts, setFamilyCounts] = useState({});
  const [familyPackages, setFamilyPackages] = useState({});
  const [familyCartItems, setFamilyCartItems] = useState([]);

  const [zestData, setZestData] = useState(null);
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const destinaltion = "payment-history";

  const handleSuccessfulPayment = () => {
    const destinaltion = "payment-history";
    navigate(`/profile/business?tab=${destinaltion}`, { replace: true });
  };
  const handleCloseRight = () => {
    setShowRight(false);
    onClose();
  };

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: icon === "success", // Show the "Login" button only if the icon is "success"
      confirmButtonText: "OK",
      allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the "OK" button click (if needed)
        if (icon === "success") {
          handleSuccessfulPayment();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        if (icon === "success") {
          handleSuccessfulPayment();
        }
      }
    });
  };

  const handleFailedPayment = () => {
    onClose();
  };

  const verifyBusinessCategory = () => {
    if (businessCategory === "sme") {
      if (calculateOverallTotal().totalEmployees > 20) {
        showAlert(
          "Incorrect Employee Size",
          " The total employee count is " +
            calculateOverallTotal().totalEmployees +
            ". This is not suitable for the SME Category(requires a maximum of 20 employees)",
          "warning"
        );

        return;
      }
    } else {
      if (calculateOverallTotal().totalEmployees < 20) {
        showAlert(
          "Incorrect Employee Size",
          " The total employee count is " +
            calculateOverallTotal().totalEmployees +
            ". This is not suitable for the Corporate Category (requires a minimum of 20 employees)",
          "warning"
        );

        return;
      }
    }
    handlePayment();
  };

  const handlePayment = async () => {
    setIsLoading(true);

    const cartDto = {
      individualPlans: cartItems,
      familyPlans: familyCartItems ,
      businessCategory: businessCategory
  };

    PlanService.initializeCorporatePayment(
      cartDto,
      localStorage.getItem("token")
    )
      .then((res) => {
        if (res.status === 200) {
          setGatewayResponse(res.data.result);

          console.log(gatewayResponse);

          try {
            // Call the Zest payment function and handle payment processing
            let handler = window.ZestPayPop.setup({
              key: res.data.result.gatewayPublicKey,
              email: res.data.result.customerEmail,
              amount: res.data.result.amount * 100,
              // amount: 20000,
              onClose: function () {
                setIsLoading(false);
              },
              callback: function (response) {
                // Extract the transaction status
                const status = response.status;

                if (status === "success") {
                  onClose();
                  showAlert(
                    "Payment has been successfully logged",
                    "A confirmation message will be sent to your email shortly",
                    "success"
                  );

                  setIsLoading(false);
                } else {
                  handleFailedPayment();
                  setIsLoading(false);
                }
              },
            });
            handler.openIframe();
          } catch (error) {
            // Handle errors, if any
            console.error("Payment error:", error);
          }
        }
      })
      .catch((err) => {
        // Handle errors, e.g., show an error message
        console.log(err);
        setIsLoading(false);
      });
  };

  const [employeeCounts, setEmployeeCounts] = useState({});

  const handleEmployeeChange = (productId, value) => {
    // Ensure the value is a non-negative number
    const newValue = Math.max(0, parseInt(value, 10)) || "";

    setEmployeeCounts({ ...employeeCounts, [productId]: newValue });
  };

  const calculateProductTotal = (product) => {
    const employeeCount = product.employeeCount || 0;
    const familySize = product.familySize || 0;
    const packages = product.packages || 0;

    let total = product.premium * (employeeCount + familySize * packages);

    // Apply discount for family plans
    if (familySize > 0) {
      const discountPercentage = 8.33334;
      const discount = (total * discountPercentage) / 100;
      total -= discount;
    }

    return Math.round(total).toFixed(2);
  };

  const calculateOverallTotal = () => {
    const totalCost = cartItems
      .concat(familyCartItems)
      .reduce((total, item) => {
        const itemTotal = parseFloat(calculateProductTotal(item));
        return total + itemTotal;
      }, 0)
      .toFixed(2);

    const totalEmployees = cartItems
      .concat(familyCartItems)
      .reduce((total, item) => {
        const employeeCount = item.employeeCount || 0;
        const familySize = item.packages || 0;
        return total + employeeCount + familySize;
      }, 0);

    return {
      totalCost,
      totalEmployees,
    };
  };

  const addToCart = (product) => {
    const existingCartItem = cartItems.find((item) => item.id === product.id);

    if (existingCartItem) {
      // Update the existing item's employee count
      const updatedCart = cartItems.map((item) =>
        item.id === product.id
          ? { ...item, employeeCount: employeeCounts[product.id] || 0 }
          : item
      );

      setCartItems(updatedCart);
    } else {
      // Add a new item to the cart with employee count
      setCartItems([
        ...cartItems,
        { ...product, employeeCount: employeeCounts[product.id] || 0 },
      ]);
    }
  };

  const handleRemoveFromCart = (productId) => {
    const updatedCart = cartItems.filter((item) => item.id !== productId);

    setCartItems(updatedCart);
  };

  const handleRemoveFromFamilyCart = (productId) => {
    const updatedCart = familyCartItems.filter((item) => item.id !== productId);

    setFamilyCartItems(updatedCart);
  };

  const handleClose2 = () => {
    setShowRight(false);
    // navigate("/dashboard"); // Redirect to dashboard or the desired page after closing the modal
  };

  const handleFamilySizeChange = (productId, value) => {
    const newValue = Math.max(0, parseInt(value, 10)) || "";
    setFamilyCounts({ ...familyCounts, [productId]: newValue });
  };

  const handleFamilyPackagesChange = (productId, value) => {
    const newValue = Math.max(0, parseInt(value, 10)) || "";
    setFamilyPackages({ ...familyPackages, [productId]: newValue });
  };

  const addToFamilyCart = (product) => {
    const existingFamilyCartItem = familyCartItems.find(
      (item) => item.id === product.id
    );

    if (existingFamilyCartItem) {
      // Update the existing item's family size and number of packages
      const updatedFamilyCart = familyCartItems.map((item) =>
        item.id === product.id
          ? {
              ...item,
              familySize: familyCounts[product.id] || 0,
              packages: familyPackages[product.id] || 0,
            }
          : item
      );

      setFamilyCartItems(updatedFamilyCart);
    } else {
      // Add a new family item to the cart
      setFamilyCartItems([
        ...familyCartItems,
        {
          ...product,
          familySize: familyCounts[product.id] || 0,
          packages: familyPackages[product.id] || 0,
        },
      ]);
    }
  };

  const individualPlansToMap =
    businessCategory === "sme" ? individualSmePlans : corporatePlansIndividual;
  const familyPlansToMap =
    businessCategory === "sme" ? familySmePlans : corporatePlansFamily;
  const specialPlansToMap =
    businessCategory === "sme"
      ? specialSmePlans
      : corporatePlansSpecialProducts;

  return (
    <React.Fragment>
      <HeaderMobile />
      <div className="main main-docs">
        <Offcanvas
          show={showRight}
          onHide={handleCloseRight}
          placement="end"
          style={{ width: "800px" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Purchase A Health Plan -{" "}
              {businessCategory === "sme"
                ? "SME Category"
                : "Corporate Category"}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div>
              <div
                style={{
                  display: "flex",
                  maxWidth: "1200px",
                  // margin: "20px auto",
                }}
              >
                <div
                  style={{
                    flex: 2.7,
                  }}
                >
                  <Tab.Container
                    defaultActiveKey={"individual-package"}
                    className="mt-5"
                  >
                    <Nav className="nav-line ">
                      <Nav.Item>
                        <Nav.Link
                          style={{ marginRight: "20px" }}
                          eventKey="individual-package"
                        >
                          Individual Packages
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          style={{ marginRight: "20px" }}
                          eventKey="family-package"
                        >
                          Family Packages
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          style={{ marginRight: "20px" }}
                          eventKey="special-package"
                        >
                          Special Packages
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="individual-package">
                        {individualPlansToMap.map((product) => (
                          <Card key={product.id} className="card-plan-pricing">
                            <Card.Body className="p-3">
                              <Row className="g-3">
                                <Col sm="4" md="3" xl="4" xxl="3">
                                  <div className="rounded px-2 bg-white">
                                    <img
                                      src={product.img}
                                      alt={product.planName}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      className="img-fluid mx-auto"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm="7"
                                  md="8"
                                  xl="7"
                                  xxl
                                  className="d-flex flex-column "
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <h6 className="fs-15 fw-semibold text-dark mb-1">
                                    {product.planName}
                                  </h6>
                                  <p className="text-secondary mb-3">
                                    {product.description}
                                  </p>

                                  <Row>
                                    <Col>
                                      <label
                                        style={{
                                          paddingBottom: "10px",
                                        }}
                                        htmlFor={`employeeInput${product.id}`}
                                      >
                                        Number of Staff:
                                      </label>
                                      <Form.Control
                                        type="number"
                                        id={`employeeInput${product.id}`}
                                        value={employeeCounts[product.id] || ""}
                                        onChange={(e) =>
                                          handleEmployeeChange(
                                            product.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-auto">
                                      <Button
                                        variant={"success"}
                                        onClick={() => addToCart(product)}
                                      >
                                        Add Plan
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ))}
                      </Tab.Pane>

                      <Tab.Pane eventKey="family-package">
                        {familyPlansToMap.map((product) => (
                          <Card key={product.id} className="card-plan-pricing">
                            <Card.Body className="p-3">
                              <Row className="g-3">
                                <Col sm="4" md="3" xl="4" xxl="3">
                                  <div className="rounded px-2 bg-white">
                                    <img
                                      src={product.img}
                                      alt={product.planName}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      className="img-fluid mx-auto"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm="7"
                                  md="8"
                                  xl="7"
                                  xxl
                                  className="d-flex flex-column "
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <h6 className="fs-15 fw-semibold text-dark mb-1">
                                    {product.planName}
                                  </h6>
                                  <p className="text-secondary mb-3">
                                    {product.description}
                                  </p>

                                  <Row style={{ paddingBottom: "10px" }}>
                                    <Col className="mt-auto">
                                      <label
                                        style={{ paddingBottom: "10px" }}
                                        htmlFor={`familySizeInput${product.id}`}
                                      >
                                        Family Size
                                      </label>
                                      <Form.Control
                                        type="number"
                                        id={`familySizeInput${product.id}`}
                                        value={familyCounts[product.id] || ""}
                                        onChange={(e) =>
                                          handleFamilySizeChange(
                                            product.id,
                                            Math.max(2, parseInt(e.target.value))
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-auto">
                                      <label
                                        style={{ paddingBottom: "10px" }}
                                        htmlFor={`packagesInput${product.id}`}
                                      >
                                        Number of Packages:
                                      </label>
                                      <Form.Control
                                        type="number"
                                        id={`packagesInput${product.id}`}
                                        value={familyPackages[product.id] || ""}
                                        onChange={(e) =>
                                          handleFamilyPackagesChange(
                                            product.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col className="mt-auto">
                                      <Button
                                        variant={"success"}
                                        onClick={() => addToFamilyCart(product)}
                                      >
                                        Add Plan
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ))}
                      </Tab.Pane>
                      <Tab.Pane eventKey="special-package">
                        {specialPlansToMap.map((product) => (
                          <Card key={product.id} className="card-plan-pricing">
                            <Card.Body className="p-3">
                              <Row className="g-3">
                                <Col sm="4" md="3" xl="4" xxl="3">
                                  <div className="rounded px-2 bg-white">
                                    <img
                                      src={product.img}
                                      alt={product.planName}
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      className="img-fluid mx-auto"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm="7"
                                  md="8"
                                  xl="7"
                                  xxl
                                  className="d-flex flex-column "
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <h6 className="fs-15 fw-semibold text-dark mb-1">
                                    {product.planName}
                                  </h6>
                                  <p className="text-secondary mb-3">
                                    {product.description}
                                  </p>

                                  <Row>
                                    <Col>
                                      <label
                                        style={{
                                          paddingBottom: "10px",
                                        }}
                                        htmlFor={`employeeInput${product.id}`}
                                      >
                                        Number of Staff:
                                      </label>
                                      <Form.Control
                                        type="number"
                                        id={`employeeInput${product.id}`}
                                        value={employeeCounts[product.id] || ""}
                                        onChange={(e) =>
                                          handleEmployeeChange(
                                            product.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col className="mt-auto">
                                      <Button
                                        variant={"success"}
                                        onClick={() => addToCart(product)}
                                      >
                                        Add Plan
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ))}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>

                <div
                  style={{
                    flex: 1.3,
                    padding: "20px",
                    backgroundColor: "#ecf7ff",
                    borderRadius: "20px",
                  }}
                >
                  <h2>Purchase Summary</h2>

                  {cartItems.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #ddd",
                        padding: "10px 0",
                      }}
                    >
                      <Row>
                        <Col>
                          <span>{item.planName}</span>
                          <br />
                          <span>
                            {item.familySize
                              ? `${item.familySize} Families, ${item.packages} Packages`
                              : `${item.employeeCount} Staff`}
                          </span>
                        </Col>

                        <Col>
                          <span>₦{calculateProductTotal(item)}</span>
                          <Link onClick={() => handleRemoveFromCart(item.id)}>
                            <i
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                color: "&#xEC1D",
                              }}
                              className="ri-delete-bin-2-fill"
                            ></i>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  {familyCartItems.map((item) => (
                    <div
                      key={item.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #ddd",
                        padding: "10px 0",
                      }}
                    >
                      <Row>
                        <Col>
                          <span>{item.planName}</span>
                          <br />
                          <span>
                            {item.familySize ? (
                              <React.Fragment>
                                 Family of ({item.familySize})
                                <br />
                                {item.packages} Staff
                              </React.Fragment>
                            ) : (
                              `${item.employeeCount} Staff`
                            )}
                          </span>
                        </Col>

                        <Col>
                          <span>₦{calculateProductTotal(item)}</span>
                          <Link
                            onClick={() => handleRemoveFromFamilyCart(item.id)}
                          >
                            <i
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                color: "&#xEC1D",
                              }}
                              className="ri-delete-bin-2-fill"
                            ></i>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                    Total: ₦{calculateOverallTotal().totalCost} 
                  </div>

                  {!isLoading && (
                    <Button
                      variant="primary"
                      style={{ marginTop: "20px" }}
                      onClick={verifyBusinessCategory}
                    >
                      Pay Online
                    </Button>
                  )}
                  {isLoading && (
                    <Button style={{ marginTop: "20px" }}>
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};

export default BusinessPlanCart;
