import React, { useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import { Card, Col, Nav, Row, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";
import HeaderMobile from "../layouts/HeaderMobile";
import Avatar from "../components/Avatar";
import { Tab } from "react-bootstrap";
import { Form } from "react-bootstrap";

import img1 from "../assets/img/img1.jpg";
import img5 from "../assets/img/img5.jpg";
import Header from "../layouts/Header";
import PaymentHistory from "../components/PaymentHistory";
import UploadWidget from "../components/utils/UploadWidget";
import { useState } from "react";
import Lottie from "lottie-react";
import IndividualSettings from "./Individual/IndividualSettings";
import { EnrolleeService } from "../service/EnrolleeService";
import animationData from "../assets/animation/animation_lmawejkm.json";


export default function EnrolleeProfile() {
  const [url, updateUrl] = useState("");
  const location = useLocation();
  const value = new URLSearchParams(location.search).get('tab');
    const [loading, setLoading] = useState(true);

  const [profileData , setProfileData] = useState({})

 const activeTab = value;

 useEffect(() => {
  EnrolleeService.getProfile(localStorage.getItem("token"))
  .then((response) => {
      console.log(response);
      if (response.status === 200) {

        setProfileData(response.data.result)
     
      setLoading(false);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  
}, []);


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
      {loading && (
        <div className={`fullscreen-loader ${loading ? 'show' : 'hide'}`}>
        <Lottie
       animationData={animationData} loop={true} autoPlay={true}
        />
      </div>
      )}
      
      {!loading && (
       
        <Row className="g-5">
          <Col xl>
            <div className="media-profile mb-5 d-flex align-items-center">
              <div className="media-img mb-3 mb-sm-0">
                <img src={profileData.imageUrl===null?img1 : profileData.imageUrl} className="img-fluid" alt="..." />
              </div>
              <div className="media-body">
                <h5 className="media-name"> {profileData.fullName}</h5>
                <p className="d-flex gap-2 mb-4">
                  <i className="ri-map-pin-line"></i> Address:  {profileData.address}
                </p>
                <p> Status: {profileData.isActive? "ACTIVE": "INACTIVE"}</p>
                <Col>
                  <div className="profile-item">
                    <i className="ri-team-line"></i>
                    <div className="profile-item-body">
                      <p> {profileData.dependantCount}</p>
                      <span>Dependents</span>
                    </div>
                  </div>
                </Col>
              </div>
            </div>

            <Tab.Container defaultActiveKey={activeTab==='' || activeTab === null? "health-plan-info": activeTab} className="mt-5">
              <Nav className="nav-line ">
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="health-plan-info"
                  >
                    Health Plan Info
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    style={{ marginRight: "20px" }}
                    eventKey="payment-history"
                  >
                    Payment History
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="profile-settings">
                    Profile Settings
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="health-plan-info">
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Plan Details</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="post-preview">
                        <Row className="g-3 d-flex align-items-center">
                          <Col sm="4">
                            <img src={img5} className="img-fluid" alt="" />
                          </Col>
                          {/* <div className="d-flex align-items-center"> */}
                          <Col sm>
                            <h5>{profileData.planName}</h5>
                           
                            <p>Start Date: {profileData.coverageStartDate} </p>
                            <p>End Date: {profileData.coverageEndDate} </p>

                            {/* <span>Bay Area, San Francisco, California</span> */}
                          </Col>

                          {/* </div> */}
                        </Row>
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>

                  {/* Provider INformation */}
                  {/* <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Provider Information</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="interest-item">
                        <div className="interest-icon bg-dark">
                          <i className="ri-github-fill"></i>
                        </div>
                        <div className="interest-body">
                          <h6>Cedar Care Hospital</h6>
                          <p>
                            Top Notch Hospital. <br />
                            <Link to="">Learn more</Link>
                          </p>
                        </div>
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <Nav>
                        <Nav.Link href="">
                          Change Provider
                          <i className="ri-arrow-down-s-line"></i>
                        </Nav.Link>
                      </Nav>
                    </Card.Footer>
                  </Card> */}
                </Tab.Pane>

                <Tab.Pane eventKey="payment-history">
                  <PaymentHistory />
                </Tab.Pane>
                <Tab.Pane eventKey="profile-settings">
                 <IndividualSettings/>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
          <Col xl="4" xxl="3" className="d-none d-xl-block">
            <h5 className="section-title mb-4">Contact Information</h5>
            <ul className="list-contact-info">
              <li>
                <i className="ri-building-fill"></i>
                <span>{profileData.address}</span>
              </li>
              <li>
                <i className="ri-home-8-fill"></i>
                <span>{profileData.gender}</span>
              </li>
              <li>
                <i className="ri-phone-fill"></i>
                <span>{profileData.phoneNumber}</span>
              </li>
              <li>
                <i className="ri-mail-fill"></i>
                <span>{profileData.email}</span>
              </li>
            </ul>

            <hr className="my-4 opacity-0" />
          </Col>
        </Row>

      )}

      </div>
    </React.Fragment>
  );
}
