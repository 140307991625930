import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Nav,
  Table,
  ProgressBar,
  Form,
  Tab,
} from "react-bootstrap";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { EnrolleeService } from "../../service/EnrolleeService";
import CorporateSettings from "./CorporateSettings";

import img6 from "../../assets/img/img6.jpg";
import img7 from "../../assets/img/img7.jpg";
import img8 from "../../assets/img/img8.jpg";
import img9 from "../../assets/img/img9.jpg";
import img10 from "../../assets/img/img10.jpg";

export default function BusinessProfile() {
  const [loading, setLoading] = useState(true);

  const [profileData, setProfileData] = useState({});
  const [planData, setPlanData] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);

  useEffect(() => {
    EnrolleeService.getCorporateProfile(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          setProfileData(response.data.result);
          setPlanData(response.data.result.corporateUserPlanSelectionList);

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    EnrolleeService.getPaymentHistory(localStorage.getItem("token"))
      .then((response) => {
        if (response.status === 200) {
          setPaymentHistory(response.data.result);

          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  ///// Skin Switch /////
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="#">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Company Profile
              </li>
            </ol>
          </div>
        </div>

        <Row className="g-3">
          <Col md="6">
            <Card className="card-one h-100">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center h-100">
                  <Col>
                    <div className={"helpdesk-icon text-white bg-primary"}>
                      <i className={"ri-building-line"}></i>
                    </div>
                  </Col>
                  <Col>
                    <h2 className="card-value mb-3">
                      {profileData.nameOfClient || "Company Name"}
                    </h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">
                      Address:{" "}
                      {profileData.addressOfClient +
                        ", " +
                        profileData.state +
                        " state" || "Address Placeholder"}
                    </h6>
                    <p className="fs-sm text-secondary mb-0">
                      Industry Sector:{" "}
                      {profileData.industrySector || "Industry Placeholder"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-one h-100">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center h-100">
                  <Col>
                    <div className={"helpdesk-icon text-white bg-primary"}>
                      <i className={"ri-building-line"}></i>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h4 className="card-value mb-3">
                      {" "}
                      {profileData.contactPersonFullName || " Placeholder"}
                    </h4>
                    <h6 className="card-label text-dark fw-semibold mb-1">
                      {profileData.email || " Placeholder"}
                    </h6>
                    <p className="fs-sm text-secondary mb-0">
                      Phone contact: {profileData.phoneNumber || " Placeholder"}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Tab.Container defaultActiveKey={"health-plan-info"} className="mt-5">
            <Nav className="nav-line ">
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="health-plan-info"
                >
                  Health Plan Info
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={{ marginRight: "20px" }}
                  eventKey="payment-history"
                >
                  Payment History
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="profile-settings">
                  Profile Settings
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="health-plan-info">
                <Row className="g-3">
                  <Col sm="4">
                    <Card className="card-one">
                      <Card.Body className="p-3">
                        <div className="d-flex d-sm-block d-xl-flex align-items-center">
                          <div
                            className={"helpdesk-icon text-white bg-primary"}
                          >
                            <i className={"ri-bell-line"}></i>
                          </div>
                          <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                            <h2 className="card-value d-flex align-items-baseline mb-0">
                              {profileData.totalStaffCovered}
                            </h2>
                            <label className="card-label fs-sm fw-medium mb-1">
                              Total Staff Covered{" "}
                            </label>
                            <div className="mutual-badge">
                              <ul>
                                {[img7, img8, img9, img10].map(
                                  (avatar, ind) => (
                                    <li key={ind}>
                                      <Avatar img={avatar} />
                                    </li>
                                  )
                                )}
                              </ul>
                              <label>
                                {profileData.totalStaffOnboarded} have been
                                onboarded
                              </label>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <Card className="card-one">
                      <Card.Body className="p-3">
                        <div className="d-flex d-sm-block d-xl-flex align-items-center">
                          <div
                            className={"helpdesk-icon text-white bg-primary"}
                          >
                            <i className={"ri-bell-line"}></i>
                          </div>
                          <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                            <h2 className="card-value d-flex align-items-baseline mb-0">
                              {profileData.totalFamiliesCovered}
                            </h2>
                            <label className="card-label fs-sm fw-medium mb-1">
                              Families Packages{" "}
                            </label>
                            <div className="mutual-badge">
                              <ul>
                                {[img7, img8, img9, img10].map(
                                  (avatar, ind) => (
                                    <li key={ind}>
                                      <Avatar img={avatar} />
                                    </li>
                                  )
                                )}
                              </ul>
                              <label>
                                {profileData.familiesOnboarded} have been
                                onboarded
                              </label>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <Card className="card-one">
                      <Card.Body className="p-3">
                        <div className="d-flex d-sm-block d-xl-flex align-items-center">
                          <div
                            className={"helpdesk-icon text-white bg-primary"}
                          >
                            <i className={"ri-bell-line"}></i>
                          </div>
                          <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                            <h4 className="card-value d-flex align-items-baseline mb-0">
                              {profileData.mostUsedPlan}
                            </h4>
                            <label className="card-label fs-sm fw-medium mb-1">
                              Most Used Plan{" "}
                            </label>
                            <div className="mutual-badge">
                              <ul>
                                {[img7, img8, img9, img10].map(
                                  (avatar, ind) => (
                                    <li key={ind}>
                                      <Avatar img={avatar} />
                                    </li>
                                  )
                                )}
                              </ul>
                              <label>
                                {profileData.mostUsedPlanNumber} staff use this
                                plan
                              </label>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12">
                    <Card className="card-one">
                      <Card.Header>
                        <Card.Title as="h6">Plan Details</Card.Title>
                        <Nav className="nav-icon nav-icon-sm ms-auto">
                          <Nav.Link href="">
                            <i className="ri-refresh-line"></i>
                          </Nav.Link>
                          <Nav.Link href="">
                            <i className="ri-more-2-fill"></i>
                          </Nav.Link>
                        </Nav>
                      </Card.Header>
                      <Card.Body>
                        {planData.length > 0 && (
                          <Table className="table-agent mb-0" responsive>
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>Staff Quota</th>
                                <th>Plan Name</th>

                                <th>Family Size</th>
                                <th>Effective Date </th>
                                <th>Renewal Date</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {planData.map((item, index) => (
                                <tr key={item.id}>
                                  <td>
                                    {item.isActive == true?  (
                                      <span className="badge bg-success">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="badge bg-warning">
                                        Inactive
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    <span className="ff-numerals">
                                      {item.numberOfEnrollees}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center gap-2">
                                      <Avatar img={img6} />
                                      <div>
                                        <h6 className="mb-0">
                                          {item.plan.planName}
                                        </h6>
                                        <span className="fs-xs text-secondary">
                                          {item.plan.planType}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="ff-numerals">
                                      {item.familySize != null
                                        ? item.familySize
                                        : "N/A"}
                                    </span>
                                  </td>
                                  <td>
                                    {item.effectiveDate !== null && (
                                      <span className="fs-xs text-secondary">
                                        {new Intl.DateTimeFormat("en-US", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        }).format(new Date(item.effectiveDate))}
                                      </span>
                                    )}
                                    {item.effectiveDate == null && (
                                      <span className="fs-xs text-secondary">
                                        Awaiting Onboarding
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {item.terminationDate !== null && (
                                      <span className="fs-xs text-secondary">
                                        {new Intl.DateTimeFormat("en-US", {
                                          day: "numeric",
                                          month: "long",
                                          year: "numeric",
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: true,
                                        }).format(
                                          new Date(item.terminationDate)
                                        )}
                                      </span>
                                    )}
                                    {item.terminationDate == null && (
                                      <span className="fs-xs text-secondary">
                                        Awaiting Onboarding
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-flex justify-content-end">
                                      <Link to="" className="link-more">
                                        <i className="ri-more-2-fill"></i>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Tab.Pane>

              <Tab.Pane eventKey="payment-history">
                <Col xs="12">
                  <Card className="card-one">
                    <Card.Header>
                      <Card.Title as="h6">Payment History</Card.Title>
                      <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link href="">
                          <i className="ri-refresh-line"></i>
                        </Nav.Link>
                        <Nav.Link href="">
                          <i className="ri-more-2-fill"></i>
                        </Nav.Link>
                      </Nav>
                    </Card.Header>
                    <Card.Body>
                      {paymentHistory.length > 0 && (
                        <Table
                          className="table-payment-history mb-0"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>Status</th>

                              <th>Amount</th>

                              <th>Transaction Reference</th>
                              <th>Date Initiated</th>
                            </tr>
                          </thead>
                          <tbody>
                            {paymentHistory.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <span
                                    className={
                                      item.transactionStatus == "SUCCESS"
                                        ? "badge bg-success"
                                        : "badge bg-primary"
                                    }
                                  >
                                    {item.transactionStatus}
                                  </span>
                                </td>

                                <td>
                                  <div>
                                    <h6 className="mb-0">{item.amount}</h6>
                                  </div>
                                </td>
                                <td>
                                  <span className="ff-numerals">
                                    {item.transactitionReference != null
                                      ? item.transactitionReference
                                      : "N/A"}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-xs text-secondary">
                                    {item.dateInitiated}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Tab.Pane>
              <Tab.Pane eventKey="profile-settings">
                <CorporateSettings />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
