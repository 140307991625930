import React from "react";
import { Button, Card, Col, Form, Row, Image, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import favicon from "../assets/img/metro health hmo final.png";
import { useState } from "react";
import { RegistrationService } from "../service/RegistrationService";
import { useNavigate } from "react-router-dom";
import bg1 from "../assets/img/metrobackground.jpeg";
import { AuthService } from "../service/AuthService";
import Swal from "sweetalert2";

export default function RetailRegistrationPage() {
  const navigate = useNavigate;
  const [step, setStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVLoading, setIsVLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  // Define error message mapping
  const errorMessages = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Please provide a valid email address",
    phoneNumber: "A Valid Phone Number is required",
    password:
      " A Valid Password is required (between 6 to 20 alphanumeric characters)",
    confirmPassword: "Passwords do not match",
  };

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  // Validation functions for the current step
  const validateStep = () => {
    const errors = {};

    if (step === 0) {
      if (!formData.firstName.trim()) {
        errors.firstName = "First Name is required";
      }

      if (!formData.lastName.trim()) {
        errors.lastName = "Last Name is required";
      }

      if (!formData.email.trim()) {
        errors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        errors.email = "Invalid email format";
      }

      if (!formData.phoneNumber.trim()) {
        errors.phoneNumber = "Phone Number is required";
      }
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber = "Phone Number should only be digits";
      }
      if (formData.phoneNumber.trim().length < 11) {
        errors.phoneNumber = "Phone Number length must be more than 11 digits";
      }
    } 
    
    else if (step === 1) {
      if (!formData.password.trim()) {
        errors.password = "Password is required";
      }

      if (!isValidPassword(formData.password.trim())) {
        errors.password = "Password is required";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Regular expression for a basic email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Test the email against the regular expression
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match only numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test if the phoneNumber contains only numeric characters
    return numericRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    // Check if the password length is between 6 and 20 characters and does not contain spaces
    return password.length >= 6 && password.length <= 20 && !/\s/.test(password);
  };

  const handleChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };

  const handlePrev = () => {
    prevStep();
  };

  const handleNext = () => {
    setShowError(true);
    if (validateStep()) {
      setStep(step + 1);

      return true;
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleRegister = (e) => {
    e.preventDefault();

    if (step === 1 && !validateStep()) {
      return; // Stop registration if there are errors in step 1
    }

    setIsLoading(true);

    // if (handleNext()) {
      RegistrationService.registerIndividual(formData)
        .then((response) => {
          //Navigate to the Dashboard page & set context to true
          if (response.status === 201) {

            setIsLoading(false);
            handleNext();
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);

          showAlert(
            "Error",
            error.response.data.result,
            "error"
          );
        });
    // }
  };

  const handleVerification = (e) => {

   
    setIsLoading(true);
    RegistrationService.verifyOtp(formData, otp)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          showAlert(
            "Verification Successful",
            "Use the button below to login",
            "success"
          );



          setIsLoading(false);
        }
      })
      .catch((error) => {
        showAlert("Verification Failed", error.response.data.result, "error");
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleResend = (e) => {

    setIsVLoading(true);
    AuthService.resendOTP(formData.email)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
       

          showAlert(
            "Sent Successfully",
            "Check your email",
            "info"
          );

          setIsVLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsVLoading(false);
      });
  };

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showConfirmButton:icon !== "success",
      showCancelButton: icon === "success", // Show the "Login" button only if the icon is "success"
      confirmButtonText: "OK",
      cancelButtonText: "Login", // Label for the login button
      allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle the "OK" button click (if needed)
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle the "Login" button click (if the icon is "success")
        if (icon === "success") {
          // Redirect the user to the login page or perform the login action here
          window.location.href = "/login"; // Change the URL as needed
        }
      }
    });
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="6" xl="7" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Image
                src={favicon}
                fluid={true}
                width="250px"
                alt="..."
                className="mx-auto d-block text-center"
                style={{ marginBottom: "30px", alignContent: "" }}
              />

              {/* <Card.Title>Sign Up</Card.Title> */}
              <Card.Text className="mx-auto d-block text-center">
                It's easy to signup and only takes a minute.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              {step == 0 && (
                <div>
                  <div className="mb-3">
                    <Row>
                      <Col>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First name"
                          aria-label="First name"
                          value={formData.firstName} // Use the value from formData
                          onChange={(event) =>
                            handleChange("firstName", event.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last name"
                          aria-label="Last name"
                          value={formData.lastName} // Use the value from formData
                          onChange={(event) =>
                            handleChange("lastName", event.target.value)
                          }
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your email address"
                      value={formData.email} // Use the value from formData
                      onChange={(event) =>
                        handleChange("email", event.target.value)
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your phone number"
                      value={formData.phoneNumber} // Use the value from formData
                      onChange={(event) =>
                        handleChange("phoneNumber", event.target.value)
                      }
                    />
                  </div>

                  <Button
                    onClick={handleNext}
                    variant="primary"
                    className="btn-sign"
                  >
                    Proceed
                  </Button>
                </div>
              )}
              {step == 1 && (
                <div>
                  <div className="mb-3">
                    <Form.Label>Password</Form.Label>
                     <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                  <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter your password"
                      value={formData.password} // Use the value from formData
                      onChange={(event) =>
                        handleChange("password", event.target.value)   }
                  />
                  <Form.Check
                      type="switch"
                      checked={showPassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                      }}
                      onChange={togglePasswordVisibility}
                  />
              </div>
                  </div>
                  <div className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm your password"
                      value={formData.confirmPassword} // Use the value from formData
                      onChange={(event) =>
                        handleChange("confirmPassword", event.target.value)
                      }
                    />
                  </div>
                  <Row className="gx-4">
                    <Col>
                      <Button onClick={handlePrev} variant="secondary">
                        Back
                      </Button>
                    </Col>
                    <Col>
                     
                      {!isLoading && (
                        <Button onClick={handleRegister} variant="primary">
                        Proceed
                      </Button>
                  )}
                  {isLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                    </Col>

                  </Row>
                </div>
              )}
              {step == 2 && (
                <div className="text-center">
                  {" "}
                  {/* Center the OTP VERIFICATION */}
                  <div className="mb-3">
                    <Form.Label>OTP VERIFICATION</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the OTP sent to your email"
                      onChange={(event) => setOtp(event.target.value)}
                    />
                  </div>
                  <Row className="gx-4">
                    <Col>
                      <Button
                        onClick={handlePrev}
                        variant="secondary"
                        size="sm"
                      >
                        Change my Email
                      </Button>
                    </Col>
                    <Col>
                     
                      {!isVLoading && (
                        <Button onClick={handleResend} variant="info" size="sm">
                        RESEND OTP
                      </Button>
                  )}
                  {isVLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                    </Col>
                  </Row>
                  <br />
                  
                  {!isLoading && (
                    <Button
                    onClick={handleVerification}
                    variant="primary"
                    className="btn-sign"
                  >
                    Verify OTP
                  </Button>
                  )}
                  {isLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                </div>
              )}
              <br />

              {showError && (
                <div>
                  {Object.keys(formErrors).map((field) => (
                    <Alert
                      variant="danger"
                      className="alert-outline d-flex align-items-center "
                    >
                      <i className="ri-information-line"></i>
                      <div key={field}>
                        {formErrors[field] && errorMessages[field]}
                      </div>
                    </Alert>
                  ))}
                </div>
              )}
            </Card.Body>

            <Card.Footer>
              Already have an account? <Link to="/login">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          {/* <img src={bg1} className="auth-img" alt="" /> */}
          <Image
            src={'https://res.cloudinary.com/dpjni3o5l/image/upload/v1699864341/Metrohealth_akcziu.jpg'}
            fluid={true}
            // width="250px"
            alt="..."
            className="auth-img"
            // style={{ marginBottom: "30px",alignContent:"" }}
          ></Image>

          
        </Col>
      </Row>
    </div>
  );
}
