import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner, Image,Alert} from "react-bootstrap";
import { Link } from "react-router-dom";
import { AuthService } from "../service/AuthService";
import favicon from "../assets/img/metro health hmo final.png";

import Swal from "sweetalert2";


export default function CreatePassword() {
  const [verificationToken, setVerificationToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const errorMessages = {

    password:
      " A Valid Password is required (between 6 to 20 alphanumeric characters)",
    confirmPassword: "Passwords do not match",
  };


  const validateStep = () => {
    const errors = {};
    setFormErrors({});

      if (!newPassword.trim()) {
        errors.password = "Password is required";
      }

      if (!isValidPassword(newPassword.trim())) {
        errors.password = "Password is required";
      }

      if (confirmPassword !== newPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const isValidPassword = (password) => {
    // Check if the password length is between 6 and 20 characters and does not contain spaces
    return password.length >= 6 && password.length <= 20 && !/\s/.test(password);
  };

  useEffect(() => {
    // Define a regular expression to match the 'token' parameter in the URL
    const tokenRegex = /token=([^&]+)/;

    // Get the current URL from the browser
    const currentUrl = window.location.href;

    // Use the regular expression to extract the token from the URL
    const tokenMatch = currentUrl.match(tokenRegex);

    // Check if a token was found and extract it from the match
    const token = tokenMatch ? tokenMatch[1] : null;

    // Do something with the token (e.g., assign it to a variable)
    if (token) {
      setVerificationToken(token);
    }
  }, []);

  const passwordDto = {
    newPassword,
    confirmPassword,
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateStep()) {
        setShowError(true);
        return; // Stop registration if there are errors in step 1
      }

    setIsLoading(true);

    AuthService.resetPassword(passwordDto, verificationToken)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 201) {
          Swal.fire(
            "Successful",
            "A reset link has been sent to your mail",
            "success"
          ).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/login";
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
       
        if (error.response && error.response.status === 500) {
            // Handle 500 status code (expired link) separately
            Swal.fire("Link Expired", "The reset link has expired", "error").then((result) => {
              if (result.isConfirmed) {
                // Redirect or take necessary action for an expired link
                window.location.href = "/forgot-password"; // Redirect to the forgot password page, for example
              }
            });
          } else {
            // For other errors, display the default error message
            Swal.fire("Action failed", error.response.data.result, "error");
          }
      });
  };

  return (
    <div className="page-sign">
 
      <Card className="card-sign">
      <Card.Header>
        <Image
                src={favicon}
                fluid={true}
                width="250px"
                alt="..."
                className="mx-auto d-block text-center"
                style={{ marginBottom: "30px",alignContent:"" }}
              />
          <Card.Title className="mx-auto d-block text-center">Create your Password</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form method="get" action="">
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                New Password{" "}
              </Form.Label>
             

<div className="d-flex align-items-center" style={{ position: 'relative' }}>
                  <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      onChange={(event) => setNewPassword(event.target.value)}
                placeholder="Enter your new password"
                  />
                  <Form.Check
                      type="switch"
                      checked={showPassword}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                      }}
                      onChange={togglePasswordVisibility}
                  />
              </div>
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">
                Confirm Password{" "}
              </Form.Label>
              <Form.Control
                onChange={(event) => setConfirmPassword(event.target.value)}
                type="password"
                placeholder="Enter your password again"
              />
            </div>

            {!isLoading && (
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="primary"
                className="btn-sign"
              >
                Create Password
              </Button>
            )}
            {isLoading && (
              <Button className="btn-sign">
                Processing...
                <span />
                <Spinner size="sm" animation="border" />
              </Button>
            )}
          </Form>

          <br />

{showError && (
  <div>
    {Object.keys(formErrors).map((field) => (
      <Alert
        variant="danger"
        className="alert-outline d-flex align-items-center "
      >
        <i className="ri-information-line"></i>
        <div key={field}>
          {formErrors[field] && errorMessages[field]}
        </div>
      </Alert>
    ))}
  </div>
)}
        </Card.Body>

        <Card.Footer>
          Is your link expired?{" "}
          <Link to="/forgot-password">Resend Reset Link</Link>
        </Card.Footer>
      </Card>
    </div>


  );
}
