import axios from "axios";
import jwtDecode from "jwt-decode";

const UPLOAD_FORM = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/business/upload/form";
const INITIALIZE_ONBOARDING =
  "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/onboarding/initialize/?token=";
const SUBMIT_ONBOARDING = "https://metrohealthwebapp-urvr65j6tq-uc.a.run.app/onboarding/submit/?token=";

class onboardingService {
    // Check if the token is expired
  isTokenExpired(token) {
       if (token === null) {
      return true; 
  }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    return decoded.exp < currentTime;
  }

  handleTokenExpiration(token) {
    if (this.isTokenExpired(token)) {
      // Handle token expiration on the client side, e.g., redirect to a login page
      window.location.href = "/login/popup";
      return Promise.reject("Token expired");
    }
    return Promise.resolve();
  }

  uploadViaFOrm(token, request) {
    return this.handleTokenExpiration(token).then(() => {
    return axios.post(UPLOAD_FORM, request, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  });
  }
  initializeOnboarding(tokenString) {
    return axios.get(INITIALIZE_ONBOARDING + tokenString, {
      headers: {
        "Content-Type": "application/json"
    },
    });
  }

  submitOnboardingForm(request, token) {
    return axios.post(SUBMIT_ONBOARDING + token, request, {
      headers: {
        "Content-Type": "application/json"
    },
    });
  }
}

export const OnboardingService = new onboardingService();
