import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Image,
  Alert,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import favicon from "../assets/img/metro health hmo final.png";
import { useState, useEffect } from "react";
import { RegistrationService } from "../service/RegistrationService";
import { useNavigate } from "react-router-dom";
import bg1 from "../assets/img/metrobackground.jpeg";
import { AuthService } from "../service/AuthService";
import Swal from "sweetalert2";
import { statesWithLgas } from "../data/StatesAndCities";
import { providerDataMaxi } from "../data/ProviderSample";
import UploadWidget from "../components/utils/UploadWidget";
import ProviderSelection from "../components/ProviderSelection";
import Select from "react-select";
import { OnboardingService } from "../service/OnboardingService";

export default function Onboarding() {
  const navigate = useNavigate;
  const [step, setStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [url, updateUrl] = useState();
  const [error, updateError] = useState();
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [onboardingResponse, setOnboardingResponse] = useState(null);
  const [beginOnboarding, setBeginOnboarding] = useState(false);
  const [token, setToken] = useState("");
 

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    providerId: "",
    planId: "",
    groupName: "",
    division: "",
    staffId: "",
    state: "",
    homeAddress: "",
    lga: "",
    title: "",
    coverageClass: "",
    dateOfBirth: "",
    gender: "",
    maritalStatus: "",
    imageUrl: "",
  });

  // Define error message mapping
  const errorMessages = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Please provide a valid email address",
    phoneNumber: "A Valid Phone Number is required",
    password:
      " A Valid Password is required (between 6 to 20 alphanumeric characters)",
    confirmPassword: "Passwords do not match",
  };

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });


    //  const handleChange = (field, value) => {
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     [field]: value,
    //   }));
    // };

    const handleChange = (field, value) => {
      setFormData((prevFormData) => {
        // Create a copy of the previous state
        const updatedFormData = { ...prevFormData };
        // Update the specific field
        updatedFormData[field] = value;
        // Return the updated state
        return updatedFormData;
      });
    };

  useEffect(() => {
    const tokenRegex = /token=([^&]+)/;

    // Get the current URL from the browser
    const currentUrl = window.location.href;

    // Use the regular expression to extract the token from the URL
    const tokenMatch = currentUrl.match(tokenRegex);

    // Check if a token was found and extract it from the match
    const token = tokenMatch ? tokenMatch[1] : null;

    // Do something with the token (e.g., assign it to a variable)

    console.log(token);

 

    if (token == null) {
      showAlert("Error", "Token is not Present", "error");
    } else {
      OnboardingService.initializeOnboarding(token)
        .then((response) => {
          if (response.status === 200) {
            setOnboardingResponse(response.data.result);
            setToken(token);
            setBeginOnboarding(true);
            console.log(onboardingResponse);

         
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  // Validation functions for the current step
  const validateStep = () => {
    const errors = {};

    if (step === 4) {
      if (!formData.firstName.trim()) {
        errors.firstName = "First Name is required";
      }

      if (!formData.lastName.trim()) {
        errors.lastName = "Last Name is required";
      }

      if (!formData.email.trim()) {
        errors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        errors.email = "Invalid email format";
      }

      if (!formData.phoneNumber.trim()) {
        errors.phoneNumber = "Phone Number is required";
      }
      if (!isValidPhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber = "Phone Number should only be digits";
      }
      if (formData.phoneNumber.trim().length < 11) {
        errors.phoneNumber = "Phone Number length must be more than 11 digits";
      }
    } else if (step === 3) {
      if (!formData.password.trim()) {
        errors.password = "Password is required";
      }

      if (formData.password !== formData.confirmPassword) {
        errors.confirmPassword = "Passwords do not match";
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isValidEmail = (email) => {
    // Regular expression for a basic email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    // Test the email against the regular expression
    return emailRegex.test(email);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // Regular expression to match only numeric characters
    const numericRegex = /^[0-9]+$/;

    // Test if the phoneNumber contains only numeric characters
    return numericRegex.test(phoneNumber);
  };



  const handlePrev = () => {
    prevStep();
  };

  const handleNext = () => {
    setShowError(true);
    if (validateStep()) {
      setStep(step + 1);

      return true;
    }
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleStep = () => {
    setStep(0);
  };

  const handleSubmission = () => {
 
    setIsLoading(true);

    formData.providerId = selectedProvider.ProviderID;
    formData.imageUrl = url;
  
      console.log(formData);


      OnboardingService.submitOnboardingForm(formData, token)
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false);
            showAlert("Succesful", "Kindly check your mail for your HMO ID", "success");
           
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
  
          showAlert("Error", error.response.data.result, "error");
        });
      }; 

  const handleStateChange = (event) => {
    console.log(event);

    // Find the selected state in the statesWithLgas array
    const selectedState = statesWithLgas.find((state) => state.value === event);

    console.log(selectedState);

    // Update the LGAs based on the selected state
    const newLGAs = selectedState ? selectedState.lgas : [];
    const lgaSelect = document.getElementById("lga");
    lgaSelect.innerHTML = ""; // Clear existing options

    // Add default option
    const defaultOption = document.createElement("option");
    defaultOption.value = "";
    defaultOption.text = "- Select -";
    lgaSelect.add(defaultOption);

    // Add LGAs to the select element
    newLGAs.forEach((lga) => {
      const option = document.createElement("option");
      option.value = lga.value;
      option.text = lga.label; // Adjusted to use the 'name' property
      lgaSelect.add(option);
    });
  };

  const showAlert = (title, text, icon) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showConfirmButton: icon !== "success",
      confirmButtonText: "OK",
      allowOutsideClick: false, // Prevent dismissing the alert by clicking outside
    }).then((result) => {
      if (result.isConfirmed) {
        if (icon === "success") {
          // Redirect the user to the login page or perform the login action here
          window.location.href = "https://metrohealthhmo.com"; // Change the URL as needed
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle the "Login" button click (if the icon is "success")
        if (icon === "success") {
          // Redirect the user to the login page or perform the login action here
          window.location.href = "https://metrohealthhmo.com"; // Change the URL as needed
        }
      }
    });
  };

  function handleOnUpload(error, result, widget) {
    if (error) {
      updateError(error);
      widget.close({
        quiet: true,
      });
      return;
    }
    updateUrl(result?.info?.secure_url);
    handleChange("imageUrl", url);
  }

  const providerOptions = providerDataMaxi.map((provider) => ({
    label: provider.ProviderName,
    value: provider.ProviderID, // You can also use provider.ProviderID or any unique identifier
  }));

  const handleChange2 = (selectedOption) => {
    const providerMatch = providerDataMaxi.find(
      (provider) => provider.ProviderID === selectedOption.value
    );
    // Set the selected provider
    setSelectedProvider(providerMatch);

    handleChange("providerId", selectedProvider.ProviderID);
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="6" xl="7" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Image
                src={favicon}
                fluid={true}
                width="250px"
                alt="..."
                className="mx-auto d-block text-center"
                style={{ marginBottom: "30px", alignContent: "" }}
              />
              {/* <Card.Title>Sign Up</Card.Title> */}
              <Card.Text className="mx-auto d-block text-center">
               <h5> Onboarding Form</h5>
              </Card.Text>
        
            </Card.Header>

            {beginOnboarding && (
              <Card.Body>
                {step == 0 && (
                  <Card className="card-group-information">
                    <Card.Body>
                      <div>
                        <div className="mb-3">
                          <Row>
                            <Col>
                              <Form.Label>Group Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={onboardingResponse.groupName}
                                aria-label="Group name"
                                disabled
                              />
                            </Col>
                            <Col>
                              <Form.Label>Division</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={onboardingResponse.division}
                                aria-label="Division"
                                disabled
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Form.Label>Plan</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={onboardingResponse.planName}
                            disabled
                          />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Staff ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your staff identification number"
                            value={formData.staffId} // Use the value from formData
                            onChange={(event) =>
                              handleChange("staffId", event.target.value)
                            }
                          />
                        </div>

                        <Button
                          onClick={handleNext}
                          variant="primary"
                          className="btn-sign"
                        >
                          Proceed
                        </Button>
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                )}

                {step == 1 && (
                  <Card className="card-personal-information">
                    <Card.Body>
                      <div>
                        <div className="mb-3">
                        <Form.Label>Title</Form.Label>
                              <Form.Select
                                onChange={(e) => {
                                  handleChange("title", e.target.value);
                                }}
                              >
                                <option value="">Choose an Option</option>
                                <option value="10">Alhaja</option>
                                <option value="9">Alhaji</option>
                                <option value="26">Barrister</option>
                                <option value="13">Chief</option>
                                <option value="17">Colonel</option>
                                <option value="4">Dr.</option>
                                <option value="21">Elder</option>
                                <option value="22">Engr.</option>
                                <option value="23">Evang.</option>
                                <option value="19">General</option>
                                <option value="27">Justice</option>
                                <option value="11">Lieutenant</option>
                                <option value="24">Madam</option>
                                <option value="18">Major</option>
                                <option value="6">Master</option>
                                <option value="3">Miss</option>
                                <option value="1">Mr</option>
                                <option value="20">Mr/Mrs</option>
                                <option value="5">Mrs</option>
                                <option value="8">Nurse</option>
                                <option value="16">Pastor</option>
                                <option value="15">Prince</option>
                                <option value="14">Princess</option>
                                <option value="7">Prof.</option>
                                <option value="25">Prophet</option>
                                <option value="12">Reverend</option>
                              </Form.Select>
                        </div>
                        <div className="mb-3">
                          <Row>
                            <Col>
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={onboardingResponse.firstName}
                                value={onboardingResponse.firstName}
                                onChange={(event) =>
                                  handleChange("firstName", event.target.value)
                                }
                                disabled
                              />
                            </Col>
                            <Col>
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={onboardingResponse.lastName}
                                value={onboardingResponse.lastName} // Use the value from formData
                                onChange={(event) =>
                                  handleChange("lastName", event.target.value)
                                }
                                disabled
                              />
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col>
                              <Form.Label>Gender</Form.Label>
                              <Form.Select
                                onChange={(e) => {
                                  handleChange("gender", e.target.value);
                                }}
                              >
                                <option value="">Choose an Option</option>
                                <option value="2">Female</option>
                                <option value="1">Male</option>
                              </Form.Select>
                            </Col>
                            <Col>
                              <Form.Label>Marital Status</Form.Label>
                              <Form.Select
                                onChange={(e) => {
                                  handleChange("maritalStatus", e.target.value);
                                }}
                              >
                                <option value="">Choose an Option</option>
                                <option value="3">Divorced</option>
                                <option value="2">Married</option>
                                <option value="1">Single</option>
                                <option value="4">Widowed</option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </div>

                        <div className="mb-3">
                          <Row>
                            <Col>
                              <div>
                                <h6>Your Photo</h6>
                    
                                {url && (
                                  <>
                                    <p>
                                      <img
                                        src={url}
                                        alt="Uploaded"
                                        style={{
                                          width: "50%",
                                          alignContent: "",
                                        }}
                                      />
                                    </p>
                                  </>
                                )}
                              </div>
                              <UploadWidget onUpload={handleOnUpload}>
                                {({ open }) => {
                                  function handleOnClick(e) {
                                    e.preventDefault();
                                    open();
                                  }
                                  return (
                                    <Button
                                      variant={"success"}
                                      onClick={handleOnClick}
                                      className="btn-sign"
                                    >
                                      Upload your photo
                                    </Button>
                                  );
                                }}
                              </UploadWidget>
                              {error && <p>{error}</p>}
                            </Col>
                            <Col>
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                name="dob"
                                type="date"
                                id="dob"
                                class="form-control input-class"
                                onChange={(e) => {
                                  handleChange("dateOfBirth", e.target.value);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>

                          <div className="mb-3">
                          <Form.Label>Coverage Class</Form.Label>
                              <Form.Control
                                type="text"
                                // placeholder={onboardingResponse.coverageClass}
                                value={onboardingResponse.coverageClass}
                                aria-label="Coverage class"
                                disabled
                              />
                        </div>

                        <Row className="gx-4">
                          <Col>
                            <Button onClick={handlePrev} variant="secondary">
                              Back
                            </Button>
                          </Col>
                          <Col>
                            <Button onClick={handleNext} variant="primary">
                              Proceed
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                    <Card.Footer></Card.Footer>
                  </Card>
                )}

                {step == 2 && (
                  <Card className="card-contact-information">
                    <Card.Body>
                      <div className="mb-3">
                        <Form.Label>Home Address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address"
                          aria-label="Address "
                          value={formData.homeAddress} // Use the value from formData
                          onChange={(event) =>
                            handleChange("homeAddress", event.target.value)
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          // placeholder={onboardingResponse.email}
                          value={onboardingResponse.email}
                    
                          disabled
                        />
                      </div>
                      <div className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter your phone number"
                          value={formData.phoneNumber} // Use the value from formData
                          onChange={(event) =>
                            handleChange("phoneNumber", event.target.value)
                          }
                        />
                      </div>
                      <div>
                        <Row>
                          <Col>
                            {" "}
                            <div className="mb-3">
                              <Form.Label>State</Form.Label>
                              <Form.Select
                                onChange={(e) => {
                                  handleChange("state", e.target.value);
                                  handleStateChange(e.target.value);
                                }}
                              >
                                <option value="">Choose an Option</option>
                                {statesWithLgas.map((state) => (
                                  <option key={state.value} value={state.value}>
                                    {state.state}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                              <Form.Label>LGA</Form.Label>
                              <select
                                name="lga"
                                id="lga"
                                className="form-control select-lga"
                                onChange={(e) => {
                                  handleChange("lga", e.target.value);
                                }}
                              ></select>
                            </div>
                          </Col>
                        </Row>

                        <Row className="gx-4">
                          <Col>
                            <Button onClick={handlePrev} variant="secondary">
                              Back
                            </Button>
                          </Col>
                          <Col>
                            <Button onClick={handleNext} variant="primary">
                              Proceed
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                )}

                {step == 3 && (
                  <Card className="card-provider-information">
                    <Card.Body>
                    <div className="mb-3">
                        <Form.Label> Choose a Provider</Form.Label>
                        <Select
                          isSearchable={true}
                          onChange={handleChange2}
                          options={providerOptions}
                          isClearable
                          placeholder="Type to Search ..."
                          style={{ marginBottom: "30px" }}
                        />
                        </div>
                        <div className="mb-3">
                        {selectedProvider && (
                          <div className="mt-3">
                            <p>
                              Provider Name: {selectedProvider.ProviderName}
                            </p>
                            <p>Provider Address: {selectedProvider.address1}</p>
                            <p>Provider ID: {selectedProvider.providerID}</p>
                            {/* Add more details as needed */}
                          </div>
                        )}

                        <Row className="gx-4">
                          <Col>
                            <Button onClick={handlePrev} variant="secondary">
                              Back
                            </Button>
                          </Col>
                          <Col>
                            {!isLoading && (
                              <Button
                                onClick={handleSubmission}
                                variant="primary"
                                className="btn-sign"
                              >
                                Submit Form
                              </Button>
                            )}
                            {isLoading && (
                              <Button className="btn-sign">
                                Processing...
                                <span />
                                <Spinner size="sm" animation="border" />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                )}

                <br />

                {showError && (
                  <div>
                    {Object.keys(formErrors).map((field) => (
                      <Alert
                        variant="danger"
                        className="alert-outline d-flex align-items-center "
                      >
                        <i className="ri-information-line"></i>
                        <div key={field}>
                          {formErrors[field] && errorMessages[field]}
                        </div>
                      </Alert>
                    ))}
                  </div>
                )}
              </Card.Body>
            )}
            {!beginOnboarding && (
              <Button className="btn-sign">
                Processing...
                <span />
                <Spinner size="sm" animation="border" />
              </Button>
            )}
            <Card.Footer>
             
             
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          {/* <img src={bg1} className="auth-img" alt="" /> */}
          <Image
            src={
              "https://res.cloudinary.com/dpjni3o5l/image/upload/v1699864341/Metrohealth_akcziu.jpg"
            }
            fluid={true}
            // width="250px"
            alt="..."
            className="auth-img"
            // style={{ marginBottom: "30px",alignContent:"" }}
          ></Image>
        </Col>
      </Row>
    </div>
  );
}
