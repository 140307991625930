import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import {AuthService} from "../service/AuthService";
import SigninModal from "../components/SigninModal";



const LogoutAndRedirect = () => {
    const navigate = useNavigate();
    const [isAuthenticated,setIsAuthenticated] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
            if (!token) {

                console.log("no token found")

// Get the pathname
                const pathname = window.location.pathname;
                handleLogout();
            
        }

            else {
            const isTokenExpired = () => {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp) {
                    const expirationTime = decodedToken.exp * 1000; // Multiply by 1000 to convert seconds to milliseconds
                    const currentTime = new Date().getTime();
                    return currentTime > expirationTime;
                }
                return true; // If the token doesn't have an expiration time, consider it as expired
            };

            if (isTokenExpired()) {
                handleLogout();
            }
        }
        }, []);

    const handleLogout = () => {

    
        // Clear the stored JWT token
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
   //     Get the pathname
        const pathname = window.location.pathname;

// Combine them all if needed, including query parameters
        let intendedUrl = pathname;
        console.log(intendedUrl)
        setIsAuthenticated(false);
    };

    return(
        <React.Fragment>
            {!isAuthenticated && (
                <SigninModal/>
            )}
        </React.Fragment>
    )// You can return null or an empty fragment since this component doesn't render anything
};

export default LogoutAndRedirect;
